import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import React from 'react';

const InternalModal = ({ openDialog, setOpenDialog, currentTipo, handleChangeInput, handleSubmit, handleUpdate }) => {
    return (
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
            <DialogTitle>{currentTipo?.id ? 'Editando Grupo' : 'Criando Grupo'}</DialogTitle>
            <DialogContent>
                <TextField value={currentTipo?.descricao} onChange={(e) => handleChangeInput(e.target.value)} />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={(e) => (currentTipo?.id ? handleUpdate(e) : handleSubmit(e))}>
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default InternalModal;
