import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { useState, useEffect } from 'react';

const FirstLoginForm = () => {
    return (
        <Grid>
            <Box>teste</Box>
        </Grid>
    );
};

export default FirstLoginForm;
