import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, TextField, Autocomplete, TablePagination } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { HeaderBox, TableBox, MainBox } from './style';
import ExamesTabela from 'components/Laboratório/Exames/Tabela';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';

const Exames = () => {
    // ============================= INSTÂNCIAS =============================
    const api = useApi();
    // ============================= STATES =============================
    const [allExames, setAllExames] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedExame, setSelectedExame] = useState(null);

    // ============================= FUNÇÕES =============================
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getExames = async () => {
        const response = await api.getAllExames();
        const filtroSemExamesEspeciais = response.data.filter((exame) => !exame.especial);
        setAllExames(filtroSemExamesEspeciais);
    };

    useEffect(() => {
        getExames();
    }, []);

    return (
        <MainCard
            title="EXAMES"
            sx={{
                height: '80vh'
            }}
        >
            <Grid container spacing={gridSpacing}>
                <MainBox>
                    <HeaderBox>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={allExames}
                            getOptionLabel={(exame) => exame.nome}
                            value={selectedExame}
                            onChange={(event, newValue) => {
                                setSelectedExame(newValue);
                            }}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} label="Pesquisar Exame" />}
                        />
                        <Link to={'/main/laboratorio/exame/novo'}>
                            <Button variant="contained">Novo Exame</Button>
                        </Link>
                    </HeaderBox>

                    <TableBox>
                        <ExamesTabela
                            allExames={allExames}
                            setAllExames={setAllExames}
                            selectedExame={selectedExame}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            getExames={getExames}
                        />
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={allExames.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableBox>
                </MainBox>
            </Grid>
        </MainCard>
    );
};

export default Exames;
