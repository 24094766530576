import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    InputLabel,
    OutlinedInput,
    Select,
    MenuItem,
    Stepper,
    Step,
    StepLabel,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Switch,
    FormControlLabel,
    Typography
} from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import useScriptRef from 'hooks/useScriptRef';
import InputMask from 'react-input-mask';
import { useNavigate } from 'react-router';
import { useApi } from 'Service/axios';
import { FormBox } from '../../RegisterForms/UserRegisterForm/style';
import { removeMask } from 'utils/removeMask';
import { toast } from 'react-toastify';

const NovoProntuario = ({ ...others }) => {
    // ---------- INSTÂNCIAS ----------
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const api = useApi();
    const navigate = useNavigate();
    // ---------- STATES ----------
    const maxEtapa = 3;
    const [etapa, setEtapa] = useState(0);
    const [open, setOpen] = useState(false);
    const [openAutismo, setOpenAutismo] = useState(false);
    const [loading, setLoading] = useState(false);
    const [checkInfo, setCheckInfo] = useState(true);
    const [pacienteId, setPacienteId] = useState(null);
    const [convenios, setConvenios] = useState([]);
    const [switchCheck, setSwitchCheck] = useState(false);
    const [payloadValues, setPayloadValues] = useState();
    const naturalidade = [
        { id: 1, value: 'Brasileira', label: 'Brasileira' },
        { id: 2, value: 'Outra', label: 'Outra' }
    ];
    // ---------- FUNÇÕES ----------
    const getConvenio = async () => {
        const response = await api.getAllConvenio();
        setConvenios(response.data);
    };

    const handleEtapaAnterior = () => {
        setEtapa((prevEtapa) => prevEtapa - 1);
    };

    // removar mascara do rg pra tirar o _ do final
    const removeMask2 = (value) => {
        return value.replace(/_/g, '');
    };

    const handleCepChange = async (event, changeValue) => {
        const cep = removeMask(event.target.value);

        if (cep.length === 8) {
            try {
                const cepData = await api.getCepLocation(cep);
                if (cepData) {
                    const { ibge, bairro, localidade, logradouro, uf } = cepData?.data;
                    changeValue('ibge', ibge);
                    changeValue('bairro', bairro);
                    changeValue('cidade', localidade);
                    changeValue('endereco', logradouro);
                    changeValue('estado', uf);
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleProximaEtapa = async (values) => {
        switch (true) {
            case etapa === 0:
                if (values.nome === '') {
                    toast.error('O nome do paciente é obrigatório');
                    return;
                } else if (values.sexo === '') {
                    toast.error('O sexo do paciente é obrigatório');
                    return;
                }
                setEtapa((prevEtapa) => prevEtapa + 1);
                break;
            case etapa === 1:
                if (checkInfo) {
                    if (values.cartao_sus === '') {
                        toast.error('O nome cartão do SUS é obrigatório');
                        return;
                    }
                    const responseNome = await api.getPacienteByNome(values.nome.toUpperCase());
                    const responseSUS = await api.getPacienteBySUS(removeMask(String(values.cartao_sus)));
                    if (responseNome.data.status && responseSUS.data.status) {
                        setPacienteId(responseSUS.data.pacienteId);
                        setOpen(true);
                        return;
                    }
                    setEtapa((prevEtapa) => prevEtapa + 1);
                    break;
                }
            default:
                setEtapa((prevEtapa) => prevEtapa + 1);
                break;
        }
    };

    const handleVerifyValues = async (values) => {
        values.cpf = removeMask(values.cpf);
        values.rg !== '' ? removeMask2(values.rg) : null;
        //values.cpf = removeMask(values.cpf);
        values.cartao_sus = removeMask(values.cartao_sus);
        values.cep !== '' ? removeMask(values.cep) : null;
        values.telefone !== '' ? removeMask(values.telefone) : null;
        values.telefone_secundario !== '' ? removeMask(values.telefone_secundario) : null;
        values.ibge !== '' ? removeMask(values.ibge) : null;
        if (values.nome_social === '') values.nome_social = values.nome;
    };

    // SUBMIT
    const handleRegister = async (values) => {
        try {
            setLoading(true);
            await api.createPaciente(values);
            toast.success('Paciente cadastrado com sucesso!');
            navigate('/main/lobby');
        } catch (error) {
            setLoading(false);
            toast.error(`Erro: ${error.message}`);
        }
    };

    useEffect(() => {
        getConvenio();
    }, []);

    return (
        <Box sx={{ width: '100%' }}>
            <Dialog open={open}>
                <DialogTitle>Atenção</DialogTitle>
                <DialogContent>
                    Já existe um paciente com este nome e cartão do sus cadastrado, deseja ir para o cadastro já existente?
                </DialogContent>
                <DialogActions>
                    <Button
                        color="error"
                        onClick={() => {
                            setOpen(false), setCheckInfo(false);
                        }}
                    >
                        FECHAR
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            navigate(`/main/editar_prontuario/${pacienteId}`), setEtapa((prevEtapa) => prevEtapa + 1);
                        }}
                    >
                        CONTINUAR
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openAutismo}>
                <DialogTitle>Atenção</DialogTitle>
                <DialogContent>Paciente registrado como autista.</DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={() => {
                            setOpenAutismo(false);
                        }}
                    >
                        CONTINUAR
                    </Button>
                </DialogActions>
            </Dialog>
            <Formik
                initialValues={{
                    autismo: false,
                    nome: '',
                    nome_social: '',
                    cpf: '',
                    sexo: '',
                    cor: '',
                    estado_civil: '',
                    data_nascimento: '',
                    rg: '',
                    nome_mae: '',
                    nome_pai: '',
                    cartao_sus: '',
                    convenio: 'SUS',
                    numero_carterinha: '',
                    naturalidade: 'Brasileira',
                    cep: '',
                    tipo_logradouro: '',
                    endereco: '',
                    numero: '',
                    bairro: '',
                    cidade: '',
                    ibge: '',
                    estado: '',
                    telefone: '',
                    telefone_secundario: '',
                    obito: false,
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    nome: Yup.string().min(3).required('Nome é obrigatorio'),
                    // nome_social: Yup.string().min(3).required('Nome Social é obrigatorio'),
                    sexo: Yup.string().required('Sexo é obrigatorio'),
                    // cor: Yup.string().min(3).required('Cor é obrigatorio'),
                    // data_nascimento: Yup.string().min(8).required('Data de Nascimento é obrigatorio'),
                    // cpf: Yup.string().min(11).required('CPF é obrigatório'),
                    // rg: Yup.string().max(9).required('RG é obrigatório'),
                    // nome_mae: Yup.string().min(3).required('Nome da Mãe é obrigatório'),
                    cartao_sus: Yup.string().min(15).required('Cartão do SUS é obrigatório')
                    // cep: Yup.string().required('CEP é obrigatório'),
                    // tipo_logradouro: Yup.string().required('Tipo Logradouro é obrigatório'),
                    // endereco: Yup.string().required('Endereço é obrigatório'),
                    // numero: Yup.string().min(1).required('Número é obrigatório'),
                    // bairro: Yup.string().required('Bairro é obrigatório'),
                    // cidade: Yup.string().required('Cidade é obrigatório'),
                    // ibge: Yup.string().required('IBGE é obrigatório'),
                    // estado: Yup.string().required('estado é obrigatório'),
                    // telefone: Yup.string().required('telefone é obrigatório')
                })}
                onSubmit={async (values, { setFieldValue, setErrors, setStatus, setSubmitting }) => {
                    let payload = { ...values, autismo: switchCheck };
                    try {
                        if (scriptedRef.current) {
                            setStatus({ success: true });
                            setSubmitting(false);
                        }
                        setFieldValue('autismo', switchCheck);
                        await handleVerifyValues(payload);
                        await handleRegister(payload);
                    } catch (err) {
                        console.error(err);
                        if (scriptedRef.current) {
                            setStatus({ success: false });
                            setErrors({ submit: err.msg });
                            setSubmitting(false);
                        }
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                    <form noValidate onSubmit={handleSubmit} {...others} style={{ display: 'flex', flexDirection: 'column' }}>
                        <Stepper activeStep={etapa} alternativeLabel>
                            <Step>
                                <StepLabel>Informações Pessoais</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel>Documentos</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel>Endereço</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel>Outros</StepLabel>
                            </Step>
                        </Stepper>
                        {/* DADOS PESSOAIS */}
                        {etapa === 0 && (
                            <>
                                {/* NOME / NOME SOCIAL */}
                                <FormBox>
                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.nome && errors.nome)}
                                        sx={{ ...theme.typography.customInput }}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-nome-register">Nome</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-nome-register"
                                            type="text"
                                            value={values.nome}
                                            name="nome"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                        />
                                        {touched.nome && errors.nome && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.nome}
                                            </FormHelperText>
                                        )}
                                    </FormControl>

                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.nome_social && errors.nome_social)}
                                        sx={{ ...theme.typography.customInput }}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-nome_social-register">Nome Social</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-nome_social-register"
                                            type="text"
                                            value={values.nome_social}
                                            name="nome_social"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                        />
                                        {touched.nome_social && errors.nome_social && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.nome_social}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </FormBox>

                                {/* NATURALIDADE / TELEFONE */}
                                <FormBox>
                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.naturalidade && errors.naturalidade)}
                                        sx={{ ...theme.typography.customInput }}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-naturalidade-register">Naturalidade</InputLabel>
                                        <Select
                                            style={{ height: '60px', padding: '20px 0px 0px 0px' }}
                                            label="Naturalidade"
                                            id="outlined-adornment-naturalidade-register"
                                            value={values.naturalidade}
                                            name="naturalidade"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        >
                                            {naturalidade.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {touched.naturalidade && errors.naturalidade && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.naturalidade}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.telefone && errors.telefone)}
                                        sx={{ ...theme.typography.customInput }}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-telefone-register">Celular</InputLabel>
                                        <InputMask
                                            mask="(99) 99999-9999"
                                            value={values.telefone}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        >
                                            {(inputProps) => (
                                                <OutlinedInput
                                                    id="outlined-adornment-telefone-register"
                                                    type="text"
                                                    name="telefone"
                                                    inputProps={inputProps}
                                                />
                                            )}
                                        </InputMask>
                                        {touched.telefone && errors.telefone && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.telefone}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.telefone_secundario && errors.telefone_secundario)}
                                        sx={{ ...theme.typography.customInput }}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-telefone_secundario-register">
                                            Telefone Secundário
                                        </InputLabel>
                                        <InputMask
                                            mask="(99) 99999-9999"
                                            value={values.telefone_secundario}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        >
                                            {(inputProps) => (
                                                <OutlinedInput
                                                    id="outlined-adornment-telefone_secundario-register"
                                                    type="text"
                                                    name="telefone_secundario"
                                                    inputProps={inputProps}
                                                />
                                            )}
                                        </InputMask>
                                        {touched.telefone_secundario && errors.telefone_secundario && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.telefone_secundario}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </FormBox>

                                {/* SEXO / COR */}
                                <FormBox>
                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.sexo && errors.sexo)}
                                        sx={{ ...theme.typography.customInput }}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-sexo-register">Sexo</InputLabel>
                                        <Select
                                            style={{ height: '60px', padding: '20px 0px 0px 0px' }}
                                            id="outlined-adornment-sexo-register"
                                            type="text"
                                            value={values.sexo}
                                            name="sexo"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                        >
                                            <MenuItem value={'masculino'}>Masculino</MenuItem>
                                            <MenuItem value={'feminino'}>Feminino</MenuItem>
                                            <MenuItem value={'outros'}>Outros</MenuItem>
                                        </Select>
                                        {touched.sexo && errors.sexo && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.sexo}
                                            </FormHelperText>
                                        )}
                                    </FormControl>

                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.cor && errors.cor)}
                                        sx={{ ...theme.typography.customInput }}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-cor-register">Cor</InputLabel>
                                        <Select
                                            style={{ height: '60px', padding: '20px 0px 0px 0px' }}
                                            id="outlined-adornment-cor-register"
                                            type="text"
                                            value={values.cor}
                                            name="cor"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                        >
                                            <MenuItem value={'amarela'}>Amarela</MenuItem>
                                            <MenuItem value={'branca'}>Branca</MenuItem>
                                            <MenuItem value={'preta'}>Preta</MenuItem>
                                            <MenuItem value={'parda'}>Parda</MenuItem>
                                            <MenuItem value={'indigena'}>Indígena</MenuItem>
                                        </Select>
                                        {touched.cor && errors.cor && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.cor}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </FormBox>

                                {/* ESTADO CIVIL / DATA NASCIMENTO */}
                                <FormBox>
                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.estado_civil && errors.estado_civil)}
                                        sx={{ ...theme.typography.customInput }}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-estado_civil-register">Estado Civil</InputLabel>
                                        <Select
                                            style={{ height: '60px', padding: '20px 0px 0px 0px' }}
                                            id="outlined-adornment-estado_civil-register"
                                            type="text"
                                            value={values.estado_civil}
                                            name="estado_civil"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                        >
                                            <MenuItem value={'solteiro'}>Solteiro(a)</MenuItem>
                                            <MenuItem value={'casado'}>Casado(a)</MenuItem>
                                            <MenuItem value={'viuvo'}>Viuvo(a)</MenuItem>
                                            <MenuItem value={'divorciado'}>Divorciado(a)</MenuItem>
                                            <MenuItem value={'desquitado'}>Desquitado(a)</MenuItem>
                                            <MenuItem value={'separado'}>Separado(a)</MenuItem>
                                            <MenuItem value={'companheiro'}>Companheiro(a)</MenuItem>
                                        </Select>
                                        {touched.estado_civil && errors.estado_civil && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.estado_civil}
                                            </FormHelperText>
                                        )}
                                    </FormControl>

                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.data_nascimento && errors.data_nascimento)}
                                        sx={{ ...theme.typography.customInput }}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-data_nascimento-register">Data Nascimento</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-data_nascimento-register"
                                            type="date"
                                            value={values.data_nascimento}
                                            name="data_nascimento"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                        />
                                        {touched.data_nascimento && errors.data_nascimento && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.data_nascimento}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </FormBox>

                                {/* NOME MAE / NOME PAI */}
                                <FormBox>
                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.nome_mae && errors.nome_mae)}
                                        sx={{ ...theme.typography.customInput }}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-nome_mae-register">Nome Mãe</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-nome_mae-register"
                                            type="text"
                                            value={values.nome_mae}
                                            name="nome_mae"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                        />
                                        {touched.nome_mae && errors.nome_mae && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.nome_mae}
                                            </FormHelperText>
                                        )}
                                    </FormControl>

                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.nome_pai && errors.nome_pai)}
                                        sx={{ ...theme.typography.customInput }}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-nome_pai-register">Nome pai</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-nome_pai-register"
                                            type="text"
                                            value={values.nome_pai}
                                            name="nome_pai"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                        />
                                        {touched.nome_pai && errors.nome_pai && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.nome_pai}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </FormBox>
                            </>
                        )}
                        {/* DOCUMENTOS */}
                        {etapa === 1 && (
                            <>
                                {/* CPF / RG */}
                                <FormBox>
                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.cpf && errors.cpf)}
                                        sx={{ ...theme.typography.customInput }}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-cpf-register">CPF</InputLabel>
                                        <InputMask mask="999.999.999-99" value={values.cpf} onBlur={handleBlur} onChange={handleChange}>
                                            {(inputProps) => (
                                                <OutlinedInput
                                                    id="outlined-adornment-cpf-register"
                                                    type="text"
                                                    name="cpf"
                                                    inputProps={inputProps}
                                                />
                                            )}
                                        </InputMask>
                                        {touched.cpf && errors.cpf && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.cpf}
                                            </FormHelperText>
                                        )}
                                    </FormControl>

                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.rg && errors.rg)}
                                        sx={{ ...theme.typography.customInput }}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-rg-register">RG</InputLabel>
                                        <InputMask mask="*********" value={values.rg} onBlur={handleBlur} onChange={handleChange}>
                                            {(inputProps) => (
                                                <OutlinedInput
                                                    id="outlined-adornment-rg-register"
                                                    type="text"
                                                    name="rg"
                                                    inputProps={inputProps}
                                                />
                                            )}
                                        </InputMask>
                                        {touched.rg && errors.rg && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.rg}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </FormBox>

                                {/* CARTAO SUS / TELEFONE */}
                                <FormBox>
                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.cartao_sus && errors.cartao_sus)}
                                        sx={{ ...theme.typography.customInput }}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-cartao_sus-register">Cartão SUS</InputLabel>
                                        <InputMask
                                            mask="999-9999-9999-9999"
                                            value={values.cartao_sus}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        >
                                            {(inputProps) => (
                                                <OutlinedInput
                                                    id="outlined-adornment-cartao_sus-register"
                                                    type="text"
                                                    name="cartao_sus"
                                                    inputProps={inputProps}
                                                />
                                            )}
                                        </InputMask>
                                        {touched.cartao_sus && errors.cartao_sus && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.cartao_sus}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.numero_carterinha && errors.numero_carterinha)}
                                        sx={{ ...theme.typography.customInput }}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-numero_carterinha-register">Número Carterinha</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-numero_carterinha-register"
                                            type="text"
                                            value={values.numero_carterinha}
                                            name="numero_carterinha"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                        />
                                        {touched.numero_carterinha && errors.numero_carterinha && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.numero_carterinha}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </FormBox>
                            </>
                        )}
                        {/* ENDEREÇO*/}
                        {etapa === 2 && (
                            <>
                                {/* CEP / IBGE */}
                                <FormBox>
                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.cep && errors.cep)}
                                        sx={{ ...theme.typography.customInput }}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-cep-register">CEP</InputLabel>
                                        <InputMask
                                            mask="99999-999"
                                            value={values.cep}
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                handleCepChange(e, setFieldValue);
                                                handleChange(e);
                                            }}
                                        >
                                            {(inputProps) => (
                                                <OutlinedInput
                                                    id="outlined-adornment-cep-register"
                                                    type="text"
                                                    name="cep"
                                                    inputProps={inputProps}
                                                />
                                            )}
                                        </InputMask>
                                        {touched.cep && errors.cep && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.cep}
                                            </FormHelperText>
                                        )}
                                    </FormControl>

                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.ibge && errors.ibge)}
                                        sx={{ ...theme.typography.customInput }}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-ibge-register">IBGE</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-ibge-register"
                                            type="text"
                                            value={values.ibge}
                                            name="ibge"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                        />
                                        {touched.ibge && errors.ibge && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.ibge}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </FormBox>

                                {/* LOGRADOURO / ENDEREÇO */}
                                <FormBox>
                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.tipo_logradouro && errors.tipo_logradouro)}
                                        sx={{ ...theme.typography.customInput }}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-tipo_logradouro-register">Tipo Logradouro</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-tipo_logradouro-register"
                                            type="text"
                                            value={values.tipo_logradouro}
                                            name="tipo_logradouro"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                        />
                                        {touched.tipo_logradouro && errors.tipo_logradouro && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.tipo_logradouro}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.endereco && errors.endereco)}
                                        sx={{ ...theme.typography.customInput }}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-endereco-register">Endereço</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-endereco-register"
                                            type="text"
                                            value={values.endereco}
                                            name="endereco"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                        />
                                        {touched.endereco && errors.endereco && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.endereco}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </FormBox>

                                {/* NUMERO / BAIRRO */}
                                <FormBox>
                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.numero && errors.numero)}
                                        sx={{ ...theme.typography.customInput }}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-numero-register">Número</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-numero-register"
                                            type="number"
                                            value={values.numero}
                                            name="numero"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                        />
                                        {touched.numero && errors.numero && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.numero}
                                            </FormHelperText>
                                        )}
                                    </FormControl>

                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.bairro && errors.bairro)}
                                        sx={{ ...theme.typography.customInput }}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-bairro-register">Bairro</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-bairro-register"
                                            type="text"
                                            value={values.bairro}
                                            name="bairro"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                        />
                                        {touched.bairro && errors.bairro && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.bairro}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </FormBox>

                                {/* CIDADE / ESTADO */}
                                <FormBox>
                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.cidade && errors.cidade)}
                                        sx={{ ...theme.typography.customInput }}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-cidade-register">Cidade</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-cidade-register"
                                            type="text"
                                            value={values.cidade}
                                            name="cidade"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                        />
                                        {touched.cidade && errors.cidade && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.cidade}
                                            </FormHelperText>
                                        )}
                                    </FormControl>

                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.estado && errors.estado)}
                                        sx={{ ...theme.typography.customInput }}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-estado-register">Estado</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-estado-register"
                                            type="text"
                                            value={values.estado}
                                            name="estado"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                        />
                                        {touched.estado && errors.estado && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.estado}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </FormBox>
                            </>
                        )}
                        {/* ATENDIMENTO*/}
                        {etapa === 3 && (
                            <>
                                {/* CONVENIO*/}
                                <FormBox>
                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.convenio && errors.convenio)}
                                        sx={{ ...theme.typography.customInput }}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-convenio-register">Convênio</InputLabel>
                                        <Select
                                            style={{ height: '60px', padding: '20px 0px 0px 0px' }}
                                            id="outlined-adornment-convenio-register"
                                            type="text"
                                            value={values.convenio}
                                            name="convenio"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                        >
                                            {convenios.length > 0 &&
                                                convenios.map((convenio) => (
                                                    <MenuItem key={convenio.id} value={convenio.id}>
                                                        {convenio.descricao}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                        {touched.convenio && errors.convenio && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.convenio}
                                            </FormHelperText>
                                        )}
                                        <Box sx={{ display: 'flex', width: '100%', marginTop: '5px', gap: '5px' }}>
                                            <Switch
                                                checked={switchCheck}
                                                onChange={() => {
                                                    setSwitchCheck(!switchCheck); /* setFieldValue('autismo', switchCheck) */ // Atualiza o valor de 'autismo' no estado do Formik
                                                }}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                            <Typography sx={{ marginTop: '8px' }}>Autismo</Typography>
                                        </Box>
                                    </FormControl>
                                </FormBox>
                            </>
                        )}

                        {errors.submit && (
                            <Box>
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            </Box>
                        )}

                        {/* BOTÕES */}
                        <Box>
                            {etapa > 0 && (
                                <Button type="button" onClick={handleEtapaAnterior}>
                                    Voltar
                                </Button>
                            )}

                            {etapa <= 2 && (
                                <Button type="button" onClick={() => handleProximaEtapa(values)}>
                                    Próximo
                                </Button>
                            )}
                            {etapa === 3 && (
                                <Button type="submit" disabled={loading}>
                                    Enviar
                                </Button>
                            )}
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    );
};

export default NovoProntuario;
