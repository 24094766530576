import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Button, FormControl, FormHelperText, InputLabel, OutlinedInput } from '@mui/material';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { strengthColor, strengthIndicator } from 'utils/password-strength';
import InputMask from 'react-input-mask';
import { FormBox } from './style';
import { useApi } from 'Service/axios';
import ReactSignatureCanvas from 'react-signature-canvas';
import { useNavigate } from 'react-router';

const MedicoRegisterForm = ({ ...others }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const [url, setUrl] = useState('');
    const [sign, setSign] = useState();
    const [strength, setStrength] = useState(0);
    const [etapa, setEtapa] = useState(0);
    const [level, setLevel] = useState();
    const api = useApi();
    const [isHabilitado, setIsHabilitado] = useState(true);

    const changePassword = (value) => {
        const temp = strengthIndicator(value);
        setStrength(temp);
        setLevel(strengthColor(temp));
    };

    useEffect(() => {
        changePassword('123456');
    }, []);

    const handleRegister = async (values) => {
        if (!values) {
            toast.error('Campos obrigatórios faltando');
            return;
        }

        const formData = new FormData();

        // Adicionando os outros campos
        for (const key in values) {
            if (values[key] !== undefined && values[key] !== null) {
                formData.append(key, values[key]);
            }
        }

        // Convertendo a assinatura para Blob
        const signatureBase64 = sign.getTrimmedCanvas().toDataURL('image/png');
        const signatureBlob = await (await fetch(signatureBase64)).blob();
        formData.append('assinatura', signatureBlob);

        if (url === '') {
            toast.update(toastId, { render: 'Assinatura inválida', type: 'error', isLoading: false, autoClose: 3000 });
            return;
        }

        const toastId = toast.loading('Salvando dados...');

        try {
            await api.createMedicoAssinatura(formData);
            toast.update(toastId, { render: 'Usuário cadastrado com sucesso', type: 'success', isLoading: false, autoClose: 3000 });
            navigate('/main/cadastro-medicos');
        } catch (error) {
            toast.update(toastId, {
                render: error?.response?.data?.message ?? error?.message,
                type: 'error',
                isLoading: false,
                autoClose: 3000
            });
        }
    };

    const removeMask = (value) => {
        return value.replace(/[^\d]/g, '');
    };

    // // ASSINATURA
    const handleGenerate = () => {
        try {
            setUrl(sign.getTrimmedCanvas().toDataURL('image/png'));
            setIsHabilitado(false);
            toast.success('Assinatura gerada aguardando cadastro');
        } catch (error) {
            toast.error('Erro ao gerar assinatura');
        }
    };

    const handleClear = () => {
        sign.clear();
        setUrl('');
    };

    return (
        <Formik
            initialValues={{
                nome: '',
                email: '',
                setor: '',
                telefone: '',
                dn: '',
                naturalidade: '',
                numero_casa: '',
                role: '',
                password: '',
                cbo: '',
                cns: '',
                nome_completo: '',
                data_nascimento: '',
                local: '',
                cpf: '',
                rg: '',
                crm_coren: '',
                filiacao: '',
                data_expedicao: '',
                endereco_completo: '',
                cidade: '',
                nome_pais: '',
                bairro: '',
                cep: '',
                carga_horaria_semanal: 0,
                especialidade: '',
                casado: false,
                filhos: 0,
                submit: null
            }}
            validationSchema={Yup.object().shape({
                cpf: Yup.string().required('CPF é obrigatório'),
                telefone: Yup.string().required('O telefone é obrigatório'),
                cns: Yup.string().required('CNS é obrigatório'),
                nome_completo: Yup.string().min(3).required('Nome Completo é obrigatório'),
                crm_coren: Yup.string().required('Número de CRM / COREN é obrigatório'),
                rg: Yup.string().required('RG é obrigatório'),
                endereco_completo: Yup.string().min(3).required('Endereço é obrigatório'),
                especialidade: Yup.string().min(3).required('Especialidade é obrigatório'),
                numero_casa: Yup.number().min(1).required('Número da casa é obrigatório'),
                nome_pais: Yup.string().required('Nome do pai/mãe é obrigatório'),
                naturalidade: Yup.string().required('Naturalidade é obrigatório'),
                dn: Yup.string().required('Data de nascimento é obrigatório'),
                cidade: Yup.string().min(3).required('Cidade é obrigatório'),
                cep: Yup.string().required('CEP é obrigatório')
            })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
                if (scriptedRef.current) {
                    setStatus({ success: true });
                    setSubmitting(false);
                }
                values.cep = removeMask(values.cep);
                values.cbo = removeMask(values.cbo);
                values.cns = removeMask(values.cns);
                values.cpf = removeMask(values.cpf);
                values.rg = removeMask(values.rg);
                values.telefone = removeMask(values.telefone);
                try {
                    await handleRegister(values);
                    resetForm();
                } catch (err) {
                    console.error(err);
                    if (scriptedRef.current) {
                        setStatus({ success: false });
                        setErrors({ submit: err.msg });
                        setSubmitting(false);
                    }
                }
            }}
        >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, resetForm }) => (
                <form noValidate onSubmit={handleSubmit} {...others} style={{ display: 'flex', flexDirection: 'column' }}>
                    {etapa === 0 && (
                        <>
                            <FormBox>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.nome && errors.nome)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-nome-register">Nome Completo</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-nome-register"
                                        type="text"
                                        value={values.nome}
                                        name="nome"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{}}
                                    />
                                    {touched.nome && errors.nome && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.nome}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.nome_pais && errors.nome_pais)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-nome_pais-register">Mãe/Pai</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-nome_pais-register"
                                        type="text"
                                        value={values.nome_pais}
                                        name="nome_pais"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{}}
                                    />
                                    {touched.nome_pais && errors.nome_pais && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.nome_pais}
                                        </FormHelperText>
                                    )}
                                </FormControl>

                                <FormControl fullWidth error={Boolean(touched.cpf && errors.cpf)} sx={{ ...theme.typography.customInput }}>
                                    <InputLabel htmlFor="outlined-adornment-cpf-register">CPF</InputLabel>
                                    <InputMask mask="999.999.999-99" value={values.cpf} onBlur={handleBlur} onChange={handleChange}>
                                        {(inputProps) => (
                                            <OutlinedInput
                                                id="outlined-adornment-cpf-register"
                                                type="text"
                                                name="cpf"
                                                inputProps={inputProps}
                                            />
                                        )}
                                    </InputMask>
                                    {touched.cpf && errors.cpf && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.cpf}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </FormBox>

                            <FormBox>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.telefone && errors.telefone)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-telefone-register">Celular</InputLabel>
                                    <InputMask mask="(99) 99999-9999" value={values.telefone} onBlur={handleBlur} onChange={handleChange}>
                                        {(inputProps) => (
                                            <OutlinedInput
                                                id="outlined-adornment-telefone-register"
                                                type="text"
                                                name="telefone"
                                                inputProps={inputProps}
                                            />
                                        )}
                                    </InputMask>
                                    {touched.telefone && errors.telefone && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.telefone}
                                        </FormHelperText>
                                    )}
                                </FormControl>

                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.naturalidade && errors.naturalidade)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-naturalidade-register">Naturalidade</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-naturalidade-register"
                                        type="text"
                                        value={values.naturalidade}
                                        name="naturalidade"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{}}
                                    />
                                    {touched.naturalidade && errors.naturalidade && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.naturalidade}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </FormBox>
                            <FormBox>
                                <FormControl fullWidth error={Boolean(touched.dn && errors.dn)} sx={{ ...theme.typography.customInput }}>
                                    <InputLabel htmlFor="outlined-adornment-dn-register">Nascimento</InputLabel>

                                    <OutlinedInput
                                        value={values.dn}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        id="outlined-adornment-dn-register"
                                        type="date"
                                        name="dn"
                                        inputProps={{}}
                                    />

                                    {touched.dn && errors.dn && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.dn}
                                        </FormHelperText>
                                    )}
                                </FormControl>

                                <FormControl fullWidth error={Boolean(touched.rg && errors.rg)} sx={{ ...theme.typography.customInput }}>
                                    <InputLabel htmlFor="outlined-adornment-rg-register">RG</InputLabel>
                                    <InputMask mask="99.999.999" value={values.rg} onBlur={handleBlur} onChange={handleChange}>
                                        {(inputProps) => (
                                            <OutlinedInput
                                                id="outlined-adornment-rg-register"
                                                type="text"
                                                name="rg"
                                                inputProps={inputProps}
                                            />
                                        )}
                                    </InputMask>
                                    {touched.rg && errors.rg && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.rg}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.crm_coren && errors.crm_coren)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-crm_coren-register">CRM / COREN</InputLabel>
                                    <InputMask mask="999999" value={values.crm_coren} onBlur={handleBlur} onChange={handleChange}>
                                        {(inputProps) => (
                                            <OutlinedInput
                                                id="outlined-adornment-crm_coren-register"
                                                type="text"
                                                name="crm_coren"
                                                inputProps={inputProps}
                                            />
                                        )}
                                    </InputMask>
                                    {touched.crm_coren && errors.crm_coren && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.crm_coren}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </FormBox>

                            <FormBox>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.especialidade && errors.especialidade)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-especialidade-register">Especialidade</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-especialidade-register"
                                        type="text"
                                        value={values.especialidade}
                                        name="especialidade"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{}}
                                    />
                                    {touched.especialidade && errors.especialidade && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.especialidade}
                                        </FormHelperText>
                                    )}
                                </FormControl>

                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.endereco_completo && errors.endereco_completo)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-especialidade-register">Endereço</InputLabel>
                                    <OutlinedInput
                                        value={values.endereco_completo}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        id="outlined-adornment-endereco_completo-register"
                                        type="text"
                                        name="endereco_completo"
                                        inputProps={{}}
                                    />

                                    {touched.endereco_completo && errors.endereco_completo && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.endereco_completo}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.numero_casa && errors.numero_casa)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-numero_casa-register">N.º</InputLabel>
                                    <OutlinedInput
                                        value={values.numero_casa}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        id="outlined-adornment-numero_casa-register"
                                        type="number"
                                        name="numero_casa"
                                        inputProps={{}}
                                    />

                                    {touched.numero_casa && errors.numero_casa && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.numero_casa}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </FormBox>
                            <FormBox>
                                <FormControl fullWidth error={Boolean(touched.cns && errors.cns)} sx={{ ...theme.typography.customInput }}>
                                    <InputLabel htmlFor="outlined-adornment-cns-register">Cartão Sus</InputLabel>
                                    <InputMask mask="999 9999 9999 9999" value={values.cns} onBlur={handleBlur} onChange={handleChange}>
                                        {(inputProps) => (
                                            <OutlinedInput
                                                id="outlined-adornment-cns-register"
                                                type="text"
                                                name="cns"
                                                inputProps={inputProps}
                                            />
                                        )}
                                    </InputMask>
                                    {touched.cns && errors.cns && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.cns}
                                        </FormHelperText>
                                    )}
                                </FormControl>

                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.cidade && errors.cidade)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-cidade-register">Cidade</InputLabel>

                                    <OutlinedInput
                                        value={values.cidade}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        id="outlined-adornment-cidade-register"
                                        type="text"
                                        name="cidade"
                                        inputProps={{}}
                                    />

                                    {touched.cidade && errors.cidade && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.cidade}
                                        </FormHelperText>
                                    )}
                                </FormControl>

                                <FormControl fullWidth error={Boolean(touched.cep && errors.cep)} sx={{ ...theme.typography.customInput }}>
                                    <InputLabel htmlFor="outlined-adornment-cep-register">CEP</InputLabel>
                                    <InputMask mask="99999-999" value={values.cep} onBlur={handleBlur} onChange={handleChange}>
                                        {(inputProps) => (
                                            <OutlinedInput
                                                id="outlined-adornment-cep-register"
                                                type="text"
                                                name="cep"
                                                inputProps={inputProps}
                                            />
                                        )}
                                    </InputMask>
                                    {touched.cep && errors.cep && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.cep}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </FormBox>
                            <FormBox sx={{ display: 'flex', flexDirection: 'column' }}>
                                <FormControl fullWidth sx={{ border: '2px solid #eee', borderRadius: '4px' }}>
                                    <ReactSignatureCanvas
                                        penColor="black"
                                        canvasProps={{ className: 'sigCanvas', height: 400 }}
                                        ref={(data) => setSign(data)}
                                    />
                                </FormControl>
                                <Box sx={{ display: 'flex', gap: '2rem', justifyContent: 'center' }}>
                                    <Button variant="contained" fullWidth color="error" onClick={handleClear}>
                                        Limpar
                                    </Button>
                                    <Button variant="contained" fullWidth onClick={handleGenerate}>
                                        Salvar
                                    </Button>
                                </Box>
                            </FormBox>
                        </>
                    )}

                    {errors?.submit && (
                        <Box sx={{ mt: 3 }}>
                            <FormHelperText error>{errors?.submit}</FormHelperText>
                        </Box>
                    )}

                    <Box sx={{ mt: 2 }}>
                        {
                            <AnimateButton>
                                <Button
                                    sx={{ marginBottom: '3em' }}
                                    disableElevation
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    disabled={isHabilitado}
                                    color="primary"
                                    onClick={() => handleRegister(values)}
                                >
                                    {'Cadastrar usuário'}
                                </Button>
                            </AnimateButton>
                        }
                    </Box>
                </form>
            )}
        </Formik>
    );
};

export default MedicoRegisterForm;
