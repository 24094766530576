import { useParams } from 'react-router-dom';
import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import {
    ExameInfoBox,
    HalfBox,
    HeaderBox,
    HeaderBoxSecondary,
    HeaderInfoBox,
    InfoBox,
    InfoBoxMedium,
    InfoBoxMediumEnd,
    TableBox
} from './style';
import { FormBox } from './style';
import { useEffect, useState } from 'react';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';
import { formatarNumero } from 'utils/formatarNumero';
import { formatDate } from 'utils/formatDate';
import formater from 'utils/DateDDMMYYY';

const LaudoExamesEspeciais = () => {
    // ============================= INSTÂNCIAS =============================
    const { prontuarioId, exameId, idExameProntuario } = useParams();
    const api = useApi();
    // ============================= STATES =============================
    const [data, setData] = useState(null);
    const [camposAgrupados, setCamposAgrupados] = useState([]);
    const [dataFormatada, setDataFormatada] = useState(null);
    const [horaFormatada, setHoraFormatada] = useState(null);
    const [loading, setLoading] = useState(true);
    const [observacao, setObservacao] = useState('');
    // ============================= FUNÇÕES =============================
    const getExameData = async (prontuarioId, exameId, idExameProntuario) => {
        const formatarData = (data) => {
            const dataObj = new Date(data);

            // Extrair partes da data
            const ano = dataObj.getFullYear();
            const mes = dataObj.getMonth() + 1; // Mês é baseado em zero
            const dia = dataObj.getDate();

            // Extrair partes da hora
            const hora = dataObj.getHours();
            const minutos = dataObj.getMinutes();
            const segundos = dataObj.getSeconds();
            const dataFormatadaString = `${dia}/${mes}/${ano}`;
            setDataFormatada(dataFormatadaString);
            const horaFormatadaString = `${hora}:${minutos}:${segundos}`;
            setHoraFormatada(horaFormatadaString);
            return dataFormatadaString + ' ' + horaFormatadaString;
        };
        try {
            const response = await api.getAllExameData(prontuarioId, exameId, idExameProntuario);
            const data = response.data;

            // Criar um objeto para armazenar os campos agrupados por categoria
            const camposAgrupados = {};

            // Percorrer os dados e agrupar os campos por categoria
            data.resultado_campo_especifico
                .sort((a, b) => a.campos_exames_especiai.id - b.campos_exames_especiai.id)
                .forEach((item) => {
                    const categoria = item.campos_exames_especiai.categoria;
                    const resultado = item.valor;
                    if (!camposAgrupados[categoria]) {
                        camposAgrupados[categoria] = [];
                    }
                    camposAgrupados[categoria].push({
                        ...item,
                        valor: resultado
                    });
                });

            // Transformar o objeto em um array de categorias
            const categoriasArray = Object.entries(camposAgrupados).map(([categoria, campos]) => ({
                categoria,
                campos
            }));

            formatarData(response?.data?.resultado_campo_especifico[0]?.data_registro);

            setData(response.data);
            setCamposAgrupados(categoriasArray);
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } catch (error) {
            toast.error('Erro ao buscar dados');
        }
    };

    function formatarData2(horaLaudo) {
        // Converte a string de data/hora para um objeto Date
        const partes = horaLaudo.split(/[\s-:]/);

        // Criar um objeto Date
        const dataHoraObj = new Date(
            partes[2], // ano
            partes[1] - 1, // mês (subtrai 1 porque os meses em JavaScript são zero-indexados)
            partes[0], // dia
            partes[3], // hora
            partes[4], // minuto
            partes[5] // segundo
        );

        // Adicionar 3 horas ao objeto Date
        dataHoraObj.setHours(dataHoraObj.getHours() + 3);

        // Formatando a nova data/hora
        const novaDataHora = dataHoraObj.toLocaleString();

        return novaDataHora;
    }

    if (!loading && data !== null) {
        window.print();
    }

    useEffect(() => {
        getExameData(prontuarioId, exameId, idExameProntuario);
    }, [prontuarioId, exameId]);

    return (
        <>
            {data !== null && (
                <FormBox>
                    <HeaderBox>
                        {/* DADOS SOLICITANTE */}
                        <InfoBox>
                            <Typography variant="h5">Paciente:</Typography>
                            <Typography>{data?.paciente?.nome_completo}</Typography>
                        </InfoBox>
                        {data?.paciente?.data_nascimento && (
                            <InfoBox>
                                <Typography variant="h5">Data Nascimento:</Typography>
                                <Typography>{formater(data?.paciente?.data_nascimento)}</Typography>
                            </InfoBox>
                        )}

                        <HeaderInfoBox>
                            <InfoBoxMedium>
                                <Typography variant="h5">Médico Solicitante:</Typography>
                                <Typography>{data?.medico?.usuario?.info_medico?.nome_completo}</Typography>
                            </InfoBoxMedium>
                            <InfoBoxMediumEnd>
                                <Typography variant="h5">CRM:</Typography>
                                <Typography>{data?.medico?.usuario?.info_medico?.crm_coren}</Typography>
                            </InfoBoxMediumEnd>
                        </HeaderInfoBox>
                        {/* DADOS EXAME */}

                        <HeaderBoxSecondary sx={{ width: '450px' }}>
                            <HalfBox sx={{ width: '60%', flexWrap: 'nowrap', height: '20px' }}>
                                <Typography variant="h5">Data e Hora Laudo: {formatarData2(data?.medico?.hora_laudo)}</Typography>
                            </HalfBox>
                        </HeaderBoxSecondary>
                        <InfoBox sx={{ justifyContent: 'center', marginTop: '10px' }}>
                            <Typography variant="h4" sx={{ fontSize: '18px' }}>
                                {data.exame.nome}
                            </Typography>
                        </InfoBox>
                        <ExameInfoBox>
                            <Box>
                                <InfoBox>
                                    <Typography variant="h5">Material:</Typography>
                                    <Typography>{data.exame.material}</Typography>
                                </InfoBox>
                            </Box>

                            <Box>
                                <InfoBox>
                                    <Typography variant="h5">Sigla:</Typography>
                                    <Typography>{data.exame.sigla}</Typography>
                                </InfoBox>
                                <InfoBox>
                                    <Typography variant="h5">Método:</Typography>
                                    <Typography>{data.exame.metodo}</Typography>
                                </InfoBox>
                            </Box>
                        </ExameInfoBox>
                    </HeaderBox>

                    <TableBox>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <b>NOME CAMPO</b>
                                        </TableCell>
                                        <TableCell align="center">
                                            <b>VALORES OBTIDOS</b>
                                        </TableCell>
                                        <TableCell align="center">
                                            <b>UNIDADE</b>
                                        </TableCell>
                                        <TableCell align="center">
                                            <b>VALORES REFERÊNCIA</b>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {camposAgrupados.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <Typography sx={{ fontWeight: 'bold' }}>{item.categoria}</Typography>
                                                {item.campos.map((campo, indexCampo) => (
                                                    <Typography key={indexCampo} sx={{ fontSize: '.87em' }}>
                                                        {campo?.campos_exames_especiai?.campo_nome}:
                                                    </Typography>
                                                ))}
                                            </TableCell>
                                            <TableCell align="center" sx={{ paddingTop: '2.3em' }}>
                                                {item.campos.map((resultado, indexResultadoValor) => (
                                                    <Typography key={indexResultadoValor} sx={{ fontSize: '.87em' }}>
                                                        {data.exame.nome.includes('HEMOGRAMA') && resultado.porcentagem
                                                            ? `${resultado.porcentagem}%`
                                                            : `${resultado.valor}`}
                                                    </Typography>
                                                ))}
                                            </TableCell>

                                            <TableCell align="center" sx={{ paddingTop: '2.3em' }}>
                                                {item.campos.map((campo, indexResultadoValor) => (
                                                    <Typography key={indexResultadoValor} sx={{ fontSize: '.87em' }}>
                                                        {data.exame.nome.includes('HEMOGRAMA') && campo.porcentagem
                                                            ? `
                                                        ${formatarNumero(campo.valor)} MM³ 
                                                        `
                                                            : campo?.campos_exames_especiai?.unidade
                                                            ? campo?.campos_exames_especiai?.unidade
                                                            : '-'}
                                                    </Typography>
                                                ))}
                                            </TableCell>

                                            <TableCell align="center" sx={{ paddingTop: '2.3em', textAlign: 'start' }}>
                                                {item.campos.map((resultado, indexResultado) => (
                                                    <Typography
                                                        key={indexResultado}
                                                        sx={{
                                                            fontSize: '.87em',
                                                            letterSpacing: data.exame.nome.includes('HEMOGRAMA') ? '1px' : '0'
                                                        }}
                                                    >
                                                        {resultado?.campos_exames_especiai?.valores}
                                                    </Typography>
                                                ))}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TableBox>
                    <Box
                        sx={{
                            display: 'flex',
                            marginTop: '2em',
                            gap: '.5em'
                        }}
                    >
                        <Typography variant="h5">Observação:</Typography>
                        <Typography>{data?.observacao?.observation}</Typography>
                        <Box
                            sx={{
                                width: '30%',
                                borderTop: '1px solid #000',
                                display: 'flex',
                                justifyContent: 'center',
                                marginLeft: 'auto',
                                marginTop: '3em'
                            }}
                        >
                            <Typography variant="h4">Responsável</Typography>
                        </Box>
                    </Box>
                </FormBox>
            )}
        </>
    );
};

export default LaudoExamesEspeciais;
