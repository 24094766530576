import { Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { useLocation } from 'react-router';

export function AtestadoRelatorio() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const nome = searchParams.get('nome');
    const nascimento = searchParams.get('nascimento');
    const data = searchParams.get('data');
    const dias = searchParams.get('dias');

    useEffect(() => {
        window.print();
    }, []);

    return (
        <Box sx={{ marginLeft: '1rem', border: '1px solid #000', borderRadius: '4px', marginTop: '1rem', padding: '1rem' }}>
            <Typography variant="h2" sx={{ textAlign: 'center' }}>
                ATESTADO MÉDICO
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '.4rem', marginTop: '1rem' }}>
                <Typography variant="h4">DADOS DO PACIENTE</Typography>
                <Typography variant="h5">NOME: {nome}</Typography>
                <Typography variant="h5">NASCIMENTO: {nascimento}</Typography>
                <Typography variant="h5">ATENDIMENTO: {data}</Typography>
            </Box>
            <Divider sx={{ width: '100%', margin: '2rem 0 2rem 0' }} />
            <Box sx={{ textAlign: 'center' }}>
                ATESTO PARA FINS DE TRABALHO QUE O(A) PACIENTE ACIMA ESTEVE EM CONSULTA MÉDICA NO DIA DE HOJE E NECESSITA PERMANECER
                AFASTADO(A) DE SUAS ATIVIDADES LABORATIVAS PELO PERÍODO DE
                {` ${dias}`} DIAS A CONTAR DESTA DATA.
            </Box>
        </Box>
    );
}
