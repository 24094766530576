import { TableCell, styled } from '@mui/material';
import { Box, Typography, Button } from '@mui/material';

export const MainBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
});
