import {
    Button,
    Box,
    TextField,
    DialogTitle,
    DialogContent,
    Typography,
    DialogActions,
    Dialog,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell
} from '@mui/material';
import { FormBox } from './style';
import { useState, useEffect } from 'react';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';

const ResultadoExameForm = ({ exame2, prontuarioID, idExameProntuario, tipo }) => {
    // ============================= INSTÂNCIA =============================
    const api = useApi();
    // ============================= STATES =============================
    const [campos, setCampos] = useState([]);
    const [exameStatus, setExameStatus] = useState(null);
    const [fullExameData, setFullExameData] = useState([]);
    const [open, setOpen] = useState(false);
    const [dataFormatada, setDataFormatada] = useState('');
    const [horaFormatada, setHoraFormatada] = useState('');
    const [loading, setLoading] = useState(false);
    const [observacao, setObservacao] = useState('');
    const [idObservacao, setIdObservacao] = useState('');
    const [observacaoSalva, setObservacaoSalva] = useState(false);
    // ============================= FUNÇÕES =============================

    const getFullExameData = async (prontuarioID) => {
        if (tipo === 'especial') {
            try {
                const response = await api.getAssociatedExameEspecial(prontuarioID, idExameProntuario);
                setFullExameData(response.data);
            } catch (error) {
                toast.error(error.message);
                toast.error(error.response.message);
            }
        } else {
            try {
                const response = await api.getAssociatedExame(prontuarioID, idExameProntuario);
                setFullExameData(response.data);
            } catch (error) {
                toast.error(error.message);
                toast.error(error.response.message);
            }
        }
    };

    // pega o status (caso já tenha sido efetuado o laudo, bloqueia o edit com base no status)
    const getExameStatus = async (idExameProntuario) => {
        if (tipo === 'especial') {
            try {
                const response = await api.verificarStatusExameEspecialProntuario(idExameProntuario);

                setExameStatus(response.data);
            } catch (error) {
                toast.error(error.response.message);
            }
        } else {
            try {
                const response = await api.verificarStatusExameProntuario(idExameProntuario);
                setExameStatus(response.data);
            } catch (error) {
                toast.error(error.message);
                toast.error(error.response.message);
            }
        }
    };

    const getObservacao = async () => {
        if (tipo === 'especial') {
            try {
                const response = await api.getObservation(prontuarioID, null, exame2?.id, idExameProntuario);
                setObservacao(response?.data[0]?.observation);
                setIdObservacao(response?.data[0]?.id);
                setObservacaoSalva(response?.data[0]?.observacaoSalva);
            } catch (error) {
                toast.error(error?.response?.data?.message ?? error?.message);
            }
        } else {
            try {
                const response = await api.getObservation(prontuarioID, exame2?.id, null, idExameProntuario);
                setObservacao(response?.data[0]?.observation);
                setIdObservacao(response?.data[0]?.id);
                setObservacaoSalva(response?.data[0]?.observacaoSalva);
            } catch (error) {
                toast.error(error?.response?.data?.message ?? error?.message);
            }
        }
    };

    const dataAserFormatada = exameStatus?.data_execucao;

    const atualizarCampoEspecifico = (indiceCampo, indiceCampoEspecifico, campo, valor) => {
        const novoValor = typeof valor === 'string' ? valor.replace(',', '.') : valor;

        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            novosCampos[indiceCampo].campos_especificos[indiceCampoEspecifico][campo] = novoValor;

            return novosCampos;
        });
    };

    const fillData = (exame2) => {
        setCampos([exame2]);
    };

    //handle change para o campo de observação
    const handleChange = (event) => {
        const { value } = event.target;
        setObservacao(value);
    };

    const formatarData = (data) => {
        const dataFormatada = new Date(data);
        const dataFormatadaString = dataFormatada.toLocaleDateString();
        setDataFormatada(dataFormatadaString);
        const horaFormatadaString = dataFormatada.toLocaleTimeString();
        setHoraFormatada(horaFormatadaString);
        return dataFormatadaString + ' ' + horaFormatadaString;
    };

    // ============================= SUBMIT =============================

    const handleSaveObservacao = async () => {
        try {
            if (tipo === 'especial') {
                await api.saveObservationEspecial(prontuarioID, exame2?.id, idExameProntuario, observacao, true);
            } else {
                await api.saveObservation(prontuarioID, exame2?.id, idExameProntuario, observacao, true);
            }
            toast.success('Observação salva com sucesso!');
            getObservacao();
        } catch (error) {
            toast.error('Erro ao salvar observação: ' + error.message);
        }
    };

    const handleSaveAll = async () => {
        try {
            setOpen(false);
            setLoading(true);
            await handleSaveObservacao();
            await api.saveExameResult(prontuarioID, idExameProntuario, campos[0]);
            await api.finalizarExame(idExameProntuario);
            toast.success('Resultados salvos com sucesso!');
            window.location.reload();
        } catch (error) {
            setLoading(false);
            toast.error(`Erro: ${error.response.data.mensagem}`);
            toast.error(`Erro: ${error.message}`);
        }
    };

    const handleEditObservacao = async () => {
        try {
            toast.success('Observação atualizada com sucesso!');
            getObservacao();
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    };

    useEffect(() => {
        formatarData(dataAserFormatada);
    }, [dataAserFormatada]);

    useEffect(() => {
        fillData(exame2);
    }, [exame2]);

    useEffect(() => {
        getFullExameData(prontuarioID);
        getObservacao(prontuarioID, exame2?.id, idExameProntuario);
        getExameStatus(idExameProntuario);
    }, [exame2]);

    return (
        <>
            {exameStatus === null ? (
                <Typography>Carregando...</Typography>
            ) : (
                <FormBox>
                    <Dialog open={open} onClose={() => setOpen(false)}>
                        <DialogTitle>Atenção!</DialogTitle>
                        <DialogContent>
                            <Typography>Ao salvar os resultados não será mais possível editar ou inserir valores neste exame.</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button color="error" onClick={() => setOpen(false)}>
                                Cancelar
                            </Button>
                            <Button variant="contained" onClick={handleSaveAll}>
                                Confirmar
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {campos[0]?.campos_especificos &&
                        campos.map((campo, indiceCampo) => (
                            <>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        height: '100%',
                                        padding: '20px',
                                        boxSizing: 'border-box'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '15px'
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                gap: '5px'
                                            }}
                                        >
                                            <Typography variant="h4">Paciente: </Typography>
                                            <Typography>{fullExameData[0]?.paciente?.nome_completo}</Typography>
                                        </Box>

                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                gap: '5px'
                                            }}
                                        >
                                            <Typography variant="h4">N° Ficha: </Typography>
                                            <Typography>{prontuarioID}</Typography>
                                        </Box>

                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                gap: '5px'
                                            }}
                                        >
                                            <Typography variant="h4">Médico Solicitante:</Typography>
                                            <Typography>{exameStatus?.usuario?.nome}</Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                gap: '5px'
                                            }}
                                        >
                                            <Typography variant="h4">CRM:</Typography>
                                            <Typography>{exameStatus?.usuario?.info_medico?.crm_coren}</Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '10px'
                                        }}
                                    >
                                        <Box sx={{ display: 'flex', gap: '5px' }}>
                                            <Typography variant="h4">Exame:</Typography>
                                            <Typography>
                                                {campo.nome} ({campo.sigla})
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', gap: '5px' }}>
                                            <Typography variant="h4">Material:</Typography>
                                            <Typography>{campo.material}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', gap: '5px' }}>
                                            <Typography variant="h4">Método:</Typography>
                                            <Typography>{campo.metodo}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', gap: '5px' }}>
                                            <Typography variant="h4">Data: </Typography>
                                            <Typography>
                                                {dataFormatada} - {horaFormatada}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            width: '40%',
                                            display: 'flex',
                                            gap: '10px',
                                            marginRight: '55px'
                                        }}
                                    >
                                        <Box sx={{ display: 'flex', minWidth: '100%' }}>
                                            <TextField
                                                id="outlined-adornment-observacao-register"
                                                disabled={loading || exameStatus?.finalizado}
                                                label="Observação"
                                                value={observacao}
                                                name="budgetObservation"
                                                onChange={handleChange}
                                                multiline
                                                variant="outlined"
                                                inputProps={{
                                                    style: { height: '100px', overflow: 'auto', minWidth: '500px', maxWidth: '500px' }
                                                }}
                                            />
                                        </Box>
                                        {!observacaoSalva ? (
                                            <Button
                                                variant="contained"
                                                disabled={loading || exameStatus?.finalizado}
                                                sx={{
                                                    display: 'flex',
                                                    height: '40px',
                                                    margin: 'auto 0'
                                                }}
                                                onClick={() => handleSaveObservacao()}
                                            >
                                                Salvar
                                            </Button>
                                        ) : (
                                            <Button
                                                variant="contained"
                                                disabled={loading || exameStatus?.finalizado}
                                                color="warning"
                                                sx={{
                                                    display: 'flex',
                                                    height: '40px',
                                                    margin: 'auto 0'
                                                }}
                                                onClick={() => handleEditObservacao()}
                                            >
                                                Editar
                                            </Button>
                                        )}
                                    </Box>
                                </Box>
                                <Box>
                                    <TableContainer
                                        component={Paper}
                                        style={{
                                            width: '100%',
                                            maxHeight: '300px',
                                            overflowY: 'auto',
                                            scrollbarWidth: 'thin',
                                            scrollbarColor: 'rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.3)'
                                        }}
                                    >
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        <b>Nome Campo</b>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <b>Unidade</b>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <b>Categoria</b>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <b>Valores Referencia</b>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <b>Valores Obtidos</b>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {campos[indiceCampo].campos_especificos.map((campoEspecifico, indiceCampoEspecifico) => (
                                                    <TableRow key={indiceCampoEspecifico}>
                                                        <TableCell>{campoEspecifico.campo_nome}</TableCell>
                                                        <TableCell align="center">{campoEspecifico.unidade}</TableCell>
                                                        <TableCell align="center">{campoEspecifico.categoria}</TableCell>
                                                        <TableCell align="center">{campoEspecifico.valores}</TableCell>
                                                        <TableCell align="center">
                                                            <form autocomplete="off">
                                                                <TextField
                                                                    autocomplete="off"
                                                                    label="Inserir Resultado"
                                                                    type="text"
                                                                    disabled={exameStatus?.finalizado}
                                                                    defaultValue={
                                                                        campoEspecifico.resultados &&
                                                                        campoEspecifico.resultados[0] &&
                                                                        campoEspecifico.resultados[0].valor
                                                                            ? campoEspecifico.resultados[0].valor
                                                                            : ''
                                                                    }
                                                                    onChange={(e) => {
                                                                        atualizarCampoEspecifico(
                                                                            indiceCampo,
                                                                            indiceCampoEspecifico,
                                                                            campos[0].especial ? 'porcentagem' : 'resultado',
                                                                            e.target.value
                                                                        );
                                                                    }}
                                                                    inputProps={{ style: { fontSize: 13, textAlign: 'center' } }}
                                                                    InputLabelProps={{ shrink: true }}
                                                                />
                                                            </form>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                                <Button
                                    variant="contained"
                                    disabled={loading || exameStatus?.finalizado}
                                    sx={{
                                        width: '300px',
                                        margin: '20px auto'
                                    }}
                                    onClick={() => setOpen(true)}
                                >
                                    Salvar resultados
                                </Button>
                            </>
                        ))}
                </FormBox>
            )}
        </>
    );
};

export default ResultadoExameForm;
