import { useApi } from 'Service/axios';
import useChamadas from 'hooks/useChamadas';
import { useState } from 'react';
import { useEffect } from 'react';
import { createContext } from 'react';

const ChamadaContext = createContext();

function ChamadasProvider({ children }) {
    const api = useApi();
    const { chamadas, setChamadas, getChamadas } = useChamadas();
    // ------------ PAINEL TRIAGEM ------------ \\
    // Pega atendimentos pra triagem
    const [atendimentos, setAtendimentos] = useState([]);
    const [quantidadeAguardando, setQuantidadeAguardando] = useState([]);
    const [chamadaAcionada, setChamadaAcionada] = useState(false);
    const [chamadasRealizadas, setChamadasRealizadas] = useState([]);

    const getFichaTriagem = async () => {
        const response = await api.getTriagem();
        setQuantidadeAguardando(response.data.length);
        setAtendimentos(response.data);
        setChamadaAcionada(false);
    };

    useEffect(() => {
        const getAndSetFichaTriagem = () => {
            getFichaTriagem();
        };
        getAndSetFichaTriagem();
        const interval = setInterval(getAndSetFichaTriagem, 30000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (chamadaAcionada) {
            getFichaTriagem();
        }
    }, [chamadaAcionada]);

    return (
        <ChamadaContext.Provider
            value={{
                chamadas,
                setChamadas,
                getChamadas,
                atendimentos,
                quantidadeAguardando,
                chamadaAcionada,
                setChamadaAcionada,
                chamadasRealizadas,
                setChamadasRealizadas
            }}
        >
            {children}
        </ChamadaContext.Provider>
    );
}

export { ChamadaContext, ChamadasProvider };
