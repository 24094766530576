import MainCard from 'ui-component/cards/MainCard';
import {
    Button,
    Grid,
    TextField,
    Typography,
    Box,
    InputLabel,
    OutlinedInput,
    Select,
    MenuItem,
    Stepper,
    Step,
    StepLabel,
    ButtonGroup,
    FormLabel
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useEffect, useState, useRef } from 'react';
import FormControl from '@mui/material/FormControl';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { useTheme } from '@mui/material/styles';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useApi } from 'Service/axios';
import * as Yup from 'yup';
import { Formik, useFormikContext } from 'formik';
import Footer from 'layout/MainLayout/Footer';
import Autocomplete from '@mui/material/Autocomplete';
import useScriptRef from 'hooks/useScriptRef';

// context
import { useContext } from 'react';
import { Context } from 'context/UserContext';

// router
import { useNavigate } from 'react-router';

const CadastroConvenio = ({ ...others }) => {
    const navigate = useNavigate();
    const { loggedUser } = useContext(Context);

    // useEffect(() => {
    //     if (!loggedUser?.usuario?.nome) {
    //         navigate('/');
    //     }
    // }, []);

    const theme = useTheme();
    const scriptedRef = useScriptRef();
    return (
        <MainCard title="Cadastro de convênio">
            <Grid container spacing={gridSpacing}>
                <Box sx={{ width: '80%', display: 'flex', justifyContent: 'center' }}>
                    <Box>
                        <Formik
                            initialValues={{
                                nome: '',
                                submit: null
                            }}
                            validationSchema={Yup.object().shape({
                                nome: Yup.string().min(3).required('Nome é obrigatorio')
                            })}
                            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                                try {
                                    if (scriptedRef.current) {
                                        setStatus({ success: true });
                                        setSubmitting(false);
                                    }

                                    handleRegister(values);
                                } catch (err) {
                                    console.error(err);
                                    if (scriptedRef.current) {
                                        setStatus({ success: false });
                                        setErrors({ submit: err.msg });
                                        setSubmitting(false);
                                    }
                                }
                            }}
                        >
                            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                                <form noValidate onSubmit={handleSubmit} {...others} style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography variant="h4" sx={{ textAlign: 'center', margin: '1em', marginBottom: '3em' }}>
                                        Complete com as informações do paciente
                                    </Typography>

                                    <FormControl>
                                        <FormControl
                                            fullWidth
                                            error={Boolean(touched.nome && errors.nome)}
                                            sx={{ ...theme.typography.customInput }}
                                        >
                                            <InputLabel htmlFor="outlined-adornment-nome-register">Nome</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-nome-register"
                                                type="text"
                                                value={values.nome}
                                                name="nome"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                inputProps={{}}
                                            />
                                            {touched.nome && errors.nome && (
                                                <FormHelperText error id="standard-weight-helper-text--register">
                                                    {errors.nome}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </FormControl>
                                    {errors.submit && (
                                        <Box sx={{ mt: 3 }}>
                                            <FormHelperText error>{errors.submit}</FormHelperText>
                                        </Box>
                                    )}

                                    {/* BOTÕES */}
                                    <Box sx={{ mt: 2 }}>
                                        <Button type="submit" variant="contained">
                                            Enviar
                                        </Button>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Box>
                </Box>
            </Grid>
        </MainCard>
    );
};

export default CadastroConvenio;
