import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { HeaderArea, RegisterArea, TriagemArea } from './styles';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { IconLungs, IconArrowAutofitHeight, IconThermometer, IconEye, IconPercentage } from '@tabler/icons';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import CloseIcon from '@mui/icons-material/Close';
import MonitorWeightIcon from '@mui/icons-material/MonitorWeight';
import CalculateIcon from '@mui/icons-material/Calculate';
import { toast } from 'react-toastify';
import { useApi } from 'Service/axios';
import { useState } from 'react';
import { useEffect } from 'react';
import { TextField } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: 2
};

export function AnalysesModal({ open, setOpen, currentRow }) {
    const handleClose = () => setOpen(false);
    const api = useApi();
    const [triagemData, setTriagemData] = useState({});
    const [medicacaoData, setMedicacaoData] = useState({});
    const [rows, setRows] = useState([]);
    const [fichaMedica, setFichaMedica] = useState(null);

    async function getFichaMedica(id) {
        try {
            const { data } = await api.getMedicalRecordObservation(id);
            setFichaMedica(data);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    async function getMedicalRecord() {
        try {
            const { data } = await api.getMedicalRecord(currentRow?.id, currentRow?.patientId);

            setTriagemData(data.triagem);
            setMedicacaoData(data.medicacao);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    const getConduct = async () => {
        try {
            const { data } = await api.getMedicalConduct(currentRow?.id);

            const row = data.map((item) => {
                let descricao;
                let qtd = '-';
                let unidade = '-';
                let aplicacao = '-';

                if (item?.tipo === 'LABORATÓRIO') {
                    if (item.item_conduta.exame) {
                        descricao = item?.item_conduta.exame?.nome;
                    } else {
                        descricao = item?.item_conduta.exames_especiai?.nome;
                    }
                } else if (item?.tipo === 'RAIO-X') {
                    descricao = item?.item_conduta.tipos_raio_x?.descricao;
                } else if (item?.tipo === 'MEDICAMENTO') {
                    let medInfo = item?.item_conduta?.item_pedido;

                    descricao = medInfo.descricao;
                    qtd = item?.item_conduta?.quantidade_item;
                    unidade = medInfo.unidade.sigla;
                    aplicacao = item?.item_conduta.via_aplicacao;
                } else if (item?.tipo === 'PROCEDIMENTO') {
                    descricao = item?.item_conduta?.procedimento?.descricao;
                }

                return {
                    ...item,
                    cod: item.id,
                    descricao: descricao,
                    qtd,
                    unidade,
                    aplicacao
                };
            });
            setRows(row);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    };

    const columns = [
        { field: 'cod', headerName: 'Código', flex: 0.5 },
        { field: 'descricao', headerName: 'Descrição', flex: 1 },
        { field: 'qtd', headerName: 'Quantidade', flex: 0.5 },
        { field: 'unidade', headerName: 'Unidade', flex: 0.5 },
        {
            field: 'aplicacao',
            headerName: 'Aplicação',
            flex: 0.5,
            renderCell: (params) => <p style={{ textTransform: 'uppercase' }}>{params.row.aplicacao}</p>
        },
        { field: 'tipo', headerName: 'Tipo', flex: 0.5 },
        {
            field: 'status',
            headerName: 'Status',
            flex: 0.5,
            renderCell: (params) =>
                params?.row?.item_conduta?.finalizado || params?.row?.item_conduta?.atendido ? (
                    <Tooltip title="Finalizado">
                        <DoneIcon />
                    </Tooltip>
                ) : (
                    <Tooltip title="Aguardando">
                        <AccessTimeIcon />
                    </Tooltip>
                )
        }
    ];

    useEffect(() => {
        if (open) {
            getConduct();
            getFichaMedica(currentRow?.id);
            getMedicalRecord();
        }
    }, [open]);

    return (
        <div>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <HeaderArea>
                        <h2>
                            FA: {currentRow?.id} - {currentRow?.patient}
                        </h2>
                        <Box>
                            <p>
                                PRONTUÁRIO: <b>{currentRow?.id}</b>
                            </p>
                            <p>
                                PACIENTE: <b>{currentRow?.patientId}</b>
                            </p>
                            <p>
                                DATA: <b>{currentRow?.date}</b>
                            </p>
                            <p>
                                IDADE: <b>{currentRow?.age}</b>
                            </p>
                        </Box>
                    </HeaderArea>

                    <TriagemArea>
                        <Box sx={{ borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc', padding: '10px' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <h4>ESCUTA INICIAL</h4>
                                <p>
                                    HORA: <b>{triagemData?.hora}</b>
                                </p>

                                <h4>
                                    RESPONSÁVEL: <b>{triagemData?.usuario?.nome}</b>
                                </h4>
                            </Box>
                            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '20px' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <MonitorHeartIcon />
                                    <TextField
                                        label="SIS"
                                        type="number"
                                        value={triagemData?.pa_1}
                                        name="pa_sis"
                                        disabled
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ width: '130px', marginLeft: '.5rem' }}
                                    />
                                    <CloseIcon sx={{ margin: '0 5px' }} />
                                    <TextField
                                        label="DIA"
                                        type="number"
                                        placeholder="DIA"
                                        value={triagemData?.pa_2}
                                        name="pa_dia"
                                        disabled
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ width: '130px' }}
                                    />
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <FavoriteIcon />
                                    <TextField
                                        label="FC"
                                        type="number"
                                        value={triagemData?.fc}
                                        name="fc"
                                        InputLabelProps={{ shrink: true }}
                                        disabled
                                        sx={{ width: '120px', marginLeft: '.5rem' }}
                                    />
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <IconLungs />
                                    <TextField
                                        label="FR"
                                        type="number"
                                        value={triagemData?.fr}
                                        name="fc"
                                        InputLabelProps={{ shrink: true }}
                                        disabled
                                        sx={{ width: '120px', marginLeft: '.5rem' }}
                                    />
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <VaccinesIcon />
                                    <TextField
                                        label="DEXTRO"
                                        type="number"
                                        value={triagemData?.dextro}
                                        name="fc"
                                        InputLabelProps={{ shrink: true }}
                                        disabled
                                        sx={{ width: '120px', marginLeft: '.5rem' }}
                                    />
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <MonitorWeightIcon />
                                    <TextField
                                        disabled
                                        label="Peso"
                                        name="peso"
                                        type="text"
                                        value={triagemData.peso}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ width: '130px', marginLeft: '.5rem' }}
                                    />
                                    <IconArrowAutofitHeight sx={{ margin: '0 5px' }} />
                                    <TextField
                                        disabled
                                        label="Altura"
                                        name="altura"
                                        type="text"
                                        value={triagemData.altura}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ width: '130px', marginLeft: '.5rem' }}
                                    />
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <CalculateIcon />
                                    <TextField
                                        label="IMC"
                                        name="IMC"
                                        type="number"
                                        value={triagemData.imc}
                                        disabled
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ width: '120px', marginLeft: '.5rem' }}
                                    />
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <IconThermometer />
                                    <TextField
                                        label="Temperatura"
                                        name="temperatura"
                                        type="text"
                                        value={triagemData?.temperatura}
                                        disabled
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ width: '120px', marginLeft: '.5rem' }}
                                    />
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <IconPercentage />
                                    <TextField
                                        label="Saturação"
                                        name="saturacao"
                                        type="number"
                                        value={triagemData?.saturacao}
                                        disabled
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ width: '120px', marginLeft: '.5rem' }}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{ marginTop: '10px' }}>
                                QUEIXA: <b>{triagemData?.queixa ? triagemData?.queixa : 'QUEIXA NÃO INFORMADA'}</b>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <p>
                                    MÉDICAÇÃO EM USO:
                                    {medicacaoData?.medicacao_em_uso?.length === 0
                                        ? 'NEGA'
                                        : medicacaoData?.medicacao_em_uso?.map((item, index) => (
                                              <span key={index}>
                                                  <b>{item} |</b>{' '}
                                              </span>
                                          ))}
                                </p>
                                <p>
                                    ALERGIA:
                                    {medicacaoData?.alergia?.length === 0
                                        ? 'NEGA'
                                        : medicacaoData?.alergia?.map((item, index) => (
                                              <span key={index}>
                                                  <b>{item} |</b>{' '}
                                              </span>
                                          ))}
                                </p>
                            </Box>
                        </Box>
                    </TriagemArea>

                    <RegisterArea>
                        <Box>
                            <h3>FICHA MÉDICA</h3>
                            <p>{fichaMedica?.conteudo ?? ''}</p>
                        </Box>
                        {/* 
                        <Box>
                            <h3>CONDUTA MÉDICA</h3>
                            {rows?.length === 0 ? (
                                <p>Nenhum dado encontrado</p>
                            ) : (
                                <DataGrid sx={{ height: '200px' }} columns={columns} rows={rows} hideFooter />
                            )}
                        </Box> */}

                        {/* <Box className="hypothesi-area">
                            <h3>hipótese diagnóstica</h3>
                            <Box>
                                <span>PRINCIPAL</span>
                                <span>SECUNDÁRIA</span>
                            </Box>
                            <Box>
                                <span>HD 3</span>
                                <span>HD 4</span>
                            </Box>
                        </Box> */}
                    </RegisterArea>
                </Box>
            </Modal>
        </div>
    );
}
