'use strict';
import { useParams, useNavigate } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import {
    Button,
    Grid,
    Box,
    Tooltip,
    TextField,
    FormControl,
    MenuItem,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useState, useEffect } from 'react';
import { useApi } from 'Service/axios';
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import { toast } from 'react-toastify';
import ActionButtonEdit from 'components/Estoque/Movimentos/BaixaFicha/EDIT/ActionButtonEdit';
import ActionButtonsEditCreate from 'components/Estoque/Movimentos/BaixaFicha/EDIT/ActionButtonEditCreate';
// ============================= INITIALSTATE =============================
const initialState = {
    documento: '',
    emissao: '',
    movimento: new Date(),
    fornecedor: 0,
    observacao: '',
    itens: []
};

const EditarBaixaFicha = () => {
    // ============================= INSTÂNCIAS =============================
    const api = useApi();
    const navigate = useNavigate();
    const { id } = useParams();
    // ============================= STATES =============================
    const [campos, setCampos] = useState([initialState]);
    const [openCreate, setOpenCreate] = useState(false);
    const [itens, setItens] = useState([]);
    const [listAllItens, setListAllItens] = useState([]);
    const [listAllAlmoxarifado, setListAllAlmoxarifado] = useState([]);
    const [listAllLocalizacao, setListAllLocalizacao] = useState([]);
    const [listAllFornecedor, setListAllFornecedor] = useState([]);
    const [dataForSubmit, setDataForSubmit] = useState(null);
    const [openConfirm, setOpenConfirm] = useState(false);
    const columns = [
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'descricao', headerName: 'DESCRIÇÃO', flex: 3 },
        { field: 'unidade', headerName: 'UN', flex: 2 },
        { field: 'quantidade', headerName: 'QNTD', flex: 1 },
        { field: 'valor_unitario', headerName: 'VALOR UN.', flex: 1 },
        { field: 'valor_total', headerName: 'VALOR T.', flex: 1 },
        {
            field: 'acao',
            headerName: 'AÇÕES',
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <ActionButtonEdit
                    status={campos[0].status}
                    setCampos={setCampos}
                    itens={itens}
                    setItens={setItens}
                    row={params.row}
                    atualizarCampoItem={atualizarCampoItem}
                    removerCampoItem={removerCampoItem}
                    listAllItens={listAllItens}
                    listAllAlmoxarifado={listAllAlmoxarifado}
                    listAllLocalizacao={listAllLocalizacao}
                />
            )
        }
    ];

    //reduce

    const somaValorTotal = itens.reduce((acumulador, objeto) => {
        return acumulador + Number(objeto.valor_total);
    }, 0);

    // ============================= FUNÇÕES =============================
    const atualizarCampo = (indiceCampo, campo, valor) => {
        setCampos((prevCampos) => {
            if (campo === 'fornecedor') {
                const novosCampos = [...prevCampos];
                novosCampos[indiceCampo][campo] = parseInt(valor);
                return novosCampos;
            } else {
                const novosCampos = [...prevCampos];
                novosCampos[indiceCampo][campo] = valor;
                return novosCampos;
            }
        });
    };

    const adicionarCampoItem = (indiceCampo) => {
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            const arrItens = novosCampos[0].itens;
            const newItens = [
                ...arrItens,
                {
                    action: 'create',
                    item_id: '',
                    descricao: '',
                    quantidade: 0,
                    unidade: '',
                    indiceCampoItem: '',
                    valor_unitario: 0,
                    valor_total: 0,
                    almoxarifado_id: '',
                    localizacao_id: '',
                    lote: '',
                    validade: '',
                    observacao: ''
                }
            ];
            novosCampos[indiceCampo].itens = newItens;
            return novosCampos;
        });
    };

    const removerCampoItem = (indiceCampo, indiceCampoItem) => {
        // remover o item do data grid
        setItens((prevItens) => {
            const novosItens = [...prevItens];
            const teste = novosItens.find((item) => item.indiceCampoItem === indiceCampoItem);
            const filtredItens = novosItens.filter((item) => item !== teste);
            return filtredItens;
        });
        // achar o item deletado e colocar a action 'delete'
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            const indiceItem = novosCampos[0].itens.findIndex((item) => item.indiceCampoItem === indiceCampoItem);

            if (indiceItem !== -1) {
                const item = novosCampos[0].itens[indiceItem];
                item.action = 'delete';
            }

            return novosCampos;
        });
        if (openCreate) {
            setOpenCreate(false);
        }
    };

    const atualizarCampoItem = (indiceCampo, indiceCampoItem, campo, valor) => {
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            novosCampos[indiceCampo].itens[indiceCampoItem][campo] = valor;
            return novosCampos;
        });
    };

    const handleSelectionModelChange = (newSelection) => {
        setSelectedItems(newSelection);
    };

    const handleClose = () => {
        setOpenCreate(false);
    };

    const handleOpenAndCreateCampoItem = (indiceCampo) => {
        setOpenCreate(true);
        adicionarCampoItem(indiceCampo);
    };

    const getListAllItens = async () => {
        const response = await api.getAllItems();
        setListAllItens(response.data);
    };

    const getListAllAlmoxarifado = async () => {
        const response = await api.getAlmoxarifados();
        setListAllAlmoxarifado(response.data);
    };

    const getListAllLocalizacao = async () => {
        const response = await api.getAllLocalizacoes();
        setListAllLocalizacao(response.data);
    };

    const getAllFonecedor = async () => {
        const response = await api.getAllFornecedor();
        setListAllFornecedor(response.data);
    };

    const getEntradaById = async (entradaId) => {
        const response = await api.getEntradaEstoqueById(entradaId);
        // ==== montando objeto de cada item ====
        const data = response.data;
        data.itens.forEach((item, index) => {
            item.descricao = item.item.descricao;
            item.unidade = item.item.unidade.descricao;
            item.action = 'update';
            delete item.item;
        });
        // ==== montando body de campos ====
        const objeto = [
            {
                id: data.id,
                documento: data.numero_documento,
                observacao: data.observacao,
                emissao: data.data_emissao,
                movimento: data.data_entrada,
                fornecedor: data.fornecedor_id,
                status: data.status,
                itens: data.itens
            }
        ];
        setCampos(objeto);
        setItens(data.itens);
    };

    const getDate = () => {
        const date = new Date();
        let currentDay = String(date.getDate()).padStart(2, '0');
        let currentMonth = String(date.getMonth() + 1).padStart(2, '0');
        let currentYear = date.getFullYear();
        let currentDate = `${currentYear}-${currentMonth}-${currentDay}`;
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            novosCampos[0].movimento = currentDate;
            return novosCampos;
        });
    };

    useEffect(() => {
        getListAllItens();
        getListAllAlmoxarifado();
        getListAllLocalizacao();
        getAllFonecedor();
        getDate();
        getEntradaById(id);
    }, []);

    //====== SUBMIT ======
    const handleSubmit = async (e, campo) => {
        e.preventDefault();
        const novosItens = [...itens];
        let arrDelete = [];

        // compara os dois estados, e adiciona em itens os "itens" que foram deletados, mas estão armazenados em campos[0].itens
        // cria um array temporário de deletados para comparar a action, caso seja "delete" da push
        // crio um novo array de itens com os dados atualizados e comparados com o main state de itens do campos[0].itens
        campos[0].itens.forEach((itemCampos) => {
            const itemExistente = novosItens.find(
                (item) => item.item_id === itemCampos.item_id && item.indiceCampoItem === itemCampos.indiceCampoItem
            );

            if (!itemExistente || itemExistente === undefined) {
                arrDelete.push(itemCampos);
            }

            if (itemCampos.action === 'delete') {
                arrDelete.push(itemCampos);
            }
        });

        // se o array de deletados tiver item, adiciona no itens
        if (arrDelete.length > 0) {
            novosItens.push(...arrDelete);
        }
        let a = [...novosItens];

        // passa as actions armazenadas no itens de campos[0].itens para o state itens
        // caso n tenha validade ou lote substituir para null
        // caso a action seja de "create", tiro o campo id antes de enviar pro backend
        for (let i = 0; i < itens.length; i++) {
            const itemItens = itens[i];
            const itemCampos = campos[0].itens.find(
                (item) => item.item_id === itemItens.item_id && item.indiceCampo === itemItens.indiceCampoItem
            );

            if (itemCampos) {
                itemItens.action = itemCampos.action;
            }

            itemItens.validade = itemItens.validade === '' ? null : itemItens.validade;
            itemItens.lote = itemItens.lote === '' ? null : itemItens.lote;

            if (itemItens.action === 'create') {
                delete itemItens.id;
            }
        }
        // pega todos os itens de campos "reais" (que não foram iniciados mas não concluiu a criação no modal)
        // verifica o id do item campos com o item de itens
        // fix na action update
        const teste = campos[0].itens
            .filter((k) => k.descricao !== '')
            .map((el) => {
                const sameId = a.find((item) => item.id === el.id);
                if (!sameId) return;
                const objeto = { ...sameId };
                if (!objeto.id && objeto.action === 'delete') {
                    return;
                }
                if (!objeto.id && objeto.action === 'update') {
                    objeto.action = 'create';
                }
                if (objeto.validade === '') {
                    delete el.validade;
                }
                if (objeto.lote === '') {
                    delete el.lote;
                }
                if (objeto.action === 'delete') {
                    a.push(objeto);
                }
                return objeto;
            });
        const payloadItens = teste.filter((item) => item !== undefined);
        const data = { entrada: campo, itens: payloadItens };

        try {
            await api.updateEntradaEstoque(campos[0].id, data.entrada, data.itens);
            setCampos(undefined);
            setCampos([
                {
                    documento: '',
                    emissao: '',
                    movimento: new Date(),
                    fornecedor: 0,
                    observacao: '',
                    itens: []
                }
            ]);
            setItens([]);
            setOpenCreate(false);
            navigate('/main/estoque/entrada');
            toast.success('Entrada cadastrada');
        } catch (error) {
            toast.error(`Erro ao editar entrada: ${error.response.data.error}`);
        }
    };

    return (
        <MainCard title="Editando Entrada">
            <Dialog open={openConfirm}>
                <DialogTitle>Atenção!</DialogTitle>
                <DialogContent>Ao continuar ficará indisponível realizar a edição outra vez, deseja continuar?</DialogContent>
                <DialogActions>
                    <Button color="error" onClick={() => setOpenConfirm(false)}>
                        Cancelar
                    </Button>
                    <Button variant="contained" onClick={(e) => handleSubmit(e, dataForSubmit)}>
                        Continuar
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid container spacing={gridSpacing}>
                <Box sx={{ width: '80%', margin: '0 auto' }}>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', marginLeft: '1em', marginTop: '1em' }}>
                        {campos.map((campo, indiceCampo) => (
                            <>
                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: '1em',
                                            width: '100%',
                                            marginBottom: '1em'
                                        }}
                                    >
                                        <FormControl fullWidth>
                                            <TextField
                                                type="text"
                                                label="Nota"
                                                disabled={campo.status}
                                                value={campo.documento}
                                                onChange={(e) => atualizarCampo(indiceCampo, 'documento', e.target.value)}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <TextField
                                                type="date"
                                                label="Data emissão"
                                                disabled={campo.status}
                                                value={campo.emissao}
                                                onChange={(e) => atualizarCampo(indiceCampo, 'emissao', e.target.value)}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <TextField
                                                type="date"
                                                label="Movimento"
                                                value={campo.movimento}
                                                disabled
                                                onChange={(e) => atualizarCampo(indiceCampo, 'movimento', e.target.value)}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <TextField
                                                select
                                                label="Fornecedor"
                                                disabled={campo.status}
                                                value={campo.fornecedor}
                                                onChange={(e) => atualizarCampo(indiceCampo, 'fornecedor', e.target.value)}
                                            >
                                                {listAllFornecedor.map((fornecedor) => (
                                                    <MenuItem key={fornecedor.id} value={fornecedor.id}>
                                                        {fornecedor.nome_fantasia}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </FormControl>
                                    </Box>
                                    <Box>
                                        <FormControl sx={{ width: '50%' }}>
                                            <TextField
                                                multiline
                                                minRows={3}
                                                label="Observação"
                                                placeholder="Observação"
                                                disabled={campo.status}
                                                value={campo.observacao}
                                                onChange={(e) => atualizarCampo(indiceCampo, 'observacao', e.target.value)}
                                            />
                                        </FormControl>
                                    </Box>
                                </Box>
                                <Box sx={{ width: '100%', marginTop: '2em', borderTop: '2px dashed #eee' }}>
                                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '1em' }}>
                                        <Box>
                                            <Typography variant="h4">Relação de itens</Typography>
                                        </Box>
                                        <Box>
                                            <Tooltip title="Adicionar item">
                                                <Button
                                                    variant="contained"
                                                    color="success"
                                                    disabled={campo.status}
                                                    onClick={(e) => handleOpenAndCreateCampoItem(indiceCampo)}
                                                >
                                                    <AddIcon />
                                                </Button>
                                            </Tooltip>
                                        </Box>
                                    </Box>

                                    <Box sx={{ marginTop: '2em', display: 'flex', flexDirection: 'column', height: '300px' }}>
                                        <DataGrid
                                            rows={itens}
                                            columns={columns}
                                            hideFooter={true}
                                            onSelectionModelChange={() => handleSelectionModelChange(params.id)}
                                        />

                                        <Box
                                            sx={{
                                                width: '40%',
                                                marginLeft: 'auto',
                                                marginTop: '1em',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                <h4>Valor Total:</h4>
                                                <Box
                                                    sx={{
                                                        border: '1px solid #ccc',
                                                        padding: '8px 16px',
                                                        borderRadius: 2
                                                    }}
                                                >
                                                    {somaValorTotal.toFixed(2)}
                                                </Box>
                                            </Box>

                                            <Tooltip title="Salvar entrada">
                                                <Button
                                                    variant="contained"
                                                    color="success"
                                                    disabled={campo.status}
                                                    onClick={(e) => {
                                                        setOpenConfirm(true), setDataForSubmit(campo);
                                                    }}
                                                >
                                                    <SaveIcon />
                                                </Button>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                </Box>
                                {campos[0]?.itens.map((item, indiceCampoItem) => (
                                    <ActionButtonsEditCreate
                                        key={indiceCampoItem}
                                        open={openCreate}
                                        handleClose={handleClose}
                                        itens={itens}
                                        setItens={setItens}
                                        campos={campos}
                                        setCampos={setCampos}
                                        adicionarCampoItem={adicionarCampoItem}
                                        atualizarCampoItem={atualizarCampoItem}
                                        removerCampoItem={removerCampoItem}
                                        indiceCampoItem={indiceCampoItem}
                                        item={item}
                                        listAllItens={listAllItens}
                                        listAllAlmoxarifado={listAllAlmoxarifado}
                                        listAllLocalizacao={listAllLocalizacao}
                                    />
                                ))}
                            </>
                        ))}
                    </Box>
                </Box>
            </Grid>
        </MainCard>
    );
};

export default EditarBaixaFicha;
