import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { ConjuntoLaudoContext } from 'context/LaudoContext';
import { useContext } from 'react';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import formateDate from 'utils/formatDate-1';
import { useState } from 'react';
import Logo from '../../../../assets/images/Logos/LOGO_DO_LAC.jpg';
import SantaCasaLogo from '../../../../assets/images/Logos/logo2.png';
import { fontSize } from '@mui/system';
import BwLogo from '../../../../assets/BW TECNOLOGIA.png';

const PdfRelatorioPeriodoPaciente = ({ data }) => {
    const styles = {
        page: {
            paddingTop: 15,
            paddingBottom: 15,
            paddingHorizontal: 15,
            backgroundColor: '#fff',
            fontSize: 10,
            fontWeight: 'normal',
            rap: 'wrap'
        },
        header: {
            padding: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        },
        image: {
            width: 100,
            backgroundSize: 'cover'
        },
        text1: {
            fontSize: 10
        },
        box: {
            border: '1px solid #ddd',
            padding: 10,

            borderRadius: 5
        },
        between: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        labelArea: {
            backgroundColor: '#ccc',
            padding: '8px',
            borderRadius: 5,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },

        row: { flexDirection: 'row', borderBottom: '1 solid #000' },
        cell: { flexGrow: 1, padding: 5 }
    };

    const formatarData = (date) => {
        const dateObj = new Date(date);
        const year = dateObj.getUTCFullYear();
        const month = ('0' + (dateObj.getUTCMonth() + 1)).slice(-2);
        const day = ('0' + dateObj.getUTCDate()).slice(-2);
        return `${day}/${month}/${year}`;
    };

    function DinamicTable({ dados }) {
        const styles = {
            table: { width: '100%', borderCollapse: 'collapse', margin: '5px' },
            cell: { borderBottom: '1px solid #999', padding: '5px', wordWrap: 'break-word' }
        };

        return (
            <View style={{ top: '4px', paddingLeft: '15px', paddingTop: '15px' }}>
                {Object.keys(dados)?.map((itemID, index) => (
                    <>
                        <Text
                            key={index}
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', left: '250px', fontSize: 12 }}
                        >
                            FICHA:{itemID}
                        </Text>

                        <View
                            key={index}
                            style={{
                                breakInside: 'auto',

                                flexDirection: 'column',
                                alignItems: 'start',
                                marginTop: '10px',

                                paddingBottom: '15px'
                            }}
                        >
                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}
                            >
                                <View style={{ display: 'flex', flexDirection: 'column', width: '20%', paddingLeft: '6px' }}>
                                    <Text style={{ fontSize: 10 }}>DATA</Text>
                                </View>
                                <View style={{ display: 'flex', flexDirection: 'column', width: '30%', fontSize: 10, left: '12px' }}>
                                    <Text>MEDICO</Text>
                                </View>

                                <View
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '35%',

                                        fontSize: 10,
                                        left: '107px'
                                    }}
                                >
                                    <Text>CÓDIGO</Text>
                                </View>
                                <View
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '45%',

                                        fontSize: 10,
                                        left: '68px'
                                    }}
                                >
                                    <Text>DESCRIÇÃO</Text>
                                </View>
                                <View
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '35%',

                                        fontSize: 10,
                                        left: '98px'
                                    }}
                                >
                                    <Text>UN</Text>
                                </View>
                                <View
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '35%',

                                        fontSize: 10,
                                        left: '69px'
                                    }}
                                >
                                    <Text>QTD</Text>
                                </View>
                            </View>
                            <View style={{ border: '1px solid #000000', width: '100%', marginTop: '4px' }}></View>

                            {dados[itemID]?.map((itemFicha, index) => (
                                <View
                                    key={itemFicha?.prontuario?.id}
                                    style={{
                                        paddingTop: '5px',
                                        width: '100%',

                                        flexDirection: 'column'
                                    }}
                                >
                                    <View
                                        style={{
                                            width: '80%',

                                            display: 'flex',
                                            flexDirection: 'row'
                                        }}
                                    >
                                        <View
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                paddingLeft: '5px',
                                                width: '15%',
                                                gap: '.3rem',
                                                color: '#000000d2'
                                            }}
                                        >
                                            <Text>{itemFicha?.data}</Text>
                                            <Text>{itemFicha?.hora}</Text>
                                        </View>
                                        <View
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'start',
                                                alignItems: 'flex-start',

                                                width: '110%',

                                                /* marginRight: '60px', */
                                                paddingLeft: '40px'
                                            }}
                                        >
                                            <Text style={{ fontSize: 9, color: '#000000d2' }}>{itemFicha?.medico?.nome}</Text>
                                        </View>
                                        <View
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'start',
                                                alignItems: 'flex-start',
                                                width: '10%',
                                                left: '13px'
                                            }}
                                        >
                                            <Text style={{ fontSize: 9, color: '#000000d2' }}>{itemFicha?.item?.id}</Text>
                                        </View>
                                        <View
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'start',
                                                alignItems: 'flex-start',
                                                width: '70%',
                                                left: '40px'

                                                /* paddingLeft: '85px' */
                                            }}
                                        >
                                            <Text style={{ fontSize: 9, color: '#000000d2', left: '10px' }}>
                                                {itemFicha?.item?.descricao}
                                            </Text>
                                        </View>
                                        <View
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'end',
                                                alignItems: 'flex-end',
                                                width: '25%',
                                                left: '65px'

                                                /* paddingLeft: '85px' */
                                            }}
                                        >
                                            <Text style={{ fontSize: 9, color: '#000000d2' }}>{itemFicha?.item?.unidade?.descricao}</Text>
                                        </View>
                                        <View
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'end',
                                                alignItems: 'flex-end',
                                                width: '5%',
                                                left: '95px'

                                                /* paddingLeft: '85px' */
                                            }}
                                        >
                                            <Text style={{ fontSize: 9, color: '#000000d2', left: '10px' }}>{itemFicha?.quantidade}</Text>
                                        </View>
                                    </View>
                                    <View style={{ border: '0.5px solid #999', width: '100%', marginTop: '5px' }}></View>
                                </View>
                            ))}
                            {/* <Text style={{ paddingLeft: '75%', fontSize: 10 }}>TOTAL: {dados[itemFicha].length}</Text> */}
                        </View>
                    </>
                ))}
            </View>
        );
    }
    const examesAgrupadosPorNome = Object.values(data?.dataPdf || {}).reduce((agrupados, item) => {
        const exames = item[0]?.item?.descricao;

        if (agrupados[exames]) {
            agrupados[exames].push(item[0]);
        } else {
            agrupados[exames] = [item[0]];
        }

        return agrupados;
    }, {});
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                {/*  <View style={styles.header}>
                    <Image src={Logo} style={styles.image} alt="logo" />
                    <Text>LABORATÓRIO DE ANÁLISES CLÍNICAS SANTA CASA DE MISERICÓRDIA DE JOANÓPOLIS</Text>
                </View> */}
                <View
                    sx={{
                        width: '100%',
                        display: 'flex',
                        padding: '.5em',
                        gap: '10px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: '20px',
                        borderTop: '1px solid #000'
                    }}
                >
                    <Image src={SantaCasaLogo} alt="Santa Casa Logotipo" style={{ width: '8%', left: '250px' }} />
                    <View style={{ left: '180px', fontSize: 7 }}>
                        <Text>SANTA CASA DE MISERICÓRDIA DE JOANÓPOLIS</Text>
                    </View>
                </View>

                <View style={styles.box}>
                    <View style={styles.labelArea}>
                        <View style={{ gap: '5px' }}>
                            <Text>RELÁTORIO DE PACIENTE POR PERÍODO </Text>
                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    paddingBottom: '5px',
                                    gap: '30px',
                                    fontSize: 10
                                }}
                            >
                                <Text>DATA INICIO: {formatarData(data.dataDate?.firstDate)}</Text>
                                <Text>
                                    DATA FINAL: <Text>{formatarData(data.dataDate?.lastDate)}</Text>
                                </Text>
                            </View>
                        </View>

                        <View style={{ gap: '5px' }}>
                            <Text>
                                CÓDIGO DO PACIENTE: <Text style={{ fontSize: 9 }}>{`${data?.dataDate?.pacienteId?.id}`}</Text>
                            </Text>
                            <Text>
                                PACIENTE:
                                <Text style={{ fontSize: 9 }}>{`${data?.dataDate?.pacienteId?.nome_completo}`}</Text>
                            </Text>
                        </View>
                    </View>
                </View>
                <View>
                    <DinamicTable dados={data?.dataPdf} />
                </View>

                <View
                    fixed
                    style={{
                        width: '100%',
                        maxHeight: '8%',
                        display: 'flex',
                        paddingTop: '25px',
                        gap: '150px',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <View
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '50%',
                            justifyContent: 'flex-start',
                            alignItems: 'center'
                        }}
                    >
                        <Image fixed src={SantaCasaLogo} alt="Santa Casa Logotipo" style={{ width: '30px' }} />
                        <View
                            fixed
                            style={{
                                padding: '1.5em',
                                gap: '5px',
                                fontSize: 5
                            }}
                        >
                            <Text>SANTA CASA DE MISERICÓRDIA DE JOANÓPOLIS</Text>
                            <Text>RUA FRANCISCO WOLHERS N°57 - CENTRO - JOANÓPOLIS - SP</Text>
                            <Text>CEP: 12980-000 - TELEFONE: (11) 4539-9333</Text>
                        </View>
                    </View>
                    <Image style={{ display: 'flex', width: '15%', justifyContent: 'flex-end', alignItems: 'center' }} src={BwLogo} />
                </View>
                <Text
                    style={{ marginLeft: '88%', paddingBottom: '10px', paddingTop: '10px' }}
                    fixed
                    render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                />
            </Page>
        </Document>
    );
};

export default PdfRelatorioPeriodoPaciente;
