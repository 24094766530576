import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { InputArea } from './styles';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, MenuItem, TextField } from '@mui/material';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2
};

export function AddExamModal({ get, currentRow, open, setOpen, setCurrentRow }) {
    const api = useApi();
    const [grupos, setGrupos] = useState([]);
    const [grupoSelecionado, setGrupoSelecionado] = useState('');
    const [selectedCheckboxes, setSelectedCheckboxes] = useState({});

    const getGrupos = async () => {
        try {
            const { data } = await api.getAllGruposRaioX();
            setGrupos(data);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    };

    const handleClose = () => {
        setCurrentRow(null);
        setOpen(false);
    };

    const [xray, setXray] = useState('');
    const [codigo, setCodigo] = useState('');

    useEffect(() => {
        getGrupos();
    }, [open]);

    useEffect(() => {
        if (currentRow) {
            const grupoId = currentRow.grupo_id;
            setGrupoSelecionado(grupoId);
            setXray(currentRow.descricao || '');
            setCodigo(currentRow.codigo || '');

            // Inicializa os checkboxes selecionados com base no currentRow.tipos
            const initialSelectedCheckboxes = {};
            currentRow.tipos?.forEach((tipo) => {
                initialSelectedCheckboxes[tipo] = true;
            });
            setSelectedCheckboxes(initialSelectedCheckboxes);
        } else {
            setXray('');
            setCodigo('');
            setSelectedCheckboxes({});
        }
    }, [currentRow]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (xray.trim().length === 0) {
            return toast.error('A Descrição não pode ser vazia');
        }
        const selectedGroups = Object.keys(selectedCheckboxes).filter((key) => selectedCheckboxes[key]);

        try {
            await api.createXRay({ descricao: xray.toUpperCase(), codigo: codigo, grupo_id: grupoSelecionado, tipos: selectedGroups });
            handleClose();
            toast.success('Raio-x cadastrado com sucesso!');
            get();
            setXray('');
            setCodigo('');
        } catch (error) {
            toast.error(error?.response?.data.message ?? error?.message);
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        if (xray.trim().length === 0) {
            return toast.error('A Descrição não pode ser vazia');
        }
        const selectedGroups = Object.keys(selectedCheckboxes).filter((key) => selectedCheckboxes[key]);

        try {
            await api.updateXRay(currentRow.id, {
                descricao: xray.toUpperCase(),
                grupo_id: grupoSelecionado,
                codigo: codigo,
                tipos: selectedGroups
            });
            handleClose();
            toast.success('Raio-x atualizado com sucesso!');
            get();
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    };

    const handleCheckboxChange = (event) => {
        setSelectedCheckboxes({
            ...selectedCheckboxes,
            [event.target.name]: event.target.checked
        });
    };

    return (
        <div>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h4" component="h2">
                        {currentRow ? 'Edição de Raio-X' : 'Cadastro de Raio-X'}
                    </Typography>
                    <InputArea onSubmit={currentRow ? handleUpdate : handleSubmit}>
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                            <Box sx={{ display: 'flex', gap: '1rem' }}>
                                <TextField label="Raio-x" fullWidth value={xray} onChange={(e) => setXray(e.target.value)} />
                                <TextField label="Codigo" fullWidth value={codigo} onChange={(e) => setCodigo(e.target.value)} />
                                <TextField
                                    select
                                    label="Grupo"
                                    value={grupoSelecionado}
                                    fullWidth
                                    onChange={(e) => setGrupoSelecionado(e.target.value)}
                                    sx={{ minWidth: '220px' }}
                                >
                                    {grupos.map((grupo) => (
                                        <MenuItem key={grupo.id} value={grupo.id}>
                                            {grupo.descricao}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                        </Box>

                        <Button variant="contained" type="submit" sx={{ width: '300px', margin: '10px auto' }}>
                            {currentRow ? 'Editar' : 'Cadastrar'}
                        </Button>
                    </InputArea>
                </Box>
            </Modal>
        </div>
    );
}
