import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import {
    IconAdjustments,
    IconBoxSeam,
    IconCurrentLocation,
    IconRuler3,
    IconCategory2,
    IconBrandSuperhuman,
    IconBuildingFactory2,
    IconArrowAutofitWidth,
    IconArrowBarDown,
    IconArrowBarUp,
    IconArrowBearLeft2,
    IconScale,
    IconListSearch,
    IconCubeSend,
    IconFileAnalytics,
    IconArrowAutofitDown,
    IconFirstAidKit,
    IconArrowBarLeft,
    IconFolderPlus,
    IconClipboardPlus
} from '@tabler/icons';

const icons = {
    IconAdjustments,
    IconBoxSeam,
    IconCurrentLocation,
    IconRuler3,
    IconCategory2,
    IconBrandSuperhuman,
    IconBuildingFactory2,
    IconArrowAutofitWidth,
    IconArrowBarDown,
    IconArrowBarUp,
    IconArrowBearLeft2,
    IconScale,
    IconListSearch,
    IconCubeSend,
    IconFileAnalytics,
    IconArrowAutofitDown,
    IconFirstAidKit,
    IconArrowBarLeft,
    IconFolderPlus,
    IconClipboardPlus,
    TroubleshootIcon
};

const estoque = {
    id: 'estoqueMenu',
    title: 'Farmácia',
    type: 'group',
    children: [
        {
            id: 'pedidosFarmacia',
            title: 'Pedidos',
            type: 'collapse',
            icon: icons.IconFolderPlus,
            children: [
                {
                    id: 'atendimentosFarmacia',
                    title: 'Atendimentos Pendentes',
                    type: 'item',
                    url: '/main/estoque/farmacia/atendimentos',
                    icon: icons.IconClipboardPlus,
                    breadcrumbs: false
                },
                {
                    id: 'consultaAtendimentosFarmacia',
                    title: 'Consulta',
                    type: 'item',
                    url: '/main/estoque/farmacia/atendimentos/consulta',
                    icon: icons.TroubleshootIcon,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'cadastroEstoque',
            title: 'Cadastros',
            type: 'collapse',
            icon: icons.IconAdjustments,
            children: [
                {
                    id: 'almoxarifadoEstoque',
                    title: 'Almoxarifado',
                    type: 'item',
                    url: '/main/estoque/cadastros/almoxarifado',
                    icon: icons.IconBoxSeam,
                    breadcrumbs: false
                },
                {
                    id: 'localizacaoEstoque',
                    title: 'Localização',
                    type: 'item',
                    url: '/main/estoque/cadastros/localizacao',
                    icon: icons.IconCurrentLocation,
                    breadcrumbs: false
                },
                {
                    id: 'unidadeEstoque',
                    title: 'Unidade',
                    type: 'item',
                    url: '/main/estoque/cadastros/unidade',
                    icon: icons.IconRuler3,
                    breadcrumbs: false
                },
                {
                    id: 'categoriaEstoque',
                    title: 'Categoria',
                    type: 'item',
                    url: '/main/estoque/cadastros/categoria',
                    icon: icons.IconCategory2,
                    breadcrumbs: false
                },
                {
                    id: 'itemEstoque',
                    title: 'Item',
                    type: 'item',
                    url: '/main/estoque/cadastros/item',
                    icon: icons.IconBrandSuperhuman,
                    breadcrumbs: false
                },
                {
                    id: 'centroCustoEstoque',
                    title: 'Kits',
                    type: 'item',
                    url: '/main/estoque/cadastro/kits',
                    icon: icons.IconFirstAidKit,
                    breadcrumbs: false
                },
                {
                    id: 'fornecedorEstoque',
                    title: 'Fornecedor',
                    type: 'item',
                    url: '/main/estoque/cadastros/fornecedor',
                    icon: icons.IconBuildingFactory2,
                    breadcrumbs: false
                },
                {
                    id: 'centroCustoEstoque',
                    title: 'Centro de Custo',
                    type: 'item',
                    url: '/main/estoque/cadastros/centro-custo',
                    icon: icons.IconBuildingFactory2,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'entradaEstoque',
            title: 'Entrada',
            type: 'item',
            url: '/main/estoque/entrada',
            icon: icons.IconCubeSend,
            breadcrumbs: false
        },
        {
            id: 'movimentosEstoque',
            title: 'Movimentos',
            type: 'collapse',
            icon: icons.IconArrowAutofitWidth,
            children: [
                {
                    id: 'baixaManual',
                    title: 'Baixa Manual',
                    type: 'item',
                    url: '/main/estoque/baixa-manual',
                    icon: icons.IconArrowBarDown,
                    breadcrumbs: false
                },
                {
                    id: 'baixaFicha',
                    title: 'Baixa Por Ficha',
                    type: 'item',
                    url: '/main/estoque/baixa-ficha/cadastro',
                    icon: icons.IconArrowAutofitDown,
                    breadcrumbs: false
                },
                {
                    id: 'estorno',
                    title: 'Estorno por Ficha',
                    type: 'item',
                    url: '/main/estoque/estorno',
                    icon: icons.IconArrowBarLeft,
                    breadcrumbs: false
                },
                {
                    id: 'entradaManual',
                    title: 'Entrada Manual',
                    type: 'item',
                    url: '/main/estoque/entrada-manual',
                    icon: icons.IconArrowBarUp,
                    breadcrumbs: false
                },
                {
                    id: 'transferenciaManual',
                    title: 'Transferencia Manual',
                    type: 'item',
                    url: '/main/estoque/transferencia-manual',
                    icon: icons.IconArrowBearLeft2,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'saldoGeral',
            title: 'Saldo Geral',
            type: 'item',
            url: '/main/estoque/saldo-geral',
            icon: icons.IconScale,
            breadcrumbs: false
        },
        {
            id: 'kardex',
            title: 'Kardex',
            type: 'item',
            url: '/main/estoque/kardex',
            icon: icons.IconListSearch,
            breadcrumbs: false
        },
        {
            id: 'relatoriosEstoque',
            title: 'Relatórios',
            type: 'item',
            url: '/main/estoque/relatorios',
            icon: icons.IconFileAnalytics,
            breadcrumbs: false
        }
    ]
};

export default estoque;
