import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Drawer,
    FormControl,
    MenuItem,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';

const ActionButtons = ({
    setItens,
    row,
    removerCampoItem,
    listAllItens,
    listAllAlmoxarifado,
    listAllLocalizacao,
    filtredLocations,
    setFiltredLocations,
    handleGetLotes,
    lotesItem,
    setPsicotropico
}) => {
    /////////////////////////////// STATES /////////////////////////
    const [open, setOpen] = useState(false);
    const [itemSelecionado, setItemSelecionado] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedItem, setSelectedItem] = useState();

    /////////////////////////////// FUNÇÕES /////////////////////////
    const handleOpen = async () => {
        setOpen(true);
    };

    const handleItemSelect = async (selectedItemId) => {
        const selectedItem = listAllItens.find((item) => item.id === selectedItemId?.id || selectedItemId?.iten?.id);

        setFiltredLocations(listAllLocalizacao.filter((localizacao) => localizacao.almoxarifado_id === selectedItem.almoxarifado.id));
        setSelectedItem(selectedItemId);
        const lotes = await handleGetLotes(selectedItem);

        setItemSelecionado({
            ...itemSelecionado,
            item_id: selectedItem.id,
            descricao: selectedItem.descricao,
            unidade: selectedItem.unidade.descricao,
            almoxarifado_id: selectedItem.almoxarifado.id,
            localizacao_id: selectedItem.localizacao.id,
            observacao: selectedItem.observacao,
            statusLote: selectedItem?.lote ? true : false,
            disponivel: lotes?.data[0]?.quantidade_disponivel,
            quantidade: selectedItem.quantidade
        });

        if (selectedItem?.iten?.psicotropico || itemSelecionado?.psicotropico) setPsicotropico(true);
    };

    const atualizarCampoItemSelecionado = async (item, campo, valor) => {
        setItemSelecionado({
            ...item,
            [campo]: campo === 'item_id' || campo === 'quantidade' ? parseInt(valor) : valor
        });
        if (campo === 'almoxarifado_id') {
            setFiltredLocations(listAllLocalizacao.filter((localizacao) => localizacao?.almoxarifado_id === valor));
        } else if (campo === 'localizacao_id') {
            const payload = { id: item?.item_id || item?.iten?.id, almoxarifado_id: item?.almoxarifado_id, localizacao_id: valor };
            try {
                const lotes = await handleGetLotes(payload);

                setItemSelecionado((prevItem) => ({
                    ...prevItem,
                    disponivel: lotes?.data[0]?.quantidade_disponivel || 0
                }));
            } catch (error) {
                toast.error('Erro ao obter lotes:', error);
            }
        } else if (campo === 'lote') {
            const payload = {
                id: item?.item_id || item?.iten?.id,
                almoxarifado_id: item?.almoxarifado_id,
                localizacao_id: item?.localizacao_id
            };
            try {
                const lotes = await handleGetLotes(payload);

                setItemSelecionado((prevItem) => ({
                    ...prevItem,
                    // lote: value,
                    disponivel: lotes?.data[0]?.quantidade_disponivel || 0
                }));
            } catch (error) {
                toast.error('Erro ao obter lotes:', error);
            }
        }
    };

    const handleEditItem = (itemSelecionado) => {
        setItens((prevItens) => {
            const indiceItem = prevItens.findIndex((item) => item.uuid === itemSelecionado.uuid);
            if (indiceItem !== -1) {
                const novosItens = [...prevItens];
                novosItens[indiceItem] = itemSelecionado;
                return novosItens;
            }
            return prevItens;
        });
        setOpen(false);
    };

    useEffect(() => {
        const novoStatusLote = !!row.psicotropico || !!itemSelecionado?.psicotropico;
        setItemSelecionado({ ...row, statusLote: novoStatusLote });
    }, []);

    return (
        <Box>
            <Dialog open={openDialog}>
                <DialogTitle>Atenção</DialogTitle>
                <DialogContent>
                    <Typography>Ao fechar todo o cadastro desse item sera cancelado, continuar?</Typography>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ display: 'flex' }}>
                        <Button color="error" onClick={() => setOpenDialog(false)}>
                            Cancelar
                        </Button>
                        <Button onClick={() => setOpen(false)}>Confirmar</Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <Drawer anchor="right" open={open}>
                <Box sx={{ width: '650px', padding: '.75em', height: '100%' }}>
                    {itemSelecionado !== null && (
                        <Box>
                            <Box sx={{ width: '100%', display: 'flex', gap: '1em' }}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        options={listAllItens}
                                        getOptionLabel={(item) => `${item.id} - ${item.descricao}`}
                                        value={
                                            listAllItens.find((el) => {
                                                if (selectedItem?.iten?.id) {
                                                    return el?.id === selectedItem?.iten?.id;
                                                } else {
                                                    return el?.id === selectedItem?.item_id;
                                                }
                                            }) || selectedItem
                                        }
                                        onChange={(e, newValue) => {
                                            handleItemSelect(newValue), setSelectedItem(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Item" />}
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <TextField
                                        label="UN"
                                        value={itemSelecionado ? itemSelecionado?.unidade : ''}
                                        disabled
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </FormControl>
                            </Box>
                            <Box sx={{ display: 'flex', gap: '1em', marginTop: '1em', marginBottom: '1em' }}>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Quantidade"
                                        type="number"
                                        value={parseFloat(itemSelecionado.quantidade)}
                                        onChange={(e) =>
                                            atualizarCampoItemSelecionado(itemSelecionado, 'quantidade', parseFloat(e.target.value))
                                        }
                                    />
                                </FormControl>
                                {!itemSelecionado.statusLote && (
                                    <FormControl fullWidth>
                                        <TextField
                                            label="Disponível"
                                            type="number"
                                            value={parseFloat(itemSelecionado.disponivel)}
                                            disabled
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </FormControl>
                                )}
                            </Box>
                            <Box sx={{ display: 'flex', gap: '1em', marginTop: '1em', marginBottom: '1em' }}>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Almoxarifado"
                                        select
                                        value={itemSelecionado.almoxarifado_id}
                                        onChange={(e) => atualizarCampoItemSelecionado(itemSelecionado, 'almoxarifado_id', e.target.value)}
                                    >
                                        {listAllAlmoxarifado.map((almoxarifado) => (
                                            <MenuItem key={almoxarifado.id} value={almoxarifado.id}>
                                                {almoxarifado.descricao}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Localização"
                                        select
                                        value={itemSelecionado.localizacao_id}
                                        onChange={(e) => atualizarCampoItemSelecionado(itemSelecionado, 'localizacao_id', e.target.value)}
                                    >
                                        {filtredLocations.map((localizacao) => (
                                            <MenuItem key={localizacao.id} value={localizacao.id}>
                                                {localizacao.descricao}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>
                            </Box>
                            <Box sx={{ display: 'flex', gap: '1em', marginTop: '1em', marginBottom: '1em' }}>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Observação"
                                        value={itemSelecionado.observacao}
                                        onChange={(e) => atualizarCampoItemSelecionado(itemSelecionado, 'observacao', e.target.value)}
                                    />
                                </FormControl>
                            </Box>
                            {row.psicotropico && (
                                <Box sx={{ display: 'flex', gap: '1em' }}>
                                    <FormControl fullWidth>
                                        <TextField
                                            label="Lote"
                                            select
                                            value={itemSelecionado.lote}
                                            onChange={(e) => {
                                                atualizarCampoItemSelecionado(itemSelecionado, 'lote', e.target.value);
                                            }}
                                        >
                                            {lotesItem.length > 0 ? (
                                                lotesItem
                                                    .filter((lote) => lote.quantidade_disponivel > 0)
                                                    .map((lote) => (
                                                        <MenuItem key={lote.lote} value={lote.lote}>
                                                            {`${lote.lote} = ${lote.quantidade_disponivel}`}
                                                        </MenuItem>
                                                    ))
                                            ) : (
                                                <MenuItem>Sem lotes disponíveis para esta localização e almoxarifado</MenuItem>
                                            )}
                                        </TextField>
                                    </FormControl>
                                </Box>
                            )}
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '2em' }}>
                                <Tooltip title="Fechar e remover">
                                    <Button variant="contained" color="error" onClick={() => setOpen(false)}>
                                        <CloseIcon />
                                    </Button>
                                </Tooltip>
                                <Button variant="contained" onClick={(e) => handleEditItem(itemSelecionado)}>
                                    Salvar alterações
                                </Button>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Drawer>
            <Box sx={{ display: 'flex', gap: 2 }}>
                <Tooltip title="Editar Item">
                    <Button
                        sx={{ all: 'unset', cursor: 'pointer' }}
                        onClick={(e) => {
                            handleOpen(),
                                setSelectedItem(itemSelecionado),
                                atualizarCampoItemSelecionado(itemSelecionado, 'lote', e.target.value);
                        }}
                    >
                        <EditIcon />
                    </Button>
                </Tooltip>
                <Tooltip title="Remover Item">
                    <Button sx={{ all: 'unset', cursor: 'pointer' }} onClick={() => removerCampoItem(row?.uuid)}>
                        <DeleteIcon />
                    </Button>
                </Tooltip>
            </Box>
        </Box>
    );
};

export default ActionButtons;
