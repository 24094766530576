import { Box, Button } from '@mui/material';
import PatientMonitoring from './patientMonitoring';
import LabMonitoring from './labMonitoring';
import MedMonitoring from './medMonitoring';
import NursingCards from './nursingpainel';
import { useEffect, useState } from 'react';
import { useApi } from 'Service/axios';
import Sound from '../../../assets/sound.mp3';
import { rowSelectionStateInitializer } from '@mui/x-data-grid/internals';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';

const NursingPainel = () => {
    const [nurseData, setNurseData] = useState([]);
    const [medicamentoData, setMedicamentoData] = useState([]);
    const [laboratorioData, setLaboratorioData] = useState([]);
    const [previousData, setPreviousData] = useState([]);
    const [playSound, setPaySound] = useState(false);
    const api = useApi();

    const [newLiberadoExists, setNewLiberadoExists] = useState(false);

    async function getMedicacao() {
        try {
            console.log('CHAMOU GET');
            const { data } = await api.getMedicacaoPendentePainel();

            // Mapear os dados recebidos
            const row = data.map((item) => ({
                id: v4(), // Gerando um ID único
                fa: item?.prontuarioId,
                name: item?.paciente,
                data: '',
                situacao: item?.status
            }));

            // Filtrar os itens com status "LIBERADO"
            const currentLiberados = row.filter((item) => item.situacao === 'LIBERADO');

            // Verificar se existe um novo "LIBERADO" em relação ao estado anterior
            const novoLiberado = currentLiberados.some((item) => !previousData.some((element) => element.fa === item.fa));

            // Se houver um novo "LIBERADO", defina como true, caso contrário, false
            if (novoLiberado) {
                setNewLiberadoExists(true);
            } else {
                setNewLiberadoExists(false);
            }

            // Atualizar o estado com os novos dados
            setMedicamentoData(row);
            setPreviousData(currentLiberados);
        } catch (error) {
            toast.error('Erro ao buscar dados de medicação');
        }
    }

    async function getLaboratorio() {
        try {
            const { data } = await api.getLaboratorioPendentePainel();
            const row = data.map((item) => {
                return {
                    id: item?.prontuarioId,
                    fa: item?.prontuarioId,
                    name: item?.paciente,
                    data: '',
                    situacao: item?.status
                };
            });

            setLaboratorioData(row);
        } catch (error) {
            toast.error('Erro ao buscar dados de laboratorio');
        }
    }

    async function getEnfermagem() {
        try {
            const { data } = await api.getAllNurse();
            const row = data.map((item) => {
                return {
                    ...item,
                    fa: item?.conduta_medica?.prontuario?.id,
                    name: item?.conduta_medica?.prontuario?.paciente?.nome_completo,
                    data: item?.dia,
                    local: item?.conduta_medica?.prontuario?.salas_enfermagem?.descricao,
                    situacao: item?.conduta_medica?.prontuario?.status,
                    medStatus: item?.status_medicacao
                };
            });
            console.log('🚀 ~ row ~ row:', row);

            // Verifica se há novos itens

            setNurseData(row);
            setMedicamentoData(row.filter((item) => item.tipo === 'MEDICAMENTO'));
            setLaboratorioData(row.filter((item) => item.tipo === 'LABORATORIO'));
        } catch (error) {
            console.log('🚀 ~ getEnfermagem ~ error:', error);
        }
    }

    useEffect(() => {
        if (newLiberadoExists) {
            playNotificationSound();
        }
    }, [newLiberadoExists]);

    function playNotificationSound() {
        const audio = new Audio(Sound);
        audio.play().then(() => {
            setPaySound(false);
        });
    }

    // Chamar a função getMedicacao a cada 5 segundos
    useEffect(() => {
        const interval = setInterval(() => {
            getMedicacao();
            getLaboratorio();
        }, 5000);

        return () => clearInterval(interval);
    }, [previousData]);

    return (
        <>
            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '1rem', marginTop: '2rem' }}>
                <NursingCards medicamentoLenght={medicamentoData.length} laboratorioLenght={laboratorioData.length} />
                <PatientMonitoring nurseData={nurseData} />
                <MedMonitoring nurseData={medicamentoData} />
                <LabMonitoring nurseData={laboratorioData} />
            </Box>

            {playSound && (
                <audio style={{ display: 'none' }} controls autoPlay>
                    <track kind="captions" src="#" srclang="en" label="Portuguese" />
                    <source src={Sound} type="audio/mp3" />
                    Seu navegador não suporta o elemento de áudio.
                </audio>
            )}
        </>
    );
};

export default NursingPainel;
