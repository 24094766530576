import { styled } from '@mui/material';
import { Box } from '@mui/material';

export const MainBox = styled(Box)({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'space-between',
    padding: '.5em'
});

export const HeaderBox = styled(Box)({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1em',
    marginLeft: '.7em'
});

export const Container = styled(Box)({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'space-between',
    padding: '1em',
    marginLeft: '.7em'
});
