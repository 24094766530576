import React from 'react';
import { Autocomplete, Button, FormControl, TextField } from '@mui/material';
import { Box } from '@mui/system';

const SearchHeader = ({ prontuarios, cleanSearch, disableSearch, selectedProntuario, setSelectedProntuario, getAllSaidasByProntuario }) => {
    return (
        <Box
            sx={{
                width: '100%',
                marginTop: '1rem',
                marginLeft: '1rem',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                gap: '1rem'
            }}
        >
            <FormControl sx={{ width: '30%' }}>
                <Autocomplete
                    disabled={disableSearch}
                    options={prontuarios}
                    getOptionLabel={(item) => `FA: ${item?.id} - ${item?.paciente?.nome_completo}`}
                    value={selectedProntuario || null}
                    onChange={(e, newValue) => setSelectedProntuario(newValue)}
                    renderInput={(params) => <TextField {...params} label="Procurar Ficha..." />}
                />
            </FormControl>
            <FormControl>
                <Button variant="contained" disabled={disableSearch} onClick={() => getAllSaidasByProntuario(selectedProntuario)}>
                    Buscar
                </Button>
            </FormControl>
            <FormControl>
                <Button variant="contained" color="error" disabled={!disableSearch} onClick={cleanSearch}>
                    Limpar Busca
                </Button>
            </FormControl>
        </Box>
    );
};

export default SearchHeader;
