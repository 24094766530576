import { Button, Box, Typography, FormControl, TextField, MenuItem, Tooltip, Autocomplete, CircularProgress } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import { useApi } from 'Service/axios';
import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { BoxContainer, BoxHeader, FieldBox, FormBox } from './style';
import FilterDrawerEntrada from './Drawer';
import CancelDialog from './CancelDialog';
import { toast } from 'react-toastify';

const FormEntrada = () => {
    // ============================= INSTÂNCIAS =============================
    const api = useApi();
    // ============================= STATES =============================
    const [entrada, setEntrada] = useState({
        itemId: '',
        unidade: '',
        data: new Date().toISOString().split('T')[0],
        quantidade: '',
        almoxarifado: '',
        localizacao: '',
        observacao: ''
    });
    const [allCentros, setAllCentros] = useState([]);
    const [selectedCentro, setSelectedCentro] = useState(null);
    const [open, setOpen] = useState(false);
    const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingPromise, setLoadingPromise] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [filtredLocations, setFiltredLocations] = useState([]);
    const [dependency, setDependency] = useState({ itens: [], almoxarifados: [], localizacoes: [], unidades: [], entradas: [] });
    const [isEditing, setIsEditing] = useState(false);
    // ============================= FUNÇÕES =============================

    const handleChangeLocations = (location) => {
        const filtredData = dependency.localizacoes.filter((i) => i.almoxarifado_id === location);
        setFiltredLocations(filtredData);
    };

    async function getAllCentros() {
        try {
            const { data } = await api.getAllCentroCusto();
            setAllCentros(data);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    const handleChangeItem = (newValue) => {
        if (newValue !== null) {
            const selectedItem = dependency.itens.find((item) => item.id === newValue.id);
            if (selectedItem) {
                handleChangeLocations(selectedItem.almoxarifado.id);
                setEntrada({
                    ...entrada,
                    itemId: selectedItem.id,
                    unidade: selectedItem.unidade.id,
                    almoxarifado: selectedItem.almoxarifado.id,
                    localizacao: selectedItem.localizacao.id
                });
            } else {
                setEntrada({});
            }
        } else {
            setEntrada({});
        }
    };

    const handleChangeField = (field, value) => {
        setEntrada({ ...entrada, [field]: value });
    };

    const resetState = () => {
        setEntrada({
            itemId: '',
            unidade: '',
            data: new Date().toISOString().split('T')[0],
            quantidade: '',
            almoxarifado: '',
            localizacao: '',
            observacao: ''
        });
    };

    const handleCheckDialog = (entrada) => {
        if (entrada.id) {
            setIsDelete(true);
            setCancelDialogOpen(true);
        } else {
            setCancelDialogOpen(true);
        }
    };

    const handleCloseDialog = () => {
        setCancelDialogOpen(false);
    };

    const handleCheckValues = (params) => {
        // tirar os campos não obrigatórios
        // ou montar objeto apenas com os campos necessários com os nomes que precisarem ser mudados
        // ex: itemId => item ou selectedItem => item
        const filtredObject = {
            item: params.itemId,
            unidade: params.unidade,
            data: params.data,
            quantidade: params.quantidade,
            almoxarifado: params.almoxarifado,
            localizacao: params.localizacao
        };

        let errorArr = [];
        Object.keys(filtredObject).forEach((field) => {
            if (filtredObject[field] === '' || filtredObject[field] === null) {
                let key = field.replace(field[0], field[0].toUpperCase());
                toast.error(`O campo ${key} deve ser informado`);
                errorArr.push(field);
            }
            return errorArr;
        });

        if (errorArr.length > 0) {
            return false;
        } else {
            return true;
        }
    };

    // === SUBMITS / API CALLS ===

    const getDependency = async () => {
        try {
            const [item, unidade, almoxarifado, localizacao, entradas] = await Promise.all([
                api.getAllItems(),
                api.getAllUnidade(),
                api.getAlmoxarifados(),
                api.getAllLocalizacoes(),
                api.getAllEntradaManual()
            ]);

            setDependency({
                itens: item.data,
                almoxarifados: almoxarifado.data,
                localizacoes: localizacao.data,
                unidades: unidade.data,
                entradas: entradas.data
            });
            setLoadingPromise(true);
        } catch (error) {
            toast.error(`Erro ao bucar dados: ${error.message}`);
        }
    };

    const handelConfirmDialog = async () => {
        if (isDelete) {
            try {
                await api.deleteEntradaManual(entrada.id);
                getDependency();
                resetState();
                setCancelDialogOpen(false);

                toast.success('Entrada excluída com sucesso');
            } catch (error) {
                toast.error(`Erro: ${error.response.data.error}`);
                setCancelDialogOpen(false);
            }
        } else {
            resetState();
            setCancelDialogOpen(false);
        }
    };

    const handleCreateEntrada = async (e, entrada) => {
        e.preventDefault();
        // caso tenha entrada.id é update
        // entrada.id === id da entrada no banco
        // entrada.itemId === id do item da entrada
        try {
            if (handleCheckValues(entrada)) {
                setLoading(true);
                if (entrada.id) {
                    // chamada axios update entrada
                    await api.updateEntradaManual(entrada.id, entrada, selectedCentro.id);
                    resetState();
                    getDependency();
                    setSelectedCentro(null);
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                    toast.success('Entrada cadastrada com sucesso');
                } else {
                    // chamada axios create entrada
                    await api.createEntradaManual(entrada, selectedCentro.id);
                    resetState();
                    getDependency();
                    setSelectedCentro(null);
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                    toast.success('Entrada cadastrada com sucesso');
                }
            }
        } catch (error) {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
            toast.error(`Erro: ${error.response.data.error}`);
        }
    };

    useEffect(() => {
        getDependency();
        getAllCentros();
    }, []);

    return (
        <>
            {loadingPromise ? (
                <BoxContainer>
                    {/* DRAWER COMPONENT */}
                    <FilterDrawerEntrada
                        open={open}
                        setOpen={setOpen}
                        itens={dependency.itens}
                        entradas={dependency.entradas}
                        setEntrada={setEntrada}
                        handleChangeLocations={handleChangeLocations}
                        setIsEditing={setIsEditing}
                        setSelectedCentro={setSelectedCentro}
                    />
                    {/* DIALOG CANCELAR  */}
                    <CancelDialog
                        open={cancelDialogOpen}
                        onClose={handleCloseDialog}
                        handelConfirmDialog={handelConfirmDialog}
                        isDelete={isDelete}
                    />
                    {/* FORM */}
                    <BoxHeader>
                        <Typography sx={{ fontSize: '1.125rem', fontWeight: '500', color: '#121926' }}>Inserir nova entrada</Typography>
                        <Tooltip title="Filtrar entrada">
                            <Button variant="contained" color="warning" onClick={() => setOpen(true)}>
                                <SearchIcon />
                            </Button>
                        </Tooltip>
                    </BoxHeader>
                    <FormBox>
                        <FieldBox>
                            <FormControl fullWidth>
                                <Autocomplete
                                    options={allCentros}
                                    getOptionLabel={(item) => `${item.id} - ${item.descricao}`}
                                    value={selectedCentro}
                                    onChange={(e, newValue) => {
                                        console.log('🚀 ~ FormEntrada ~ newValue:', newValue);
                                        setSelectedCentro(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Centro de custo" />}
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <Autocomplete
                                    options={dependency.itens}
                                    getOptionLabel={(item) => `${item.id} - ${item.descricao}`}
                                    value={dependency.itens.find((el) => el.id === entrada.itemId) || null}
                                    onChange={(e, newValue) => handleChangeItem(newValue)}
                                    renderInput={(params) => <TextField {...params} label="Item" />}
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <TextField
                                    select
                                    label="Unidade"
                                    disabled
                                    value={entrada.unidade}
                                    onChange={(e) => handleChangeField('unidade', e.target.value)}
                                >
                                    {dependency.unidades.map((unidade) => (
                                        <MenuItem key={unidade.id} value={unidade.id}>
                                            {unidade.descricao}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                        </FieldBox>
                        <FieldBox>
                            <FormControl fullWidth>
                                <TextField type="date" label="Data" value={entrada.data} disabled />
                            </FormControl>
                            <FormControl fullWidth>
                                <TextField
                                    type="number"
                                    label="Quantidade"
                                    value={entrada.quantidade}
                                    onChange={(e) => handleChangeField('quantidade', e.target.value)}
                                />
                            </FormControl>
                        </FieldBox>
                        <FieldBox>
                            <FormControl fullWidth>
                                <TextField
                                    select
                                    label="Almoxarifado"
                                    value={entrada.almoxarifado}
                                    onChange={(e) => {
                                        handleChangeField('almoxarifado', e.target.value), handleChangeLocations(e.target.value);
                                    }}
                                >
                                    {dependency.almoxarifados
                                        .filter((almoxarifado) => !isEditing || almoxarifado.id == entrada.almoxarifado)
                                        .map((almoxarifado) => (
                                            <MenuItem key={almoxarifado.id} value={almoxarifado.id}>
                                                {almoxarifado.descricao}
                                            </MenuItem>
                                        ))}
                                </TextField>
                            </FormControl>
                            <FormControl fullWidth>
                                <TextField
                                    select
                                    label="Localização"
                                    value={entrada.localizacao}
                                    onChange={(e) => handleChangeField('localizacao', e.target.value)}
                                >
                                    {filtredLocations
                                        .filter((localizacao) => !isEditing || localizacao.id === entrada.localizacao)
                                        .map((localizacao) => (
                                            <MenuItem key={localizacao.id} value={localizacao.id}>
                                                {localizacao.descricao}
                                            </MenuItem>
                                        ))}
                                </TextField>
                            </FormControl>
                        </FieldBox>
                        <FieldBox>
                            <FormControl fullWidth>
                                <TextField
                                    label="Observação"
                                    multiline
                                    minRows={5}
                                    value={entrada.observacao}
                                    onChange={(e) => handleChangeField('observacao', e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </FormControl>
                        </FieldBox>
                    </FormBox>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1em' }}>
                        <Tooltip title="Salvar entrada">
                            <Button variant="contained" color="success" disabled={loading} onClick={(e) => handleCreateEntrada(e, entrada)}>
                                <SaveIcon />
                            </Button>
                        </Tooltip>
                        <Tooltip title={entrada.id ? 'Excluir entrada' : 'Cancelar cadastro de entrada'}>
                            <Button
                                variant="contained"
                                color="error"
                                disabled={entrada.itemId === ''}
                                onClick={() => handleCheckDialog(entrada)}
                            >
                                <DeleteIcon />
                            </Button>
                        </Tooltip>
                    </Box>
                </BoxContainer>
            ) : (
                <>
                    <CircularProgress />
                </>
            )}
        </>
    );
};

export default FormEntrada;
