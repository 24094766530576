import MainCard from 'ui-component/cards/MainCard';
import { Grid } from '@mui/material';
import { gridSpacing } from 'store/constant';
import NovoProntuario from 'components/RecepcaoForms/NovoProntuario';
import { FormBox } from './style';

const CriarProntuario = () => {
    return (
        <MainCard title="Criar prontuário">
            <Grid container spacing={gridSpacing} sx={{ display: 'flex', justifyContent: 'center' }}>
                <FormBox>
                    <NovoProntuario />
                </FormBox>
            </Grid>
        </MainCard>
    );
};

export default CriarProntuario;
