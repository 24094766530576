import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router';
import { useApi } from 'Service/axios';
import { HeaderBox, Title, Container, Mid, Body, CampoContainer, Campo, ContainerPai, p } from './style';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ConjuntoLaudoContext } from 'context/LaudoContext';
import { toast } from 'react-toastify';

const FichaBancada2 = () => {
    const { numero, prontuarioId } = useParams();
    const api = useApi();
    const { fichaBancadaPayload } = useContext(ConjuntoLaudoContext);
    const [loading, setLoading] = useState(true);
    const [paciente, setPaciente] = useState({});
    const [idade, setIdade] = useState('');
    const [data, setData] = useState('');
    const [exame, setExame] = useState([]);

    const formatDate = (inputDate) => {
        const dateParts = inputDate.split('-');
        const year = parseInt(dateParts[0]);
        const month = parseInt(dateParts[1]) - 1;
        const day = parseInt(dateParts[2]);
        return new Date(year, month, day);
    };

    const padWithZero = (number) => {
        return (number < 10 ? '0' : '') + number;
    };

    const formatDateNascimento = (inputDate) => {
        const dateParts = inputDate.split('-');
        const year = parseInt(dateParts[0]);
        const month = parseInt(dateParts[1]);
        const day = parseInt(dateParts[2]);
        return padWithZero(day) + '/' + padWithZero(month) + '/' + year;
    };

    const calculateAge = (birthDate) => {
        const diffMilliseconds = new Date() - birthDate;
        const diffDate = new Date(diffMilliseconds);

        const ageYears = diffDate.getUTCFullYear() - 1970;
        const ageMonths = diffDate.getUTCMonth();
        const ageDays = diffDate.getUTCDate() - 1;

        return `${ageYears} anos, ${ageMonths} meses e ${ageDays} dias`;
    };

    const getPacienteData = async () => {
        try {
            const response = await api.getPacienteById(numero);
            setPaciente(response.data);
            const birthDate = formatDate(response.data.data_nascimento);
            setIdade(calculateAge(birthDate));
            setData(formatDateNascimento(response.data.data_nascimento));
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    };

    const getExamesForFicha = async (prontuarioId, payload) => {
        try {
            const response = await api.getExamesForFichaBancada(prontuarioId, payload);
            setExame(response.data);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    };

    function agruparPorCategoria(campos) {
        return campos.reduce((acc, campoAtual) => {
            const { categoria } = campoAtual;
            if (!acc[categoria]) {
                acc[categoria] = [];
            }
            acc[categoria].push(campoAtual.campo_nome);
            return acc;
        }, {});
    }

    useEffect(() => {
        Promise.all([getPacienteData(), getExamesForFicha(prontuarioId, fichaBancadaPayload)]).then(() => {
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        if (!loading) {
            window.print();
        }
    }, [loading]);

    return (
        <>
            {paciente !== null && (
                <>
                    <Box>
                        <Title
                            variant="h1"
                            sx={{
                                fontSize: '1.5em',
                                marginTop: '1em',
                                marginBottom: '1em'
                            }}
                        >
                            <b>FICHA DE BANCADA</b>
                        </Title>
                    </Box>
                    <HeaderBox>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: '1em',
                                fontSize: '1em'
                            }}
                        >
                            <Title
                                sx={{
                                    marginLeft: '1em'
                                }}
                            >
                                NOME: <b>{paciente?.nome_completo}</b>
                            </Title>
                            <Title>
                                CONVÊNIO: <b>{paciente?.convenio?.descricao}</b>
                            </Title>
                            <Title
                                sx={{
                                    marginRight: '1em'
                                }}
                            >
                                TELEFONE: <b>{paciente?.telefone}</b>
                            </Title>
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '.4em' }}>
                            <Title
                                sx={{
                                    marginLeft: '1em'
                                }}
                            >
                                MÉDICO SOLICITANTE:{' '}
                                <b>
                                    {exame[0]?.medicoData?.data.usuario?.info_medico?.nome_completo
                                        ? exame[0]?.medicoData?.data.usuario?.info_medico?.nome_completo
                                        : 'Não informado'}
                                </b>
                            </Title>

                            <Title>
                                DATA DE NASCIMENTO: <b>{data}</b>
                            </Title>
                            <Title
                                sx={{
                                    marginRight: '1em'
                                }}
                            >
                                IDADE: <b>{idade}</b>
                            </Title>
                        </Box>
                    </HeaderBox>
                    {exame.map((exameItem, index) => (
                        <ContainerPai key={index}>
                            {/* O Mid será a parte onde haverá o nome do exame e o cód de amostra */}
                            <Mid>
                                <Box
                                    sx={{
                                        marginLeft: '.50em',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        width: '98%'
                                    }}
                                >
                                    <Title>
                                        EXAME:{' '}
                                        <b>
                                            {exameItem?.id} - {exameItem?.exame?.nome}
                                        </b>
                                    </Title>
                                    <Title>
                                        CÓDIGO DE AMOSTRA: <b>{prontuarioId}</b>
                                    </Title>
                                </Box>
                            </Mid>
                            {/* O Body deverá fazer um map de campos especificos e agrupa-los por cartegoria, deverá haver um "titulo da categoria e todos os campos abaixo" */}
                            <Body>
                                <Box>
                                    <Box sx={{ width: '100%', paddingTop: '1em' }}>
                                        {exameItem.exame &&
                                            exameItem.exame.campos_especificos &&
                                            (() => {
                                                const camposAgrupados = agruparPorCategoria(exameItem.exame.campos_especificos);

                                                return Object.keys(camposAgrupados).map((categoria, categoriaIndex) => (
                                                    <Box key={categoriaIndex}>
                                                        <Typography
                                                            sx={{
                                                                display: 'flex',
                                                                marginLeft: '1em',
                                                                fontWeight: 'bold',
                                                                height: '25px',
                                                                alignItems: 'center'
                                                            }}
                                                        >
                                                            {categoria}:
                                                        </Typography>

                                                        <CampoContainer>
                                                            {camposAgrupados[categoria].map((campoNome, campoIndex) => (
                                                                <Campo key={campoIndex}>
                                                                    <Typography
                                                                        sx={{
                                                                            marginLeft: '1em',
                                                                            fontWeight: 'bold'
                                                                        }}
                                                                    >
                                                                        {campoNome}
                                                                    </Typography>
                                                                    :
                                                                </Campo>
                                                            ))}
                                                        </CampoContainer>
                                                    </Box>
                                                ));
                                            })()}
                                    </Box>
                                </Box>
                            </Body>
                        </ContainerPai>
                    ))}
                </>
            )}
        </>
    );
};

export default FichaBancada2;
