import React, { useState } from 'react';
import {
    Button,
    Typography,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Tooltip,
    TablePagination
} from '@mui/material';
import { useApi } from 'Service/axios';
import PrintIcon from '@mui/icons-material/Print';

const TabelaAtendimento = ({ Allprontuario }) => {
    const api = useApi();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(4);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const dataFormatada = (data_atendimento) => {
        if (data_atendimento) {
            const dataObj = new Date(data_atendimento);
            const dia = dataObj.getDate().toString().padStart(2, '0');
            const mes = (dataObj.getMonth() + 1).toString().padStart(2, '0');
            const ano = dataObj.getFullYear();
            return `${dia}/${mes}/${ano}`;
        }
        return '';
    };

    //hora deve ser no formato hh:mm

    const horaFormatada = (hora_atendimento) => {
        if (hora_atendimento) {
            const hora = hora_atendimento.substring(0, 2);
            const minuto = hora_atendimento.substring(3, 5);
            return `${hora}:${minuto}`;
        }
        return '';
    };

    const handleOpenNewRoute = async (e, id, prontuarioID) => {
        let pronturio = Allprontuario.find((prontuario_id) => {
            return prontuario_id?.id === prontuarioID;
        });

        e.preventDefault();
        //const numeroAtendimento = await api.createNovoAtendimento();
        if (pronturio?.procedimento_externo) {
            window.open(`/relatorios/entrada3/${id}/numero/${prontuarioID}`);
        } else {
            window.open(`/relatorios/entrada2/${id}/numero/${prontuarioID}`);
        }
    };

    return (
        <>
            {Allprontuario !== null ? (
                <Box
                    sx={{
                        width: '100%',
                        minHeight: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginLeft: '3em'
                    }}
                >
                    <Typography variant="h2" sx={{ color: '#c9a86f' }}>
                        Histórico de Atendimentos
                    </Typography>
                    <div
                        style={{
                            minWidth: '100%',
                            overflowX: 'auto'
                        }}
                    >
                        <TableContainer
                            component={Paper}
                            style={{
                                width: '100%',
                                maxHeight: '600px',
                                overflowY: 'auto',
                                scrollbarWidth: 'thin',
                                scrollbarColor: 'rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.3)'
                            }}
                        >
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Ficha</TableCell>
                                        <TableCell align="center" placeholder="Procedimento Externo -  Ficha Atendimento">
                                            Tipo
                                        </TableCell>
                                        <TableCell align="center">Médico</TableCell>
                                        <TableCell align="center">Consulta</TableCell>
                                        <TableCell align="center">Data</TableCell>
                                        <TableCell align="center">Hora</TableCell>
                                        <TableCell align="center">Ações</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Allprontuario.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((prontuario) => (
                                        <TableRow key={prontuario.id}>
                                            <TableCell align="center">{prontuario.id}</TableCell>
                                            <TableCell align="center">{prontuario.procedimento_externo ? 'P.E' : 'F.A'}</TableCell>
                                            <TableCell align="center">
                                                {prontuario?.usuario?.info_medico?.nome_completo
                                                    ? prontuario?.usuario?.info_medico?.nome_completo
                                                    : '-'}
                                            </TableCell>
                                            <TableCell align="center">{prontuario?.tipo_consultum?.descricao}</TableCell>
                                            <TableCell align="center">{dataFormatada(prontuario.data_atendimento)}</TableCell>
                                            <TableCell align="center">{horaFormatada(prontuario.hora_atendimento)}</TableCell>
                                            <TableCell align="center">
                                                <Tooltip title="Imprimir ficha">
                                                    <Button onClick={(e) => handleOpenNewRoute(e, prontuario.paciente.id, prontuario.id)}>
                                                        <PrintIcon />
                                                    </Button>
                                                </Tooltip>
                                                {/* <Tooltip title="Laboratório">
                                                    <Button>
                                                        <IconBrandAsana />
                                                    </Button>
                                                </Tooltip> */}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[4, 7, 10]}
                            component="div"
                            count={Allprontuario.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                </Box>
            ) : (
                <Typography>Sem fichas de atendimento</Typography>
            )}
        </>
    );
};

export default TabelaAtendimento;
