import { Grid, IconButton, Tooltip } from '@mui/material';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';

import { DataGrid } from '@mui/x-data-grid';
import { Delete, Edit } from '@mui/icons-material';
import { AddModal } from './components/AddModal';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';

export function Clinic() {
    const api = useApi();
    const [row, setRow] = useState([]);
    const [isEditable, setIsEditable] = useState(false);
    const [currentRow, setCurrentRow] = useState({});
    const [openModal, setOpenModal] = useState(false);

    async function get() {
        try {
            const { data } = await api.getAllClinic();
            setRow(data);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    async function handleDelete(id) {
        const toastId = toast.loading('Deletando consultório...');
        try {
            await api.deleteClinic(id);
            toast.update(toastId, {
                render: 'Consultório deletado com sucesso!',
                type: 'success',
                isLoading: false,
                autoClose: 2000
            });
            get();
        } catch (error) {
            toast.update(toastId, {
                render: 'Erro ao deletar consultório!',
                type: 'error',
                isLoading: false,
                autoClose: 2000
            });
        }
    }

    useEffect(() => {
        get();
    }, []);

    const columns = [
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'descricao', headerName: 'Consultorio', flex: 1 },
        {
            field: 'acoes',
            headerName: 'Ações',
            flex: 0.5,
            renderCell: (params) => (
                <>
                    <Tooltip title="Editar">
                        <IconButton
                            onClick={() => {
                                setIsEditable(true);
                                setCurrentRow(params.row);
                                setOpenModal(true);
                            }}
                        >
                            <Edit />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <IconButton onClick={() => handleDelete(params.row.id)}>
                            <Delete />
                        </IconButton>
                    </Tooltip>
                </>
            )
        }
    ];
    return (
        <MainCard title="CADASTRO CONSULTÓRIOS">
            <AddModal
                get={get}
                editable={isEditable}
                setIsEditable={setIsEditable}
                currentRow={currentRow}
                open={openModal}
                setOpen={setOpenModal}
            />
            <Grid spacing={gridSpacing}>
                <DataGrid rows={row} columns={columns} autoHeight />
            </Grid>
        </MainCard>
    );
}
