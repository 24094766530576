import { FormControl, InputLabel, OutlinedInput, TableCell, styled } from '@mui/material';
import { Box } from '@mui/material';

// components

export const MainBox = styled(Box)({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'space-between',
    marginTop: '1.5em',
    padding: '.5em'
});

export const HeaderBox = styled(Box)({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1em',
    marginLeft: '.7em',
    marginTop: '1em'
});

export const Container = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between'
});

export const TableBox = styled(Box)({
    width: '100%',
    padding: '2em',
    marginTop: '2em'
});

export const OrderButton = styled(TableCell)({
    cursor: 'pointer',
    transition: 'all .15s ease',

    b: {
        marginRight: '1em'
    },
    '&:hover': {
        backgroundColor: 'rgba(39, 157, 245, 0.52)'
    }
});

export const OrderNameButton = styled(TableCell)({
    cursor: 'pointer',
    transition: 'all .15s ease',

    b: {
        marginRight: '1em'
    },
    '&:hover': {
        backgroundColor: 'rgba(39, 157, 245, 0.52)'
    }
});

export const FormBox = styled(Box)({
    marginTop: '.4em',
    marginBottom: '.6em',
    width: '100%'
});

export const Label = styled(InputLabel)({
    fontSize: '1em'
});

export const Input = styled(OutlinedInput)({
    fontSize: '1em',
    width: '100%'
});

export const SecondaryBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
});

export const SecondaryFormBox = styled(FormControl)({
    marginTop: '.4em',
    marginBottom: '.6em',
    width: '45%'
});
