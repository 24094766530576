import { Button, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';

const ActionButton = ({ row, setBaixa, resetStates, handleChangeLocations, handleGetLotes, setHasLote, setIsEditing }) => {
    /////////////////////////////// FUNÇÕES /////////////////////////
    const handleOpen = async () => {
        const payload = { id: row.item_id, almoxarifado_id: row.almoxarifado_id, localizacao_id: row.localizacao_id };
        const lote = await handleGetLotes(payload);
        if (row.item.psicotropico) setHasLote(true);

        setBaixa({
            id: row.id,
            itemId: row.item_id,
            almoxarifado: row.almoxarifado_id,
            localizacao: row.localizacao_id,
            unidade: row.unidade_id,
            observacao: row.observacao,
            quantidade: row.quantidade,
            pacienteId: row.prontuario_id,
            centroId: row.centro_id,
            disponivel: lote?.data[0]?.quantidade_disponivel || 0,
            loteId: row.lote,
            medicoId: row.medico_id,
            data: row.data
        });
        setIsEditing(true);
        handleChangeLocations(row.almoxarifado_id);
        resetStates();
    };

    return (
        <Box>
            <Tooltip title="Editar Baixa">
                <Button onClick={handleOpen}>
                    <EditIcon />
                </Button>
            </Tooltip>
        </Box>
    );
};

export default ActionButton;
