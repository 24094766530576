import { Autocomplete, Box, Button, IconButton, Modal, TextField, Tooltip } from '@mui/material';
import { BtnArea, CustomInput, InputArea, TitleArea } from './styles';
import { DataGrid } from '@mui/x-data-grid';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { useState } from 'react';
import { useEffect } from 'react';
import { useApi } from 'Service/axios';
import { formatarData } from 'utils/formatarData';
import { toast } from 'react-toastify';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2
};

const ExamModal = ({ open, setOpen, currentRow, getAllProntuario }) => {
    const api = useApi();
    const [openAddExam, setOpenAddExam] = useState(false);
    const [exams, setExams] = useState([]);
    const [newExm, setNewExam] = useState('');
    const [registredExams, setRegistredExams] = useState([]);
    const [selectedItens, setSelectedItens] = useState([]);

    const handleClose = () => {
        setOpen(false);
    };

    async function getAllXRayData() {
        try {
            const { data } = await api.getAllProcedimentos();
            setExams(data);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    useEffect(() => {
        getAllXRayData();
    }, []);

    const columns = [
        { field: 'description', headerName: 'Description', flex: 1 },
        { field: 'date', headerName: 'Date', flex: 1 },
        { field: 'status', headerName: 'Status', flex: 1 }
    ];

    function handleSelection(selectedItems) {
        setSelectedItens(selectedItems);
    }

    async function getExamsLinked() {
        try {
            const { data } = await api.getLinkedExames(currentRow.id);

            const row = data.map((item) => ({
                id: item?.id,
                description: item?.procedimento?.descricao,
                date: formatarData(item?.createdAt),
                status: item?.finalizado ? 'Finalizado' : 'Pendente'
            }));

            setRegistredExams(row);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    useEffect(() => {
        if (currentRow) {
            getExamsLinked();
        }
    }, [currentRow]);

    async function handleLinkExam(id) {
        const toastId = toast.loading('Vinculando exame...');
        try {
            await api.postLinkExams(id, currentRow.id);
            getExamsLinked();
            toast.update(toastId, { render: 'Exame vinculado com sucesso', type: 'success', isLoading: false, autoClose: 2000 });
            setOpenAddExam(false);
        } catch (error) {
            toast.update(toastId, { render: 'Erro ao vincular exame', type: 'error', isLoading: false, autoClose: 2000 });
        }
    }

    async function handleFinishLinkExam() {
        const payload = selectedItens.map((item) => ({ id: item }));
        const toastId = toast.loading('Finalizando exames...');
        try {
            await api.finishLinkedExams(payload);
            toast.update(toastId, { render: 'Exames finalizados com sucesso', type: 'success', isLoading: false, autoClose: 2000 });
            getExamsLinked();
            getAllProntuario();
            handleClose();
        } catch (error) {
            toast.update(toastId, { render: 'Erro ao finalizar exames', type: 'error', isLoading: false, autoClose: 2000 });
        }
    }

    return (
        <Box>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
                <Box sx={style}>
                    <TitleArea>
                        <h2 id="modal-title">Procedimentos</h2>
                        <h2 id="modal-title">{`F.a.: ${currentRow?.id} - ${currentRow?.patient}`}</h2>
                    </TitleArea>

                    <Tooltip title="Adicionar">
                        <IconButton onClick={() => setOpenAddExam(true)}>
                            <PostAddIcon />
                        </IconButton>
                    </Tooltip>
                    {openAddExam && (
                        <InputArea>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={exams}
                                sx={{ width: 300 }}
                                getOptionLabel={(option) => `${option.descricao} - ${option.codigo}`}
                                onChange={(event, newValue) => {
                                    handleLinkExam(newValue?.id);
                                }}
                                renderInput={(params) => <TextField {...params} label="ECG" value={newExm} />}
                            />
                        </InputArea>
                    )}

                    <Box>
                        {registredExams.length === 0 ? (
                            <h3>Nenhum exame vinculado</h3>
                        ) : (
                            <>
                                <DataGrid
                                    rows={registredExams}
                                    columns={columns}
                                    hideFooter={true}
                                    checkboxSelection
                                    onRowSelectionModelChange={handleSelection}
                                    isRowSelectable={(params) => params.row.status !== 'Finalizado'}
                                />
                                <BtnArea>
                                    <Button variant="contained" onClick={handleFinishLinkExam}>
                                        Finalizar
                                    </Button>
                                </BtnArea>
                            </>
                        )}
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
};

export default ExamModal;
