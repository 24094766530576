import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Button, Drawer, FormControl, FormHelperText, InputLabel, OutlinedInput, Typography } from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useApi } from 'Service/axios';
import { FormBox } from './style';
import { toast } from 'react-toastify';

const CadastroCentroCusto = ({ open, handleClose, getAllCentroCusto, ...others }) => {
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const api = useApi();
    const [loading, setLoading] = useState(false);

    const handleRegister = async (values) => {
        setLoading(true);
        // monta um objeto apenas com valores !== de ""
        const filteredValues = Object.entries(values).reduce((acc, [key, value]) => {
            if (value !== '') {
                acc[key] = value;
            }
            return acc;
        }, {});

        // monta o payload final
        const CentroCusto = filteredValues;
        try {
            await api.createCentroCusto(CentroCusto);
            values.descricao = '';
            await getAllCentroCusto();
            setLoading(false);
            toast.success('Cadastrado com suecesso!');
        } catch (error) {
            toast.error(error.message);
        }
    };

    return (
        <Drawer anchor="right" open={open} onClose={handleClose}>
            <Box sx={{ width: '450px', padding: '.75em', height: '100%' }}>
                <Formik
                    initialValues={{
                        descricao: '',
                        submit: null
                    }}
                    validationSchema={Yup.object().shape({
                        descricao: Yup.string().required('Descricao é obrigatorio')
                    })}
                    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                        try {
                            if (scriptedRef.current) {
                                setStatus({ success: true });
                                setSubmitting(false);
                            }
                            await handleRegister(values);
                        } catch (err) {
                            console.error(err);
                            if (scriptedRef.current) {
                                setStatus({ success: false });
                                setErrors({ submit: err.msg });
                                setSubmitting(false);
                            }
                        }
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                        <form
                            noValidate
                            onSubmit={handleSubmit}
                            {...others}
                            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}
                        >
                            <Box>
                                <Typography variant="h3" sx={{ marginBottom: '1em' }}>
                                    Cadastro de centro de custo
                                </Typography>

                                <FormBox>
                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.descricao && errors.descricao)}
                                        sx={{ ...theme.typography.customInput }}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-descricao-register">Descricão</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-descricao-register"
                                            type="text"
                                            value={values.descricao}
                                            name="descricao"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                        />
                                        {touched.descricao && errors.descricao && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.descricao}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </FormBox>
                            </Box>

                            {errors.submit && (
                                <Box sx={{ mt: 3 }}>
                                    <FormHelperText error>{errors.submit}</FormHelperText>
                                </Box>
                            )}

                            {/* BOTÕES */}
                            <Box sx={{ mt: 2, width: '20%', marginLeft: 'auto' }}>
                                <AnimateButton>
                                    <Button disableElevation disabled={loading} fullWidth size="large" type="submit" variant="contained">
                                        {loading ? 'Aguarde...' : 'Adicionar'}
                                    </Button>
                                </AnimateButton>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Box>
        </Drawer>
    );
};

export default CadastroCentroCusto;
