import { Box, Button, Modal, TextField } from '@mui/material';
import { useApi } from 'Service/axios';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#fff',
    boxShadow: 24,
    p: 4,
    borderRadius: 2
};

export function AddModal({ get, editable, currentRow, open, setOpen, setIsEditable }) {
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setIsEditable(false);
        setOpen(false);
    };
    const [value, setValue] = useState('');

    const api = useApi();

    // Update value when currentRow changes
    useEffect(() => {
        if (currentRow && editable) {
            setValue(currentRow.descricao);
        } else {
            setValue('');
        }
    }, [currentRow, editable]);

    async function addClinic() {
        const payload = {
            descricao: value
        };
        const toastId = toast.loading('Adicionando consultório...');
        try {
            await api.AddClinic(payload);
            toast.update(toastId, {
                render: 'Consultório adicionado com sucesso!',
                type: 'success',
                isLoading: false,
                autoClose: 2000
            });
            setValue('');
            setOpen(false);
            get();
        } catch (error) {
            console.error(error);
            toast.update(toastId, {
                render: 'Erro ao adicionar consultório!',
                type: 'error',
                isLoading: false,
                autoClose: 2000
            });
        }
    }

    async function handleEdit() {
        const payload = {
            descricao: value
        };
        const toastId = toast.loading('Editando consultório...');
        try {
            await api.editClinic(currentRow?.id, payload);
            toast.update(toastId, {
                render: 'Consultório editado com sucesso!',
                type: 'success',
                isLoading: false,
                autoClose: 2000
            });
            setValue('');
            setOpen(false);
            get();
        } catch (error) {
            console.error(error);
            toast.update(toastId, {
                render: 'Erro ao editado consultório!',
                type: 'error',
                isLoading: false,
                autoClose: 2000
            });
        }
    }

    return (
        <div>
            <Button onClick={handleOpen} variant="contained" sx={{ marginBottom: '10px' }}>
                Adicionar consultório
            </Button>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <h3 style={{ textAlign: 'center' }}>{editable ? 'Editar consultório' : 'Adicionar consultório'}</h3>
                    <TextField
                        fullWidth
                        label="Nome do consultório"
                        variant="outlined"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                    />
                    <Button
                        sx={{ marginTop: '10px' }}
                        variant="contained"
                        onClick={() => {
                            if (editable) {
                                handleEdit();
                            } else {
                                addClinic();
                            }
                        }}
                    >
                        {editable ? 'Editar' : 'Adicionar'}
                    </Button>
                </Box>
            </Modal>
        </div>
    );
}
