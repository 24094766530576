import { Button, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { IconPrinter, IconFileDescription } from '@tabler/icons';
import ActionButtonComparecimento from 'components/RelatoriosLobby/Comparecimento/ActionButtonComparecimento';

const RelatoriosLobby = () => {
    // ---- INSTÂNCIAS ----
    // ---- STATES ----
    const relatorios = [{ id: 1, nome: 'DECLARAÇÃO DE COMPARECIMENTO', componente: <ActionButtonComparecimento /> }];
    const columns = [
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'nome', headerName: 'NOME', flex: 2 },
        { field: 'componente', headerName: 'AÇÕES', flex: 0.5, sortable: false, renderCell: (params) => params.row.componente }
    ];
    // ---- FUNÇÕES ----
    const handleSelectionModelChange = (newSelection) => {
        setSelectedItems(newSelection);
    };

    return (
        <MainCard title="LISTA DE RELATÓRIOS">
            <Grid spacing={gridSpacing}>
                <Box>
                    <DataGrid
                        rows={relatorios}
                        columns={columns}
                        hideFooter={true}
                        onSelectionModelChange={() => handleSelectionModelChange(params.id)}
                    />
                </Box>
            </Grid>
        </MainCard>
    );
};

export default RelatoriosLobby;
