import { Box, Button, Checkbox, Grid, IconButton, Modal, Tooltip } from '@mui/material';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useApi } from 'Service/axios';
import { useLocation, useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { formatarData } from 'utils/formatarData';
import ListIcon from '@mui/icons-material/List';
import { IconTextCaption } from '@tabler/icons';
import ArticleIcon from '@mui/icons-material/Article';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { useContext } from 'react';
import { ConjuntoLaudoContext } from 'context/LaudoContext';
import { PDFViewer } from '@react-pdf/renderer';
import { Print } from '@mui/icons-material';
import PdfViwer from './components/PdfViwer';
import PdfViwerSpecial from './components/PdfViwerSpecial';

const stylePdf = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1100,
    bgcolor: 'background.paper',
    boxShadow: 24,
    height: '90vh'
};

export function WriteOffExamsLab() {
    const location = useLocation();
    const { id, nome } = useParams();
    const navigate = useNavigate();
    const api = useApi();
    const { laudoPayload, setLaudoPayload, setFichaBancadaPayload } = useContext(ConjuntoLaudoContext);
    const [specialExamsRow, setSpecialExamsRow] = useState([]);
    const [rows, setRows] = useState([]);
    const [checked, setChecked] = useState([]);
    const [openModalPdf, setOpenModalPdf] = useState(false);
    const [openModalPdfSpecial, setOpenModalPdfSpecial] = useState(false);
    const [currentRow, setCurrentRow] = useState(null);

    const columns = [
        {
            field: 'check',
            headerName: 'SEL',
            flex: 0.2,
            renderCell: (params) => (
                <Checkbox
                    checked={checked.some((checkedItem) => checkedItem.id === params.row.id)}
                    onChange={(event) => handleChange(event, params.row)}
                    inputProps={{ 'aria-label': 'controlled' }}
                    disabled={params.row.status !== 'FINALIZADO'}
                />
            )
        },
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'exame', headerName: 'EXAME', flex: 1 },
        { field: 'date', headerName: 'DATA', flex: 1 },
        { field: 'hour', headerName: 'HORA', flex: 1 },
        { field: 'status', headerName: 'STATUS', flex: 1 },
        {
            field: 'actions',
            headerName: 'AÇÕES',
            flex: 1,
            renderCell: (params) => (
                <Box>
                    <Tooltip title="Incluir resultado">
                        <IconButton
                            disabled={params.row.status === 'FINALIZADO' || params.row.status_coleta}
                            onClick={() => handleNavigateExamResult(params.row)}
                        >
                            <IconTextCaption />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Ficha de bancada">
                        <IconButton onClick={() => handleOpenPaper(params.row)}>
                            <ArticleIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Desativar pedido de exame">
                        <IconButton onClick={() => disableExam(params.row.id)}>
                            <FilterListOffIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            )
        }
    ];
    const specialColumns = [
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'exame', headerName: 'EXAME', flex: 1 },
        { field: 'date', headerName: 'DATA', flex: 1 },
        { field: 'hour', headerName: 'HORA', flex: 1 },
        { field: 'status', headerName: 'STATUS', flex: 1 },
        {
            field: 'actions',
            headerName: 'AÇÕES',
            flex: 1,
            renderCell: (params) => (
                <Box>
                    <Tooltip title="Incluir resultado">
                        <IconButton disabled={params.row.status === 'FINALIZADO'} onClick={() => handleNavigateExamResult(params.row)}>
                            <IconTextCaption />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Ficha de bancada">
                        <IconButton onClick={() => handleOpenPaper(params.row)}>
                            <ArticleIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Desativar pedido de exame">
                        <IconButton onClick={() => disableExam(params.row.id)}>
                            <FilterListOffIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Imprimir">
                        <IconButton
                            disabled={params.row.status !== 'FINALIZADO'}
                            onClick={() => {
                                submitHoraLaudo(params.row);
                                setCurrentRow(params.row);
                                setOpenModalPdfSpecial(true);
                            }}
                        >
                            <Print />
                        </IconButton>
                    </Tooltip>
                </Box>
            )
        }
    ];

    function handleNavigateExamResult(data) {
        localStorage.setItem('URL_BACK', location.pathname);
        navigate(
            `/main/laboratorio/ficha/${data.pacienteId}/resultado/${data.exameId}/prontuario/${data.prontuarioId}/${data.id}?tipo=${data.tipo}`
        );
    }

    function handleOpenPaper(data) {
        window.open(`/relatorios/fichaBancada/${data.exameId}/numero/${data.pacienteId}/prontuario/${data.prontuarioId}/${data.id}`);
    }

    async function disableExam(exameProntuarioId) {
        try {
            await api.desativarExames(exameProntuarioId);
            toast.success('Sucesso ao desativar exame');
        } catch (error) {
            toast.success(error?.response?.data?.message ?? error?.message);
        }
    }

    function handleChange(event, item) {
        if (event.target.checked) {
            setChecked((prevState) => [...prevState, item]);
        } else {
            setChecked((prevState) => prevState.filter((checkedItem) => checkedItem.id !== item.id));
        }
    }

    async function handleOpenAllFichaBancadaNavigate() {
        try {
            const dataSource = checked.length > 0 ? checked : rows;

            const payload = dataSource.map((item) => ({
                exameId: item?.especial ? null : item?.exameId,
                exameEspecialId: item?.especial ? item?.exameId : null,
                idExameProntuario: item.id
            }));

            setFichaBancadaPayload(payload);

            navigate(`/relatorios/AllFichaBancada/numero/${rows[0].pacienteId}/prontuario/${id}`);
            window.open(`/main/laboratorio/controle/atendimento/${id}/${nome}`);
        } catch (error) {
            toast.error(error.message);
        }
    }

    async function getAllItens(id) {
        try {
            const { data } = await api.getAllPendindExamsLabByProntuarioId(id);

            const normalExams = data.filter((item) => item?.exame_prontuario?.exame);
            const specialExams = data.filter((item) => item?.exame_prontuario?.exames_especiai);

            const row = normalExams.map((item) => ({
                ...item,
                id: item?.exame_prontuario?.id,
                prontuarioId: item?.exame_prontuario?.prontuario?.id,
                pacienteId: item?.exame_prontuario?.prontuario?.paciente?.id,
                exame: item?.exame_prontuario?.exame?.nome ?? item?.exame_prontuario?.exames_especiai?.nome,
                exameId: item?.exame_prontuario?.exames_especiai?.id ?? item?.exame_prontuario?.exame?.id,
                date: formatarData(item?.createdAt.split('T')[0]),
                hour: item?.createdAt.split('T')[1].split('.')[0],
                status: item?.atendimentos_enfermagems[0]?.status == 'COLETA' ? 'ESPERA COLETA' : 'COLETADO',
                especial: item?.exame_prontuario?.exames_especiai ? true : false,
                tipo: item?.exame_prontuario?.exames_especiai ? 'especial' : 'normal',
                status_coleta: item?.atendimentos_enfermagems[0]?.status == 'COLETA' ? true : false
            }));

            const specialRows = specialExams.map((item) => ({
                ...item,
                id: item?.exame_prontuario?.id,
                prontuarioId: item?.exame_prontuario?.prontuario?.id,
                pacienteId: item?.exame_prontuario?.prontuario?.paciente?.id,
                exame: item?.exame_prontuario?.exame?.nome ?? item?.exame_prontuario?.exames_especiai?.nome,
                exameId: item?.exame_prontuario?.exames_especiai?.id ?? item?.exame_prontuario?.exame?.id,
                date: formatarData(item?.createdAt.split('T')[0]),
                hour: item?.createdAt.split('T')[1].split('.')[0],
                status: item?.exame_prontuario?.finalizado ? 'FINALIZADO' : 'AGUARDANDO',
                especial: item?.exame_prontuario?.exames_especiai ? true : false,
                tipo: item?.exame_prontuario?.exames_especiai ? 'especial' : 'normal'
            }));
            setSpecialExamsRow(specialRows);
            setRows(row);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    async function submitHoraLaudo(data) {
        const items = Array.isArray(data) ? data : [data];

        const payload = items.map((item) => ({
            idExameProntuario: item.id,
            exameId: !item?.especial ? item?.exameId : null,
            exameEspecialId: item?.especial ? item?.exameId : null,
            prontuarioId: item.prontuarioId,
            especial: item?.especial
        }));
        await api.getHoraUnicaLaudo(payload);
    }

    useEffect(() => {
        getAllItens(id);
    }, []);

    return (
        <MainCard title={`FA ${id} - ${nome}`}>
            <Grid spacing={gridSpacing} sx={{ position: 'relative' }}>
                <Box sx={{ width: '100%' }}>
                    {specialExamsRow.length > 0 && (
                        <>
                            <h3 style={{ textAlign: 'center' }}>Exames Especiais</h3>
                            <DataGrid sx={{ marginBottom: '40px' }} rows={specialExamsRow} columns={specialColumns} hideFooter={true} />
                        </>
                    )}
                    {rows.length > 0 && (
                        <>
                            <h3 style={{ textAlign: 'center' }}>Exames Normais</h3>
                            <DataGrid rows={rows} columns={columns} hideFooter={true} />
                        </>
                    )}
                </Box>
                <Box sx={{ width: '100%', display: 'flex', gap: '1rem', justifyContent: 'flex-end', marginTop: '1rem' }}>
                    <Tooltip title="Impimir fichas de bancada">
                        <Button variant="contained" onClick={handleOpenAllFichaBancadaNavigate}>
                            <ListIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Visualiar PDF">
                        <Button
                            variant="contained"
                            onClick={() => {
                                submitHoraLaudo(checked);
                                setOpenModalPdf(true);
                            }}
                            disabled={checked.length < 1}
                        >
                            <Print />
                        </Button>
                    </Tooltip>
                </Box>
                <Modal
                    open={openModalPdf}
                    onClose={() => setOpenModalPdf(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={stylePdf}>
                        <PDFViewer width={'100%'} height={'100%'}>
                            <PdfViwer examsChecked={checked} />
                        </PDFViewer>
                    </Box>
                </Modal>
                <Modal
                    open={openModalPdfSpecial}
                    onClose={() => setOpenModalPdfSpecial(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={stylePdf}>
                        <PDFViewer width={'100%'} height={'100%'}>
                            <PdfViwerSpecial currentExam={currentRow} />
                        </PDFViewer>
                    </Box>
                </Modal>
            </Grid>
        </MainCard>
    );
}
