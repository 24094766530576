// mui
import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, TextField, Typography, Box, InputLabel, Select, MenuItem, ButtonGroup, FormLabel } from '@mui/material';
import { gridSpacing } from 'store/constant';
// context
import { useContext } from 'react';
import { ChamadaContext } from 'context/ChamadasContext';
import { Context } from 'context/UserContext';
// router
import { useNavigate } from 'react-router';
import { useEffect } from 'react';

const TelaChamadas = () => {
    const navigate = useNavigate();
    const { chamadas } = useContext(ChamadaContext);
    const { loggedUser } = useContext(Context);

    // useEffect(() => {
    //     if (!loggedUser?.usuario?.nome) {
    //         navigate('/');
    //     }
    // }, []);
    return (
        <MainCard title="Tela de chamadas">
            <Grid container spacing={gridSpacing}>
                a
            </Grid>
        </MainCard>
    );
};

export default TelaChamadas;
