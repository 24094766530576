import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const TimerArea = styled(Box)`
    display: flex;
    align-items: center;
    gap: 0.9375rem;
    position: absolute;
    top: -95px;
    right: 15px;
`;
