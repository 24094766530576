import React, { useState } from 'react';
import { Autocomplete, Button, Drawer, FormControl, MenuItem, TextField, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { v4 as uuidv4 } from 'uuid';

const ActionButtonsCreate = ({
    open,
    handleClose,
    setItens,
    campos,
    adicionarCampoItem,
    atualizarCampoItem,
    listAllItens,
    listAllAlmoxarifado,
    listAllLocalizacao
}) => {
    /////////////////////////////// STATES /////////////////////////
    const [copiaItemSelecionado, setCopiaItemSelecionado] = useState(null);
    const item = campos?.itens[campos?.itens.length - 1];
    /////////////////////////////// FUNÇÕES /////////////////////////
    const adicionarItemNaTabela = (novoItem) => {
        const novoItemSemKit = {
            ...novoItem,
            uuid: uuidv4()
        };
        setItens((prevItens) => [...prevItens, novoItemSemKit]);
    };

    const handleChangeItem = async (newValue) => {
        setCopiaItemSelecionado(newValue);
        atualizarCampoItem(item.uuid, 'item_id', newValue?.id);
        atualizarCampoItem(item.uuid, 'descricao', newValue?.descricao);
        atualizarCampoItem(item.uuid, 'unidade', newValue?.unidade?.descricao);
        atualizarCampoItem(item.uuid, 'almoxarifado_id', newValue?.almoxarifado?.id);
        atualizarCampoItem(item.uuid, 'localizacao_id', newValue?.localizacao?.id);
    };

    return (
        <Box>
            <Drawer anchor="right" open={open}>
                <Box sx={{ width: '650px', padding: '.75em', height: '100%' }}>
                    <Box>
                        <Box sx={{ width: '100%', display: 'flex', gap: '1em' }}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    options={listAllItens.filter((item) => !item?.kit_itens)}
                                    getOptionLabel={(item) => `${item.id} - ${item?.descricao} - ${!item.apelido ? '' : item?.apelido} `}
                                    value={listAllItens.find((el) => el.id === item?.item_id) || null}
                                    onChange={(e, newValue) => handleChangeItem(newValue)}
                                    renderInput={(params) => <TextField {...params} label="Item" />}
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <TextField label="UN" value={item?.unidade} disabled InputLabelProps={{ shrink: true }} />
                            </FormControl>
                        </Box>
                        <Box sx={{ display: 'flex', gap: '1em', marginTop: '1em', marginBottom: '1em' }}>
                            <FormControl fullWidth>
                                <TextField
                                    label="Quantidade"
                                    type="number"
                                    value={item?.quantidade}
                                    onChange={(e) => {
                                        let resultado = parseFloat(e.target.value * item.valor_unitario);
                                        atualizarCampoItem(item?.uuid, 'quantidade', parseFloat(e.target.value)),
                                            atualizarCampoItem(item?.uuid, 'valor_total', resultado);
                                    }}
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <TextField
                                    label="Valor Unitário"
                                    type="number"
                                    value={item?.valor_unitario}
                                    onChange={(e) => {
                                        let resultado = parseFloat(item?.quantidade * e.target.value);
                                        atualizarCampoItem(item?.uuid, 'valor_unitario', parseFloat(e.target.value)),
                                            atualizarCampoItem(item?.uuid, 'valor_total', resultado);
                                    }}
                                />
                            </FormControl>
                        </Box>
                        <FormControl fullWidth>
                            <TextField label="Valor total" type="number" value={item?.valor_total} disabled />
                        </FormControl>
                        <Box sx={{ display: 'flex', gap: '1em', marginTop: '1em', marginBottom: '1em' }}>
                            <FormControl fullWidth>
                                <TextField
                                    label="Almoxarifado"
                                    select
                                    value={item?.almoxarifado_id}
                                    onChange={(e) => atualizarCampoItem(item?.uuid, 'almoxarifado_id', e.target.value)}
                                >
                                    {listAllAlmoxarifado.map((almoxarifado) => (
                                        <MenuItem key={almoxarifado.id} value={almoxarifado.id}>
                                            {almoxarifado.descricao}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                            <FormControl fullWidth>
                                <TextField
                                    label="Localização"
                                    select
                                    value={item?.localizacao_id}
                                    onChange={(e) => atualizarCampoItem(item?.uuid, 'localizacao_id', e.target.value)}
                                >
                                    {listAllLocalizacao.map((localizacao) => (
                                        <MenuItem key={localizacao.id} value={localizacao.id}>
                                            {localizacao.descricao}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                        </Box>
                        <Box sx={{ display: 'flex', gap: '1em', marginTop: '1em', marginBottom: '1em' }}>
                            <FormControl fullWidth>
                                <TextField
                                    label="Observação"
                                    value={item?.observacao}
                                    onChange={(e) => atualizarCampoItem(item?.uuid, 'observacao', e.target.value)}
                                />
                            </FormControl>
                        </Box>
                        <Box sx={{ display: 'flex', gap: '1em' }}>
                            {copiaItemSelecionado?.lote && (
                                <FormControl fullWidth>
                                    <TextField
                                        label="Lote"
                                        type="text"
                                        value={item?.lote}
                                        onChange={(e) => atualizarCampoItem(item?.uuid, 'lote', e.target.value)}
                                    />
                                </FormControl>
                            )}
                            {copiaItemSelecionado?.validade && (
                                <FormControl fullWidth>
                                    <TextField
                                        type="date"
                                        label="Data de Validade"
                                        value={item?.validade}
                                        onChange={(e) => atualizarCampoItem(item?.uuid, 'validade', e.target.value)}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </FormControl>
                            )}
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '2em', gap: '1em' }}>
                            <Tooltip title="Fechar">
                                <Button variant="contained" color="error" onClick={() => handleClose()}>
                                    <CloseIcon />
                                </Button>
                            </Tooltip>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    adicionarItemNaTabela(item);
                                    adicionarCampoItem();
                                }}
                            >
                                Salvar e fechar
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Drawer>
        </Box>
    );
};

export default ActionButtonsCreate;
