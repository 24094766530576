import { useParams, Link } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, Box, TablePagination, Autocomplete, Tooltip, TextField } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useState } from 'react';
import { useEffect } from 'react';
import { useApi } from 'Service/axios';
import { HeaderBox, TableBox } from './style';
import LocalizacaoTabela from 'components/Estoque/Localizacao/Tabela';
import DrawerCreateLocalizacao from 'components/Estoque/Localizacao/DrawerCreate';
import { toast } from 'react-toastify';
import DrawerEditLocalizacao from 'components/Estoque/Localizacao/DrawerEdit';

const Localizacao = () => {
    // ============================= INSTÂNCIAS =============================
    const api = useApi();
    // ============================= STATES =============================
    const [allLocalizacao, setAllLocalizacao] = useState([]);
    const [selectedLocalizacao, setSelectedLocalizacao] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [almoxarifados, setAlmoxarifados] = useState([]);
    const [localizacao, setLocalizacao] = useState(null);
    // ============================= FUNÇÕES =============================

    const handleClickOpenEdit = (e, item) => {
        e.preventDefault();
        setLocalizacao(item);
        setOpenEdit(true);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getAlmoxarifados = async () => {
        try {
            const response = await api.getAlmoxarifados();
            setAlmoxarifados(response.data);
        } catch (error) {
            toast.error(error.response.data.mensagem);
        }
    };

    const getLocalizacoes = async () => {
        try {
            const response = await api.getAllLocalizacoes();
            setAllLocalizacao(response.data);
        } catch (error) {}
    };

    useEffect(() => {
        getLocalizacoes();
        getAlmoxarifados();
    }, []);

    return (
        <MainCard title="LOCALIZAÇÃO">
            <Grid container spacing={gridSpacing}>
                {/* DRAWERS */}
                <DrawerCreateLocalizacao
                    open={open}
                    handleClose={handleClose}
                    getLocalizacoes={getLocalizacoes}
                    almoxarifados={almoxarifados}
                />
                <DrawerEditLocalizacao
                    open={openEdit}
                    handleClose={handleCloseEdit}
                    getLocalizacoes={getLocalizacoes}
                    almoxarifados={almoxarifados}
                    item={localizacao}
                />
                <HeaderBox>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={allLocalizacao}
                        getOptionLabel={(localizacao) => localizacao.descricao}
                        value={selectedLocalizacao}
                        onChange={(event, newValue) => {
                            setSelectedLocalizacao(newValue);
                        }}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Pesquisar Localização" />}
                    />
                    <Button variant="contained" size="medium" onClick={() => setOpen(true)} sx={{ height: '40px' }}>
                        Adicionar
                    </Button>
                </HeaderBox>

                <TableBox>
                    <LocalizacaoTabela
                        allLocalizacao={allLocalizacao}
                        setAllLocalizacao={setAllLocalizacao}
                        selectedLocalizacao={selectedLocalizacao}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        getLocalizacoes={getLocalizacoes}
                        handleClickOpenEdit={handleClickOpenEdit}
                    />
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={allLocalizacao.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableBox>
            </Grid>
        </MainCard>
    );
};

export default Localizacao;
