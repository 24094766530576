import React from 'react';
import MainCard from '../../../ui-component/cards/MainCard';
import { Button, CircularProgress, Divider, Grid, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { gridSpacing } from '../../../store/constant';
import { useApi } from '../../../Service/axios';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import SearchHeader from './components/SearchHeader';
import { DataGrid } from '@mui/x-data-grid';
import ActionButtonEstorno from './components/ActionButtonEstorno';
import DialpadIcon from '@mui/icons-material/Dialpad';
import DialogEstornoTotal from './components/DialogEstornoTotal';
import { LoadAnimated } from '../../../ui-component/LoadAnimated';

const Extorno = () => {
    const api = useApi();

    const [prontuarios, setProntuarios] = useState([]);
    const [selectedProntuario, setSelectedProntuario] = useState(null);
    const [saidas, setSaidas] = useState([]);
    const [disableSearch, setDisableSearch] = useState(false);
    const [openDrawer, setOpenDrawer] = useState();
    const [openDialogTotal, setOpenDialogTotal] = useState(false);
    const [selectedSaida, setSelectedSaida] = useState();
    const [quantidadeEstorno, setQuantidadeEstorno] = useState(null);
    // Columns do DataGrid
    const columns = [
        { field: 'id', headerName: 'ID SAIDA', flex: 0.5 },
        { field: 'item', headerName: 'ITEM', flex: 1, valueGetter: (params) => params.row.item.descricao },
        { field: 'quantidade', headerName: 'QTD SAIDA', flex: 0.5 },
        { field: 'totalEstorno', headerName: 'QTD ESTORNADO', flex: 0.5 },
        {
            field: 'extornado',
            headerName: 'ESTORNO TOTAL',
            flex: 0.5,
            valueGetter: (params) => (params?.row?.quantidade == params?.row?.totalEstorno ? 'SIM' : 'NÃO')
        },
        {
            field: 'actions',
            headerName: 'AÇÕES',
            flex: 0.5,
            renderCell: (params) => (
                <Tooltip title="Inserir quantidade">
                    <Button
                        disabled={params.row.totalEstorno == params.row.quantidade}
                        onClick={() => {
                            setOpenDrawer(true);
                            setSelectedSaida(params?.row);
                        }}
                    >
                        <DialpadIcon />
                    </Button>
                </Tooltip>
            )
        }
    ];

    function handleClose() {
        setQuantidadeEstorno(null);
        setOpenDrawer(false);
    }

    function handleCloseDialog() {
        setOpenDialogTotal(false);
    }

    function cleanSearch() {
        setSaidas([]);
        setDisableSearch(false);
        setSelectedSaida();
        setQuantidadeEstorno(null);
        setSelectedProntuario(null);
    }

    async function getProntuarios() {
        try {
            const { data } = await api.getAllProntuario();
            setProntuarios(data);
        } catch (error) {
            toast.error('Erro ao buscar prontuários');
        }
    }

    async function getAllSaidasByProntuario(prontuario) {
        try {
            if (prontuario == null) {
                return toast.warning('É necessário selecionar uma ficha');
            }

            const { data } = await api.getAllSaidasByProntuarioId(prontuario?.id);
            if (data.length == 0) {
                return toast.warning('Sem saídas registradas para essa ficha');
            }
            setDisableSearch(true);
            setSaidas(data);
        } catch (error) {
            toast.error('Erro ao buscar saídas');
        }
    }

    async function handleEstornoTotal() {
        // Se todas as saidas já tiver o saldo estornado não envia a requisição
        const todasAsSaidasEstornadas = saidas.every((saida) => saida?.quantidade == saida?.totalEstorno);
        if (todasAsSaidasEstornadas) {
            setOpenDialogTotal(false);
            return toast.error('Todas as saidas já foram estornadas');
        }
        // Montamos o payload com todas as saidas com saldo de estorno disponível e passamos pro handleSubmit
        // const saidasEstorno = saidas
        //     .filter((item) => item?.quantidade != item?.totalEstorno)
        //     .map((saida) => ({
        //         id: saida?.id,
        //         quantidade_extorno: saida?.quantidade - (saida?.totalEstorno ?? 0),
        //         prontuario_id: saida?.prontuario_id
        //     }));
        const saidasEstorno = saidas
            .filter((item) => {
                if (item.quantidade === item.totalEstorno) {
                    return false; // Pula este item
                }
                return true; // Mantém este item
            })
            .map((saida) => ({
                id: saida.id,
                quantidade_extorno: saida.quantidade - (saida.totalEstorno ?? 0),
                prontuario_id: saida.prontuario_id
            }));

        await handleSubmit(saidasEstorno);
        cleanSearch();
    }

    async function handleSubmit(estornoData) {
        try {
            // Quando for estorno de apenas um item
            if (estornoData.length == 1 && estornoData[0]?.quantidade_extorno > selectedSaida?.quantidade) {
                return toast.warning('Quantidade de estorno não pode ser maior que a de saida');
            }
            await api.createEstorno(estornoData);
            await getAllSaidasByProntuario(selectedProntuario);
            // Reset dos states em uso
            setOpenDrawer(false);
            setOpenDialogTotal(false);
            setSelectedSaida();
            setQuantidadeEstorno(null);

            toast.success('Estorno concluído com sucesso');
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error.message);
        }
    }

    useEffect(() => {
        getProntuarios();
    }, []);

    return (
        <React.Fragment>
            {prontuarios.length == 0 && (
                <MainCard title="ESTORNO">
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <LoadAnimated sx={{ margin: '0 auto' }} />
                    </Box>
                </MainCard>
            )}
            {prontuarios.length > 0 && (
                <MainCard title="ESTORNO">
                    {/* Modal de inserir quantidade de estorno */}
                    <ActionButtonEstorno
                        open={openDrawer}
                        handleClose={handleClose}
                        selectedSaida={selectedSaida}
                        quantidadeEstorno={quantidadeEstorno}
                        setQuantidadeEstorno={setQuantidadeEstorno}
                        handleSubmit={handleSubmit}
                    />
                    {/* Modal de estorno total */}
                    <DialogEstornoTotal
                        open={openDialogTotal}
                        handleCloseDialog={handleCloseDialog}
                        handleEstornoTotal={handleEstornoTotal}
                    />
                    <Grid container spacing={gridSpacing}>
                        {/* Campo de pesquisa */}
                        <SearchHeader
                            prontuarios={prontuarios}
                            cleanSearch={cleanSearch}
                            disableSearch={disableSearch}
                            selectedProntuario={selectedProntuario}
                            setSelectedProntuario={setSelectedProntuario}
                            getAllSaidasByProntuario={getAllSaidasByProntuario}
                        />
                        {/*  Datagrid com os dados de saida  */}

                        {saidas.length > 0 && (
                            <React.Fragment>
                                <Divider sx={{ width: '100%', margin: '2rem 1rem auto' }} />
                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', maxHeight: '450px' }}>
                                    <Tooltip title="Realizar estorno de todos os itens">
                                        <Button
                                            variant="contained"
                                            sx={{ width: '210px', marginLeft: 'auto', marginTop: '1rem' }}
                                            onClick={() => setOpenDialogTotal(true)}
                                        >
                                            Estorno Total
                                        </Button>
                                    </Tooltip>
                                    <DataGrid columns={columns} rows={saidas} sx={{ marginTop: '2rem', marginLeft: '2rem' }} />
                                </Box>
                            </React.Fragment>
                        )}
                    </Grid>
                </MainCard>
            )}
        </React.Fragment>
    );
};

export default Extorno;
