import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, Box, Select, MenuItem, FormControl, Typography, Checkbox, FormControlLabel, Switch } from '@mui/material';
import { gridSpacing } from 'store/constant';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { formatDate } from 'utils/formatDate';
import { useParams } from 'react-router-dom';
import { useApi } from 'Service/axios';
import { useEffect, useState } from 'react';
import ModalPacientDetails from '../../../components/modalPacients';
import ModalProntuario from 'components/ModalProntuario';
import ModalProntuarioStyled from 'components/ModalProntruarioNovo';
import TabelaAtendimento from 'components/tabela-atendimento';

import { toast } from 'react-toastify';

const Prontuario = () => {
    const { id } = useParams();
    const api = useApi();
    const [paciente, setPaciente] = useState(null);
    const [prontuario, setProntuario] = useState(null);
    const [openPrint, setOpenPrint] = useState(false);
    const [openPrintExterno, setOpenPrintExterno] = useState(false);
    const [data, setData] = useState('');
    const [convenios, setConvenios] = useState([]);
    const [convenio, setConvenio] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [openModalAutismo, setOpenModalAutismo] = useState(false);
    const [openProntuario, setOpenProntuario] = useState(false);
    const [tipoConsulta, setTipoConsulta] = useState([]);
    const [tipoSelecionado, setTipoSelecionado] = useState('');
    const [dataSelecionada, setDataSelecionada] = useState('');
    const [horaSelecionada, setHoraSelecionada] = useState('');
    const [procedimentoExternoSelecionado, setprocedimentoExternoSelecionado] = useState({
        dextro: false,
        inalacao: false,
        medicacao: false,
        ret_ponto: false,
        curativo: false,
        pa: false,
        ecg: false,
        raio_x: false,
        outros: false
    });
    const [medico, setMedico] = useState([]);
    const [medicoSelecionado, setMedicoSelecionado] = useState('');
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState(false);
    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const handleOpenProntuario = (e) => {
        e.preventDefault();
        setOpenProntuario(true);
    };

    const handleCloseProntuario = (e) => {
        e.preventDefault();
        setOpenProntuario(false);
    };

    const handleOpenModalAutismo = () => {
        setOpenModalAutismo(true);
    };

    const handleOpenPrint = (e) => {
        e.preventDefault();
        setOpenPrint(true);
    };

    const handleClosePrint = (e) => {
        e.preventDefault();
        setOpenPrint(false);
    };

    const get = async (id, procedimento_externo) => {
        // paciente / convenio
        const response = await api.getPacienteById(id);
        setPaciente({ ...response.data, procedimento_externo });

        const convenioResponse = await api.getAllConvenio();
        setConvenios(convenioResponse.data);

        const protuarioResponse = await api.getProntuarioByPaciente(response.data.id);
        setProntuario(protuarioResponse.data);
        // =============== DATA ====================
        const dataOriginal = formatDate(response.data.data_nascimento);
        // Dividir a data em componentes: ano, mês, dia
        const partesData = dataOriginal.split('-');
        const ano = partesData[0];
        const mes = partesData[1];
        const dia = partesData[2];
        // Formatar a data no formato "dd/mm/yyyy"
        setData(dia + '/' + mes + '/' + ano);
    };

    const getTipoConsulta = async () => {
        try {
            const response = await api.getTipoConsulta();
            setTipoConsulta(response.data);
        } catch (error) {
            console.error('Erro ao obter o tipo de consulta:', error);
        }
    };

    const getAllMedicos = async () => {
        try {
            const response = await api.getAllMedicos();
            setMedico(response.data);
            return response.data;
        } catch (error) {
            console.error('Erro ao obter o tipo de consulta:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);

            const response = await api.createProntuario({
                id_paciente: paciente.id.toString(),
                id_medico: medicoSelecionado.toString(),
                id_tipo_consulta: tipoSelecionado.toString(),
                data_atendimento: dataSelecionada,
                hora_atendimento: horaSelecionada,
                interno: checked
            });

            if (paciente.autismo) {
                handleOpenModalAutismo();
            }
            setOpenPrint(false);
            get(id, false);
            getTipoConsulta();
            getAllMedicos();
            setLoading(false);
            toast.success('Prontuário criado com sucesso!');
        } catch (error) {
            toast.error(error?.response?.data?.message, setOpenPrint(false), setMedicoSelecionado(''), setTipoSelecionado(''));
        }
    };
    const handleProcedimentoChange = (e) => {
        const { name, checked } = e.target;
        setprocedimentoExternoSelecionado((prevState) => ({
            ...prevState,
            [name]: checked
        }));
    };

    const handleSubmitExterno = async (e) => {
        try {
            setLoading(true);
            await api.createAtendimentoExterno({
                id_paciente: paciente.id.toString(),
                id_medico: null,
                id_tipo_consulta: null,
                data_atendimento: dataSelecionada,
                hora_atendimento: horaSelecionada,
                procedimento_externo: true,
                procedimentos: procedimentoExternoSelecionado
            });
            if (paciente.autismo) {
                handleOpenModalAutismo();
            }
            setOpenPrintExterno(false);
            get(id, true, procedimentoExternoSelecionado);
            getTipoConsulta();
            getAllMedicos();
            setLoading(false);
            toast.success('Atendimente Externo criado com sucesso!');
        } catch (error) {
            console.error('Erro ao criar o prontuário:', error);
        }
    };

    const handleTipoSelecionado = (e) => {
        setTipoSelecionado(e.target.value);
    };
    const handleDataSelecionada = (event) => {
        const selectedDate = event.target.value;
        setDataSelecionada(selectedDate);
    };

    const handleHoraSelecionada = (event) => {
        const selectedHora = event.target.value;
        setHoraSelecionada(selectedHora);
    };

    const handleMedicoSelecionado = (e) => {
        setMedicoSelecionado(e.target.value);
    };

    useEffect(() => {
        get(id);
        getTipoConsulta();
        getAllMedicos();
    }, []);

    useEffect(
        () => {
            if (dataSelecionada === '') {
                const today = new Date();
                const formattedDate = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(
                    today.getDate()
                ).padStart(2, '0')}`;
                setDataSelecionada(formattedDate);
            }

            if (horaSelecionada === '') {
                const today = new Date();
                const formattedDate = `${String(today.getHours()).padStart(2, '0')}:${String(today.getMinutes()).padStart(2, '0')}`;
                setHoraSelecionada(formattedDate);
            }
        },
        [dataSelecionada],
        [horaSelecionada]
    );

    return (
        <>
            <ModalPacientDetails paciente={paciente} open={openModal} onClose={setOpenModal} convenio={convenio} />
            {paciente !== null && (
                <MainCard title={`Prontuário do Paciente: ${paciente.nome_completo}`}>
                    {/* MODAL INFOS */}
                    <Dialog open={openProntuario} onClose={handleCloseProntuario}>
                        <DialogContent sx={{ width: '100%' }}>
                            <ModalProntuario prontuario={paciente} convenio={convenio} handleCloseProntuario={handleCloseProntuario} />
                        </DialogContent>
                    </Dialog>
                    {/* NOVO ATENDIMENTO */}
                    <Dialog open={openPrint} onClose={handleClosePrint}>
                        <DialogTitle>Relatório de Atendimento</DialogTitle>

                        <DialogContent>
                            <FormControlLabel
                                checked={checked}
                                onChange={handleChange}
                                control={<Switch inputProps={{ 'aria-label': 'controlled' }} />}
                                label="Interno"
                            />

                            <DialogContentText>Selecione o Médico:</DialogContentText>
                            <Select
                                value={medicoSelecionado}
                                onChange={handleMedicoSelecionado}
                                sx={{
                                    width: '300px',
                                    marginBottom: '1em'
                                }}
                            >
                                {medico.map((medico) => (
                                    <MenuItem key={medico.id} value={medico.id}>
                                        {medico?.info_medico?.nome_completo}
                                    </MenuItem>
                                ))}
                            </Select>

                            <DialogContentText>Qual o tipo de Consulta?</DialogContentText>
                            <Select
                                value={tipoSelecionado}
                                onChange={handleTipoSelecionado}
                                sx={{
                                    width: '300px'
                                }}
                            >
                                {tipoConsulta.map((tipo) => (
                                    <MenuItem key={tipo.id} value={tipo.id}>
                                        {tipo.descricao}
                                    </MenuItem>
                                ))}
                            </Select>
                            <DialogContentText sx={{ marginTop: '1em' }}>Selecione a Data:</DialogContentText>
                            <FormControl fullWidth variant="outlined">
                                <input
                                    type="date"
                                    value={dataSelecionada}
                                    disabled
                                    onChange={handleDataSelecionada}
                                    style={{
                                        width: '300px',
                                        height: '50px',
                                        borderRadius: '10px',
                                        border: '1px solid #a0a0a0',
                                        marginBottom: '1em'
                                    }}
                                />
                            </FormControl>
                            <DialogContentText sx={{ marginTop: '1em' }}>Hora do Atendimento:</DialogContentText>
                            <FormControl fullWidth variant="outlined">
                                <input
                                    type="time"
                                    disabled
                                    value={horaSelecionada}
                                    onChange={handleHoraSelecionada}
                                    style={{
                                        width: '300px',
                                        height: '50px',
                                        borderRadius: '10px',
                                        border: '1px solid #a0a0a0',
                                        marginBottom: '1em'
                                    }}
                                />
                            </FormControl>
                        </DialogContent>

                        <DialogActions>
                            <Button color="error" onClick={() => setOpenPrint(false)}>
                                Cancelar
                            </Button>
                            <Button
                                variant="contained"
                                disabled={loading}
                                onClick={(e) => {
                                    handleSubmit(e);
                                }}
                            >
                                Confirmar
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={openPrintExterno} onClose={handleClosePrint}>
                        <DialogTitle style={{ paddingRight: '60px' }}>Atendimento Externo</DialogTitle>
                        <DialogContent>
                            <DialogContentText sx={{ marginTop: '1em' }}>Selecione a Data:</DialogContentText>
                            <FormControl fullWidth variant="outlined">
                                <input
                                    type="date"
                                    value={dataSelecionada}
                                    disabled
                                    onChange={handleDataSelecionada}
                                    style={{
                                        width: '300px',
                                        height: '50px',
                                        borderRadius: '10px',
                                        border: '1px solid #a0a0a0',
                                        marginBottom: '1em'
                                    }}
                                />
                            </FormControl>
                            <DialogContentText sx={{ marginTop: '1em' }}>Hora do Atendimento:</DialogContentText>
                            <FormControl fullWidth variant="outlined">
                                <input
                                    type="time"
                                    disabled
                                    value={horaSelecionada}
                                    onChange={handleHoraSelecionada}
                                    style={{
                                        width: '300px',
                                        height: '50px',
                                        borderRadius: '10px',
                                        border: '1px solid #a0a0a0',
                                        marginBottom: '1em'
                                    }}
                                />
                            </FormControl>
                            <FormControl fullWidth variant="outlined">
                                <DialogContentText>PROCEDIMENTO</DialogContentText>
                                <FormControlLabel
                                    value="INALAÇÃO"
                                    onChange={handleProcedimentoChange}
                                    control={<Checkbox />}
                                    label="INALAÇÃO"
                                    name="inalacao"
                                />
                                <FormControlLabel
                                    value={'MEDICAÇÃO'}
                                    onChange={handleProcedimentoChange}
                                    control={<Checkbox />}
                                    label="MEDICAÇÃO"
                                    name="medicacao"
                                />
                                <FormControlLabel
                                    value="DEXTRO"
                                    onChange={handleProcedimentoChange}
                                    control={<Checkbox />}
                                    label="DEXTRO"
                                    name="dextro"
                                />
                                <FormControlLabel
                                    value="RET.PONTO"
                                    onChange={handleProcedimentoChange}
                                    control={<Checkbox />}
                                    label="RET.PONTO"
                                    name="ret_ponto"
                                />
                                <FormControlLabel
                                    value="CURATIVO"
                                    onChange={handleProcedimentoChange}
                                    control={<Checkbox />}
                                    label="CURATIVO"
                                    name="curativo"
                                />
                                <FormControlLabel
                                    value="P.A"
                                    onChange={handleProcedimentoChange}
                                    control={<Checkbox />}
                                    label="P.A"
                                    name="pa"
                                />
                                <FormControlLabel
                                    value="ECG"
                                    name="ecg"
                                    onChange={handleProcedimentoChange}
                                    control={<Checkbox />}
                                    label="ECG"
                                />
                                <FormControlLabel
                                    value="RAIO-X"
                                    onChange={handleProcedimentoChange}
                                    control={<Checkbox />}
                                    label="RAIO-X"
                                    name="raio_x"
                                />
                                <FormControlLabel
                                    value="EXAME_CABO"
                                    onChange={handleProcedimentoChange}
                                    control={<Checkbox />}
                                    label="EXAME LABORATORIAL"
                                    name="exame_cabo"
                                />
                                <FormControlLabel
                                    value="OUTROS"
                                    onChange={handleProcedimentoChange}
                                    control={<Checkbox />}
                                    label="OUTROS"
                                    name="outros"
                                />
                            </FormControl>
                        </DialogContent>

                        <DialogActions>
                            <Button color="error" onClick={() => setOpenPrintExterno(false)}>
                                Cancelar
                            </Button>
                            <Button
                                variant="contained"
                                disabled={loading}
                                onClick={(e) => {
                                    handleSubmitExterno(e);
                                }}
                            >
                                Confirmar
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/* DIALOG DE AUTISMO */}
                    <Dialog open={openModalAutismo}>
                        <DialogTitle>Atenção!</DialogTitle>
                        <DialogContent>
                            <Typography variant="h3">Cadastro do paciente está habilitado campo 'Autismo'</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" onClick={() => setOpenModalAutismo(false)}>
                                Ok
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Grid container spacing={gridSpacing}>
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', marginLeft: '1.3em' }}>
                            <div
                                style={{
                                    margin: '1em 0',
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <Button
                                    variant="contained"
                                    sx={{ marginLeft: '1em' }}
                                    onClick={(e) => {
                                        handleOpenProntuario(e);
                                    }}
                                >
                                    Dados Completos
                                </Button>
                                <Box>
                                    <Button
                                        style={{ marginRight: '1em' }}
                                        variant="contained"
                                        onClick={() => setOpenPrintExterno(true)}
                                        tipoConsulta={tipoConsulta}
                                        data_atendimento={dataSelecionada}
                                    >
                                        Procedimento Externo
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={handleOpenPrint}
                                        tipoConsulta={tipoConsulta}
                                        data_atendimento={dataSelecionada}
                                    >
                                        Iniciar Atendimento
                                    </Button>
                                </Box>
                            </div>
                            {/* CAIXA DE INFOS PACIENTE */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <ModalProntuarioStyled paciente={paciente} />
                                <TabelaAtendimento Allprontuario={prontuario} />
                            </Box>
                        </Box>
                    </Grid>
                </MainCard>
            )}
        </>
    );
};

export default Prontuario;
