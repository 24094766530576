import { Stepper, Step, StepLabel, Box, Button, TextField, InputLabel } from '@mui/material';
import { useState } from 'react';
import { formatarCEL } from 'utils/formatCEL';
import { formatarCPF } from 'utils/formatCpf';
import { formatarRG } from 'utils/formatRG';
import { formatarSUS } from 'utils/formatSUS';
import { formatarCEP } from 'utils/formatCEP';
import formater from 'utils/DateDDMMYYY';
import { MainBoxRight, MainBoxLeft, ContentBox } from './style';

function ModalProntuario({ prontuario, convenio }) {
    const [etapa, setEtapa] = useState(0);

    return (
        <Box sx={{ width: '100%' }}>
            <Box>
                <Stepper activeStep={etapa} alternativeLabel sx={{ marginBottom: '3em', marginTop: '2em', borderBottom: '1px solid #000' }}>
                    <Step>
                        <StepLabel>Informações Pessoais</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Documentos</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Endereço</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Outros</StepLabel>
                    </Step>
                </Stepper>

                {etapa === 0 && (
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <MainBoxRight sx={{ padding: '1em', marginRight: '2em' }}>
                            <ContentBox>
                                <InputLabel id="nome_completo">Nome Completo</InputLabel>
                                <TextField id="nome_completo" value={prontuario.nome_completo} disabled />
                            </ContentBox>
                            <ContentBox>
                                <InputLabel id="nome_social">Nome Social</InputLabel>
                                <TextField id="nome_social" value={prontuario.nome_social} disabled />
                            </ContentBox>
                            <ContentBox>
                                <InputLabel id="naturalidade">Naturalidade</InputLabel>
                                <TextField id="naturalidade" value={prontuario.naturalidade} disabled />
                            </ContentBox>
                            <ContentBox>
                                <InputLabel id="telefone">Telefone</InputLabel>
                                <TextField id="telefone" value={prontuario.telefone ? formatarCEL(prontuario.telefone) : ''} disabled />
                            </ContentBox>
                            <ContentBox>
                                <InputLabel id="sexo">Sexo</InputLabel>
                                <TextField
                                    id="sexo"
                                    value={prontuario.sexo === 3 ? 'Outros' : prontuario.sexo === 2 ? 'Feminino' : 'Masculino'}
                                    disabled
                                />
                            </ContentBox>
                        </MainBoxRight>
                        <MainBoxLeft sx={{ padding: '1em', marginLeft: '2em' }}>
                            <ContentBox>
                                <InputLabel id="cor">Cor</InputLabel>
                                <TextField id="cor" value={prontuario.cor} disabled />
                            </ContentBox>
                            <ContentBox>
                                <InputLabel id="estado_civil">Estado Civil</InputLabel>
                                <TextField id="estado_civil" value={prontuario.estado_civil} disabled />
                            </ContentBox>
                            <ContentBox>
                                <InputLabel id="data_nascimento">Data Nascimento</InputLabel>
                                <TextField
                                    id="data_nascimento"
                                    value={prontuario.data_nascimento ? formater(prontuario.data_nascimento) : ''}
                                    disabled
                                />
                            </ContentBox>
                            <ContentBox>
                                <InputLabel id="nome_mae">Nome Mãe</InputLabel>
                                <TextField id="nome_mae" value={prontuario.nome_mae} disabled />
                            </ContentBox>
                            <ContentBox>
                                <InputLabel id="nome_pai">Nome Pai</InputLabel>
                                <TextField id="nome_pai" value={prontuario.nome_pai} disabled />
                            </ContentBox>
                        </MainBoxLeft>
                    </Box>
                )}

                {etapa === 1 && (
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <MainBoxRight sx={{ padding: '1em', marginRight: '2em' }}>
                            <ContentBox>
                                <InputLabel id="cpf">CPF</InputLabel>
                                <TextField id="cpf" value={prontuario.cpf ? formatarCPF(prontuario.cpf) : ''} disabled />
                            </ContentBox>
                            <ContentBox>
                                <InputLabel id="rg">RG</InputLabel>
                                <TextField id="rg" value={prontuario.rg ? formatarRG(prontuario.rg) : ''} disabled />
                            </ContentBox>
                        </MainBoxRight>
                        <MainBoxLeft sx={{ padding: '1em', marginLeft: '2em' }}>
                            <ContentBox>
                                <InputLabel id="cartao_sus">Cartão SUS</InputLabel>
                                <TextField
                                    id="cartao_sus"
                                    value={prontuario.cartao_sus ? formatarSUS(prontuario.cartao_sus) : ''}
                                    disabled
                                />
                            </ContentBox>
                            <ContentBox>
                                <InputLabel id="numero_carteirinha">Número Carteirinha</InputLabel>
                                <TextField id="numero_carteirinha" value={prontuario.numero_carteirinha} disabled />
                            </ContentBox>
                        </MainBoxLeft>
                    </Box>
                )}

                {etapa === 2 && (
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <MainBoxRight sx={{ padding: '1em', marginRight: '2em' }}>
                            <ContentBox>
                                <InputLabel id="cep">CEP</InputLabel>
                                <TextField id="cep" value={prontuario.cep ? formatarCEP(prontuario.cep) : ''} disabled />
                            </ContentBox>
                            <ContentBox>
                                <InputLabel id="ibge">IBGE</InputLabel>
                                <TextField id="ibge" value={prontuario.ibge} disabled />
                            </ContentBox>
                            <ContentBox>
                                <InputLabel id="tipo_logradouro">Tipo logradouro</InputLabel>
                                <TextField id="tipo_logradouro" value={prontuario.tipo_logradouro} disabled />
                            </ContentBox>
                            <ContentBox>
                                <InputLabel id="endereco">Endereço</InputLabel>
                                <TextField id="endereco" value={prontuario.endereco} disabled />
                            </ContentBox>
                        </MainBoxRight>
                        <MainBoxLeft sx={{ padding: '1em', marginLeft: '2em' }}>
                            <ContentBox>
                                <InputLabel id="numero">Número</InputLabel>
                                <TextField id="numero" value={prontuario.numero} disabled />
                            </ContentBox>
                            <ContentBox>
                                <InputLabel id="bairro">Bairro</InputLabel>
                                <TextField id="bairro" value={prontuario.bairro} disabled />
                            </ContentBox>
                            <ContentBox>
                                <InputLabel id="cidade">Cidade</InputLabel>
                                <TextField id="cidade" value={prontuario.cidade} disabled />
                            </ContentBox>
                            <ContentBox>
                                <InputLabel id="estado">Estado</InputLabel>
                                <TextField id="estado" value={prontuario.estado} disabled />
                            </ContentBox>
                        </MainBoxLeft>
                    </Box>
                )}

                {etapa === 3 && (
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <MainBoxRight sx={{ padding: '1em', marginRight: '2em' }}>
                            <ContentBox>
                                <InputLabel id="id_convenio">Convênio</InputLabel>
                                <TextField id="id_convenio" value={prontuario.convenio.descricao} disabled />
                            </ContentBox>
                        </MainBoxRight>
                        <MainBoxLeft sx={{ padding: '1em', marginLeft: '2em' }}>
                            <ContentBox>
                                <InputLabel id="obito">Óbito</InputLabel>
                                <TextField id="obito" value={prontuario.obito === false ? 'Não' : 'Sim'} disabled />
                            </ContentBox>
                        </MainBoxLeft>
                    </Box>
                )}

                <Box sx={{ mt: 2, width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    {etapa > 0 && (
                        <Button type="button" onClick={(e) => setEtapa((prevEtapa) => prevEtapa - 1)}>
                            Voltar
                        </Button>
                    )}

                    {etapa <= 2 && (
                        <Button type="button" onClick={(e) => setEtapa((prevEtapa) => prevEtapa + 1)} sx={{ marginLeft: 'auto' }}>
                            Próximo
                        </Button>
                    )}
                </Box>
            </Box>
        </Box>
    );
}

export default ModalProntuario;
