import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { ConjuntoLaudoContext } from 'context/LaudoContext';
import { useContext } from 'react';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import formateDate from 'utils/formatDate-1';
import { useState } from 'react';
import Logo from '../../../../assets/images/Logos/LOGO_DO_LAC.jpg';
import SantaCasaLogo from '../../../../assets/images/Logos/logo2.png';
import { fontSize } from '@mui/system';
import BwLogo from '../../../../assets/BW TECNOLOGIA.png';

const PdfExamesPeriodoPaciente = ({ data }) => {
    const styles = {
        page: {
            paddingTop: 15,
            paddingBottom: 15,
            paddingHorizontal: 15,
            backgroundColor: '#fff',
            fontSize: 10,
            fontWeight: 'normal',
            rap: 'wrap'
        },
        header: {
            padding: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        },
        image: {
            width: 100,
            backgroundSize: 'cover'
        },
        text1: {
            fontSize: 10
        },
        box: {
            border: '1px solid #ddd',
            padding: 10,

            borderRadius: 5
        },
        between: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        labelArea: {
            backgroundColor: '#ccc',
            padding: '8px',
            borderRadius: 5,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },

        row: { flexDirection: 'row', borderBottom: '1 solid #000' },
        cell: { flexGrow: 1, padding: 5 }
    };

    const formatarData = (date) => {
        const dateObj = new Date(date);
        const year = dateObj.getUTCFullYear();
        const month = ('0' + (dateObj.getUTCMonth() + 1)).slice(-2);
        const day = ('0' + dateObj.getUTCDate()).slice(-2);
        return `${day}/${month}/${year}`;
    };

    const splitDateAndTime = (dateTimeString) => {
        const [date, time] = dateTimeString.split(' ');
        return { date, time };
    };

    function DinamicTable({ dados }) {
        const styles = {
            table: { width: '100%', borderCollapse: 'collapse', margin: '5px' },
            cell: { borderBottom: '1px solid #999', padding: '5px', wordWrap: 'break-word' }
        };

        const examesAgrupados = dados.reduce((agrupados, item) => {
            const dataExecucao = splitDateAndTime(item?.data_execucao).date;

            if (agrupados[dataExecucao]) {
                agrupados[dataExecucao].push(item);
            } else {
                agrupados[dataExecucao] = [item];
            }

            return agrupados;
        }, {});
        return (
            <View style={{ top: '4px', paddingLeft: '15px', paddingTop: '15px' }}>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingBottom: '5px',
                        gap: '30px',
                        fontSize: 10
                    }}
                >
                    <Text>DATA INICIO: {formatarData(data.dataDate?.firstDate)}</Text>
                    <Text>
                        DATA FINAL: <Text>{formatarData(data.dataDate?.lastDate)}</Text>
                    </Text>
                </View>
                {Object.keys(examesAgrupados)?.map((exameID, index) => (
                    <View
                        key={index}
                        style={{
                            breakInside: 'auto',

                            flexDirection: 'column',
                            alignItems: 'start',
                            marginTop: '10px',

                            paddingBottom: '15px'
                        }}
                    >
                        <View
                            style={{
                                display: 'flex',
                                flexDirection: 'row'
                            }}
                        >
                            <View style={{ display: 'flex', flexDirection: 'column', width: '20%', paddingLeft: '6px' }}>
                                <Text style={{ fontSize: 12 }}>
                                    DATA:{' '}
                                    <Text style={{ fontSize: 10, color: '#000000d2' }}>
                                        {splitDateAndTime(examesAgrupados[exameID][0]?.data_execucao).date}
                                    </Text>
                                </Text>
                            </View>
                            <View style={{ display: 'flex', flexDirection: 'column', width: '15%', fontSize: 12 }}>
                                <Text>HORARIO</Text>
                            </View>

                            <View style={{ display: 'flex', flexDirection: 'column', width: '35%', marginRight: '162px', fontSize: 12 }}>
                                <Text>EXAME</Text>
                            </View>
                        </View>
                        <View style={{ border: '1px solid #000000', width: '100%', marginTop: '4px' }}></View>

                        {examesAgrupados[exameID]?.map((exame, index) => (
                            <View
                                key={exame?.exame?.id}
                                style={{
                                    /* top: '4px', */
                                    paddingTop: '5px',
                                    width: '100%',

                                    flexDirection: 'column'
                                }}
                            >
                                <View
                                    style={{
                                        width: '80%',

                                        display: 'flex',
                                        flexDirection: 'row'
                                    }}
                                >
                                    <View
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            paddingLeft: '110px',
                                            width: '10%',
                                            color: '#000000d2'
                                        }}
                                    >
                                        <Text>{splitDateAndTime(exame?.data_execucao).time}</Text>
                                    </View>
                                    <View
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'start',
                                            alignItems: 'flex-start',
                                            width: '90%',

                                            paddingLeft: '85px'
                                        }}
                                    >
                                        <Text style={{ fontSize: 9, color: '#000000d2' }}>
                                            {exame?.exame?.nome || exame?.exames_especiai?.nome}
                                        </Text>
                                    </View>
                                </View>
                                <View style={{ border: '0.5px solid #999', width: '100%', marginTop: '5px' }}></View>
                            </View>
                        ))}
                        <Text style={{ paddingLeft: '75%', fontSize: 10 }}>TOTAL: {examesAgrupados[exameID].length}</Text>
                    </View>
                ))}
            </View>
        );
    }
    const examesAgrupadosPorNome = data?.dataPdf.reduce((agrupados, item) => {
        const exames = item?.exame?.nome || item?.exames_especiai?.nome;

        if (agrupados[exames]) {
            agrupados[exames].push(item);
        } else {
            agrupados[exames] = [item];
        }

        return agrupados;
    }, {});
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.header}>
                    <Image src={Logo} style={styles.image} alt="logo" />
                    <Text>LABORATÓRIO DE ANÁLISES CLÍNICAS SANTA CASA DE MISERICÓRDIA DE JOANÓPOLIS</Text>
                </View>

                <View style={styles.box}>
                    <View style={styles.labelArea}>
                        <View style={{ gap: '5px' }}>
                            <Text>RELÁTORIO DE EXAMES POR PERÍODO PACIENTE</Text>
                        </View>

                        <View>
                            <Text>
                                PACIENTE:
                                <Text
                                    style={{ fontSize: 9 }}
                                >{`  ${data?.dataPdf[0]?.prontuario?.paciente.id} - ${data?.dataPdf[0]?.prontuario?.paciente?.nome_completo}`}</Text>
                            </Text>
                        </View>
                    </View>
                </View>
                <View>
                    <DinamicTable dados={data?.dataPdf} />
                </View>

                <Text style={{ fontSize: 10 }}>TOTAL GERAL:{data?.dataPdf.length}</Text>

                <View style={{ display: 'flex', flexDirection: 'column', paddingTop: '10px' }}>
                    <View style={{ display: 'flex', flexDirection: 'column' }}>
                        <Text style={{ display: 'flex', fontSize: 12 }}>RESUMO</Text>
                        <View style={{ border: '1px solid #000000', width: '100%', marginTop: '4px' }}></View>

                        <View style={{ display: 'flex', fontSize: 12, flexDirection: 'row', gap: '240px' }}>
                            <Text style={{ display: 'flex', fontSize: 12 }}>EXAME</Text>
                            <Text style={{ display: 'flex', fontSize: 12 }}>QUANTIDADE</Text>
                        </View>

                        <View style={{ border: '1px solid #000000', width: '100%', marginTop: '4px' }}></View>
                        {Object.keys(examesAgrupadosPorNome).map((exameName, index) => (
                            <>
                                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                    <Text key={index} style={{ fontSize: 10, paddingTop: '5px', width: '48%' }}>{`${exameName}`}</Text>
                                    <View>
                                        <Text
                                            key={index}
                                            style={{
                                                fontSize: 10,
                                                paddingLeft: '15px',
                                                paddingTop: '5px',
                                                color: '#000000d2',

                                                justifyContent: 'start',
                                                alignItems: 'flex-start'
                                            }}
                                        >{`${examesAgrupadosPorNome[exameName].length}`}</Text>
                                    </View>
                                </View>
                                <>
                                    <View style={{ border: '0.5px solid #999', width: '100%', marginTop: '5px' }}></View>
                                </>
                            </>
                        ))}
                    </View>
                </View>

                <View
                    fixed
                    style={{
                        width: '100%',
                        maxHeight: '8%',
                        display: 'flex',

                        paddingTop: '25px',
                        gap: '150px',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <View
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '50%',
                            justifyContent: 'flex-start',
                            alignItems: 'center'
                        }}
                    >
                        <Image fixed src={SantaCasaLogo} alt="Santa Casa Logotipo" style={{ width: '30px' }} />
                        <View
                            fixed
                            style={{
                                padding: '1.5em',
                                gap: '5px',
                                fontSize: 5
                            }}
                        >
                            <Text>SANTA CASA DE MISERICÓRDIA DE JOANÓPOLIS</Text>
                            <Text>RUA FRANCISCO WOLHERS N°57 - CENTRO - JOANÓPOLIS - SP</Text>
                            <Text>CEP: 12980-000 - TELEFONE: (11) 4539-9333</Text>
                        </View>
                    </View>
                    <Image style={{ display: 'flex', width: '15%', justifyContent: 'flex-end', alignItems: 'center' }} src={BwLogo} />
                </View>
                <Text
                    style={{ marginLeft: '88%', paddingBottom: '10px', paddingTop: '10px' }}
                    fixed
                    render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                />
            </Page>
        </Document>
    );
};

export default PdfExamesPeriodoPaciente;
