// assets
import { IconBellRinging } from '@tabler/icons';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';
const icons = {
    IconBellRinging,
    PersonalVideoIcon
};

const doctorsOffice = {
    id: 'consultorio',
    title: 'Consultório Médico',
    type: 'group',
    children: [
        {
            id: 'clinic',
            title: 'Consultório',
            type: 'item',
            url: '/main/consultorio/salas',
            icon: icons.IconBellRinging,
            breadcrumbs: false
        },
        {
            id: 'atendimento',
            title: 'Atendimento',
            type: 'item',
            url: '/main/consultorio/atendimento',
            icon: icons.PersonalVideoIcon,
            breadcrumbs: false
        }
    ]
};

export default doctorsOffice;
