import MainCard from 'ui-component/cards/MainCard';
import { Grid, TextField, TablePagination, Autocomplete, Box, Card, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useApi } from 'Service/axios';
import { useEffect, useState } from 'react';

import ExamesAtendimentoTabela from 'components/Laboratório/Tabela';
import { MainBox, TableBox } from './style';
import { HeaderBox } from 'views/Lobby/style';
import { toast } from 'react-toastify';

const Laboratorio = () => {
    // ============================= INSTÂNCIAS =============================
    const api = useApi();
    // ============================= STATES =============================
    const [allFichas, setAllFichas] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedFicha, setSelectedFicha] = useState(null);
    // ============================= FUNÇÕES =============================

    const getFicha = async () => {
        const response = await api.getAllProntuario();
        setAllFichas(response.data);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        getFicha();
    }, []);

    return (
        <MainCard title="LABORATÓRIO">
            <Grid container spacing={gridSpacing}>
                <MainBox>
                    <HeaderBox>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={allFichas}
                            getOptionLabel={(item) => `${String(item.id)} - ${item?.paciente?.nome_completo}`}
                            value={selectedFicha}
                            onChange={(event, newValue) => {
                                setSelectedFicha(newValue);
                            }}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} label="Pesquisar Ficha" />}
                            filterOptions={(options, { inputValue }) =>
                                options.filter(
                                    (option) =>
                                        String(option.id).includes(inputValue) ||
                                        option?.paciente?.nome_completo.toLowerCase().includes(inputValue.toLowerCase())
                                )
                            }
                        />
                    </HeaderBox>
                    <TableBox>
                        <ExamesAtendimentoTabela
                            allFichas={allFichas}
                            setAllFichas={setAllFichas}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            selectedFicha={selectedFicha}
                        />
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={allFichas.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableBox>
                </MainBox>
            </Grid>
        </MainCard>
    );
};

export default Laboratorio;
