import styled from '@emotion/styled';
import { Box, TextField } from '@mui/material';

export const TitleArea = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const InputArea = styled(Box)`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
`;

export const CustomInput = styled(TextField)`
    flex: 1;
`;

export const BtnArea = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
`;
