import { useApi } from 'Service/axios';
import { Button, Tooltip } from '@mui/material';
import { useState, useEffect } from 'react';
import { Box } from '@mui/system';

import EditIcon from '@mui/icons-material/Edit';

const ActionButton = ({ row, setTransferencia, resetStates, handleChangeLocationsOrigem, handleChangeLocationsDestino }) => {
    /////////////////////////////// INSTANCIAS /////////////////////////
    const api = useApi();
    /////////////////////////////// STATES /////////////////////////

    /////////////////////////////// FUNÇÕES /////////////////////////
    const handleOpen = async () => {
        setTransferencia({
            id: row.id,
            itemId: row.item_id,
            almoxarifado_origem: row.origemAlmoxarifado.id,
            localizacao_origem: row.origemLocalizacao.id,
            lote_origem: row.origem_lote,
            unidade: row.unidade_id,
            observacao: row.observacao,
            quantidade: row.quantidade,
            data: row.data_transferencia,
            almoxarifado_destino: row.destinoAlmoxarifado.id,
            localizacao_destino: row.destinoLocalizacao.id
        });

        handleChangeLocationsOrigem(row.origemAlmoxarifado.id);
        handleChangeLocationsDestino(row.destinoAlmoxarifado.id);
        resetStates();
    };

    return (
        <Box>
            <Tooltip title="Editar Entrada">
                <Button onClick={handleOpen}>
                    <EditIcon />
                </Button>
            </Tooltip>
        </Box>
    );
};

export default ActionButton;
