import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Drawer,
    FormControl,
    MenuItem,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/Logout';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';

const ActionButtonsEditCreate = ({
    open,
    handleClose,
    itens,
    setItens,
    campos,
    setCampos,
    adicionarCampoItem,
    atualizarCampoItem,
    removerCampoItem,
    indiceCampoItem,
    item,
    listAllItens,
    listAllAlmoxarifado,
    listAllLocalizacao
}) => {
    /////////////////////////////// INSTANCIAS /////////////////////////

    /////////////////////////////// STATES /////////////////////////
    const [openDialog, setOpenDialog] = useState(false);
    const [indexItem, setIndexItem] = useState(null);
    const api = useApi();
    /////////////////////////////// FUNÇÕES /////////////////////////
    const handleOpen = async () => {
        setOpen(true);
    };

    const handleCreteItem = async () => {
        const ultimoItem = itens[itens.length - 1];
        let ultimoId;
        if (!ultimoItem) {
            ultimoId = await api.getUltimoIdKitItem();
        }
        const novoId = ultimoItem ? ultimoItem.id + 1 : ultimoId.data.id + 1;
        const novoItem = { ...item, id: novoId };
        setItens((prevItens) => [...prevItens, novoItem]);
    };

    useEffect(() => {
        atualizarCampoItem(0, indiceCampoItem, 'indiceCampoItem', indiceCampoItem);
    }, []);

    return (
        <Box>
            <Dialog open={openDialog}>
                <DialogTitle>Atenção</DialogTitle>
                <DialogContent>
                    <Typography>Ao fechar todo o cadastro desse item sera cancelado, continuar?</Typography>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ display: 'flex' }}>
                        <Button color="error" onClick={() => setOpenDialog(false)}>
                            Cancelar
                        </Button>
                        <Button
                            onClick={() => {
                                removerCampoItem(0, indiceCmpoItem), setOpenDialog(false);
                            }}
                        >
                            Confirmar
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <Drawer anchor="right" open={open}>
                <Box sx={{ width: '650px', padding: '.75em', height: '100%' }}>
                    <Box>
                        <FormControl fullWidth>
                            <TextField
                                select
                                label="Item"
                                value={item.id}
                                onChange={(e) => {
                                    const objeto = e.target.value;

                                    atualizarCampoItem(0, indiceCampoItem, 'item_id', objeto.id);
                                    atualizarCampoItem(0, indiceCampoItem, 'descricao', objeto.descricao);
                                    atualizarCampoItem(0, indiceCampoItem, 'unidade', objeto.unidade.descricao);
                                    atualizarCampoItem(0, indiceCampoItem, 'unidade_id', objeto.unidade_id);
                                    atualizarCampoItem(0, indiceCampoItem, 'almoxarifado_id', objeto.almoxarifado.id);
                                    atualizarCampoItem(0, indiceCampoItem, 'localizacao_id', objeto.localizacao.id);
                                }}
                            >
                                {listAllItens.map((i) => (
                                    <MenuItem key={i.id} value={i}>
                                        {i.descricao}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormControl>
                        <Box sx={{ display: 'flex', gap: '1em', marginTop: '1em', marginBottom: '1em' }}>
                            <FormControl fullWidth>
                                <TextField
                                    label="Quantidade"
                                    type="number"
                                    value={item.quantidade}
                                    onChange={(e) => atualizarCampoItem(0, indiceCampoItem, 'quantidade', parseFloat(e.target.value))}
                                />
                            </FormControl>
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '2em' }}>
                            <Tooltip title="Fechar">
                                <Button variant="contained" color="warning" onClick={handleClose}>
                                    <LogoutIcon />
                                </Button>
                            </Tooltip>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    handleCreteItem(), toast.success('Entrada cadastrada');
                                }}
                            >
                                Salvar
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    handleCreteItem(), handleClose(), toast.success('Entrada cadastrada');
                                }}
                            >
                                Salvar e fechar
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Drawer>
        </Box>
    );
};

export default ActionButtonsEditCreate;
