import NurginLayoute from 'layout/NursingLayoute';
import NursingPainel from 'views/enfermagem/Painel';

const NursingChamadas = {
    path: '/chamadas',
    element: <NurginLayoute />,
    children: [
        {
            path: '/chamadas/enfermagem/painel',
            element: <NursingPainel />
        }
    ]
};

export default NursingChamadas;
