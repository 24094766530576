import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    MenuItem,
    Switch,
    TextField,
    Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useApi } from 'Service/axios';

const ActionButtonGeralSimples = () => {
    // ---- INSTÂNCIAS ----
    const api = useApi();
    // ---- STATES ----
    const [allProducts, setAllProducts] = useState([]);
    const [filtredProducts, setFiltredProducts] = useState([]);
    const [product, setProduct] = useState(null);
    const [checked, setChecked] = useState(true);
    const [switchCheck, setSwitchCheck] = useState(false);
    const [open, setOpen] = useState(false);
    // ---- FUNÇÕES ----

    const getProducts = async () => {
        try {
            const response = await api.getAllItemsNoFilter();
            setAllProducts(response.data);
            setFiltredProducts(response.data.filter((item) => item.psicotropico === true));
        } catch (error) {
            toast.error(`Erro ao buscar dados: ${error.message}`);
        }
    };

    const handleChangeCheckbox = (e) => {
        const newCheckedValue = e.target.checked;
        setChecked(newCheckedValue);

        if (!newCheckedValue) {
            setFiltredProducts(allProducts.filter((item) => item.psicotropico === false));
        } else {
            setFiltredProducts(allProducts.filter((item) => item.psicotropico === true));
        }
    };

    const handleChangeSwitch = (e) => {
        const newSwitchCheckValue = e.target.checked;
        setSwitchCheck(e.target.checked);

        if (!newSwitchCheckValue) {
            setFiltredProducts(allProducts.filter((item) => item.psicotropico === true));
        } else {
            setFiltredProducts(allProducts);
        }
    };

    const handleChangeInput = (value) => {
        setProduct(value);
    };

    const handleClose = () => {
        setProduct(null);
        setChecked(true);
        setFiltredProducts(allProducts.filter((item) => item.psicotropico === true));
        setOpen(false);
    };

    const handleNavigate = (params) => {
        if (!switchCheck && params === null && checked) {
            window.open(`/relatorios/estoque-geral-simples/${'psicotropicos'}`);
        } else if (!switchCheck && params === null && !checked) {
            window.open(`/relatorios/estoque-geral-simples/${'abertos'}`);
        } else {
            handleClose();
            if (switchCheck) {
                window.open(`/relatorios/estoque-geral-simples/${'todos'}`);
            } else {
                window.open(`/relatorios/estoque-geral-simples/${params}`);
            }
        }
    };

    useEffect(() => {
        getProducts();
    }, []);

    return (
        <Box>
            <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'md'}>
                <DialogContent>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ textAlign: 'center', padding: '.5em' }}>
                            <Typography variant="h3">RELATÓRIO DE ESTOQUE GERAL SIMPLES</Typography>
                        </Box>
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ padding: '.4em' }}>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Item"
                                        select
                                        value={product}
                                        disabled={switchCheck}
                                        onChange={(e) => handleChangeInput(e.target.value)}
                                    >
                                        {filtredProducts.map((product) => (
                                            <MenuItem key={product.id} value={product.id}>
                                                {product.descricao}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            disabled={switchCheck}
                                            label="TESTE"
                                            checked={checked}
                                            onChange={(e) => handleChangeCheckbox(e)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    }
                                    label="PSICOTRÓPICO"
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={switchCheck}
                                            onChange={(e) => handleChangeSwitch(e)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    }
                                    label="Todos"
                                />
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button color="error" onClick={() => setOpen(false)}>
                        Cancelar
                    </Button>
                    <Button variant="contained" onClick={() => handleNavigate(product)}>
                        Gerar
                    </Button>
                </DialogActions>
            </Dialog>
            <Button onClick={() => setOpen(true)}>Abrir</Button>
        </Box>
    );
};

export default ActionButtonGeralSimples;
