import { useParams } from 'react-router-dom';
import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import {
    ExameInfoBox,
    HalfBox,
    HeaderBox,
    HeaderBoxSecondary,
    HeaderInfoBox,
    InfoBox,
    InfoBoxMedium,
    InfoBoxMediumEnd,
    TableBox
} from './style';
import { FormBox } from './style';
import { useEffect, useState } from 'react';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';
import formater from 'utils/DateDDMMYYY';

const LaudoExame = () => {
    // ============================= INSTÂNCIAS =============================
    const { prontuarioId, exameId, idExameProntuario } = useParams();
    const api = useApi();
    // ============================= STATES =============================
    const [data, setData] = useState(null);
    const [camposAgrupados, setCamposAgrupados] = useState([]);
    const [dataFormatada, setDataFormatada] = useState(null);
    const [horaFormatada, setHoraFormatada] = useState(null);
    const [loading, setLoading] = useState(true);
    const [observacao, setObservacao] = useState('');
    // ============================= FUNÇÕES =============================
    const getExameData = async (prontuarioId, exameId, idExameProntuario) => {
        try {
            const { data } = await api.getAllExameData(prontuarioId, exameId, idExameProntuario);

            // Criar um objeto para armazenar os campos agrupados por categoria
            const camposAgrupados = {};

            // Percorrer os dados e agrupar os campos por categoria
            data.resultado_campo_especifico.forEach((item) => {
                const categoria = item.campos_especifico.categoria;
                const resultado = item.valor;
                if (!camposAgrupados[categoria]) {
                    camposAgrupados[categoria] = [];
                }
                camposAgrupados[categoria].push({
                    ...item,
                    valor: resultado
                });
            });

            // Transformar o objeto em um array de categorias
            const categoriasArray = Object.entries(camposAgrupados).map(([categoria, campos]) => ({
                categoria,
                campos: campos.sort((a, b) => a.id - b.id)
            }));

            setData(response.data);
            setCamposAgrupados(categoriasArray);
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } catch (error) {
            toast.error('Erro ao buscar dados');
        }
    };

    const getObservacao = async () => {
        try {
            const response = await api.getObservation(prontuarioId, exameId, null, idExameProntuario);
            setObservacao(response.data[0].observation);

            return response;
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    };

    const dataDefault = data?.medico?.data_execucao;
    const formatarData = (data) => {
        const dataFormatada = new Date(data);
        const dataFormatadaString = dataFormatada.toLocaleDateString();
        setDataFormatada(dataFormatadaString);
        const horaFormatadaString = dataFormatada.toLocaleTimeString();
        setHoraFormatada(horaFormatadaString);
        return dataFormatadaString + ' ' + horaFormatadaString;
    };

    if (!loading && data !== null) {
        window.print();
    }

    function formatarData2(horaLaudo) {
        if (!horaLaudo) {
            return 'Data/hora inválida';
        }

        // Converte a string de data/hora para um objeto Date
        const partes = horaLaudo.split(/[\s-:]/);

        // Verifica se a divisão resultou no número esperado de partes
        if (partes.length < 6) {
            return 'Data/hora inválida';
        }

        // Criar um objeto Date
        const dataHoraObj = new Date(
            partes[2], // ano
            partes[1] - 1, // mês (subtrai 1 porque os meses em JavaScript são zero-indexados)
            partes[0], // dia
            partes[3], // hora
            partes[4], // minuto
            partes[5] // segundo
        );

        // Adicionar 3 horas ao objeto Date
        dataHoraObj.setHours(dataHoraObj.getHours() + 3);

        // Formatando a nova data/hora
        const novaDataHora = dataHoraObj.toLocaleString();

        return novaDataHora;
    }

    useEffect(() => {
        formatarData(dataDefault);
    }, [dataDefault]);

    useEffect(() => {
        getExameData(prontuarioId, exameId, idExameProntuario);
        getObservacao(prontuarioId, exameId, idExameProntuario);
    }, [prontuarioId, exameId]);

    return (
        <>
            {data !== null && (
                <FormBox>
                    <HeaderBox>
                        {/* DADOS SOLICITANTE */}
                        <InfoBox>
                            <Typography variant="h5">Paciente:</Typography>
                            <Typography>{data?.paciente?.nome_completo}</Typography>
                        </InfoBox>
                        {data?.paciente?.data_nascimento && (
                            <InfoBox>
                                <Typography variant="h5">Data Nascimento:</Typography>
                                <Typography>{formater(data?.paciente?.data_nascimento)}</Typography>
                            </InfoBox>
                        )}
                        <HeaderInfoBox>
                            <InfoBoxMedium>
                                <Typography variant="h5">Médico Solicitante:</Typography>
                                <Typography>{data?.medico?.usuario?.info_medico?.nome_completo}</Typography>
                            </InfoBoxMedium>
                            <InfoBoxMediumEnd>
                                <Typography variant="h5">CRM:</Typography>
                                <Typography>{data?.medico?.usuario?.info_medico?.crm_coren}</Typography>
                            </InfoBoxMediumEnd>
                        </HeaderInfoBox>
                        {/* DADOS EXAME */}

                        <HeaderBoxSecondary>
                            <HalfBox>
                                <Typography variant="h5">Data e hora Laudo: {formatarData2(data?.medico?.hora_laudo)}</Typography>
                                {/* <Typography>{dataFormatada}</Typography> */}
                            </HalfBox>
                            {/* <HalfBox>
                                <Typography variant="h5">Hora:</Typography>
                                <Typography>{horaFormatada}</Typography>
                            </HalfBox> */}
                        </HeaderBoxSecondary>
                        <InfoBox sx={{ justifyContent: 'center', marginTop: '10px' }}>
                            <Typography variant="h4" sx={{ fontSize: '18px' }}>
                                {data.exame.nome}
                            </Typography>
                        </InfoBox>
                        <ExameInfoBox>
                            <Box>
                                <InfoBox>
                                    <Typography variant="h5">Material:</Typography>
                                    <Typography>{data.exame.material}</Typography>
                                </InfoBox>
                            </Box>

                            <Box>
                                <InfoBox>
                                    <Typography variant="h5">Sigla:</Typography>
                                    <Typography>{data.exame.sigla}</Typography>
                                </InfoBox>
                                <InfoBox>
                                    <Typography variant="h5">Método:</Typography>
                                    <Typography>{data.exame.metodo}</Typography>
                                </InfoBox>
                            </Box>
                        </ExameInfoBox>
                    </HeaderBox>

                    <TableBox>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <b>NOME CAMPO</b>
                                        </TableCell>
                                        <TableCell align="center">
                                            <b>VALORES OBTIDOS</b>
                                        </TableCell>
                                        <TableCell align="center">
                                            <b>UNIDADE</b>
                                        </TableCell>
                                        <TableCell align="center">
                                            <b>VALORES REFERÊNCIA</b>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {camposAgrupados.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <Typography sx={{ fontWeight: 'bold' }}>{item.categoria}</Typography>
                                                {item.campos.map((campo, indexCampo) => (
                                                    <Typography key={indexCampo} sx={{ fontSize: '.87em' }}>
                                                        {campo.campos_especifico.campo_nome}:
                                                    </Typography>
                                                ))}
                                            </TableCell>
                                            <TableCell align="center" sx={{ paddingTop: '2em' }}>
                                                {item.campos.map((resultado, indexResultadoValor) => (
                                                    <Typography key={indexResultadoValor} sx={{ fontSize: '.87em' }}>
                                                        {resultado.valor}
                                                    </Typography>
                                                ))}
                                            </TableCell>
                                            <TableCell align="center" sx={{ paddingTop: '2em' }}>
                                                {item.campos.map((campo, indexResultadoValor) => (
                                                    <Typography key={indexResultadoValor} sx={{ fontSize: '.87em' }}>
                                                        {campo.campos_especifico.unidade}
                                                    </Typography>
                                                ))}
                                            </TableCell>
                                            <TableCell align="center" sx={{ paddingTop: '2em' }}>
                                                {item.campos.map((resultado, indexResultado) => (
                                                    <Typography key={indexResultado} sx={{ fontSize: '.87em' }}>
                                                        {resultado.campos_especifico.valores}
                                                    </Typography>
                                                ))}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TableBox>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: '2em'
                        }}
                    >
                        <Typography variant="h5">Observação:</Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                width: '100%'
                            }}
                        >
                            <Typography
                                sx={{
                                    padding: '1em'
                                }}
                            >
                                {observacao ? observacao : ''}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                width: '30%',
                                borderTop: '1px solid #000',
                                display: 'flex',
                                justifyContent: 'center',
                                marginLeft: 'auto',
                                marginTop: '3em'
                            }}
                        >
                            <Typography variant="h4">Responsável</Typography>
                        </Box>
                    </Box>
                </FormBox>
            )}
        </>
    );
};

export default LaudoExame;
