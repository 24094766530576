import { Modal, styled } from '@mui/material';
import { css } from '@mui/styled-engine';

export const CustomModal = styled(Modal)`
    ${({ theme }) => css`
        backdrop-filter: blur(3px);
        display: flex;
        align-items: center;
        justify-content: center;
        & .modal {
            background-color: #fff;
            box-shadow: 5px 6px 8px rgba(16, 24, 40, 0.05);
            width: 800px;
            max-height: 550px;
            border-radius: 30px;
            padding: 10px 30px;
            overflow: auto;

            & .header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                & p {
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 2.6rem;
                    line-height: 3.4rem;
                    color: #3f3f55;
                    margin-left: 16px;
                    padding: 0;
                }
                & .close-button {
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 2.6rem;
                    line-height: 3.4rem;
                    color: ${theme.palette.text.primary};
                    cursor: pointer;
                    border: none;
                }
            }

            & h3 {
                margin: 12px 0 0px 0;
                font-family: 'Nunito';
                font-style: normal;
                font-weight: 700;
                font-size: 1.3rem;
                line-height: 2.4rem;
                color: #3f3f55;
            }

            & .one {
                & span {
                    font-family: 'Nunito';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 1.6rem;
                    line-height: 2.4rem;
                    color: #3f3f55;
                }
            }
        }
        & .modal::-webkit-scrollbar {
            width: 5px;
        }
    `}
`;
