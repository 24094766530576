import MainCard from 'ui-component/cards/MainCard';
import {
    Button,
    Grid,
    Box,
    TablePagination,
    Typography,
    TableBody,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Tooltip
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useNavigate } from 'react-router';
import { useApi } from 'Service/axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MainBox, HeaderBox } from '../style';
import { Container } from './style';
import ResumoFichaLab from 'components/Laboratório/Ficha/Resumo';
import TabelaExamesFicha from 'components/Laboratório/Ficha/TabelaExames';
import ModalAdicionarExame from 'components/Laboratório/Ficha/ModalAddExame';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import DescriptionIcon from '@mui/icons-material/Description';
import ModalTabelaExames from 'components/Laboratório/Ficha/ModalTabelaExames';

const FichaLaboratorio = () => {
    // ============================= INSTÂNCIAS =============================
    const api = useApi();
    const { id, prontuarioId } = useParams();

    // ============================= STATES =============================
    const [data, setData] = useState(null);
    const [allExames, setAllExames] = useState([]);
    const [associatedExames, setAssociatedExames] = useState([]);
    const [selectedExame, setSelectedExame] = useState([]);
    const [allMedicos, setAllMedicos] = useState([]);
    const [selectedMedico, setSelectedMedico] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [openModal, setOpenModal] = useState(false);
    const [openModalExames, setOpenModalExames] = useState(false);
    const [teste, setTeste] = useState(null);
    const [dataGridRows, setDataGridRows] = useState([]);
    const [selectedExameProntuario, setSelectedExameProntuario] = useState(null);
    const [dataSelectedExameProntuario, setDataSelectedExameProntuario] = useState('');

    // ============================= FUNÇÕES =============================
    const formatarData = (date) => {
        const dataObj = new Date(date);
        let dia = dataObj.getDate();
        const mes = dataObj.getMonth() + 1;
        const ano = dataObj.getFullYear();
        const diaFormatado = dia === 32 ? (dia = '01') : dia < 10 ? '0' + dia : dia;
        const mesFormatado = mes < 10 ? '0' + mes : mes;
        return (date = `${diaFormatado}/${mesFormatado}/${ano}`);
    };

    const handleSelectExame = (event) => {
        const {
            target: { value }
        } = event;
        setSelectedExame(typeof value === 'string' ? value.split(',') : value);
    };

    const handleSelectMedico = (e) => {
        const medicoID = e.target.value;
        setSelectedMedico(medicoID);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleCloseModalExames = () => {
        setOpenModalExames(false);
    };

    const handleAddExameToTable = async (exameID) => {
        try {
            const response = await api.associateExameOnProntuario(parseInt(prontuarioId), selectedExame, selectedMedico);
            toast.success(response.data.message);
            window.location.reload();
        } catch (error) {
            toast.error(error.response.data.message);
        }
    };

    const getDependency = async (prontuarioId, id) => {
        try {
            const [prontuario, associatedExame, prontuarioByPaciente, exames, medicos] = await Promise.all([
                api.getProntuarioById(prontuarioId),
                api.getAssociatedExame(prontuarioId),
                api.getProntuarioByPaciente(id),
                api.getAllExames(),
                api.getAllMedicos()
            ]);

            setTeste(prontuario.data);
            setAssociatedExames(associatedExame.data);
            setData(prontuarioByPaciente.data);
            setAllExames(exames.data);
            setAllMedicos(medicos.data);

            // --- MONTAR DADOS PRO DATA GRID ---

            const agruparDados = associatedExame.data.map((el) => {
                const exameProntuario = el.exame_prontuarios;

                // Use o método reduce para agrupar os campos por categoria
                const camposAgrupados = exameProntuario.reduce((agrupados, item) => {
                    const categoria = formatarData(item.data_execucao);

                    // Se a categoria já existir no objeto agrupados, adicione o item a ela
                    if (agrupados[categoria]) {
                        agrupados[categoria].push(item);
                    } else {
                        // Se a categoria ainda não existir, crie um novo array com o item
                        agrupados[categoria] = [item];
                    }

                    return agrupados;
                }, {});

                return {
                    ...el,
                    camposAgrupados
                };
            });

            setDataGridRows(agruparDados[0]?.camposAgrupados);
        } catch (error) {
            toast.error(`Erro ao bucar dados: ${error.message}`);
        }
    };

    useEffect(() => {
        getDependency(prontuarioId, id);
    }, [id, openModalExames]);

    return (
        <MainCard
            title="Laboratório"
            secondary={
                <Button onClick={() => setOpenModal(true)} variant="contained">
                    Incluir Exame
                </Button>
            }
        >
            <ModalAdicionarExame
                openModal={openModal}
                setOpenModal={setOpenModal}
                allExames={allExames}
                allMedicos={allMedicos}
                handleSelectExame={handleSelectExame}
                handleSelectMedico={handleSelectMedico}
                selectedExame={selectedExame}
                selectedMedico={selectedMedico}
                handleAddExameToTable={handleAddExameToTable}
            />
            <ModalTabelaExames
                openModalExames={openModalExames}
                setOpenModalExames={setOpenModalExames}
                handleCloseModalExames={handleCloseModalExames}
                selectedExameProntuario={selectedExameProntuario}
                dataSelectedExameProntuario={dataSelectedExameProntuario}
                prontuarioId={prontuarioId}
                idFicha={id}
                paciente={teste}
            />
            <Grid container spacing={gridSpacing}>
                <MainBox>
                    <HeaderBox>
                        <Container>
                            {data !== null && <ResumoFichaLab data={teste} />}
                            <Box>
                                <TableContainer component={Paper} sx={{ maxHeight: '450px' }}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    <b>DATA</b>
                                                </TableCell>
                                                <TableCell>
                                                    <b>EXAMES</b>
                                                </TableCell>
                                                <TableCell>
                                                    <b>AÇÕES</b>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Object.keys(dataGridRows).map((categoria, categoriaIndex) => (
                                                <TableRow key={categoriaIndex}>
                                                    <TableCell size="large">{categoria}</TableCell>
                                                    <TableCell size="large">{dataGridRows[categoria].length}</TableCell>
                                                    <TableCell size="small">
                                                        <Tooltip title="Abrir">
                                                            <Button
                                                                onClick={() => {
                                                                    setOpenModalExames(true),
                                                                        setSelectedExameProntuario(dataGridRows[categoria]),
                                                                        setDataSelectedExameProntuario(categoria);
                                                                }}
                                                            >
                                                                <DescriptionIcon />
                                                            </Button>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {/* <TabelaExamesFicha
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                    idFicha={teste}
                                    id={id}
                                    prontuarioId={prontuarioId}
                                    associatedExames={associatedExames}
                                /> */}
                                <TablePagination
                                    rowsPerPageOptions={[10, 15, 20]}
                                    component="div"
                                    count={Object.keys(dataGridRows).length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Box>
                        </Container>
                    </HeaderBox>
                </MainBox>
            </Grid>
        </MainCard>
    );
};

export default FichaLaboratorio;
