import {
    IconClipboardPlus,
    IconBrandAsana,
    IconFileAnalytics,
    IconArrowBarDown,
    IconNotes,
    IconArrowBarUp,
    IconFolderPlus,
    IconArrowAutofitWidth
} from '@tabler/icons';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';

const icons = {
    IconClipboardPlus,
    IconBrandAsana,
    IconFileAnalytics,
    IconArrowBarDown,
    IconNotes,
    IconArrowBarUp,
    IconFolderPlus,
    TroubleshootIcon,
    IconArrowAutofitWidth
};

const laboratorio = {
    id: 'laboratorioMenu',
    title: 'Laboratório',
    type: 'group',
    children: [
        {
            id: 'pedidosLab',
            title: 'Pedidos',
            type: 'collapse',
            icon: icons.IconFolderPlus,
            children: [
                {
                    id: 'atendimentosPendentes',
                    title: 'Atendimentos Pendentes',
                    type: 'item',
                    url: '/main/laboratorio/controle/atendimentos',
                    icon: icons.IconClipboardPlus,
                    breadcrumbs: false
                },
                {
                    id: 'consultaAtendimentosPendentes',
                    title: 'Consulta',
                    type: 'item',
                    url: '/main/laboratorio/controle/atendimentos/consulta',
                    icon: icons.TroubleshootIcon,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'labFichas',
            title: 'Atendimento Geral',
            type: 'item',
            url: '/main/laboratorio/atendimentos',
            icon: icons.IconClipboardPlus,
            breadcrumbs: false
        },
        {
            id: 'labExames',
            title: 'Exames',
            type: 'item',
            url: '/main/laboratorio/exames',
            icon: icons.IconBrandAsana,
            breadcrumbs: false
        },
        {
            id: 'Relatorios',
            title: 'Relatorios',
            type: 'item',
            url: '/main/laboratorio/relatorios',
            icon: icons.IconFileAnalytics,
            breadcrumbs: false
        },
        {
            id: 'movimentosLab',
            title: 'Movimentos',
            type: 'collapse',
            icon: icons.IconArrowAutofitWidth,
            children: [
                {
                    id: 'baixaManual',
                    title: 'Baixa Manual',
                    type: 'item',
                    url: '/main/estoque/baixa-manual',
                    icon: icons.IconArrowBarDown,
                    breadcrumbs: false
                },
                {
                    id: 'entradaManual',
                    title: 'Entrada Manual',
                    type: 'item',
                    url: '/main/estoque/entrada-manual',
                    icon: icons.IconArrowBarUp,
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default laboratorio;
