import MainCard from 'ui-component/cards/MainCard';
import { Grid, Box } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useApi } from 'Service/axios';
import FormTransferencia from 'components/Estoque/Movimentos/Transferencia';

const TransferenciaManual = () => {
    // ============================= INSTÂNCIAS ==========================
    const api = useApi();
    // ============================= STATES ==============================

    // ============================= FUNÇÕES =============================

    return (
        <MainCard title="TRANSFERÊNCIA MANUAL">
            <Grid container spacing={gridSpacing}>
                <Box sx={{ width: '100%', height: '70vh', display: 'flex', justifyContent: 'center' }}>
                    <FormTransferencia />
                </Box>
            </Grid>
        </MainCard>
    );
};

export default TransferenciaManual;
