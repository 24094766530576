import { useParams, Link } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, Box, TablePagination, Autocomplete, Tooltip, TextField } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useState } from 'react';
import { useEffect } from 'react';
import { useApi } from 'Service/axios';
import { HeaderBox, TableBox } from './style';
import { toast } from 'react-toastify';
import ItemTabela from 'components/Estoque/Item/Tabela';
import DrawerCreateItem from 'components/Estoque/Item/DrawerCreate';
import DrawerEditItem from 'components/Estoque/Item/DrawerEdit';

const Item = () => {
    // ============================= INSTÂNCIAS =========================
    const api = useApi();
    // ============================= STATES =============================
    const [allItem, setAllItem] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [item, setItem] = useState(null);
    const [almoxarifados, setAlmoxarifados] = useState([]);
    const [localizacoes, setLocalizacoes] = useState([]);
    const [unidades, setUnidades] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [filtredLocations, setFiltredLocations] = useState(localizacoes);
    // ============================= FUNÇÕES =============================

    const handleChangeLocations = (location) => {
        const filtredData = localizacoes.filter((i) => i.almoxarifado.id === location);
        setFiltredLocations(filtredData);
    };

    const handleClickOpenEdit = (e, item) => {
        e.preventDefault();
        setItem(item);
        setOpenEdit(true);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getItems = async () => {
        try {
            const response = await api.getAllItemsNoFilter();
            setAllItem(response.data);
        } catch (error) {
            toast.error(error.response.data.mensagem);
        }
    };

    const getAllData = async () => {
        try {
            const [responseAlmoxarifado, responseLocalizacao, responseCategorias, responseUnidade] = await Promise.all([
                api.getAlmoxarifados(),
                api.getAllLocalizacoes(),
                api.getAllCategorias(),
                api.getAllUnidade()
            ]);

            setAlmoxarifados(responseAlmoxarifado.data);
            setLocalizacoes(responseLocalizacao.data);
            setCategorias(responseCategorias.data);
            setFiltredLocations(responseLocalizacao.data);
            setUnidades(responseUnidade.data);
        } catch (error) {
            toast.error(error.response.data.mensagem);
        }
    };

    useEffect(() => {
        getItems();
        getAllData();
    }, []);

    return (
        <MainCard title="ITEM">
            <Grid container spacing={gridSpacing}>
                {/* DRAWERS */}
                <DrawerCreateItem
                    open={open}
                    handleClose={handleClose}
                    getItems={getItems}
                    almoxarifados={almoxarifados}
                    categorias={categorias}
                    unidades={unidades}
                    localizacoes={localizacoes}
                    filtredLocations={filtredLocations}
                    handleChangeLocations={handleChangeLocations}
                />
                <DrawerEditItem
                    open={openEdit}
                    handleClose={handleCloseEdit}
                    getItems={getItems}
                    item={item}
                    almoxarifados={almoxarifados}
                    categorias={categorias}
                    unidades={unidades}
                    localizacoes={localizacoes}
                    filtredLocations={filtredLocations}
                    handleChangeLocations={handleChangeLocations}
                />
                <HeaderBox>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={allItem}
                        getOptionLabel={(item) => `${item.id}-${item.descricao}-${!item.apelido ? '' : item.apelido}`}
                        value={selectedItem}
                        onChange={(event, newValue) => {
                            setSelectedItem(newValue);
                        }}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Pesquisar Item" />}
                    />
                    <Button variant="contained" size="medium" onClick={() => setOpen(true)} sx={{ height: '40px' }}>
                        Adicionar
                    </Button>
                </HeaderBox>

                <TableBox>
                    <ItemTabela
                        allItem={allItem}
                        setAllItem={setAllItem}
                        selectedItem={selectedItem}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        getItems={getItems}
                        handleClickOpenEdit={handleClickOpenEdit}
                    />
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={allItem.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableBox>
            </Grid>
        </MainCard>
    );
};

export default Item;
