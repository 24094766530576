import { useParams, Link, useLocation, useNavigate } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { Container, HeaderBox, MainBox } from './style';
import ResultadoExameForm from 'components/Laboratório/Ficha/ResultadoExameForm';
import { useState, useEffect } from 'react';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';

const ResultadoExame = () => {
    // ============================= INSTÂNCIAS =============================
    const { idFicha, id, prontuarioId, idExame } = useParams();
    // id paciente, id exmae, id prontuario, id exame prontuario
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const tipo = searchParams.get('tipo');
    const [backUrl, setBackUrl] = useState(null);
    const navigate = useNavigate();

    const api = useApi();
    // ============================= STATES =============================
    const [exame, setExame] = useState(null);
    const [exame2, setExame2] = useState(null);

    // ============================= FUNÇÕES =============================

    const getExame = async (exameId) => {
        if (tipo === 'especial') {
            const response = await api.getExameEspecialById(exameId);
            setExame(response.data);
            return response;
        } else {
            const response = await api.getExameById(exameId);
            setExame(response.data);
            return response;
        }
    };

    const getCamposEspecificosAndResults = async (prontuarioId, exame) => {
        if (tipo === 'especial') {
            const response = await api.getExameEspecialAndResultById(prontuarioId, exame, idExame);
            const data = { ...response.data, campos_especificos: response.data.campos_exames_especiais };
            setExame2(data);
            return response;
        } else {
            const response = await api.getExameAndResultById(prontuarioId, exame, idExame);
            setExame2(response.data);
            return response;
        }
    };

    const getDependency = () => {
        getExame(id);
        getCamposEspecificosAndResults(prontuarioId, id);
    };

    useEffect(() => {
        const url = localStorage.getItem('URL_BACK');
        if (url) {
            setBackUrl(url);
        }
    }, []);

    useEffect(() => {
        getDependency();
    }, [id]);

    function handleNavigate() {
        navigate(backUrl);
    }

    return (
        <MainCard title="Resultado do exame">
            <Grid container spacing={gridSpacing}>
                <MainBox>
                    <HeaderBox>
                        <Button onClick={handleNavigate} type="button" variant="contained">
                            Voltar pra ficha
                        </Button>
                    </HeaderBox>
                    <Container>
                        {exame !== null && (
                            <ResultadoExameForm exame2={exame2} prontuarioID={prontuarioId} idExameProntuario={idExame} tipo={tipo} />
                        )}
                    </Container>
                </MainBox>
            </Grid>
        </MainCard>
    );
};

export default ResultadoExame;
