import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useApi } from 'Service/axios';
import { HeaderBox, HeaderInfo, InfoBox } from './style.js';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import DataArrayIcon from '@mui/icons-material/DataArray';
import { formatarSUS } from 'utils/formatSUS';
import { formatarRG } from 'utils/formatRG';
import { formatarCPF } from 'utils/formatCpf';
import { toast } from 'react-toastify';

const RelatorioTriagem = () => {
    const { id, numero } = useParams();

    const api = useApi();
    const [paciente, setPaciente] = useState(null);
    const [prontuario, setProntuario] = useState(null);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState('');
    const [dadosTriagem, setDadosTriagem] = useState('');
    const [idade, setIdade] = useState('');
    const [hour, sethour] = useState(null);
    const [user, setUser] = useState(null);
    const [day, setDay] = useState(null);

    const getPaciente = async (id) => {
        const response = await api.getPacienteById(id);
        setPaciente(response.data);

        setTimeout(() => {
            setLoading(false);
        }, 1000);

        // ======================== DATA + IDADE ========================

        const formatDate = (inputDate) => {
            const dateParts = inputDate.split('-');
            const year = parseInt(dateParts[0]);
            const month = parseInt(dateParts[1]) - 1;
            const day = parseInt(dateParts[2]);
            return new Date(year, month, day);
        };

        const formatDateNascimento = (inputDate) => {
            const dateParts = inputDate.split('-');
            const year = parseInt(dateParts[0]);
            const month = parseInt(dateParts[1]);
            const day = parseInt(dateParts[2]);
            return day + '/' + month + '/' + year;
        };

        const dataNascimento = formatDate(response?.data?.data_nascimento);

        const dataAtual = new Date();

        const diffMilliseconds = dataAtual - dataNascimento;
        const diffDate = new Date(diffMilliseconds);

        const idadeAnos = diffDate.getUTCFullYear() - 1970;
        const idadeMeses = diffDate.getUTCMonth();
        const idadeDias = diffDate.getUTCDate() - 1;

        setIdade(`${idadeAnos} anos, ${idadeMeses} meses e ${idadeDias} dias`);
        setData(formatDateNascimento(response.data.data_nascimento));
    };

    if (loading === false && paciente.nome_completo) {
        window.print();
    }
    const getTriagemFicha = async (id, ficha) => {
        const response = await api.getTriagemById({ id, ficha });
        setDadosTriagem(response?.data);
    };

    function obterDataHoraBrasileira() {
        const agora = new Date();

        const dia = agora.getDate().toString().padStart(2, '0');
        const mes = (agora.getMonth() + 1).toString().padStart(2, '0');
        const ano = agora.getFullYear();

        const hora = agora.getHours().toString().padStart(2, '0');
        const minutos = agora.getMinutes().toString().padStart(2, '0');

        setDay(`${dia}/${mes}/${ano}`);
        sethour(`${hora}:${minutos}`);
    }

    const getProntuario = async (id) => {
        try {
            const { data } = await api.getProntuarioById(id);
            setProntuario(data);
        } catch (error) {
            toast.error('Erro ao buscar prontuário');
        }
    };

    useEffect(() => {
        const userStorage = localStorage.getItem('nome');
        setUser(userStorage);
        obterDataHoraBrasileira();
    }, []);

    useEffect(() => {
        getPaciente(numero);
        getTriagemFicha(numero, id);
        getProntuario(id);
    }, []);

    return (
        <>
            {paciente && prontuario && (
                <Box sx={{ width: '100%', marginLeft: '1.2em', padding: '30px' }}>
                    <HeaderBox sx={{ borderBottom: 'none' }}>
                        <HeaderInfo>
                            F.A: <strong>{id}</strong>
                        </HeaderInfo>
                        <HeaderInfo>
                            PRONTUÁRIO: <strong>{numero}</strong>
                        </HeaderInfo>
                        <HeaderInfo>CONVENIO: {paciente?.convenio?.descricao ?? ''}</HeaderInfo>
                    </HeaderBox>
                    <HeaderBox sx={{ marginTop: '.4rem' }}>
                        <HeaderInfo>
                            DATA ATENDIMENTO: {prontuario?.data ?? ''} - {prontuario?.hora ?? ''}
                        </HeaderInfo>
                        <HeaderInfo>RECEPCIONISTA: {prontuario?.usuario?.nome.toUpperCase() ?? ''}</HeaderInfo>
                    </HeaderBox>

                    <Box sx={{ borderBottom: '1px solid #000' }}>
                        <InfoBox>
                            <HeaderInfo>PACIENTE: {paciente.nome_completo}</HeaderInfo>
                            <HeaderInfo>NASC: {data}</HeaderInfo>
                            <HeaderInfo>SEXO: {paciente.sexo === 1 ? 'M' : paciente.sexo === 2 ? 'F' : 'Outros'}</HeaderInfo>
                        </InfoBox>
                        <InfoBox>
                            <HeaderInfo>COR: {paciente.cor}</HeaderInfo>
                            <HeaderInfo>IDADE: {idade}</HeaderInfo>
                            <HeaderInfo>NATURALIDADE: {paciente.naturalidade}</HeaderInfo>
                            <HeaderInfo>ESTADO CIVIL: {paciente.estado_civil}</HeaderInfo>
                        </InfoBox>
                        <InfoBox>
                            <HeaderInfo>MÃE: {paciente.nome_mae}</HeaderInfo>
                            <HeaderInfo>CPF: {paciente.cpf ? formatarCPF(paciente.cpf) : ''}</HeaderInfo>
                            <HeaderInfo>RG: {paciente.rg ? formatarRG(paciente.rg) : ''}</HeaderInfo>
                            <HeaderInfo>SUS: {paciente.cartao_sus ? formatarSUS(paciente.cartao_sus) : ''}</HeaderInfo>
                        </InfoBox>
                        <InfoBox>
                            <HeaderInfo>
                                ENDEREÇO: {paciente.endereco}, {paciente.numero} - {paciente.bairro} - {paciente.cidade} - {paciente.estado}
                            </HeaderInfo>
                        </InfoBox>
                        <InfoBox>
                            <HeaderInfo>CEP: {paciente.cep}</HeaderInfo>
                            <HeaderInfo>TELEFONE: {paciente.telefone}</HeaderInfo>
                        </InfoBox>
                    </Box>
                    <Box sx={{ padding: '.2em 0', marginBottom: '8px', borderBottom: '1px solid #000' }}>
                        <Box sx={{ display: 'flex', gap: '30px' }}>
                            <HeaderInfo>1 - ESCUTA INICIAL</HeaderInfo>
                            <Typography>/</Typography>
                            <Typography sx={{ textTransform: 'uppercase' }}>Queixa e Duração: </Typography>
                        </Box>
                        <Typography sx={{ textTransform: 'uppercase' }}>{dadosTriagem?.triagem?.queixa}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', gap: '10px' }}>
                        <Typography sx={{ fontSize: '.8em' }}>2 - SINAIS VITAIS</Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '1.5rem' }}>
                            <Box sx={{ display: 'flex', gap: '3px' }}>
                                <Typography>
                                    PA:<span>{dadosTriagem?.triagem?.pa_1}</span>
                                </Typography>
                                <Typography>
                                    {' '}
                                    X <span>{dadosTriagem?.triagem?.pa_2}</span>
                                </Typography>
                            </Box>
                            <Box>
                                <Typography>
                                    FC: <span>{dadosTriagem?.triagem?.fc}</span>
                                </Typography>
                            </Box>
                            <Box>
                                <Typography>
                                    SAT: <span> {dadosTriagem?.triagem?.saturacao}</span>
                                </Typography>
                            </Box>
                            <Box sx={{ border: '1px solid #red', marginRight: 'auto' }}>
                                <Typography>
                                    TEMP:<span> {dadosTriagem?.triagem?.temperatura}</span>{' '}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography>
                                    DEXTRO:<span> {dadosTriagem?.triagem?.dextro}</span>{' '}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography>
                                    PESO:<span> {dadosTriagem?.triagem?.peso} KG</span>{' '}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            borderBottom: '1px solid #000',
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: '10px'
                        }}
                    >
                        {/* <Box sx={{ display: 'flex', width: '15%', flexDirection: 'column', gap: '3px' }}>
                            <Box sx={{ display: 'flex' }}>
                                <Typography>PESO: {dadosTriagem?.triagem?.peso}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex' }}>
                                <Typography>ALTURA: {dadosTriagem?.triagem?.altura}</Typography>
                            </Box>
                        </Box> */}
                        <Box sx={{ display: 'flex', gap: '100px' }}>
                            <Box sx={{ border: '1px solid #blue', display: 'flex', gap: '10px' }}>
                                MEDICAMENTOS EM USO:
                                <Box>
                                    {dadosTriagem?.medicacao?.medicacao_em_uso?.map((item) => (
                                        <Typography sx={{ textTransform: 'uppercase' }}>{item}</Typography>
                                    ))}
                                </Box>
                            </Box>

                            <Box sx={{ display: 'flex', gap: '10px' }}>
                                ALERGIA:
                                <Box>
                                    {dadosTriagem?.medicacao?.alergia?.map((item) => (
                                        <Typography sx={{ textTransform: 'uppercase' }}>{item}</Typography>
                                    ))}
                                </Box>
                            </Box>
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '10px 0 5px 0' }}>
                            <Box sx={{ display: 'flex', gap: '5px' }}>
                                <Typography sx={{ fontSize: '.85em', marginBottom: '.3em' }}>ENFERMAGEM COREN:</Typography>
                                <Typography sx={{ fontSize: '.85em', textTransform: 'uppercase' }}>
                                    {dadosTriagem?.triagem?.usuario?.nome}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', gap: '5px' }}>
                                <Typography sx={{ fontSize: '.85em', marginBottom: '.3em' }}>DATA ESCUTA INICIAL:</Typography>
                                <Typography sx={{ fontSize: '.85em' }}>
                                    {dadosTriagem?.triagem?.data ?? ''} - {dadosTriagem?.triagem?.hora ?? ''}
                                </Typography>
                            </Box>
                            {/* <Box sx={{ display: 'flex', gap: '5px' }}>
                                <Typography sx={{ fontSize: '.85em', marginBottom: '.3em' }}>HORÁRIO TRIAGEM:</Typography>
                                <Typography sx={{ fontSize: '.85em' }}></Typography>
                            </Box> */}
                        </Box>
                    </Box>
                    {/* <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '.5em 0 .5em 0',
                            borderBottom: '1px solid #000'
                        }}
                    >
                        <Typography>4 - CLASSIFICAÇÃO</Typography>
                        <Typography>NÍVEIS:</Typography>
                        <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                            <DataArrayIcon sx={{ fontSize: '1em' }} /> VERMELHO
                        </Typography>
                        <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                            <DataArrayIcon sx={{ fontSize: '1em' }} /> AMARELO
                        </Typography>
                        <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                            <DataArrayIcon sx={{ fontSize: '1em' }} /> VERDE
                        </Typography>
                        <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                            <DataArrayIcon sx={{ fontSize: '1em' }} /> AZUL
                        </Typography>
                    </Box> */}
                    <Box sx={{ padding: '.3em 0 .3em 0', borderBottom: '1px solid #000', flexWrap: 'wrap' }}>
                        <Typography>5- PRINCIPAIS DADOS DE ANAMNESE EXAME FÍSICO</Typography>
                        <Typography>
                            ________________________________________________________________________________________________________________
                        </Typography>
                        <Typography>
                            ________________________________________________________________________________________________________________
                        </Typography>
                        <Typography>
                            ________________________________________________________________________________________________________________
                        </Typography>
                        <Typography>
                            ________________________________________________________________________________________________________________
                        </Typography>
                        <Typography>
                            ________________________________________________________________________________________________________________
                        </Typography>
                        <Typography>
                            ________________________________________________________________________________________________________________
                        </Typography>
                        <Typography>
                            ________________________________________________________________________________________________________________
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', padding: '.5em 0 .5em 0', borderBottom: '1px solid #000' }}>
                        <Typography>6- EXAMES COMPLEMENTARES E SUBSIDIÁRIOS</Typography>
                        <Box sx={{ display: 'flex' }}>
                            <Typography sx={{ marginRight: '3em', display: 'flex', alignItems: 'center' }}>
                                <DataArrayIcon sx={{ fontSize: '1em' }} /> ECG
                            </Typography>
                            <Typography>IMAGEM:</Typography>
                            <Typography>
                                __________________________________________________________________________________________
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '.3em 0 .3em 0' }}>
                            <Typography>7- DIAGNÓSTICOS</Typography>
                            <Typography>A- PRINCIPAL CID: ________________________</Typography>
                            <Typography>B- SECUNDÁRIO CID: ________________________</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ padding: '.3em 0 .3em 0', borderBottom: '1px solid #000' }}>
                        <Typography sx={{ textAlign: 'center' }}>8- PRESCRIÇÃO MÉDICA E ANOTAÇÕES DE ENFERMAGEM</Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '1em' }}>
                            <Box>
                                <Typography>_________________________________________________________</Typography>
                                <Typography>_________________________________________________________</Typography>
                                <Typography>_________________________________________________________</Typography>
                                <Typography>_________________________________________________________</Typography>
                                <Typography>_________________________________________________________</Typography>
                                <Typography>_________________________________________________________</Typography>
                                <Typography>_________________________________________________________</Typography>
                                <Typography>_________________________________________________________</Typography>
                                <Typography>_________________________________________________________</Typography>
                                <Typography>_________________________________________________________</Typography>
                            </Box>
                            <Box>
                                <Typography>____________________________________________________</Typography>
                                <Typography>____________________________________________________</Typography>
                                <Typography>____________________________________________________</Typography>
                                <Typography>____________________________________________________</Typography>
                                <Typography>____________________________________________________</Typography>
                                <Typography>____________________________________________________</Typography>
                                <Typography>____________________________________________________</Typography>
                                <Typography>____________________________________________________</Typography>
                                <Typography>____________________________________________________</Typography>
                                <Typography>____________________________________________________</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{ display: 'flex', justifyContent: 'space-between', padding: '.3em 0 .3em 0', borderBottom: '1px solid #000' }}
                    >
                        <Typography>9- DESTINO</Typography>
                        <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                            <DataArrayIcon sx={{ fontSize: '1em' }} /> ALTA
                        </Typography>
                        <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                            <DataArrayIcon sx={{ fontSize: '1em' }} /> UBS
                        </Typography>
                        <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                            <DataArrayIcon sx={{ fontSize: '1em' }} /> OBSERVAÇÃO
                        </Typography>
                        <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                            <DataArrayIcon sx={{ fontSize: '1em' }} /> INTERNAÇÃO
                        </Typography>
                        <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                            <DataArrayIcon sx={{ fontSize: '1em' }} /> ÓBITO
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: '5em' }}>
                        <Box>
                            <Typography>__________________________________________________</Typography>
                            <Typography sx={{ textAlign: 'center' }}>10- ASSINATURA DO PACIENTE OU RESPONSÁVEL</Typography>
                        </Box>
                        <Box>
                            <Typography>__________________________________________________</Typography>
                            <Typography sx={{ textAlign: 'end' }}>11- ASSINATURA DO MÉDICO</Typography>
                        </Box>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default RelatorioTriagem;
