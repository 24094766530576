import React from 'react';
import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import SantaCasaLogo from '../../../../assets/images/Logos/logo2.png';
import BwLogo from '../../../../assets/BW TECNOLOGIA.png';

const PdfReceita = ({ data }) => {
    console.log('🚀 ~ PdfReceita ~ data:', data);
    const styles = {
        page: {
            paddingTop: 15,
            paddingBottom: 15,
            paddingHorizontal: 15,
            backgroundColor: '#fff',
            fontSize: 10,
            fontWeight: 'normal',
            rap: 'wrap',
            position: 'relative'
        },
        header: {
            padding: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        },
        image: {
            width: 100,
            backgroundSize: 'cover'
        },
        text1: {
            fontSize: 10
        },
        box: {
            border: '1px solid #ddd',
            padding: 10,

            borderRadius: 5
        },
        between: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        labelArea: {
            backgroundColor: '#ccc',
            padding: '8px',
            borderRadius: 5,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },

        row: { flexDirection: 'row', borderBottom: '1 solid #000' },
        cell: { flexGrow: 1, padding: 5 },
        fixed: {
            bottom: 0,
            left: 0,
            width: '100%',
            padding: 10,
            position: 'absolute',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
        }
    };

    const getCurrentDateTime = () => {
        const now = new Date();
        const date = now.toLocaleDateString('pt-BR'); // Formato DD/MM/YYYY
        const time = now.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' }); // Formato HH:MM
        return { date, time };
    };

    const { date, time } = getCurrentDateTime();

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View
                    sx={{
                        width: '100%',
                        display: 'flex',
                        padding: '.5em',
                        gap: '10px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: '20px',
                        borderTop: '1px solid #000'
                    }}
                >
                    <Image src={SantaCasaLogo} alt="Santa Casa Logotipo" style={{ width: '8%', left: '250px' }} />
                    <View style={{ left: '180px', fontSize: 7 }}>
                        <Text>SANTA CASA DE MISERICÓRDIA DE JOANÓPOLIS</Text>
                    </View>
                </View>
                <View style={{ marginTop: '15px' }}>
                    <Text style={{ textAlign: 'center', marginBottom: '15px' }}>RECEITA MÉDICA</Text>

                    <View style={{ display: 'flex', justifyContent: 'space-between', fontSize: 9 }}>
                        <View>
                            <Text>{data.paciente?.nome_completo}</Text>
                            <Text>{`DATA/HORA DE ATENDIMENTO: ${date} ${time}`}</Text>
                            <View style={{ display: 'flex', flexDirection: 'row' }}>
                                <Text>{data.paciente?.endereco} </Text>
                                <Text>{data.paciente?.numero ? ', ' + data.paciente.numero : ''}</Text>
                                <Text>{data.paciente?.bairro ? ', ' + data.paciente.bairro : ''}</Text>
                                <Text>{data.paciente?.cidade ? ', ' + data.paciente.cidade : ''}</Text>
                                <Text>{data.paciente?.estado ? ', ' + data.paciente.estado : ''}</Text>
                                <Text>{data.paciente?.cep ? ', ' + data.paciente.cep : ''}</Text>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={{ marginTop: '25px', textAlign: 'center' }}>
                    {data?.prescricao?.map((item) => (
                        <Text key={item} style={{ fontSize: 9 }}>
                            {item}
                        </Text>
                    ))}
                </View>

                {data?.controle_especial && (
                    <View style={{ ...styles.box, marginTop: '20px' }}>
                        <Text style={{ fontSize: 9, textAlign: 'center', borderBottom: '1px solid #ccc' }}>Identificação do comprador</Text>

                        <View>
                            <Text style={{ fontSize: 9, borderBottom: '1px solid #ccc', marginTop: '10px' }}>NOME: </Text>
                            <Text style={{ fontSize: 9, borderBottom: '1px solid #ccc', marginTop: '10px' }}>ENDEREÇO: </Text>
                            <Text style={{ fontSize: 9, borderBottom: '1px solid #ccc', marginTop: '10px' }}>TELEFONE: </Text>
                            <View style={{ display: 'flex', gap: '10px', flexDirection: 'row' }}>
                                <Text style={{ fontSize: 9, borderBottom: '1px solid #ccc', width: '250px', marginTop: '10px' }}>
                                    IDENTIDADE N.º:
                                </Text>
                                <Text style={{ fontSize: 9, borderBottom: '1px solid #ccc', width: '250px', marginTop: '10px' }}>
                                    ÓRGÃO EMISSOR:
                                </Text>
                            </View>
                            <View style={{ display: 'flex', flexDirection: 'row', gap: '40px', margin: '20px 0' }}>
                                <View
                                    style={{
                                        height: '150px',
                                        width: '100%',
                                        borderRadius: '8px',
                                        border: '1px solid #ccc',
                                        padding: '10px',
                                        position: 'relative',
                                        textAlign: 'center'
                                    }}
                                >
                                    <Text style={{ borderTop: '1px solid #ccc', position: 'absolute', bottom: 0, left: '40px' }}>
                                        Catimbo/ CRM/ Assinatuira do médico
                                    </Text>
                                </View>
                                <View
                                    style={{
                                        height: '150px',
                                        width: '100%',
                                        borderRadius: '8px',
                                        border: '1px solid #ccc',
                                        padding: '10px',
                                        position: 'relative'
                                    }}
                                >
                                    <View>
                                        <Text style={{ fontSize: 9, borderBottom: '1px solid #ccc', textAlign: 'center' }}>
                                            IDENTIFICAÇÃO DO FORNECEDOR{' '}
                                        </Text>
                                    </View>

                                    <View
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            gap: '10px',
                                            textAlign: 'center',
                                            position: 'absolute',
                                            bottom: 0,
                                            left: '15px'
                                        }}
                                    >
                                        <Text style={{ fontSize: 9, borderTop: '1px solid #ccc', width: '100px' }}>Data: </Text>
                                        <Text style={{ fontSize: 9, borderTop: '1px solid #ccc', width: '100px' }}>Assinatura: </Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                )}

                <View fixed style={styles.fixed}>
                    <View
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '50%',
                            justifyContent: 'flex-start',
                            alignItems: 'center'
                        }}
                    >
                        <Image fixed src={SantaCasaLogo} alt="Santa Casa Logotipo" style={{ width: '30px' }} />
                        <View
                            fixed
                            style={{
                                padding: '20px',
                                gap: '5px',
                                fontSize: 5
                            }}
                        >
                            <Text>SANTA CASA DE MISERICÓRDIA DE JOANÓPOLIS</Text>
                            <Text>RUA FRANCISCO WOLHERS N°57 - CENTRO - JOANÓPOLIS - SP</Text>
                            <Text>CEP: 12980-000 - TELEFONE: (11) 4539-9333</Text>
                        </View>
                    </View>
                    <Image style={{ display: 'flex', width: '15%', justifyContent: 'flex-end', alignItems: 'center' }} src={BwLogo} />
                </View>
                {/* <Text
                        style={{ marginLeft: '88%', paddingBottom: '10px', paddingTop: '10px' }}
                        fixed
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                    /> */}
            </Page>
        </Document>
    );
};

export default PdfReceita;
