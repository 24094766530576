import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const HeaderArea = styled(Box)`
    & div {
        display: flex;
        justify-content: space-between;
        margin-top: 1.25rem;
    }
`;

export const TriagemArea = styled(Box)`
    border-radius: 5px;
    padding: 0.5125rem;
    margin-bottom: 1.25rem;

    & .title-triagem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 2.375rem;
    }

    & .patient-data {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 4.375rem;
    }

    & .footer-triagem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 2.375rem;
    }

    & .flex-item {
        display: flex;
        align-items: center;
        gap: 5px;
    }
`;
export const RegisterArea = styled(Box)`
    border-radius: 5px;
    padding: 0 2.85rem;
    margin-bottom: 1.25rem;
    height: 300px;
    overflow-y: auto;

    /* & .item-area {
        height: 8rem;
    } */

    & .hypothesi-area {
        & div {
            display: flex;
            justify-content: space-between;
            margin-bottom: 0.625rem;

            & span {
                display: block;
                border: 1px solid #808080;
                padding: 0.5rem;
                border-radius: 5px;
                width: 22.625rem;
            }
        }
    }
`;
