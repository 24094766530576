import {
    Button,
    Box,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Collapse,
    Tooltip,
    IconButton,
    DialogTitle
} from '@mui/material';
import { useState, useEffect } from 'react';
import { useApi } from 'Service/axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import SubCard from 'ui-component/cards/SubCard';
import { toast } from 'react-toastify';

// =============== TABELA ================

function Row({ item, getExames }) {
    // =============== STATES ================
    const theme = useTheme();
    const api = useApi();
    const [openTable, setOpenTable] = useState(false);
    const [openDeleteCampo, setOpenDeleteCampo] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [itemId, setItemId] = useState(null);
    const [campoId, setCampoId] = useState(null);
    // =============== FUNÇÕES ================
    // delete functions
    const handleClickOpenDelete = (e, id) => {
        e.preventDefault();
        setOpenDelete(true);
        setItemId(id);
    };

    const handleClickOpenDeleteCampo = (e, id) => {
        e.preventDefault();
        setOpenDeleteCampo(true);
        setCampoId(id);
    };

    const handleCloseDelete = (e) => {
        e.preventDefault();
        setOpenDelete(false);
        setOpenDeleteCampo(false);
    };
    const handleCancelDeleteDialog = () => {
        setOpenDelete(false);
        setOpenDeleteCampo(false);
    };

    const handleDeleteItem = async (e, id) => {
        e.preventDefault();
        try {
            await api.deleteExame(id);
            toast.success('Deletado com sucesso!');
            getExames();
            setOpenDelete(false);
        } catch (error) {
            setOpenDelete(false);
            toast.error(error.response.data.mensagem);
        }
    };

    const handleDeleteCampoEspecifico = async (e, campoEspecificoID) => {
        try {
            await api.deleteCampoEspecifico(campoEspecificoID);
            toast.success('Deletado com sucesso!');
            getExames();
            setOpenDeleteCampo(false);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    };

    return (
        <>
            <Dialog
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="alert-dialog-title-delete"
                aria-describedby="alert-dialog-description-delete"
            >
                <DialogTitle id="alert-dialog-title-delete">{'Deletar Registro'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description-delete">Tem certeza que deseja excluir o Exame ?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(e) => handleDeleteItem(e, itemId)}>Sim</Button>
                    <Button onClick={handleCancelDeleteDialog}>Cancelar</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openDeleteCampo} onClose={handleCloseDelete}>
                <DialogTitle>Excluir Campo</DialogTitle>
                <DialogContent>
                    <DialogContentText>Tem certeza que deseja excluir o campo específico ?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(e) => handleDeleteCampoEspecifico(e, campoId)}>Sim</Button>
                    <Button onClick={handleCancelDeleteDialog}>Cancelar</Button>
                </DialogActions>
            </Dialog>
            <TableRow hover sx={{ '& > *': { borderBottom: 'unset' } }}>
                {/* <TableCell sx={{ pl: 3 }}>
                    {item.campos_especificos.length > 0 && (
                        <Tooltip title={openTable ? 'Ocultar' : 'Expandir'}>
                            <IconButton aria-label="expand row" size="small" onClick={() => setOpenTable(!openTable)}>
                                {openTable ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </Tooltip>
                    )}
                </TableCell> */}
                <TableCell>{item.id}</TableCell>
                <TableCell align="center">{item.nome}</TableCell>
                <TableCell align="center">{item.material}</TableCell>
                <TableCell align="center">{item.sigla}</TableCell>
                <TableCell align="center">{item.metodo}</TableCell>

                <TableCell align="center">
                    <Link to={`/main/laboratorio/exame/${item.id}`}>
                        <Tooltip title="Editar Exame">
                            <IconButton>
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                    </Link>
                    <Tooltip title="Excluir Exame">
                        <IconButton onClick={(e) => handleClickOpenDelete(e, item.id)}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
            {/* COLAPSE */}
            {item.campos_especificos.length > 0 && (
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                        <Collapse in={openTable} timeout="auto" unmountOnExit>
                            {openTable && (
                                <Box sx={{ margin: 1 }}>
                                    <TableContainer>
                                        <SubCard
                                            sx={{ bgcolor: theme.palette.mode === 'dark' ? 'dark.800' : 'grey.50', mb: 2 }}
                                            title="Campos específicos do exame"
                                            content={false}
                                        >
                                            <Table size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Nome campo</TableCell>
                                                        <TableCell align="center">Unidade</TableCell>
                                                        <TableCell align="center">Categoria</TableCell>
                                                        <TableCell align="center">Sexo</TableCell>
                                                        <TableCell align="center">Valores</TableCell>
                                                        <TableCell align="center">Ações</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                {item.campos_especificos.map((item) => (
                                                    <TableBody key={item.id}>
                                                        <TableCell>{item.campo_nome}</TableCell>
                                                        <TableCell align="center">{item.unidade}</TableCell>
                                                        <TableCell align="center">{item.categoria}</TableCell>
                                                        <TableCell align="center">
                                                            {item.sexo === '3' ? 'Ambos' : item.sexo === '1' ? 'Masculino' : 'Feminino'}
                                                        </TableCell>
                                                        <TableCell align="center">{item.valores}</TableCell>
                                                        <TableCell align="center">
                                                            <Tooltip title="Excluir Campo">
                                                                <IconButton onClick={(e) => handleClickOpenDeleteCampo(e, item.id)}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableBody>
                                                ))}
                                            </Table>
                                        </SubCard>
                                    </TableContainer>
                                </Box>
                            )}
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
}

export default Row;
