import { Button, Box, Typography, FormControl, TextField, MenuItem, Tooltip, Autocomplete } from '@mui/material';
import { useEffect, useState } from 'react';
import { useApi } from 'Service/axios';
import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { BoxContainer, BoxHeader, FieldBox, FormBox } from './style';
import FilterDrawerEntrada from './Drawer';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import CancelDialog from './CancelDialog';
import { toast } from 'react-toastify';

const FormTransferencia = () => {
    // ============================= INSTÂNCIAS =============================
    const api = useApi();
    // ============================= STATES =============================
    const [transferencia, setTransferencia] = useState({
        itemId: '',
        unidade: '',
        data: new Date().toISOString().split('T')[0],
        quantidade: '',
        almoxarifado_origem: '',
        localizacao_origem: '',
        lote_origem: '',
        almoxarifado_destino: '',
        localizacao_destino: '',
        observacao: ''
    });
    const [open, setOpen] = useState(false);
    const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [filtredLocationsOrigem, setFiltredLocationsOrigem] = useState([]);
    const [filtredLocationsDestino, setFiltredLocationsDestino] = useState([]);
    const [dependency, setDependency] = useState({ itens: [], almoxarifados: [], localizacoes: [], unidades: [], transferencias: [] });
    const [lotes, setLotes] = useState([]);

    // ============================= FUNÇÕES =============================

    const handleGetItemLotes = async (dados) => {
        try {
            const { data } = await api.getLoteByItemId(dados);
            setLotes(data);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    };

    const handleChangeLocationsOrigem = (location) => {
        const filtredData = dependency.localizacoes.filter((i) => i.almoxarifado_id === location);
        setFiltredLocationsOrigem(filtredData);
    };

    const handleChangeLocationsDestino = (location) => {
        const filtredData = dependency.localizacoes.filter((i) => i.almoxarifado_id === location);
        setFiltredLocationsDestino(filtredData);
    };

    const handleChangeItem = (newValue) => {
        if (newValue.psicotropico) {
            const payload = { id: newValue.id, almoxarifado_id: newValue.almoxarifado_id, localizacao_id: newValue.localizacao_id };
            handleGetItemLotes(payload);
        }

        const selectedItem = dependency.itens.find((item) => item.id === newValue.id);
        handleChangeLocationsOrigem(selectedItem.almoxarifado.id);

        setTransferencia({
            ...transferencia,
            itemId: selectedItem.id,
            unidade: selectedItem.unidade.id,
            almoxarifado_origem: selectedItem.almoxarifado.id,
            localizacao_origem: selectedItem.localizacao.id
        });
    };

    const handleChangeField = (field, value) => {
        const updatedTransferencia = { ...transferencia, [field]: value };

        if (field === 'almoxarifado_origem' || field === 'localizacao_origem') {
            const payload = {
                id: transferencia.itemId,
                almoxarifado_id: field === 'almoxarifado_origem' ? value : transferencia.almoxarifado_origem,
                localizacao_id: field === 'localizacao_origem' ? value : transferencia.localizacao_origem
            };
            handleGetItemLotes(payload);
        }

        setTransferencia(updatedTransferencia);
    };

    const resetState = () => {
        setTransferencia({
            itemId: '',
            unidade: '',
            data: new Date().toISOString().split('T')[0],
            quantidade: '',
            almoxarifado_origem: '',
            localizacao_origem: '',
            lote_origem: '',
            almoxarifado_destino: '',
            localizacao_destino: '',

            observacao: ''
        });
    };

    const handelConfirmDialog = () => {
        resetState();
        setCancelDialogOpen(false);
    };

    const handleCloseDialog = () => {
        setCancelDialogOpen(false);
    };

    const handleCheckDialog = (transferencia) => {
        if (transferencia.id) {
            setIsDelete(true);
            setCancelDialogOpen(true);
        } else {
            setCancelDialogOpen(true);
        }
    };

    const handleCheckValues = (params) => {
        // tirar os campos não obrigatórios
        // ou montar objeto apenas com os campos necessários com os nomes que precisarem ser mudados
        // ex: itemId => item ou selectedItem => item
        const filtredObject = {
            item: params.itemId,
            unidade: params.unidade,
            data: params.data,
            quantidade: params.quantidade,
            almoxarifado_origem: params.almoxarifado_origem,
            localizacao_origem: params.localizacao_origem,
            almoxarifado_destino: params.almoxarifado_destino,
            localizacao_destino: params.localizacao_destino
        };

        let errorArr = [];
        Object.keys(filtredObject).forEach((field) => {
            if (filtredObject[field] === '' || filtredObject[field] === null) {
                let key = field.replace(field[0], field[0].toUpperCase()).replace('_', ' ');
                toast.error(`O campo ${key} deve ser informado`);
                errorArr.push(field);
            }
            return errorArr;
        });

        if (errorArr.length > 0) {
            return false;
        } else {
            return true;
        }
    };

    // === SUBMITS / API CALLS ===

    const getDependency = async () => {
        try {
            const [item, unidade, almoxarifado, localizacao, transferencias] = await Promise.all([
                api.getAllItems(),
                api.getAllUnidade(),
                api.getAlmoxarifados(),
                api.getAllLocalizacoes(),
                api.getAllTransferencia()
            ]);

            setDependency({
                itens: item.data,
                almoxarifados: almoxarifado.data,
                localizacoes: localizacao.data,
                unidades: unidade.data,
                transferencias: transferencias.data
            });
        } catch (error) {
            toast.error(`Erro ao bucar dados: ${error.message}`);
        }
    };

    const handleCreateTransferencia = async (e, transferencia) => {
        e.preventDefault();
        try {
            if (handleCheckValues(transferencia)) {
                if (transferencia.id) {
                    // chamada axios update
                    //
                    await api.updateTransferencia(transferencia.id, transferencia);
                    resetState();
                    getDependency();
                    toast.success('Transferência atualizada com sucesso');
                } else {
                    // chamada axios create
                    //
                    await api.createTransferencia(transferencia);
                    resetState();
                    getDependency();
                    toast.success('Transferência cadastrada com sucesso');
                }
            }
        } catch (error) {
            toast.error(`Erro: ${error.response.data.message}`);
        }
    };

    useEffect(() => {
        getDependency();
    }, []);

    return (
        <BoxContainer>
            {/* DRAWER COMPONENT */}
            <FilterDrawerEntrada
                open={open}
                setOpen={setOpen}
                itens={dependency.itens}
                transferencias={dependency.transferencias}
                setTransferencia={setTransferencia}
                handleChangeLocationsOrigem={handleChangeLocationsOrigem}
                handleChangeLocationsDestino={handleChangeLocationsDestino}
            />
            {/* DIALOG CANCELAR  */}
            <CancelDialog
                open={cancelDialogOpen}
                onClose={handleCloseDialog}
                handelConfirmDialog={handelConfirmDialog}
                isDelete={isDelete}
            />
            {/* FORM */}
            <BoxHeader>
                <Typography sx={{ fontSize: '1.125rem', fontWeight: '500', color: '#121926' }}>Inserir nova transferência</Typography>
                {/* <Tooltip title="Filtrar Transferência">
                    <Button variant="contained" color="warning" onClick={() => setOpen(true)}>
                        <SearchIcon />
                    </Button>
                </Tooltip> */}
            </BoxHeader>
            <FormBox>
                <FieldBox>
                    <FormControl fullWidth>
                        <TextField type="date" label="Data" value={transferencia.data} disabled />
                    </FormControl>
                    <FormControl fullWidth>
                        <Autocomplete
                            options={dependency.itens}
                            getOptionLabel={(item) => `${item.id} - ${item.descricao}`}
                            value={dependency.itens.find((el) => el.id === transferencia.itemId) || null}
                            onChange={(e, newValue) => handleChangeItem(newValue)}
                            renderInput={(params) => <TextField {...params} label="Item" />}
                        />
                        {/* <TextField select label="Item" value={transferencia.itemId} onChange={(e) => handleChangeItem(e)}>
                            {dependency.itens.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.descricao}
                                </MenuItem>
                            ))}
                        </TextField> */}
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            select
                            label="Unidade"
                            disabled
                            value={transferencia.unidade}
                            onChange={(e) => handleChangeField('unidade', e.target.value)}
                        >
                            {dependency.unidades.map((unidade) => (
                                <MenuItem key={unidade.id} value={unidade.id}>
                                    {unidade.descricao}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            type="number"
                            label="Quantidade"
                            value={transferencia.quantidade}
                            onChange={(e) => handleChangeField('quantidade', e.target.value)}
                        />
                    </FormControl>
                </FieldBox>
                <FieldBox sx={{ borderTop: '2px dashed #eee', padding: '1em 0 1em 0' }}>
                    <Typography variant="h5">ORIGEM</Typography>
                    <Typography variant="h5">DESTINO</Typography>
                </FieldBox>
                <FieldBox>
                    <FormControl fullWidth>
                        <TextField
                            select
                            label="Almoxarifado Origem"
                            value={transferencia.almoxarifado_origem}
                            onChange={(e) => {
                                handleChangeField('almoxarifado_origem', e.target.value), handleChangeLocationsOrigem(e.target.value);
                            }}
                        >
                            {dependency.almoxarifados.map((almoxarifado) => (
                                <MenuItem key={almoxarifado.id} value={almoxarifado.id}>
                                    {almoxarifado.descricao}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            select
                            label="Localização Origem"
                            value={transferencia.localizacao_origem}
                            onChange={(e) => handleChangeField('localizacao_origem', e.target.value)}
                        >
                            {filtredLocationsOrigem.map((localizacao) => (
                                <MenuItem key={localizacao.id} value={localizacao.id}>
                                    {localizacao.descricao}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                    {lotes.length > 0 && (
                        <FormControl fullWidth>
                            <TextField
                                select
                                label="Lote Origem"
                                value={transferencia.lote_origem}
                                onChange={(e) => handleChangeField('lote_origem', e.target.value)}
                            >
                                {lotes
                                    .filter((el) => el.quantidade_disponivel > 0)
                                    .map((lote) => (
                                        <MenuItem key={lote.id} value={lote.lote}>
                                            {lote.lote} = {lote.quantidade_disponivel}
                                        </MenuItem>
                                    ))}
                            </TextField>
                        </FormControl>
                    )}
                    <CompareArrowsIcon />
                    <FormControl fullWidth>
                        <TextField
                            select
                            label="Almoxarifado Destino"
                            value={transferencia.almoxarifado_destino}
                            onChange={(e) => {
                                handleChangeField('almoxarifado_destino', e.target.value), handleChangeLocationsDestino(e.target.value);
                            }}
                        >
                            {dependency.almoxarifados.map((almoxarifado) => (
                                <MenuItem key={almoxarifado.id} value={almoxarifado.id}>
                                    {almoxarifado.descricao}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            select
                            label="Localização Destino"
                            value={transferencia.localizacao_destino}
                            onChange={(e) => handleChangeField('localizacao_destino', e.target.value)}
                        >
                            {filtredLocationsDestino.map((localizacao) => (
                                <MenuItem key={localizacao.id} value={localizacao.id}>
                                    {localizacao.descricao}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                </FieldBox>
                <FieldBox>
                    <FormControl fullWidth>
                        <TextField
                            label="Observação"
                            multiline
                            minRows={5}
                            value={transferencia.observacao}
                            onChange={(e) => handleChangeField('observacao', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                        />
                    </FormControl>
                </FieldBox>
            </FormBox>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1em' }}>
                <Tooltip title="Salvar transferencia">
                    <Button variant="contained" color="success" onClick={(e) => handleCreateTransferencia(e, transferencia)}>
                        <SaveIcon />
                    </Button>
                </Tooltip>
                {/* <Tooltip title={transferencia.id ? 'Excluir transferencia' : 'Cancelar cadastro de transferencia'}>
                    <Button variant="contained" color="error" onClick={() => handleCheckDialog(transferencia)}>
                        <DeleteIcon />
                    </Button>
                </Tooltip> */}
            </Box>
        </BoxContainer>
    );
};

export default FormTransferencia;
