import { useApi } from 'Service/axios';
import { IconButton, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';

const LobbyActionButton = ({ row }) => {
    /////////////////////////////// INSTANCIAS /////////////////////////
    const api = useApi();
    /////////////////////////////// STATES /////////////////////////
    /////////////////////////////// FUNÇÕES /////////////////////////

    return (
        <Box>
            <Link to={`/main/editar_prontuario/${row.id}`}>
                <Tooltip title="Editar">
                    <IconButton>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
            </Link>
            <Link to={`/main/prontuario/${row.id}`}>
                <Tooltip title="Abrir">
                    <IconButton>
                        <FolderOpenIcon />
                    </IconButton>
                </Tooltip>
            </Link>
        </Box>
    );
};

export default LobbyActionButton;
