import { Autocomplete, Box, Button, IconButton, Modal, TextField, Tooltip } from '@mui/material';
import { BtnArea, CustomInput, InputArea, TitleArea } from './styles';
import { DataGrid } from '@mui/x-data-grid';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { useState } from 'react';
import { useEffect } from 'react';
import { useApi } from 'Service/axios';
import { formatarData } from 'utils/formatarData';
import { toast } from 'react-toastify';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2
};

const ExamModal = ({ open, setOpen, currentRow, getAllProntuario }) => {
    const api = useApi();
    const [openAddExam, setOpenAddExam] = useState(false);
    const [exams, setExams] = useState([]);
    const [newExm, setNewExam] = useState('');
    const [registredExams, setRegistredExams] = useState([]);
    const [selectedItens, setSelectedItens] = useState([]);

    const handleClose = () => {
        setOpen(false);
    };

    async function getAllXRayData() {
        try {
            const { data } = await api.getAllXRay();
            setExams(data);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    useEffect(() => {
        getAllXRayData();
    }, []);

    const columns = [
        { field: 'description', headerName: 'Description', flex: 1 },
        { field: 'date', headerName: 'Date', flex: 1 },
        { field: 'status', headerName: 'Status', flex: 1 }
    ];

    function handleSelection(selectedItems) {
        setSelectedItens(selectedItems);
    }

    async function getExamsLinked() {
        try {
            const { data } = await api.getLinkExamXray(currentRow.id);

            const row = data.map((item) => ({
                id: item?.id,
                description: exams.find((exam) => exam.id === item.tipo_raio_x)?.descricao,
                date: formatarData(item?.createdAt),
                status: item?.finalizado ? 'Finalizado' : 'Pendente'
            }));

            setRegistredExams(row);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    useEffect(() => {
        if (currentRow) {
            getExamsLinked();
        }
    }, [currentRow]);

    async function handleLinkExam(id) {
        try {
            await api.linkExamXray(currentRow.id, id);

            getExamsLinked();
            toast.success('Exame vinculado com sucesso!');
            setOpenAddExam(false);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    async function handleFinishLinkExam() {
        const payload = selectedItens.map((item) => ({ id: item }));
        try {
            await api.finishLinkExamXray(payload);
            toast.success('Exames finalizados com sucesso');
            getExamsLinked();
            getAllProntuario();
            handleClose();
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
            <Box sx={style}>
                <TitleArea>
                    <h2 id="modal-title">Raio X </h2>
                    <h2 id="modal-title">{`F.a.: ${currentRow?.id} - ${currentRow?.patient}`}</h2>
                </TitleArea>

                <Tooltip title="Adicionar">
                    <IconButton onClick={() => setOpenAddExam(true)}>
                        <PostAddIcon />
                    </IconButton>
                </Tooltip>
                {openAddExam && (
                    <InputArea>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={exams}
                            sx={{ width: 300 }}
                            getOptionLabel={(option) => `${option.descricao} - ${option.codigo}`}
                            onChange={(event, newValue) => {
                                handleLinkExam(newValue?.id);
                            }}
                            renderInput={(params) => <TextField {...params} label="Exames de Raio-x" value={newExm} />}
                        />
                    </InputArea>
                )}

                <Box>
                    {registredExams.length === 0 ? (
                        <h3>Nenhum exame vinculado</h3>
                    ) : (
                        <>
                            <DataGrid
                                rows={registredExams}
                                columns={columns}
                                hideFooter={true}
                                checkboxSelection
                                onRowSelectionModelChange={handleSelection}
                                isRowSelectable={(params) => params.row.status !== 'Finalizado'}
                            />
                            <BtnArea>
                                <Button variant="contained" onClick={handleFinishLinkExam}>
                                    Finalizar
                                </Button>
                            </BtnArea>
                        </>
                    )}
                </Box>
            </Box>
        </Modal>
    );
};

export default ExamModal;
