import { FormControl, InputLabel, OutlinedInput, styled } from '@mui/material';
import Box from '@mui/material/Box';
import { style } from '@mui/system';

export const MainBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column'
});

export const FormBox = styled(Box)({
    marginTop: '.4em',
    marginBottom: '.6em',
    width: '100%'
});

export const Label = styled(InputLabel)({
    fontSize: '1em'
});

export const Input = styled(OutlinedInput)({
    fontSize: '1em',
    width: '100%'
});

export const SecondaryBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
});

export const SecondaryFormBox = styled(FormControl)({
    marginTop: '.4em',
    marginBottom: '.6em',
    width: '45%'
});
