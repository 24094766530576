import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';

const ActionButtonEstorno = ({ open, handleClose, selectedSaida, quantidadeEstorno, setQuantidadeEstorno, handleSubmit }) => {
    return (
        <Dialog open={open}>
            <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <Typography variant="h4">Informe a quantidade</Typography>
                <form autoComplete="off">
                    <TextField autoComplete="off" value={quantidadeEstorno} onChange={(e) => setQuantidadeEstorno(e.target.value)} />
                </form>
            </DialogContent>
            <DialogActions>
                <Box sx={{ display: 'flex' }}>
                    <Button color="error" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button
                        onClick={async () =>
                            await handleSubmit([
                                {
                                    id: selectedSaida?.id,
                                    quantidade_extorno: quantidadeEstorno,
                                    prontuario_id: selectedSaida?.prontuario_id
                                }
                            ])
                        }
                    >
                        Confirmar
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default ActionButtonEstorno;
