import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import ActionButtonGeral from 'components/Estoque/Relatorios/ActionButtonGeral';
import ActionButtonGeralSimples from 'components/Estoque/Relatorios/ActionButtonGeralSimples';
import ActionButtonPorPaciente from 'components/Estoque/Relatorios/ActionButtonPorPaciente';
import ActionButtonPeriodo from 'components/Estoque/Relatorios/ActionButtonProduto';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';

const RelatoriosEstoque = () => {
    // ---- INSTÂNCIAS ----
    // ---- STATES ----
    const relatorios = [
        { id: 1, nome: 'ESTOQUE GERAL DETALHADO', componente: <ActionButtonGeral /> },
        { id: 2, nome: 'ESTOQUE GERAL SIMPLES', componente: <ActionButtonGeralSimples /> },
        { id: 3, nome: 'MOVIMENTAÇÃO POR PRODUTO', componente: <ActionButtonPeriodo /> },
        { id: 4, nome: 'MOVIMENTAÇÃO POR PACIENTE', componente: <ActionButtonPorPaciente /> }
    ];
    const columns = [
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'nome', headerName: 'NOME', flex: 2 },
        { field: 'componente', headerName: 'AÇÕES', flex: 0.5, sortable: false, renderCell: (params) => params.row.componente }
    ];
    // ---- FUNÇÕES ----
    const handleSelectionModelChange = (newSelection) => {
        setSelectedItems(newSelection);
    };

    return (
        <MainCard title="LISTA DE RELATÓRIOS">
            <Grid spacing={gridSpacing}>
                <Box>
                    <DataGrid
                        rows={relatorios}
                        columns={columns}
                        hideFooter={true}
                        onSelectionModelChange={() => handleSelectionModelChange(params.id)}
                    />
                </Box>
            </Grid>
        </MainCard>
    );
};

export default RelatoriosEstoque;
