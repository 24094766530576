import newLogo from '../assets/images/Backgrounds/prontuario-digital.png';

const Logo = (props) => {
    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={logo} alt="Berry" width="100" />
         *
         */
        <>
            <img src={newLogo} alt="Santa casa" width={142} height={70} style={{ marginLeft: '2em' }} />
        </>
    );
};

export default Logo;
