import { Box, Button, ButtonGroup, Grid, IconButton, Tooltip } from '@mui/material';
import { useApi } from 'Service/axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { CardButton } from './components/CardButton';
import { IconPill, IconTemperature, IconBodyScan, IconHeartRateMonitor, IconEye, IconEdit, IconTrash } from '@tabler/icons-react';
import { LaboratoryModal } from './components/Laboratory';
import { RayxModal } from './components/RayXModal';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import { Medicine } from './components/Medicine';
import { EcgModal } from './components/EcgModal';
import { EditEcgModal } from './components/EditEcgModal';
import { EditRayX } from './components/EditRayX';
import { EditExams } from './components/EditExams';
import { EditeMedicine } from './components/EditeMedicine';
import DoneIcon from '@mui/icons-material/Done';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CancelIcon from '@mui/icons-material/Cancel';
import React from 'react';

export function MedicalContuct() {
    const { prontuarioId, name } = useParams();
    const api = useApi();

    const [openLaboratory, setOpenLaboratory] = useState(false);
    const [openRayx, setOpenRayx] = useState(false);
    const [openMedicine, setOpenMedicine] = useState(false);
    const [rows, setRows] = useState([]);
    const [openEcg, setOpenEcg] = useState(false);
    const [currentInfos, setCurrentInfos] = useState({});

    const [openEditEcg, setOpenEditEcg] = useState(false);
    const [openEditRayx, setOpenEditRayx] = useState(false);
    const [openEditExams, setOpenEditExams] = useState(false);
    const [openEditMedicine, setOpenEditMedicine] = useState(false);

    const columns = [
        { field: 'cod', headerName: 'Código', flex: 0.5 },
        { field: 'descricao', headerName: 'Descrição', flex: 1 },
        { field: 'qtd', headerName: 'Quantidade', flex: 0.5 },
        { field: 'unidade', headerName: 'Unidade', flex: 0.5 },
        {
            field: 'aplicacao',
            headerName: 'Aplicação',
            flex: 0.5,
            renderCell: (params) => <p style={{ textTransform: 'uppercase' }}>{params.row.aplicacao}</p>
        },
        { field: 'tipo', headerName: 'Tipo', flex: 0.5 },
        {
            field: 'status',
            headerName: 'Status',
            flex: 0.5,
            renderCell: (params) => {
                if (params?.row?.item_conduta?.finalizado || params?.row?.item_conduta?.atendido) {
                    return (
                        <Tooltip title="Finalizado">
                            <DoneIcon />
                        </Tooltip>
                    );
                } else if (isButtonDisabled(params)) {
                    return (
                        <Tooltip title="Cancelado">
                            <CancelIcon />
                        </Tooltip>
                    );
                } else {
                    return (
                        <Tooltip title="Aguardando">
                            <AccessTimeIcon />
                        </Tooltip>
                    );
                }
            }
        },
        {
            field: 'actions',
            headerName: 'Ações',
            flex: 0.5,
            renderCell: (params) => {
                return (
                    <div>
                        <Tooltip
                            title="Análise / Edite"
                            onClick={() => {
                                if (params.row.tipo === 'PROCEDIMENTO') {
                                    setOpenEditEcg(true);
                                    setCurrentInfos(params.row);
                                }
                                if (params.row.tipo === 'RAIO-X') {
                                    setOpenEditRayx(true);
                                    setCurrentInfos(params.row);
                                }
                                if (params.row.tipo === 'LABORATÓRIO') {
                                    setOpenEditExams(true);
                                    setCurrentInfos(params.row);
                                }
                                if (params.row.tipo === 'MEDICAMENTO') {
                                    setOpenEditMedicine(true);
                                    setCurrentInfos(params.row);
                                }
                            }}
                        >
                            <IconButton disabled={isButtonDisabled(params)}>
                                <IconEye /> /
                                <IconEdit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete" onClick={() => handleDelete(params.row)}>
                            <IconButton disabled={isButtonDisabled(params)}>
                                <IconTrash />
                            </IconButton>
                        </Tooltip>
                    </div>
                );
            }
        }
    ];

    function isButtonDisabled(params) {
        const { item_conduta, conduta_inativada } = params?.row || {};
        return item_conduta?.finalizado || item_conduta?.atendido || item_conduta?.ativo === false || conduta_inativada === true;
    }

    function handleBackPage() {
        history.back();
    }

    const getConduct = async () => {
        try {
            const { data } = await api.getMedicalConduct(prontuarioId);

            const row = data.map((item) => {
                let descricao;
                let qtd = '-';
                let unidade = '-';
                let aplicacao = '-';

                if (item?.tipo === 'LABORATÓRIO') {
                    if (item.item_conduta.exame) {
                        descricao = item?.item_conduta.exame?.nome;
                    } else {
                        descricao = item?.item_conduta.exames_especiai?.nome;
                    }
                } else if (item?.tipo === 'RAIO-X') {
                    descricao = item?.item_conduta.tipos_raio_x?.descricao;
                } else if (item?.tipo === 'MEDICAMENTO') {
                    let medInfo = item?.item_conduta?.item_pedido;

                    descricao = medInfo.descricao;
                    qtd = item?.item_conduta?.quantidade_item;
                    unidade = medInfo.unidade.sigla;
                    aplicacao = item?.item_conduta.via_aplicacao;
                } else if (item?.tipo === 'PROCEDIMENTO') {
                    descricao = item?.item_conduta?.procedimento?.descricao;
                }

                return {
                    ...item,
                    cod: item.id,
                    descricao: descricao,
                    qtd,
                    unidade,
                    aplicacao
                };
            });
            setRows(row);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    };

    async function handleDelete(row) {
        const toastId = toast.loading('Deletando dados ...');
        try {
            if (row.tipo === 'LABORATÓRIO') {
                await api.deleteConductLab(row.id);
                getConduct();
                toast.update(toastId, { render: 'Dados deletados com sucesso!', type: 'success', isLoading: false, autoClose: 2000 });
            } else if (row.tipo === 'RAIO-X') {
                await api.deleteConductRayx(row.id);
                getConduct();
                toast.update(toastId, { render: 'Dados deletados com sucesso!', type: 'success', isLoading: false, autoClose: 2000 });
            } else if (row.tipo === 'MEDICAMENTO') {
                const userId = localStorage.getItem('id');
                await api.deletarMedicamentoDaConduta({ conduta_id: row.id, responsavel_id: userId });
                getConduct();
                toast.update(toastId, { render: 'Dados deletados com sucesso!', type: 'success', isLoading: false, autoClose: 2000 });
            } else if (row.tipo === 'PROCEDIMENTO') {
                await api.deleteConductEcg(row.id);
                getConduct();
                toast.update(toastId, { render: 'Dados deletados com sucesso!', type: 'success', isLoading: false, autoClose: 2000 });
            }
        } catch (error) {
            toast.update(toastId, { render: 'Erro ao deletar dados!', type: 'error', isLoading: false, autoClose: 2000 });
        }
    }

    useEffect(() => {
        getConduct();
    }, []);

    return (
        <MainCard title={`Conduta médica - ${name}`}>
            <LaboratoryModal open={openLaboratory} setOpen={setOpenLaboratory} name={name} prontuarioId={prontuarioId} get={getConduct} />
            <RayxModal open={openRayx} setOpen={setOpenRayx} name={name} prontuarioId={prontuarioId} get={getConduct} />
            <Medicine open={openMedicine} setOpen={setOpenMedicine} name={name} prontuarioId={prontuarioId} get={getConduct} />
            <EcgModal open={openEcg} setOpen={setOpenEcg} name={name} prontuarioId={prontuarioId} get={getConduct} />

            <EditEcgModal open={openEditEcg} setOpen={setOpenEditEcg} infos={currentInfos} get={getConduct} />
            <EditRayX open={openEditRayx} setOpen={setOpenEditRayx} infos={currentInfos} get={getConduct} />
            <EditExams open={openEditExams} setOpen={setOpenEditExams} infos={currentInfos} get={getConduct} />
            <EditeMedicine open={openEditMedicine} setOpen={setOpenEditMedicine} infos={currentInfos} get={getConduct} />

            <Grid spacing={gridSpacing} sx={{ position: 'relative' }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        margin: '0 auto',
                        width: '800px'
                    }}
                >
                    <ButtonGroup variant="contained" aria-label="Basic button group">
                        <Button sx={{ width: '200px' }} onClick={() => setOpenMedicine(true)}>
                            <IconPill size={62} /> Medicamentos
                        </Button>
                        <Button sx={{ width: '200px' }} onClick={() => setOpenLaboratory(true)}>
                            <IconTemperature size={62} /> Laboratório
                        </Button>
                        <Button onClick={() => setOpenRayx(true)} sx={{ width: '200px' }}>
                            <IconBodyScan size={62} /> Raio-x
                        </Button>
                        <Button sx={{ width: '200px' }} onClick={() => setOpenEcg(true)}>
                            <IconHeartRateMonitor size={62} /> Procedimentos
                        </Button>
                    </ButtonGroup>
                </Box>
                <Box sx={{ marginTop: '1rem', height: '500px' }}>
                    {rows.length === 0 ? (
                        <p>Nenhum dado encontrado</p>
                    ) : (
                        <DataGrid columns={columns} rows={rows} onSelectionModelChange={() => handleSelectionModelChange(params.id)} />
                    )}
                </Box>
                <Box sx={{ marginTop: '.7rem' }}>
                    <Button variant="contained" color="error" onClick={handleBackPage}>
                        Voltar para ficha
                    </Button>
                </Box>
            </Grid>
        </MainCard>
    );
}
