import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';
import formateDate from 'utils/formatDate-1';

const TabelaPainelTriagem = ({ atendimentos }) => {
    const calculateTime = (time) => {
        const recordDate = new Date(time);
        const currentDate = new Date();
        const timeDifference = currentDate.getTime() - recordDate.getTime();
        const waitTimeInSeconds = Math.floor(timeDifference / 1000);

        const hours = Math.floor(waitTimeInSeconds / 3600);
        const minutes = Math.floor((waitTimeInSeconds % 3600) / 60);
        const seconds = waitTimeInSeconds % 60;

        return `${hours}h ${minutes}m ${seconds}s`;
    };

    return (
        <TableContainer component={Paper}>
            <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <b>F.A</b>
                        </TableCell>
                        <TableCell align="center">
                            <b>PACIENTE</b>
                        </TableCell>
                        <TableCell align="center">
                            <b>IDADE</b>
                        </TableCell>
                        <TableCell align="center">
                            <b>DATA</b>
                        </TableCell>
                        <TableCell align="center">
                            <b>HORA</b>
                        </TableCell>
                        <TableCell align="center">
                            <b>ETAPA</b>
                        </TableCell>
                        <TableCell align="center">
                            <b>ESPERA</b>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {atendimentos.map((item) => (
                        <TableRow key={item?.id}>
                            <TableCell>{item?.id}</TableCell>
                            <TableCell align="center">{item?.paciente.nome_completo}</TableCell>
                            <TableCell align="center">{item?.paciente?.idade} ANOS</TableCell>
                            <TableCell align="center">{formateDate(item?.data_atendimento)}</TableCell>
                            <TableCell align="center">{item.hora_atendimento}</TableCell>
                            <TableCell align="center">{item?.status}</TableCell>
                            <TableCell align="center">{calculateTime(item.data_atendimento)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TabelaPainelTriagem;
