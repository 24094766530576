import {
    Autocomplete,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    MenuItem,
    Switch,
    TextField,
    Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useApi } from 'Service/axios';

const ActionButtonPeriodo = () => {
    // ---- INSTÂNCIAS ----
    const api = useApi();
    // ---- STATES ----
    const [allProducts, setAllProducts] = useState([]);
    const [filtredProducts, setFiltredProducts] = useState([]);
    const [product, setProduct] = useState(null);
    const [data, setData] = useState({ productId: '', firstDate: '', lastDate: '' });
    const [checked, setChecked] = useState(true);
    const [switchCheck, setSwitchCheck] = useState(false);
    const [open, setOpen] = useState(false);
    // ---- FUNÇÕES ----

    const getProducts = async () => {
        try {
            const response = await api.getAllItemsNoFilter();
            setAllProducts(response.data);
            setFiltredProducts(response.data.filter((item) => item.psicotropico === true));
        } catch (error) {
            toast.error(`Erro ao buscar dados: ${error.message}`);
        }
    };

    const handleChangeCheckbox = (e) => {
        const newCheckedValue = e.target.checked;
        setChecked(newCheckedValue);

        if (!newCheckedValue) {
            setFiltredProducts(allProducts.filter((item) => item.psicotropico === false));
        } else {
            setFiltredProducts(allProducts.filter((item) => item.psicotropico === true));
        }
    };

    const handleChangeSwitch = (e) => {
        const newSwitchCheckValue = e.target.checked;
        setSwitchCheck(e.target.checked);

        if (!newSwitchCheckValue) {
            setFiltredProducts(allProducts.filter((item) => item.psicotropico === true));
        } else {
            setFiltredProducts(allProducts);
        }
    };

    const handleChangeInput = (campo, value) => {
        setData({ ...data, [campo]: value });
    };

    const handleClose = () => {
        setProduct(null);
        setChecked(true);
        setFiltredProducts(allProducts.filter((item) => item.psicotropico === true));
        setOpen(false);
        setData({ productId: '', firstDate: '', lastDate: '' });
    };

    const handleNavigate = (params) => {
        if (params.firstDate === '' || params.lastDate === '') {
            toast.error('Deve ser informado uma data inicial e uma final para fazer a consulta');
            return;
        }
        handleClose();
        if (params.productId !== '') {
            window.open(
                `/relatorios/movimentacao-periodo?productId=${params.productId}&firstDate=${params.firstDate}&lastDate=${params.lastDate}`
            );
        } else {
            window.open(
                `/relatorios/movimentacao-periodo?tipo=${checked ? '2' : '1'}&firstDate=${params.firstDate}&lastDate=${params.lastDate}`
            );
        }
    };

    useEffect(() => {
        getProducts();
    }, []);

    return (
        <Box>
            <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'md'}>
                <DialogContent>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ textAlign: 'center', padding: '.5em' }}>
                            <Typography variant="h3">MOVIMENTAÇÃO POR PRODUTO</Typography>
                        </Box>

                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '.4em', gap: '2em' }}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        options={filtredProducts}
                                        getOptionLabel={(item) => `${item.id} - ${item.descricao}`}
                                        value={filtredProducts.find((item) => item.id === data?.productId) || null}
                                        onChange={(e, newValue) => handleChangeInput('productId', newValue.id)}
                                        renderInput={(params) => <TextField {...params} label="Item" />}
                                    />

                                    {/* <TextField
                                        label="Item"
                                        select
                                        value={data.productId}
                                        disabled={switchCheck}
                                        onChange={(e) => handleChangeInput('productId', e.target.value)}
                                    >
                                        {filtredProducts.map((product) => (
                                            <MenuItem key={product.id} value={product.id}>
                                                {product.descricao}
                                            </MenuItem>
                                        ))}
                                    </TextField> */}
                                </FormControl>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            label="TESTE"
                                            checked={checked}
                                            onChange={(e) => handleChangeCheckbox(e)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    }
                                    label="PSICOTRÓPICO"
                                />
                                {/* <FormControlLabel
                                    control={
                                        <Switch
                                            checked={switchCheck}
                                            onChange={(e) => handleChangeSwitch(e)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    }
                                    label="Todos"
                                /> */}
                            </Box>
                            <Box
                                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '.4em', gap: '2em' }}
                            >
                                <FormControl fullWidth>
                                    <TextField
                                        label="Data"
                                        type="date"
                                        value={product}
                                        disabled={switchCheck}
                                        onChange={(e) => handleChangeInput('firstDate', e.target.value)}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </FormControl>
                                <Typography>Até</Typography>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Data"
                                        type="date"
                                        value={product}
                                        disabled={switchCheck}
                                        onChange={(e) => handleChangeInput('lastDate', e.target.value)}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </FormControl>
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button color="error" onClick={() => setOpen(false)}>
                        Cancelar
                    </Button>
                    <Button variant="contained" onClick={() => handleNavigate(data)}>
                        Gerar
                    </Button>
                </DialogActions>
            </Dialog>
            <Button onClick={() => setOpen(true)}>Abrir</Button>
        </Box>
    );
};

export default ActionButtonPeriodo;
