import { Button, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, Select, TextField, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';
import { useState } from 'react';
import {
    ButtonBox,
    CamposBox,
    CamposContainer,
    CamposEspecificosBox,
    CamposEspecificosContainer,
    CamposEspecificosFirstLine,
    CamposEspecificosSecondLine,
    CamposEspecificosSecondLineBox,
    MainBox,
    MainContainer
} from './style';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useApi } from 'Service/axios';
// ============================= INITIAL STATE PRO FORM =============================
const initialState = {
    nome: '',
    material: '',
    sigla: '',
    metodo: '',
    camposEspecificos: []
};
const FormNovoExame = () => {
    // ============================= INSTÂNCIAS =============================
    const navigate = useNavigate();
    const api = useApi();
    // ============================= STATES =============================
    const [campos, setCampos] = useState([initialState]);
    const [exameCriado, setExameCriado] = useState(null);
    const [loading, setLoading] = useState(false);
    // ============================= FUNÇÕES =============================
    const handleBack = (indiceCampo) => {
        const newCampos = [...campos];
        newCampos[indiceCampo].camposEspecificos = [];
        setCampos(newCampos);
        navigate('/main/laboratorio/exames');
    };

    const adicionarCampoEspecifico = (indiceCampo) => {
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            novosCampos[indiceCampo].camposEspecificos.push({
                nome_especifico: '',
                unidade: '',
                categoria: '',
                faixa_etaria1: '',
                faixa_etaria2: '',
                tipo: '',
                sexo: '',
                valores: ''
            });
            return novosCampos;
        });
    };

    const atualizarCampo = (indiceCampo, campo, valor) => {
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            novosCampos[indiceCampo][campo] = valor;
            return novosCampos;
        });
    };

    const atualizarCampoEspecifico = (indiceCampo, indiceCampoEspecifico, campo, valor) => {
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            novosCampos[indiceCampo].camposEspecificos[indiceCampoEspecifico][campo] = valor;
            return novosCampos;
        });
    };

    const removerCampoEspecifico = (indiceCampo, indiceCampoEspecifico) => {
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            novosCampos[indiceCampo].camposEspecificos.splice(indiceCampoEspecifico, 1);
            return novosCampos;
        });
    };

    // ============================= SUBMIT =============================
    const handleSubmit = async (e, campos) => {
        e.preventDefault();
        const dataPayload = campos[0];

        try {
            setLoading(true);
            if (!dataPayload.nome) {
                toast.error('O nome do exame é obrigatório');
                setLoading(false);
                return;
            }
            const response = await api.createExame(dataPayload);
            setExameCriado(response.data);
            setCampos(undefined);
            setCampos([
                {
                    nome: '',
                    material: '',
                    sigla: '',
                    metodo: '',
                    camposEspecificos: []
                }
            ]);
            toast.success('Exame cadastrado com sucesso');
        } catch (error) {
            setLoading(false);
            toast.error(error.response.data.mensagem);
        }
    };

    useEffect(() => {
        setCampos([
            {
                nome: '',
                material: '',
                sigla: '',
                metodo: '',
                camposEspecificos: []
            }
        ]);
    }, []);

    return (
        <MainBox>
            {campos.map((campo, indiceCampo) => (
                <MainContainer key={indiceCampo}>
                    <CamposContainer>
                        <CamposBox>
                            <FormControl fullWidth>
                                <TextField
                                    label="Nome"
                                    type="text"
                                    value={campo.nome}
                                    onChange={(e) => atualizarCampo(indiceCampo, 'nome', e.target.value)}
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <TextField
                                    label="Material"
                                    type="text"
                                    value={campo.material}
                                    onChange={(e) => atualizarCampo(indiceCampo, 'material', e.target.value)}
                                />
                            </FormControl>
                        </CamposBox>
                        <CamposBox>
                            <FormControl fullWidth>
                                <TextField
                                    label="Sigla"
                                    type="text"
                                    value={campo.sigla}
                                    onChange={(e) => atualizarCampo(indiceCampo, 'sigla', e.target.value)}
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <TextField
                                    label="Método"
                                    type="text"
                                    value={campo.metodo}
                                    onChange={(e) => atualizarCampo(indiceCampo, 'metodo', e.target.value)}
                                />
                            </FormControl>
                        </CamposBox>
                    </CamposContainer>
                    {campo.camposEspecificos.map((campoEspecifico, indiceCampoEspecifico) => (
                        <CamposEspecificosContainer key={indiceCampoEspecifico}>
                            <CamposEspecificosBox>
                                <CamposEspecificosFirstLine>
                                    <FormControl fullWidth>
                                        <TextField
                                            label="Nome Campo"
                                            type="text"
                                            value={campoEspecifico.nome_especifico}
                                            onChange={(e) =>
                                                atualizarCampoEspecifico(
                                                    indiceCampo,
                                                    indiceCampoEspecifico,
                                                    'nome_especifico',
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <TextField
                                            label="Unidade"
                                            type="text"
                                            value={campoEspecifico.unidade}
                                            onChange={(e) =>
                                                atualizarCampoEspecifico(indiceCampo, indiceCampoEspecifico, 'unidade', e.target.value)
                                            }
                                        />
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <TextField
                                            label="Categoria"
                                            type="text"
                                            value={campoEspecifico.categoria}
                                            onChange={(e) =>
                                                atualizarCampoEspecifico(indiceCampo, indiceCampoEspecifico, 'categoria', e.target.value)
                                            }
                                        />
                                    </FormControl>
                                </CamposEspecificosFirstLine>

                                <CamposEspecificosSecondLine>
                                    <CamposEspecificosSecondLineBox>
                                        <FormControl>
                                            <TextField
                                                sx={{ maxWidth: '100px' }}
                                                label="De"
                                                type="number"
                                                value={campoEspecifico.faixa_etaria1}
                                                onChange={(e) =>
                                                    atualizarCampoEspecifico(
                                                        indiceCampo,
                                                        indiceCampoEspecifico,
                                                        'faixa_etaria1',
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </FormControl>
                                        <FormControl sx={{ marginRight: '1em' }}>
                                            <Typography>Até</Typography>
                                        </FormControl>
                                        <FormControl>
                                            <TextField
                                                sx={{ maxWidth: '100px' }}
                                                label="Até"
                                                type="number"
                                                value={campoEspecifico.faixa_etaria2}
                                                onChange={(e) =>
                                                    atualizarCampoEspecifico(
                                                        indiceCampo,
                                                        indiceCampoEspecifico,
                                                        'faixa_etaria2',
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <InputLabel>Tipo</InputLabel>
                                            <Select
                                                sx={{ width: '100px' }}
                                                type="text"
                                                value={campoEspecifico.tipo}
                                                onChange={(e) =>
                                                    atualizarCampoEspecifico(indiceCampo, indiceCampoEspecifico, 'tipo', e.target.value)
                                                }
                                            >
                                                <MenuItem value={'1'}>ANOS</MenuItem>
                                                <MenuItem value={'2'}>MESES</MenuItem>
                                                <MenuItem value={'3'}>DIAS</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </CamposEspecificosSecondLineBox>
                                    <FormControlLabel
                                        value="1"
                                        control={
                                            <Radio
                                                checked={campoEspecifico.sexo === '1'}
                                                onChange={(e) =>
                                                    atualizarCampoEspecifico(indiceCampo, indiceCampoEspecifico, 'sexo', e.target.value)
                                                }
                                                value="1"
                                                name="Masculino"
                                                inputProps={{ 'aria-label': 'Masculino' }}
                                            />
                                        }
                                        label="Masculino"
                                    />
                                    <FormControlLabel
                                        value="2"
                                        control={
                                            <Radio
                                                checked={campoEspecifico.sexo === '2'}
                                                onChange={(e) =>
                                                    atualizarCampoEspecifico(indiceCampo, indiceCampoEspecifico, 'sexo', e.target.value)
                                                }
                                                value="2"
                                                name="Feminino"
                                                inputProps={{ 'aria-label': 'Feminino' }}
                                            />
                                        }
                                        label="Feminino"
                                    />
                                    <FormControlLabel
                                        value="3"
                                        control={
                                            <Radio
                                                checked={campoEspecifico.sexo === '3'}
                                                onChange={(e) =>
                                                    atualizarCampoEspecifico(indiceCampo, indiceCampoEspecifico, 'sexo', e.target.value)
                                                }
                                                value="3"
                                                name="Ambos"
                                                inputProps={{ 'aria-label': 'Ambos' }}
                                            />
                                        }
                                        label="Ambos"
                                    />
                                </CamposEspecificosSecondLine>
                            </CamposEspecificosBox>

                            <FormControl>
                                <TextField
                                    label="Valores"
                                    type="text"
                                    value={campoEspecifico.valores}
                                    onChange={(e) =>
                                        atualizarCampoEspecifico(indiceCampo, indiceCampoEspecifico, 'valores', e.target.value)
                                    }
                                />
                            </FormControl>

                            <ButtonBox>
                                <Tooltip title="Remover Campo Específico">
                                    <Button
                                        variant="contained"
                                        color="error"
                                        onClick={(e) => removerCampoEspecifico(indiceCampo, indiceCampoEspecifico)}
                                    >
                                        <DeleteIcon />
                                    </Button>
                                </Tooltip>
                            </ButtonBox>
                        </CamposEspecificosContainer>
                    ))}
                    <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '2rem', width: '100%' }}>
                        <Tooltip title="Adicionar Campo Específico">
                            <Button variant="contained" onClick={() => adicionarCampoEspecifico(indiceCampo)}>
                                <AddIcon />
                            </Button>
                        </Tooltip>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '1rem' }}>
                            <Button color="error" onClick={() => handleBack(indiceCampo)}>
                                Voltar para tabela
                            </Button>
                            <Tooltip title="Atualizar todos os campos">
                                <Button variant="contained" onClick={(e) => handleSubmit(e, campos)} disabled={loading}>
                                    {loading ? 'Aguarde...' : 'Cadastrar Exame'}
                                </Button>
                            </Tooltip>
                        </Box>
                    </Box>
                </MainContainer>
            ))}
        </MainBox>
    );
};

export default FormNovoExame;
