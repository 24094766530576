import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, TextField, Typography, Box, InputLabel, Select, MenuItem, ButtonGroup, FormLabel } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { Outlet } from 'react-router-dom';
import { MainBox } from './style';
import Logo from '../../../assets/images/Logos/logo.png';
import Logo2 from '../../../assets/BW TECNOLOGIA.png';

const MainRelatorio = () => {
    const usuario = localStorage.getItem('nome');

    const dataHoraAtual = new Date();

    const dia = dataHoraAtual.getDate();
    const mes = dataHoraAtual.getMonth() + 1;
    const ano = dataHoraAtual.getFullYear();

    const horas = dataHoraAtual.getHours();
    const minutos = dataHoraAtual.getMinutes();
    const segundos = dataHoraAtual.getSeconds();

    const dataFormatada = (dia < 10 ? '0' + dia : dia) + '/' + (mes < 10 ? '0' + mes : mes) + '/' + ano;
    const horaFormatada =
        (horas < 10 ? '0' + horas : horas) +
        ':' +
        (minutos < 10 ? '0' + minutos : minutos) +
        ':' +
        (segundos < 10 ? '0' + segundos : segundos);

    return (
        <MainCard>
            <Grid container spacing={gridSpacing}>
                <MainBox style={{ pageBreakInside: 'avoid' }}>
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 30px' }}>
                        <div style={{ display: 'flex', alignItems: 'center', marginRight: 'auto', marginLeft: '1em' }}>
                            <img src={Logo} alt="logo Santa Casa" style={{ width: '50px', height: 'auto', marginRight: '10px' }} />
                            <h3>SANTA CASA DE MISERICÓRDIA DE JOANÓPOLIS</h3>
                        </div>
                        <div>
                            <img
                                src={Logo2}
                                alt="logo Santa Casa"
                                style={{ width: '90px', height: 'auto', marginRight: '10px', marginTop: '10px' }}
                            />
                        </div>
                    </Box>

                    <Box sx={{ display: 'flex', width: '100%', padding: '0  0 0 35px', justifyContent: 'space-between' }}>
                        <Typography constiant="body" sx={{ fontSize: '.8em' }}>
                            PRONTUÁRIO ELETRÔNICO
                        </Typography>
                        <Typography constiant="body" sx={{ fontSize: '.8em', padding: '0  35px 0 0' }}>
                            USUÁRIO - {usuario}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            marginLeft: 'auto',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            padding: '0 30px'
                        }}
                    >
                        <Typography constiant="body" sx={{ marginRight: '.7em', fontSize: '.8em' }}>
                            DATA - {dataFormatada}
                        </Typography>
                        <Typography constiant="body" sx={{ fontSize: '.8em' }}>
                            HORA - {horaFormatada}
                        </Typography>
                    </Box>
                    <Outlet />
                </MainBox>
            </Grid>
        </MainCard>
    );
};

export default MainRelatorio;
