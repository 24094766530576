import { styled } from '@mui/material';
import { Box } from '@mui/material';

export const FormBox = styled(Box)({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
});

export const FieldsContainer = styled(Box)({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem'
});

export const FormInputBox = styled(Box)({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
});

export const FieldBox = styled(Box)({
    width: '45%'
});
