import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, Box } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useEffect, useState } from 'react';
import { useApi } from 'Service/axios';
import { MainBox, TableBox, HeaderBox } from './style';
import CadastroCentroCusto from 'components/Estoque/CentroCusto/CadastroCentroCusto/index';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import ActionButtons from 'components/Estoque/CentroCusto/ActionButton';

function CentroCustos() {
    // ==== INSTÂNCIAS =====
    const api = useApi();
    // ==== STATES =====
    const [allCentroCusto, setAllCentroCusto] = useState([]);
    const [open, setOpen] = useState(false);
    const columns = [
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'descricao', headerName: 'Descrição', flex: 2 },
        {
            field: 'acao',
            headerName: 'AÇÕES',
            flex: 1,
            sortable: false,
            renderCell: (params) => <ActionButtons row={params.row} getAllCentroCusto={getAllCentroCusto} />
        }
    ];
    // ==== FUNÇÕES =====

    const handleClose = () => {
        setOpen(false);
    };

    const getAllCentroCusto = async () => {
        try {
            const response = await api.getAllCentroCusto();
            setAllCentroCusto(response.data);
        } catch (error) {
            toast.error(error.message);
        }
    };

    useEffect(() => {
        getAllCentroCusto();
    }, []);

    return (
        <MainCard title="CENTRO DE CUSTO">
            <Grid container spacing={gridSpacing}>
                <MainBox>
                    <TableBox sx={{ width: '100%' }}>
                        <HeaderBox>
                            <Button variant="contained" onClick={() => setOpen(true)}>
                                Cadastrar Centro de Custo
                            </Button>
                        </HeaderBox>
                        {open && (
                            <Box sx={{ width: '80%', margin: '2em' }}>
                                <CadastroCentroCusto open={open} handleClose={handleClose} getAllCentroCusto={getAllCentroCusto} />
                            </Box>
                        )}
                        <Box sx={{ width: '100%', height: '450px' }}>
                            <DataGrid
                                rows={allCentroCusto}
                                columns={columns}
                                hideFooter={true}
                                onSelectionModelChange={() => handleSelectionModelChange(params.id)}
                            />
                        </Box>
                    </TableBox>
                </MainBox>
            </Grid>
        </MainCard>
    );
}

export default CentroCustos;
