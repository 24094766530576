import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import React from 'react';
import { Box } from '@mui/system';

function DeleteItemModal({ open, setOpen, observation, setObservation, handleRemoveItem, deleteItemUUID }) {
    return (
        <Dialog open={open} maxWidth={'lg'} fullWidth={true}>
            <DialogTitle>DESATIVAR PEDIDO</DialogTitle>
            <DialogContent>
                <Box sx={{ marginTop: '.4rem' }}>
                    <TextField
                        multiline
                        minRows={4}
                        fullWidth
                        label={'Observação de desativação'}
                        value={observation}
                        onChange={(e) => setObservation(e.target.value)}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error">
                    Cancelar
                </Button>
                <Button variant="contained" onClick={() => handleRemoveItem(deleteItemUUID)}>
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DeleteItemModal;
