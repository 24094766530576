import { FormControl, TableCell, styled } from '@mui/material';
import { Box } from '@mui/system';

export const MainBox = styled(Box)({
    display: 'flex',
    width: '100%',
    gap: '1rem',
    flexDirection: 'column',
    alignItems: 'center'
});

export const MainContainer = styled(Box)({
    width: '80%',
    display: 'flex',
    flexDirection: 'column'
});

export const CamposContainer = styled(Box)({
    display: 'flex',
    gap: '1rem',
    flexDirection: 'column',
    width: '100%'
});

export const CamposBox = styled(Box)({
    width: '75%',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '1rem'
});

export const CamposEspecificosContainer = styled(Box)({
    display: 'flex',
    gap: '1rem',
    width: '100%',
    flexDirection: 'column',
    alignContent: 'space-between',
    marginTop: '1rem',
    paddingTop: '1rem',
    borderTop: '2px dashed #b3b3b3'
});

export const CamposEspecificosBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column'
});

export const CamposEspecificosFirstLine = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    gap: '1rem'
});

export const CamposEspecificosSecondLine = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '1rem'
});

export const CamposEspecificosSecondLineBox = styled(Box)({
    display: 'flex',
    gap: '.5rem',
    alignItems: 'center',
    marginRight: '1rem'
});

export const ButtonBox = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-end'
});
