import { TableCell, styled } from '@mui/material';
import { Box, Typography, Button } from '@mui/material';

export const OrderButton = styled(TableCell)({
    cursor: 'pointer',
    transition: 'all .15s ease',

    b: {
        marginRight: '1em'
    },
    '&:hover': {
        backgroundColor: 'rgba(39, 157, 245, 0.52)'
    }
});

export const OrderNameButton = styled(TableCell)({
    cursor: 'pointer',
    transition: 'all .15s ease',

    b: {
        marginRight: '1em'
    },
    '&:hover': {
        backgroundColor: 'rgba(39, 157, 245, 0.52)'
    }
});
