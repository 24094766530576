import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, TextField, Typography, Box, InputLabel, Select, MenuItem, ButtonGroup, FormLabel } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { Outlet } from 'react-router-dom';

import Logo from '../../assets/images/Logos/logo.png';
import Logo2 from '../../assets/logobw2.png';

const TriagemLayout = () => {
    return (
        <MainCard>
            <Grid container spacing={gridSpacing}>
                <Box
                    style={{
                        pageBreakInside: 'avoid',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%'
                    }}
                >
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 30px' }}>
                        <div style={{ display: 'flex', alignItems: 'center', marginRight: 'auto', marginLeft: '1em' }}>
                            <img src={Logo} alt="logo Santa Casa" style={{ width: '70px', height: 'auto', marginRight: '10px' }} />
                            <h3>SANTA CASA DE MISERICÓRDIA DE JOANÓPOLIS</h3>
                        </div>
                        <div>
                            <img src={Logo2} alt="logo Santa Casa" style={{ width: '210px', marginRight: '10px', marginTop: '10px' }} />
                        </div>
                    </Box>

                    <Outlet />
                </Box>
            </Grid>
        </MainCard>
    );
};

export default TriagemLayout;
