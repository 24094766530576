import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { ConjuntoLaudoContext } from 'context/LaudoContext';
import { useContext } from 'react';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import formateDate from 'utils/formatDate-1';
import { useState } from 'react';
import Logo from '../../../../../assets/images/Logos/LOGO_DO_LAC.jpg';
import SantaCasaLogo from '../../../../../assets/images/Logos/logo2.png';
import { display, fontSize } from '@mui/system';
import BwLogo from '../../../../../assets/BW TECNOLOGIA.png';

const PdfViwerSpecial = ({ currentExam }) => {
    console.log('🚀 ~ PdfViwerSpecial ~ currentExam:', currentExam);
    const [patient, setPatient] = useState({});
    const [medico, setMedico] = useState({});
    const [allExams, setAllExams] = useState([]);
    const [campoAgrupados, setCamposAgrupados] = useState([]);
    const api = useApi();
    const styles = {
        page: {
            paddingTop: 15,
            paddingBottom: 15,
            paddingHorizontal: 15,
            backgroundColor: '#fff',
            fontSize: 8,
            fontWeight: 'normal',
            rap: 'wrap',
            position: 'relative'
        },
        header: {
            padding: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        },
        image: {
            width: 100,
            backgroundSize: 'cover'
        },
        text1: {
            fontSize: 8
        },
        box: {
            border: '1px solid #ddd',
            padding: 10,

            borderRadius: 5
        },
        between: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        labelArea: {
            backgroundColor: '#ccc',
            padding: '8px',
            borderRadius: 5,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        row: { flexDirection: 'row', borderBottom: '1 solid #000' },
        cell: { flexGrow: 1, padding: 5 },
        fixed: {
            bottom: 0,
            left: 0,
            width: '100%',
            padding: 10,
            position: 'absolute',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
        }
    };

    const getExameData = async () => {
        try {
            const { data } = await api.getAllExameData(
                currentExam?.prontuario_id,
                currentExam?.exame_prontuario?.exames_especiai?.id,
                currentExam?.exame_prontuario.id
            );
            console.log('🚀 ~ getExameData ~ data:', data);

            setPatient(data?.paciente);
            setMedico(data?.medico);
            setAllExams(data);

            const camposAgrupados = {};

            data?.resultado_campo_especifico.forEach((campoEspecifico) => {
                const categoria = campoEspecifico?.campos_exames_especiai?.categoria;
                const resultado = campoEspecifico.valor;
                if (!camposAgrupados[categoria]) {
                    camposAgrupados[categoria] = [];
                }
                camposAgrupados[categoria].push({
                    ...campoEspecifico,
                    valor: resultado
                });
            });

            const categoriasArray = Object.entries(camposAgrupados).map(([categoria, campos]) => ({
                categoria,
                campos: campos.sort((a, b) => a.id - b.id)
            }));
            console.log('🚀 ~ categoriasArray ~ categoriasArray:', categoriasArray);

            setCamposAgrupados(categoriasArray);
        } catch (error) {
            console.log('🚀 ~ getExameData ~ error:', error);
        }
    };

    useEffect(() => {
        getExameData();
    }, [currentExam]);

    const formatarData = (date) => {
        const dateObj = new Date(date);
        const year = dateObj.getUTCFullYear();
        const month = ('0' + (dateObj.getUTCMonth() + 1)).slice(-2);
        const day = ('0' + dateObj.getUTCDate()).slice(-2);
        return `${day}/${month}/${year}`;
    };

    const getCurrentDateTime = () => {
        const now = new Date();
        const date = now.toLocaleDateString('pt-BR'); // Formato DD/MM/YYYY
        const time = now.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' }); // Formato HH:MM
        return { date, time };
    };

    const { date, time } = getCurrentDateTime();

    function DinamicTable({ dados }) {
        console.log('🚀 ~ DinamicTable ~ dados:', dados);
        const styles = {
            table: { width: '100%', borderCollapse: 'collapse', margin: '10px' },
            cell: { borderBottom: '1px solid #eee', padding: '5px', wordWrap: 'break-word', display: 'flex', justifyContent: 'center' }
        };

        return (
            <View>
                <View style={styles.table}>
                    <View style={{ flexDirection: 'row' }}>
                        <View style={{ ...styles.cell, width: '25%' }}>
                            <Text>NOME CAMPO</Text>
                        </View>
                        <View style={{ ...styles.cell, width: '20%' }}>
                            <Text>VALORES OBTIDOS</Text>
                        </View>

                        <View style={{ ...styles.cell, width: '15%' }}>
                            <Text>UNIDADE</Text>
                        </View>
                        <View style={{ ...styles.cell, width: '40%' }}>
                            <Text>VALORES REFERÊNCIA</Text>
                        </View>
                    </View>

                    {dados?.map((item, index) => (
                        <View key={index} style={{ flexDirection: 'row' }}>
                            <View style={{ ...styles.cell, width: '25%' }}>
                                <Text>{item?.campos_exames_especiai?.campo_nome}</Text>
                            </View>
                            <View style={{ ...styles.cell, width: '20%' }}>
                                <Text>{item?.porcentagem ? `${item?.porcentagem} %` : item.valor}</Text>
                            </View>
                            <View style={{ ...styles.cell, width: '15%' }}>
                                <Text>{item?.porcentagem ? `${item.valor}  MM³` : item?.campos_exames_especiai?.unidade}</Text>
                            </View>
                            <View style={{ ...styles.cell, width: '40%' }}>
                                <Text>{item?.campos_exames_especiai?.valores ? item?.campos_exames_especiai?.valores : '-'}</Text>
                            </View>
                        </View>
                    ))}
                </View>
            </View>
        );
    }

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.header}>
                    <Image src={Logo} style={styles.image} alt="logo" />
                    <Text>LABORATÓRIO DE ANÁLISES CLÍNICAS SANTA CASA DE MISERICÓRDIA DE JOANÓPOLIS</Text>
                </View>
                <View style={{ borderBottom: '1px solid #eee', marginTop: '5px' }} />
                <View style={{ marginTop: '15px', marginBottom: '20px' }}>
                    <View style={{ display: 'flex', justifyContent: 'space-between', fontSize: 8 }}>
                        <View>
                            <Text>Paciente: {patient?.nome_completo}</Text>
                            <Text>Data Nascimento: {formatarData(patient?.data_nascimento)}</Text>
                            <View style={styles.between}>
                                <Text>Médico Solicitante: {medico?.nome_completo}</Text>
                                <Text>CRM: {medico?.crm_coren}</Text>
                            </View>
                            <Text>Data e hora Laudo: {patient?.hora_laudo}</Text>
                        </View>
                    </View>
                </View>
                <View>
                    <Text style={{ textAlign: 'center', marginBottom: '10px' }}>{currentExam?.exame}</Text>
                    <View style={styles.between}>
                        <Text>Método: {currentExam?.exame_prontuario.exames_especiai.metodo}</Text>
                        <Text>Sigla: {currentExam?.exame_prontuario.exames_especiai.sigla}</Text>
                    </View>
                </View>
                {campoAgrupados.map((item) => {
                    return (
                        <View>
                            <Text style={{ textAlign: 'center' }}>{item?.categoria}</Text>

                            <View>
                                <DinamicTable dados={item?.campos} />
                            </View>
                        </View>
                    );
                })}
                <View style={{ display: 'flex', alignItems: 'flex-end', marginTop: '50px' }}>
                    <View>
                        <View style={{ width: '200px', borderTop: '1px solid #eee', textAlign: 'center' }}>
                            <Text>Responsável</Text>
                        </View>
                    </View>
                </View>

                <View fixed style={styles.fixed}>
                    <View
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '50%',
                            justifyContent: 'flex-start',
                            alignItems: 'center'
                        }}
                    >
                        <Image fixed src={SantaCasaLogo} alt="Santa Casa Logotipo" style={{ width: '30px' }} />
                        <View
                            fixed
                            style={{
                                padding: '20px',
                                gap: '5px',
                                fontSize: 5
                            }}
                        >
                            <Text>SANTA CASA DE MISERICÓRDIA DE JOANÓPOLIS</Text>
                            <Text>RUA FRANCISCO WOLHERS N°57 - CENTRO - JOANÓPOLIS - SP</Text>
                            <Text>CEP: 12980-000 - TELEFONE: (11) 4539-9333</Text>
                        </View>
                    </View>
                    <Image style={{ display: 'flex', width: '15%', justifyContent: 'flex-end', alignItems: 'center' }} src={BwLogo} />
                </View>
            </Page>
        </Document>
    );
};

export default PdfViwerSpecial;
