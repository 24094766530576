import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip
} from '@mui/material';
import React from 'react';
import { OrderButton } from './style';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import formateDate from 'utils/formatDate-1';
import { IconFileInvoice, IconFolder, IconAlertTriangle } from '@tabler/icons';
import { useState } from 'react';

const TabelaTriagemConsulta = ({ allFichas, page, rowsPerPage }) => {
    function calcularIdade(dataNascimento) {
        const dataNascimentoDate = new Date(dataNascimento);
        const agora = new Date();

        let idade = agora.getFullYear() - dataNascimentoDate.getFullYear();
        const mesAtual = agora.getMonth();
        const diaAtual = agora.getDate();
        const mesNascimento = dataNascimentoDate.getMonth();
        const diaNascimento = dataNascimentoDate.getDate();

        if (mesAtual < mesNascimento || (mesAtual === mesNascimento && diaAtual < diaNascimento)) {
            idade--;
        }

        return `${idade} anos`;
    }
    return (
        <TableContainer component={Paper} sx={{ maxHeight: '580px' }}>
            <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">
                            <b>F.A</b>
                        </TableCell>

                        <TableCell align="center">
                            <b>Paciente</b>
                        </TableCell>

                        <TableCell align="center">
                            <b>Idade</b>
                        </TableCell>

                        <TableCell align="center">
                            <b>Data</b>
                        </TableCell>

                        <TableCell align="center">
                            <b>Hora</b>
                        </TableCell>

                        <TableCell align="center">
                            <b>Status</b>
                        </TableCell>

                        <TableCell align="center">
                            <b>Ações</b>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {allFichas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
                        <TableRow key={item?.id}>
                            <TableCell>{item?.prontuario?.id}</TableCell>
                            <TableCell align="center">{item?.prontuario?.paciente?.nome_completo}</TableCell>
                            <TableCell align="center">{calcularIdade(item?.prontuario?.paciente?.data_nascimento)}</TableCell>
                            <TableCell align="center">{formateDate(item?.prontuario?.data_atendimento)}</TableCell>
                            <TableCell align="center">{item?.prontuario?.hora_atendimento}</TableCell>
                            <TableCell align="center">ENCERRADO</TableCell>
                            <TableCell align="center">
                                <Link to={`/main/laboratorio/triagem/${item?.id}`}>
                                    <Tooltip title="Ficha Triagem">
                                        <IconButton>
                                            <IconFolder />
                                        </IconButton>
                                    </Tooltip>
                                </Link>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TabelaTriagemConsulta;
