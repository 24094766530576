import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { InputArea } from './styles';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Tooltip } from '@mui/material';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import InternalModal from '../InternalModal';
import DeleteModal from '../DeleteModal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem'
};

export function AddTipoProcedimento({ get, open, setOpen }) {
    const api = useApi();
    const [allTipos, setAllTipos] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [currentTipo, setCurrentTipo] = useState(null);
    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.5 },
        { field: 'descricao', headerName: 'Descrição', flex: 1 },
        {
            field: 'actions',
            headerName: 'Ações',
            flex: 0.5,
            renderCell: (params) => (
                <Box>
                    <Tooltip title="Editar">
                        <IconButton
                            onClick={() => {
                                setCurrentTipo(params.row);
                                setOpenDialog(true);
                            }}
                        >
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Deletar">
                        <IconButton
                            onClick={() => {
                                setCurrentTipo(params.row);
                                setOpenDelete(true);
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            )
        }
    ];
    const getTipos = async () => {
        try {
            const { data } = await api.getAllTipoProcedimento();
            const dadosFormatados = data.map((tipo) => ({ id: tipo.id, descricao: tipo.descricao }));
            setAllTipos(dadosFormatados);
        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleOpenDialog = () => {
        setCurrentTipo({ descricao: '' });
        setOpenDialog(true);
    };

    const handleChangeInput = (data) => {
        setCurrentTipo({ ...currentTipo, descricao: data });
    };

    const handleClose = () => {
        setOpen(false);
    };

    const resetStatesAndGet = () => {
        get();
        getTipos();
        setCurrentTipo(null);
        setOpenDialog(false);
        setOpenDelete(false);
    };

    const handleDeleteTipo = async (e) => {
        e.preventDefault();
        try {
            await api.deleteTipoProcedimento(currentTipo?.id);
            resetStatesAndGet();
            toast.success('Tipo de Procedimento deletado com sucesso!');
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error.message);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (currentTipo?.descricao?.trim().length == 0) {
            return toast.warning('A descrição não pode ser vazia');
        }
        try {
            await api.createTipoProcedimento(currentTipo?.descricao);
            resetStatesAndGet();
            toast.success('Tipo de Procedimento cadastrado com sucesso!');
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error.message);
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        if (currentTipo?.descricao.trim().length == 0) {
            return toast.warning('A descrição não pode ser vazia');
        }
        try {
            await api.updateTipoProcedimento(currentTipo?.id, currentTipo?.descricao);
            resetStatesAndGet();
            toast.success('Tipo de Procedimento atualizado com sucesso!');
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error.message);
        }
    };

    useEffect(() => {
        getTipos();
    }, []);

    return (
        <div>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <DeleteModal openDelete={openDelete} setOpenDelete={setOpenDelete} handleDeleteTipo={handleDeleteTipo} />
                    <InternalModal
                        openDialog={openDialog}
                        setOpenDialog={setOpenDialog}
                        currentTipo={currentTipo}
                        handleChangeInput={handleChangeInput}
                        handleSubmit={handleSubmit}
                        handleUpdate={handleUpdate}
                    />
                    <Button sx={{ width: '30%' }} variant="contained" onClick={handleOpenDialog}>
                        Criar novo tipo
                    </Button>
                    <DataGrid rows={allTipos} columns={columns} />
                </Box>
            </Modal>
        </div>
    );
}
