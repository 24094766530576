import { Box, Divider } from '@mui/material';
import { IconListDetails, IconStethoscope, IconUsers, IconVaccine, IconVaccineBottle } from '@tabler/icons';
import { useState } from 'react';
import { useEffect } from 'react';
import { useApi } from 'Service/axios';
import UserCountCard from 'ui-component/UserCountCard';

const NursingCards = ({ medicamentoLenght, laboratorioLenght }) => {
    const api = useApi();

    const [totalAtendimentos, setTotalAtendimentos] = useState([]);
    const [totalProcedimentos, setTotalProcedimentos] = useState([]);

    async function getEnfermagem() {
        try {
            const { data } = await api.getTotalAtendimentosDia();
            setTotalAtendimentos(data?.total_prontuarios);
            const response = await api.getTotalPEDia();
            setTotalProcedimentos(response?.data?.total_prontuarios);
        } catch (error) {
            console.log('🚀 ~ getEnfermagem ~ error:', error);
        }
    }

    useEffect(() => {
        getEnfermagem();
    }, []);

    return (
        <Box sx={{ padding: '0 1rem' }}>
            <Box>
                <h1 style={{ textAlign: 'center' }}>PAINEL DE ENFERMAGEM</h1>
                <Divider />
            </Box>

            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '1rem', marginTop: '5rem' }}>
                <UserCountCard color={'#2196f3'} primary={totalAtendimentos} secondary={'ATENDIMENTOS'} iconPrimary={IconUsers} />
                <UserCountCard color={'#2196f3'} primary={medicamentoLenght} secondary={'MEDICAÇÕES'} iconPrimary={IconVaccineBottle} />
                <UserCountCard color={'#2196f3'} primary={laboratorioLenght} secondary={'LABORATÓRIO'} iconPrimary={IconStethoscope} />
                <UserCountCard color={'#2196f3'} primary={totalProcedimentos} secondary={'PROCEDIMENTOS'} iconPrimary={IconListDetails} />
            </Box>
        </Box>
    );
};

export default NursingCards;
