import MainRelatorio from 'layout/RelatorioLayout/Main';
import { element } from 'prop-types';
import Entrada from 'views/Relatorios/Entrada';
import LaudoExame from 'views/Relatorios/LaudoExame';
import FichaBancada from 'views/Relatorios/fichaBancada';
import FichaBancada2 from 'views/Relatorios/fichaBancada2';
import ConjuntoLaudos from 'views/Relatorios/ConjuntoLaudos';
import EntradaNovaVersao from 'views/Relatorios/EntradaNovaVersao';
import Comparecimento from 'views/Relatorios/Comparecimento';
import RelatorioEstoqueGeral from 'views/Relatorios/Estoque/EstoqueGeral';
import RelatorioPorPeriodo from 'views/Relatorios/Estoque/SolicitadosPorPeriodo';
import RelatorioEstoqueGeralSimples from 'views/Relatorios/Estoque/EstoqueGeralSimples';
import RelatorioPacientePorPeriodo from 'views/Relatorios/Estoque/SolicitadosPorPeriodo';
import EntradaExterna from 'views/Relatorios/EntradaExterna';
import RelatorioTriagem from 'views/Laboratorio/Triagem/Relatorio';
import { AtestadoRelatorio } from 'views/Relatorios/Atestado';

const RelatoriosRoutes = {
    path: '/relatorios',
    element: <MainRelatorio />,
    children: [
        {
            path: '/relatorios/entrada/:id/numero/:numero',
            element: <Entrada />
        },
        {
            path: '/relatorios/comparecimento',
            element: <Comparecimento />
        },
        {
            path: '/relatorios/entrada2/:id/numero/:numero',
            element: <EntradaNovaVersao />
        },
        {
            path: '/relatorios/triagem/:id/:numero/:numero',
            element: <RelatorioTriagem />
        },
        {
            path: '/relatorios/entrada3/:id/numero/:numero',
            element: <EntradaExterna />
        },
        {
            path: '/relatorios/fichaBancada/:id/numero/:numero/prontuario/:prontuarioId/:idExameProntuario',
            element: <FichaBancada />
        },
        {
            path: '/relatorios/AllFichaBancada/numero/:numero/prontuario/:prontuarioId',
            element: <FichaBancada2 />
        },
        {
            path: '/relatorios/resultado/:prontuarioId/:exameId',
            element: <LaudoExame />
        },
        {
            path: '/relatorios/estoque-geral/:value/:id',
            element: <RelatorioEstoqueGeral />
        },
        {
            path: '/relatorios/estoque-geral-simples/:id',
            element: <RelatorioEstoqueGeralSimples />
        },
        {
            path: '/relatorios/movimentacao-periodo',
            element: <RelatorioPorPeriodo />
        },
        {
            path: '/relatorios/movimentacao-periodo-paciente/',
            element: <RelatorioPacientePorPeriodo />
        },
        {
            path: '/relatorios/atestado',
            element: <AtestadoRelatorio />
        }
    ]
};

export default RelatoriosRoutes;
