import { TableCell, styled } from '@mui/material';
import { Box, Typography, Button } from '@mui/material';
import { styleds } from '@emotion/styled';

// ===================HEADER===================
export const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginLeft: '1%',
    border: '2px solid #000'
});

export const HeaderBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    margin: '0 auto',
    borderTop: '2px solid #000'
});

export const Title = styled(Typography)({
    fontSize: '1em'
});

export const Mid = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: '40px',
    margin: '0 auto',
    border: '1px solid #000'
});

export const Body = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    border: '1px solid #181818'
});

export const CampoContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'start',
    minWidth: '99%',
    margin: '0 auto'
});

export const Campo = styled(Box)({
    width: '100%',
    height: '25px',
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    borderTop: '1px solid #181818',
    borderBottom: '1px solid #181818',
    fontSize: '15px'
});
