import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Drawer,
    FormControl,
    InputLabel,
    OutlinedInput,
    Tooltip,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { Box } from '@mui/system';
import { v4 as uuidv4 } from 'uuid';
import CloseIcon from '@mui/icons-material/Close';

const CreateAlergia = ({ open, handleClose, setItens, removerCampoItem, indiceCampoItem }) => {
    /////////////////////////////// STATES /////////////////////////
    const [openDial, setOpenDialog] = useState();
    const [alergia, setAlergia] = useState([]);

    /////////////////////////////// FUNÇÕES /////////////////////////

    const handleSubmit = (e) => {
        e.preventDefault();
        setItens((prevItens) => [...prevItens, { id: uuidv4(), alergia }]);
        setAlergia('');
    };

    return (
        <Box>
            <Dialog open={openDial}>
                <DialogTitle>Atenção</DialogTitle>
                <DialogContent>
                    <Typography>Ao fechar todo o cadastro desse item sera cancelado, continuar?</Typography>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ display: 'flex' }}>
                        <Button color="error" onClick={() => setOpenDialog(false)}>
                            Cancelar
                        </Button>
                        <Button onClick={() => removerCampoItem(0, indiceCampoItem)}>Confirmar</Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <Drawer anchor="right" open={open} style={{ maxHeight: '350px' }}>
                <Box sx={{ width: '650px', padding: '.75em', height: '100%', marginTop: '10px' }}>
                    <Box>
                        <Box sx={{ width: '100%', display: 'flex', gap: '1em' }}>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="outlined-adornment-nome-register">ALERGIA</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-nome-register"
                                    type="text"
                                    name="alergia"
                                    sx={{ width: '100%', marginTop: '1em' }}
                                    value={alergia}
                                    onChange={(e) => setAlergia(e.target.value)}
                                />
                            </FormControl>
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '2em', gap: '1em' }}>
                            <Tooltip title="Fechar">
                                <Button variant="contained" color="error" onClick={() => handleClose()}>
                                    <CloseIcon />
                                </Button>
                            </Tooltip>
                            <form onSubmit={handleSubmit}>
                                <Button variant="contained" type="submit">
                                    Salvar
                                </Button>
                            </form>
                        </Box>
                    </Box>
                </Box>
            </Drawer>
        </Box>
    );
};

export default CreateAlergia;
