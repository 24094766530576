import { Card, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import React from 'react';
import { Box } from '@mui/system';

const Aguardando = ({ aguardandoQTD }) => {
    return (
        <Card
            sx={{
                background: '#2196f3',
                color: '#fff',
                padding: '1rem',
                minWidth: '250px',
                '&:hover svg': {
                    opacity: '1',
                    transform: 'scale(1.1)'
                },
                height: '65px',
                display: 'flex',
                flexDirection: 'row-reverse',
                alignItems: 'center',
                justifyContent: 'space-around'
            }}
        >
            <Box
                sx={{
                    color: '#fff',
                    marginLeft: '1rem',
                    '&> svg': {
                        width: 40,
                        height: 40,
                        opacity: '0.4',
                        transition: 'all .3s ease-in-out'
                    }
                }}
            >
                <PersonIcon />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h2" color="inherit">
                    {aguardandoQTD}
                </Typography>

                <Typography variant="subtitle2" color="inherit">
                    Aguardando
                </Typography>
            </Box>
        </Card>
    );
};

export default Aguardando;
