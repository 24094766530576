import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip
} from '@mui/material';
import React from 'react';
import { OrderButton } from './style';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import Row from './Row';
import { useState } from 'react';

const UnidadeTabela = ({ allUnidade, setAllUnidade, selectedUnidade, getUnidades, page, rowsPerPage, handleClickOpenEdit }) => {
    const [orderById, setOrderById] = useState('desc');
    const [orderByNome, setOrderByNome] = useState('desc');
    const [orderBySigla, setOrderBySigla] = useState('desc');

    const handleSortById = () => {
        const sortedUnidade = [...allUnidade].sort((a, b) => {
            if (orderById === 'desc') {
                return b.id - a.id;
            } else {
                return a.id - b.id;
            }
        });

        setAllUnidade(sortedUnidade);
        setOrderById(orderById === 'asc' ? 'desc' : 'asc');
    };

    const handleSortByNome = () => {
        const sortedUnidade = [...allUnidade].sort((a, b) => {
            const nomeA = a.descricao.toUpperCase();
            const nomeB = b.descricao.toUpperCase();

            if (orderByNome === 'desc') {
                if (nomeA < nomeB) return -1;
                if (nomeA > nomeB) return 1;
            } else {
                if (nomeA > nomeB) return -1;
                if (nomeA < nomeB) return 1;
            }

            return 0;
        });

        setAllUnidade(sortedUnidade);
        setOrderByNome(orderByNome === 'asc' ? 'desc' : 'asc');
    };

    const handleSortBySigla = () => {
        const sortedUnidade = [...allUnidade].sort((a, b) => {
            const nomeA = a.sigla.toUpperCase();
            const nomeB = b.sigla.toUpperCase();

            if (orderBySigla === 'desc') {
                if (nomeA < nomeB) return -1;
                if (nomeA > nomeB) return 1;
            } else {
                if (nomeA > nomeB) return -1;
                if (nomeA < nomeB) return 1;
            }

            return 0;
        });

        setAllUnidade(sortedUnidade);
        setOrderBySigla(orderBySigla === 'asc' ? 'desc' : 'asc');
    };

    return (
        <TableContainer
            component={Paper}
            sx={{
                maxHeight: '300px'
            }}
        >
            <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
                <TableHead>
                    <TableRow>
                        <OrderButton>
                            <TableSortLabel onClick={handleSortById} direction={orderById === 'asc' ? 'desc' : 'asc'}>
                                <b>ID</b>
                            </TableSortLabel>
                        </OrderButton>
                        <OrderButton align="center">
                            <TableSortLabel onClick={handleSortByNome} direction={orderByNome === 'asc' ? 'desc' : 'asc'}>
                                <b>Descricao</b>
                            </TableSortLabel>
                        </OrderButton>
                        <OrderButton align="center">
                            <TableSortLabel onClick={handleSortBySigla} direction={orderBySigla === 'asc' ? 'desc' : 'asc'}>
                                <b>Sigla</b>
                            </TableSortLabel>
                        </OrderButton>
                        <TableCell align="center">
                            <b>Ações</b>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {allUnidade.length > 0 &&
                        allUnidade
                            .filter((unidade) => (selectedUnidade ? unidade.descricao.includes(selectedUnidade.descricao) : true))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((item) => (
                                <Row key={item.id} item={item} getUnidades={getUnidades} handleClickOpenEdit={handleClickOpenEdit} />
                            ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default UnidadeTabela;
