import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { ConjuntoLaudoContext } from 'context/LaudoContext';
import { useContext } from 'react';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import formateDate from 'utils/formatDate-1';
import { useState } from 'react';
import SantaCasaLogo from '../../../../assets/images/Logos/logo2.png';
import BwLogo from '../../../../assets/BW TECNOLOGIA.png';

import Logo from '../../../../assets/images/Logos/LOGO_DO_LAC.jpg';
import { fontSize, Box } from '@mui/system';
Font.register({
    family: 'Oswald',
    fontWeight: 900,
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

const PdfPorPacientePorExames = ({ data }) => {
    const [pageNumber, setPageNumber] = useState(1);
    const styles = {
        page: {
            paddingTop: 15,
            paddingBottom: 15,
            paddingHorizontal: 35,
            backgroundColor: '#fff',
            fontSize: 10,

            rap: 'wrap'
        },
        header: {
            padding: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        },
        image: {
            width: 100,
            backgroundSize: 'cover'
        },
        text1: {
            fontSize: 10
        },
        box: {
            border: '1px solid #ddd',
            padding: 10,

            borderRadius: 5
        },
        between: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        labelArea: {
            backgroundColor: '#ccc',
            padding: '8px',
            borderRadius: 5,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },

        row: { flexDirection: 'row', borderBottom: '1 solid #000' },
        cell: { flexGrow: 1, padding: 5 }
    };

    const formatarData = (date) => {
        const dateObj = new Date(date);
        const year = dateObj.getUTCFullYear();
        const month = ('0' + (dateObj.getUTCMonth() + 1)).slice(-2);
        const day = ('0' + dateObj.getUTCDate()).slice(-2);
        return `${day}/${month}/${year}`;
    };
    const splitDateAndTime = (dateTimeString) => {
        const [date, time] = dateTimeString.split(' ');
        return { date, time };
    };

    // COMPONENTE DA TABELA

    function DinamicTable({ dados }) {
        const styles = {
            table: { width: '100%', borderCollapse: 'collapse', margin: '5px' },
            cell: { borderBottom: '1px solid #999', padding: '5px', wordWrap: 'break-word' }
        };
        const examesAgrupados = dados.reduce((agrupados, item) => {
            const exames = item?.exame?.nome || item.exames_especiai?.nome;

            if (agrupados[exames]) {
                agrupados[exames].push(item);
            } else {
                agrupados[exames] = [item];
            }

            return agrupados;
        }, {});

        return (
            <View>
                {Object.keys(examesAgrupados).map((exameId, index) => (
                    <View
                        key={index}
                        style={{
                            display: 'flex',

                            flexDirection: 'column',

                            alignItems: 'center'
                        }}
                    >
                        <View key={index} style={{ width: '80%', marginTop: '15px', alignItems: 'center', justifyContent: 'center' }}>
                            <Text>
                                <Text style={{ fontSize: 12 }}>EXAME:</Text>
                                <Text style={{ width: '80%', color: '#000000d2', fontSize: 12 }}> {exameId}</Text>
                            </Text>
                        </View>

                        <View
                            style={{
                                top: '20px',
                                display: 'flex',
                                flexDirection: 'row',

                                marginBottom: '15px'
                            }}
                        >
                            <View style={{ display: 'flex', flexDirection: 'column', width: '19%', paddingLeft: '36px', fontSize: 12 }}>
                                <Text>CODIGO</Text>
                            </View>
                            <View style={{ display: 'flex', flexDirection: 'column', width: '40.5%', fontSize: 12, paddingRight: '35px' }}>
                                <Text>PACIENTE</Text>
                            </View>

                            <View style={{ display: 'flex', flexDirection: 'column', width: '27%', paddingLeft: '108px', fontSize: 12 }}>
                                <Text>DATA</Text>
                            </View>
                            <View style={{ display: 'flex', flexDirection: 'column', width: '27%', paddingLeft: '50px', fontSize: 12 }}>
                                <Text>HORARIO</Text>
                            </View>
                        </View>
                        <View style={{ border: '1px solid #000000', width: '100%', marginTop: '14px' }}></View>
                        {examesAgrupados[exameId].map((exameId, index) => (
                            <>
                                <View
                                    key={exameId?.exame?.id}
                                    style={{ width: '100%', color: '#000000d2', fontSize: 9, paddingBottom: '0px' }}
                                >
                                    <View
                                        style={{
                                            width: '80%',

                                            display: 'flex',
                                            flexDirection: 'row',
                                            paddingTop: '5px'
                                        }}
                                    >
                                        <View
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                width: '4.5%',
                                                paddingLeft: '16px'
                                            }}
                                        >
                                            <Text>{exameId?.id}</Text>
                                        </View>
                                        <View
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'start',
                                                alignItems: 'flex-start',
                                                width: '95%',
                                                paddingLeft: '47px'
                                            }}
                                        >
                                            <Text>{exameId?.prontuario?.paciente?.nome_completo}</Text>
                                        </View>

                                        <View
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                width: '10%',
                                                top: '-11px',
                                                paddingLeft: '55px'
                                            }}
                                        >
                                            <Text> {splitDateAndTime(exameId?.data_execucao).date}</Text>
                                        </View>
                                        <View style={{ display: 'flex', flexDirection: 'column', left: '85px', width: '10%' }}>
                                            <Text>{splitDateAndTime(exameId?.data_execucao).time}</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ border: '0.5px solid #999', width: '100%' }}></View>
                            </>
                        ))}
                        <Text style={{ marginTop: '10px', paddingLeft: '75%', fontSize: 10 }}>
                            TOTAL: {examesAgrupados[exameId].length}
                        </Text>
                    </View>
                ))}
            </View>
        );
    }

    return (
        <Document>
            <>
                <Page size="A4" style={styles.page}>
                    <View style={styles.header}>
                        <Image src={Logo} style={styles.image} alt="logo" />
                        <Text>LABORATÓRIO DE ANÁLISES CLÍNICAS SANTA CASA DE MISERICÓRDIA DE JOANÓPOLIS</Text>
                    </View>

                    <View style={styles.box}>
                        <View style={styles.labelArea}>
                            <View style={{ gap: '5px' }}>
                                <Text>RELATÓRIO DE EXAMES POR PERÍODO PACIENTE</Text>

                                <Text>Data Inicio: {formatarData(data.dataDate?.firstDate)}</Text>

                                <Text>
                                    Data Final: <Text>{formatarData(data.dataDate?.lastDate)}</Text>
                                </Text>
                            </View>
                            <View>
                                <Text>
                                    Filtro:
                                    {data?.filtro === 'todos'
                                        ? 'TODOS'
                                        : data?.dataPdf?.length > 0 &&
                                          (data.dataPdf[0]?.exame?.nome || data.dataPdf[0]?.exames_especiai?.nome)}
                                </Text>
                            </View>
                        </View>
                    </View>
                    <View>
                        <DinamicTable dados={data?.dataPdf} />
                    </View>
                    <Text style={{ fontSize: 10 }}>TOTAL GERAL:{data?.dataPdf.length}</Text>
                    <View
                        fixed
                        style={{
                            width: '100%',
                            maxHeight: '8%',
                            display: 'flex',
                            padding: '10px',
                            gap: '150px',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <View
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '50%',
                                justifyContent: 'flex-start',
                                alignItems: 'center'
                            }}
                        >
                            <Image fixed src={SantaCasaLogo} alt="Santa Casa Logotipo" style={{ width: '30px' }} />
                            <View
                                fixed
                                style={{
                                    padding: '1.5em',
                                    gap: '5px',
                                    fontSize: 5
                                }}
                            >
                                <Text>SANTA CASA DE MISERICÓRDIA DE JOANÓPOLIS</Text>
                                <Text>RUA FRANCISCO WOLHERS N°57 - CENTRO - JOANÓPOLIS - SP</Text>
                                <Text>CEP: 12980-000 - TELEFONE: (11) 4539-9333</Text>
                            </View>
                        </View>
                        <Image style={{ display: 'flex', width: '15%', justifyContent: 'flex-end', alignItems: 'center' }} src={BwLogo} />
                    </View>
                    <Text
                        style={{ marginLeft: '88%', paddingBottom: '10px', paddingTop: '10px' }}
                        fixed
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                    />
                </Page>
            </>
        </Document>
    );
};

export default PdfPorPacientePorExames;
