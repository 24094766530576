import MainCard from 'ui-component/cards/MainCard';
import {
    Grid,
    TextField,
    Box,
    Typography,
    Tooltip,
    Button,
    Divider,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useApi } from 'Service/axios';
import { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CreateMedicacaoTriagem from '../CreateMedicacaoUso';
import CreateAlergia from '../CreateAlergia';
import { useNavigate, useParams } from 'react-router-dom';
import ActionButtonsDeleteAlergia from '../CreateAlergia/ActionButtonsDeleteAlergia';
import ActionButtonsDeleteMedicacao from '../CreateMedicacaoUso/ActionButtonsDeleteMedicacao';
import { toast } from 'react-toastify';
import InputMask from 'react-input-mask';
import { v4 as uuidv4 } from 'uuid';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { IconLungs, IconArrowAutofitHeight, IconThermometer, IconPercentage } from '@tabler/icons';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import MonitorWeightIcon from '@mui/icons-material/MonitorWeight';
import { removeMask } from 'utils/removeMask';
import CalculateIcon from '@mui/icons-material/Calculate';
import CloseIcon from '@mui/icons-material/Close';

const initialState = {
    itens: []
};

const FichaTriagem = ({ ...others }) => {
    const api = useApi();
    const navigate = useNavigate();

    const { id, ficha } = useParams();
    // ============================= STATES =============================
    const [itensMedicacao, setItensMedicacao] = useState([]);
    const [itensValue, setItensValue] = useState({
        id_prontuario: '',
        pa_sis: '',
        pa_dia: '',
        fc: '',
        fr: '',
        dextro: '',
        peso: '',
        altura: '',
        temperatura: '',
        saturacao: '',
        medicacao_em_uso: '',
        alergia: '',
        queixa: '',
        imc: 0
    });
    const [campos, setCampos] = useState([initialState]);
    const [itensAlergia, setItensAlergia] = useState([]);
    const [openModalMedicamentos, setOpenModalMedicamentos] = useState(false);
    const [openModalAlergia, setOpenModalAlergia] = useState(false);
    const [paciente, setPaciente] = useState(null);
    const [imc, setImc] = useState(0);
    // ============================= FUNÇÕES =============================

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        // Verifica se o valor inserido tem mais de 2 dígitos
        if (name === 'queixa') {
            setItensValue({ ...itensValue, [name]: value });
        }
        if (name === 'pa_sis' || name === 'fc' || name === 'pa_dia' || name === 'dextro') {
            if (value.length > 3) return;
            const truncatedValue = value.slice(0, 3);
            setItensValue({ ...itensValue, [name]: truncatedValue });
        }

        if (name === 'peso' || name === 'altura' || name === 'temperatura' || name === 'saturacao') {
            if (!/^\d*\.?\d*$/.test(value)) return;
            if (value.length > 6) return;
            setItensValue({ ...itensValue, [name]: value });
        }
        setItensValue({ ...itensValue, [name]: value });
    };

    const atualizarCampoItem = (indiceCampo, indiceCampoItem, campo, valor) => {
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            novosCampos[indiceCampo].itens[indiceCampoItem][campo] = valor;
            return novosCampos;
        });
    };
    const handleClose = () => {
        setOpenModalMedicamentos(false);
        setOpenModalAlergia(false);
    };

    const calculateIMC = () => {
        const alturaFormatada = parseFloat(removeMask(itensValue.altura).replace(',', '.'));

        const weightInKg = itensValue.peso;
        const heightInM = alturaFormatada / 100;

        const imcResult = weightInKg / (heightInM * heightInM);
        setImc(imcResult.toFixed(2));
        setItensValue({ ...itensValue, imc: imcResult.toFixed(2) });
    };

    const getMedicacaoAndAlergiaByPaciente = async (pacienteId) => {
        const response = await api.getMedicacaoAlergiaByPacienteId(pacienteId);

        response.data.medicacao_em_uso.forEach((item) => {
            setItensMedicacao((prev) => [...prev, { medicacao: item, id: uuidv4() }]);
        });

        response.data.alergia.forEach((item) => {
            setItensAlergia((prev) => [...prev, { alergia: item, id: uuidv4() }]);
        });
    };

    const getPaciente = async (pacienteId) => {
        try {
            const { data } = await api.getPacienteById(pacienteId);
            setPaciente(data);
        } catch (error) {
            toast.error('Erro ao buscar paciente');
        }
    };

    const handleSubmit = async (e, values) => {
        let arrayDeMedicacao = itensMedicacao?.map((itens) => itens?.medicacao);
        let arrayDeAlergia = itensAlergia?.map((itens) => itens?.alergia);

        values.id_prontuario = id;

        const response = await api.createPreencherTriagem(itensValue);

        if (response.status === 201) {
            setItensValue({
                id_prontuario: '',
                pa_sis: '',
                pa_dia: '',
                fc: '',
                fr: '',
                dextro: '',
                peso: '',
                altura: '',
                temperatura: '',
                saturacao: '',
                medicacao_em_uso: '',
                alergia: '',
                queixa: '',
                imc: 0
            });

            window.open(`/relatorios/triagem/${id}/numero/${ficha}`);
        }
        await api.createMedicacaoAlegia({ ficha, arrayDeMedicacao, arrayDeAlergia });
        navigate(-1);
    };

    useEffect(() => {
        getMedicacaoAndAlergiaByPaciente(ficha);
        getPaciente(ficha);
    }, []);

    useEffect(() => {
        if (itensValue.peso && itensValue.altura) {
            calculateIMC();
        }
    }, [itensValue]);

    return (
        <MainCard title={`Escuta Inicial F.A: ${id} - ${paciente?.nome_completo || ''}`}>
            <CreateMedicacaoTriagem
                open={openModalMedicamentos}
                handleClose={handleClose}
                setItens={setItensMedicacao}
                itens={itensMedicacao}
                atualizarCampoItem={atualizarCampoItem}
                campos={campos}
                setCampos={setCampos}
            />
            <CreateAlergia
                open={openModalAlergia}
                handleClose={handleClose}
                setItens={setItensAlergia}
                itens={itensAlergia}
                atualizarCampoItem={atualizarCampoItem}
                campos={campos}
                setCampos={setCampos}
            />
            <Grid container spacing={gridSpacing} sx={{ gap: '1rem' }}>
                <Box sx={{ width: '80%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '1rem auto' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <MonitorHeartIcon />
                        <TextField
                            label="SIS"
                            type="number"
                            value={itensValue.pa_sis}
                            name="pa_sis"
                            onChange={handleInputChange}
                            sx={{ width: '130px', marginLeft: '.5rem' }}
                        />
                        {/* <Typography sx={{ margin: '0 .3rem 0 .3rem' }}>X</Typography> */}
                        <CloseIcon />
                        <TextField
                            label="DIA"
                            type="number"
                            value={itensValue.pa_dia}
                            name="pa_dia"
                            onChange={handleInputChange}
                            sx={{ width: '130px' }}
                        />
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <FavoriteIcon />
                        <TextField
                            label="FC"
                            type="number"
                            value={itensValue.fc}
                            name="fc"
                            onChange={handleInputChange}
                            sx={{ width: '120px', marginLeft: '.5rem' }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <IconLungs />
                        <InputMask mask="99" value={itensValue.fr} onChange={handleInputChange}>
                            {(inputProps) => (
                                <TextField
                                    label="FR"
                                    type="text"
                                    name="fr"
                                    sx={{ width: '120px', marginLeft: '.5rem' }}
                                    inputProps={inputProps}
                                />
                            )}
                        </InputMask>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <VaccinesIcon />
                        <TextField
                            label="DEXTRO"
                            type="number"
                            name="dextro"
                            value={itensValue.dextro}
                            onChange={handleInputChange}
                            sx={{ width: '120px', marginLeft: '.5rem' }}
                        />
                    </Box>
                </Box>
                <Divider sx={{ width: '80%', margin: '0 auto' }} />
                <Box sx={{ width: '80%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '1rem auto' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <MonitorWeightIcon />
                        <TextField
                            label="Peso"
                            name="peso"
                            type="text"
                            value={itensValue.peso}
                            onChange={handleInputChange}
                            sx={{ width: '130px', marginLeft: '.5rem' }}
                        />
                        <IconArrowAutofitHeight />
                        <TextField
                            label="Altura"
                            name="altura"
                            type="text"
                            value={itensValue.altura}
                            onChange={handleInputChange}
                            sx={{ width: '130px', marginLeft: '.5rem' }}
                        />
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <CalculateIcon />
                        <TextField label="IMC" name="IMC" type="number" value={imc} disabled sx={{ width: '120px', marginLeft: '.5rem' }} />
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <IconThermometer />
                        <TextField
                            label="Temperatura"
                            name="temperatura"
                            type="text"
                            value={itensValue.temperatura}
                            onChange={handleInputChange}
                            sx={{ width: '120px', marginLeft: '.5rem' }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <IconPercentage />
                        <TextField
                            label="Saturação"
                            name="saturacao"
                            type="number"
                            value={itensValue.saturacao}
                            onChange={handleInputChange}
                            sx={{ width: '120px', marginLeft: '.5rem' }}
                        />
                    </Box>
                </Box>
                {/* TABELA */}

                <Box
                    sx={{
                        width: '80%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        margin: '1rem auto'
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '42%' }}>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                            <Typography sx={{ marginTop: '15px' }}>MEDICAÇÃO EM USO:</Typography>
                            <Box>
                                <Tooltip title="Adicionar item">
                                    <Button
                                        variant="contained"
                                        color="success"
                                        onClick={(e) => setOpenModalMedicamentos(!openModalMedicamentos)}
                                    >
                                        <AddIcon />
                                    </Button>
                                </Tooltip>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '1rem', height: '200px' }}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table" stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <b>Medicacao</b>
                                            </TableCell>
                                            <TableCell align="center">
                                                <b>AÇÕES</b>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {itensMedicacao.map((item) => (
                                            <TableRow>
                                                <TableCell>{item.medicacao}</TableCell>
                                                <TableCell align="center">
                                                    <ActionButtonsDeleteMedicacao
                                                        row={item}
                                                        getEntradas={itensMedicacao}
                                                        setItens={setItensMedicacao}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '42%' }}>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                            <Typography sx={{ marginTop: '15px' }}>ALERGIA:</Typography>
                            <Box>
                                <Tooltip title="Adicionar item">
                                    <Button variant="contained" color="success" onClick={(e) => setOpenModalAlergia(!openModalAlergia)}>
                                        <AddIcon />
                                    </Button>
                                </Tooltip>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '1rem', height: '200px' }}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table" stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <b>Alergia</b>
                                            </TableCell>
                                            <TableCell align="center">
                                                <b>AÇÕES</b>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {itensAlergia.map((item) => (
                                            <TableRow>
                                                <TableCell>{item.alergia}</TableCell>
                                                <TableCell align="center">
                                                    <ActionButtonsDeleteAlergia
                                                        row={item}
                                                        getEntradas={itensAlergia}
                                                        setItens={setItensAlergia}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Box>

                <Box
                    sx={{
                        width: '80%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignContent: 'space-between',
                        justifyContent: 'center',
                        margin: '0 auto'
                    }}
                >
                    <TextField
                        label="Queixa"
                        name="queixa"
                        value={itensValue.queixa}
                        onChange={handleInputChange}
                        multiline={true}
                        minRows={5}
                        sx={{ width: '100%' }}
                    />
                    <Tooltip title="Salvar entrada">
                        <Button
                            variant="contained"
                            color="success"
                            onClick={(e) => handleSubmit(e, itensValue)}
                            sx={{ width: '420px', margin: '1rem auto' }}
                        >
                            <SaveIcon />
                        </Button>
                    </Tooltip>
                </Box>
            </Grid>
        </MainCard>
    );
};

export default FichaTriagem;
