import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useApi } from 'Service/axios';
import { HeaderBox, HeaderInfo, InfoBox } from './style';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import DataArrayIcon from '@mui/icons-material/DataArray';
import { formatarSUS } from 'utils/formatSUS';
import { formatarRG } from 'utils/formatRG';
import { formatarCPF } from 'utils/formatCpf';

const EntradaExterna = () => {
    const { id, numero } = useParams();
    const api = useApi();
    const [paciente, setPaciente] = useState(null);
    const [prontuario, setProntuario] = useState([]);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState('');
    const [dataAtendimento, setDataAtendimento] = useState('');
    const [idade, setIdade] = useState('');

    const getPaciente = async (id) => {
        const response = await api.getPacienteById(id);
        setPaciente(response.data);

        setTimeout(() => {
            setLoading(false);
        }, 1000);

        // ======================== DATA + IDADE ========================

        const formatDateNascimento = (inputDate) => {
            const dateParts = inputDate.split('-');
            const year = parseInt(dateParts[0]);
            const month = parseInt(dateParts[1]);
            const day = parseInt(dateParts[2]);
            return day + '/' + month + '/' + year;
        };

        const dataNascimento = formatDateNascimento(response?.data?.data_nascimento);

        const dataAtual = new Date();

        const diffMilliseconds = dataAtual - dataNascimento;
        const diffDate = new Date(diffMilliseconds);

        const idadeAnos = diffDate.getUTCFullYear() - 1970;
        const idadeMeses = diffDate.getUTCMonth();
        const idadeDias = diffDate.getUTCDate() - 1;

        setIdade(`${idadeAnos} anos, ${idadeMeses} meses e ${idadeDias} dias`);
        setData(dataNascimento);
    };

    if (loading === false && paciente.nome_completo) {
        window.print();
    }
    const calcularIdade = (prontuarios) => {
        // Calcular a idade da pessoa
        const prontuariosComIdade = prontuarios?.map((prontuario) => {
            const paciente = prontuario.paciente;

            if (paciente && paciente.data_nascimento) {
                const dataNascimento = new Date(paciente.data_nascimento);
                const hoje = new Date();
                let idade = hoje.getFullYear() - dataNascimento.getFullYear();
                const mesAtual = hoje.getMonth();
                const mesNascimento = dataNascimento.getMonth();
                if (mesAtual < mesNascimento || (mesAtual === mesNascimento && hoje.getDate() < dataNascimento.getDate())) {
                    idade--;
                }
                return {
                    ...prontuario,
                    paciente: { ...prontuario.paciente, idade }
                };
            }
            // Caso o paciente não tenha data de nascimento preenchida no cadastro
            // Passamos idade como null
            return {
                ...prontuario,
                paciente: { ...prontuario.paciente, idade: null }
            };
        });

        return prontuariosComIdade;
    };
    let resultadoPronturaioIdade = calcularIdade(prontuario);

    let resultado = resultadoPronturaioIdade?.find((idadeUsuario) => {
        return idadeUsuario?.id_paciente === paciente?.id;
    });

    const getProntuario = async () => {
        const protuarioResponse = await api.getProntuarioByPaciente(id);
        setProntuario(protuarioResponse.data);
        const formatDateNascimento = (inputDate) => {
            const dateParts = inputDate.split('-');
            const year = parseInt(dateParts[0]);
            const month = parseInt(dateParts[1]);
            const day = parseInt(dateParts[2]);
            return day + '/' + month + '/' + year;
        };
        const dataDoAtendimento = formatDateNascimento(protuarioResponse?.data[0]?.data_atendimento);
        setDataAtendimento(dataDoAtendimento);
    };

    useEffect(() => {
        getPaciente(id);
        getProntuario(id);
        calcularIdade(prontuario);
    }, [id]);

    return (
        <>
            {paciente && (
                <Box sx={{ width: '100%', marginLeft: '1.2em', padding: '30px' }}>
                    <HeaderBox>
                        <HeaderInfo>
                            P.E: <strong>{numero}</strong>
                        </HeaderInfo>

                        <HeaderInfo>CONVENIO: {paciente?.convenio?.descricao ?? ''}</HeaderInfo>
                    </HeaderBox>
                    <Box sx={{ borderBottom: '1px solid #000' }}>
                        <InfoBox sx={{ marginBottom: '10px', marginTop: '10px' }}>
                            <HeaderInfo>NOME PACIENTE: {paciente?.nome_completo}</HeaderInfo>
                            <HeaderInfo>NOME SOCIAL: {paciente?.nome_social}</HeaderInfo>
                            <HeaderInfo>COR: {paciente?.cor}</HeaderInfo>
                        </InfoBox>
                        <InfoBox sx={{ marginBottom: '10px' }}>
                            <HeaderInfo>Nº MATRICULA: {id}</HeaderInfo>
                            <HeaderInfo>DATA NASC.: {!data ? '' : data}</HeaderInfo>
                            <HeaderInfo>IDADE: {`  ${resultado?.paciente?.idade} ANOS`}</HeaderInfo>
                        </InfoBox>
                        <InfoBox sx={{ marginBottom: '10px' }}>
                            <HeaderInfo>
                                ENDEREÇO: {paciente?.tipo_logradouro} {paciente?.endereco} NUMERO:{paciente?.numero}
                            </HeaderInfo>
                        </InfoBox>
                        <InfoBox>
                            <HeaderInfo>
                                BAIRRO: {paciente?.bairro} / CIDADE: {paciente?.cidade}-{paciente?.estado}
                            </HeaderInfo>
                        </InfoBox>
                        <InfoBox>
                            <HeaderInfo sx={{ marginBottom: '10px' }}>DATA: {dataAtendimento}</HeaderInfo>
                            <HeaderInfo>HORÁRIO: {prontuario[0]?.hora_atendimento}</HeaderInfo>
                            <HeaderInfo>CPF: {paciente?.cpf}</HeaderInfo>
                            <HeaderInfo>RG: {paciente?.rg}</HeaderInfo>
                            <HeaderInfo>SUS: {paciente?.cartao_sus}</HeaderInfo>
                        </InfoBox>

                        <InfoBox>
                            <HeaderInfo>TELEFONE: {paciente?.telefone}</HeaderInfo>
                            <HeaderInfo>AUTISMO: {paciente?.autismo ? 'SIM' : 'NÃO'}</HeaderInfo>
                        </InfoBox>
                    </Box>
                    <Box sx={{ padding: '.5em 0 .5em 0', borderBottom: '1px solid #000' }}>
                        <HeaderInfo>PROCEDIMENTO</HeaderInfo>
                        <Box sx={{ display: 'flex', gap: '20px', marginTop: '10px', flexWrap: 'wrap' }}>
                            <Box sx={{ display: 'flex', gap: '5px' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        width: '20px', // Ajuste a largura conforme necessário
                                        height: '20px', // Ajuste a altura conforme necessário
                                        border: '1px solid #000', // Cor da borda
                                        borderRadius: '0' // Borda quadrada
                                    }}
                                >
                                    {prontuario[0]?.procedimentos_externos[0]?.inalacao ? (
                                        <Typography style={{ paddingLeft: '3px' }}>✔</Typography>
                                    ) : (
                                        ''
                                    )}
                                </Box>
                                <Typography sx={{ marginBottom: '10px' }}>INALAÇÃO</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', gap: '5px' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        width: '20px', // Ajuste a largura conforme necessário
                                        height: '20px', // Ajuste a altura conforme necessário
                                        border: '1px solid #000', // Cor da borda
                                        borderRadius: '0' // Borda quadrada
                                    }}
                                >
                                    {prontuario[0]?.procedimentos_externos[0]?.medicacao ? (
                                        <Typography style={{ paddingLeft: '3px' }}>✔</Typography>
                                    ) : (
                                        ''
                                    )}
                                </Box>
                                <Typography sx={{ marginBottom: '10px' }}>MEDICAÇÃO</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', gap: '5px' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        width: '20px', // Ajuste a largura conforme necessário
                                        height: '20px', // Ajuste a altura conforme necessário
                                        border: '1px solid #000', // Cor da borda
                                        borderRadius: '0' // Borda quadrada
                                    }}
                                >
                                    {prontuario[0]?.procedimentos_externos[0]?.dextro ? (
                                        <Typography style={{ paddingLeft: '3px' }}>✔</Typography>
                                    ) : (
                                        ''
                                    )}
                                </Box>
                                <Typography sx={{ marginBottom: '10px' }}>DEXTRO</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', gap: '5px' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        width: '20px', // Ajuste a largura conforme necessário
                                        height: '20px', // Ajuste a altura conforme necessário
                                        border: '1px solid #000', // Cor da borda
                                        borderRadius: '0' // Borda quadrada
                                    }}
                                >
                                    {prontuario[0]?.procedimentos_externos[0]?.ret_ponto ? (
                                        <Typography style={{ paddingLeft: '3px' }}>✔</Typography>
                                    ) : (
                                        ''
                                    )}
                                </Box>
                                <Typography sx={{ marginBottom: '10px' }}>RET.PONTO</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', gap: '5px' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        width: '20px', // Ajuste a largura conforme necessário
                                        height: '20px', // Ajuste a altura conforme necessário
                                        border: '1px solid #000', // Cor da borda
                                        borderRadius: '0' // Borda quadrada
                                    }}
                                >
                                    {prontuario[0]?.procedimentos_externos[0]?.curativo ? (
                                        <Typography style={{ paddingLeft: '3px' }}>✔</Typography>
                                    ) : (
                                        ''
                                    )}
                                </Box>
                                <Typography sx={{ marginBottom: '10px' }}>CURATIVO</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', gap: '5px' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        width: '20px', // Ajuste a largura conforme necessário
                                        height: '20px', // Ajuste a altura conforme necessário
                                        border: '1px solid #000', // Cor da borda
                                        borderRadius: '0' // Borda quadrada
                                    }}
                                >
                                    {prontuario[0]?.procedimentos_externos[0]?.pa ? (
                                        <Typography style={{ paddingLeft: '3px' }}>✔</Typography>
                                    ) : (
                                        ''
                                    )}
                                </Box>
                                <Typography sx={{ marginBottom: '10px' }}>P.A</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', gap: '5px' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        width: '20px', // Ajuste a largura conforme necessário
                                        height: '20px', // Ajuste a altura conforme necessário
                                        border: '1px solid #000', // Cor da borda
                                        borderRadius: '0' // Borda quadrada
                                    }}
                                >
                                    {prontuario[0]?.procedimentos_externos[0]?.ecg ? (
                                        <Typography style={{ paddingLeft: '3px' }}>✔</Typography>
                                    ) : (
                                        ''
                                    )}
                                </Box>
                                <Typography sx={{ marginBottom: '10px' }}>ECG</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', gap: '5px' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        width: '20px', // Ajuste a largura conforme necessário
                                        height: '20px', // Ajuste a altura conforme necessário
                                        border: '1px solid #000', // Cor da borda
                                        borderRadius: '0' // Borda quadrada
                                    }}
                                >
                                    {prontuario[0]?.procedimentos_externos[0]?.raio_x ? (
                                        <Typography style={{ paddingLeft: '3px' }}>✔</Typography>
                                    ) : (
                                        ''
                                    )}
                                </Box>
                                <Typography sx={{ marginBottom: '10px' }}>RAIO-X</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', gap: '5px' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        width: '20px', // Ajuste a largura conforme necessário
                                        height: '20px', // Ajuste a altura conforme necessário
                                        border: '1px solid #000', // Cor da borda
                                        borderRadius: '0' // Borda quadrada
                                    }}
                                >
                                    {prontuario[0]?.procedimentos_externos[0]?.exame_cabo ? (
                                        <Typography style={{ paddingLeft: '3px' }}>✔</Typography>
                                    ) : (
                                        ''
                                    )}
                                </Box>
                                <Typography sx={{ marginBottom: '10px' }}>EXAME LABORATORIAL</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', gap: '5px' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        width: '20px', // Ajuste a largura conforme necessário
                                        height: '20px', // Ajuste a altura conforme necessário
                                        border: '1px solid #000', // Cor da borda
                                        borderRadius: '0' // Borda quadrada
                                    }}
                                >
                                    {prontuario[0]?.procedimentos_externos[0]?.outros ? (
                                        <Typography style={{ paddingLeft: '3px' }}>✔</Typography>
                                    ) : (
                                        ''
                                    )}
                                </Box>
                                <Typography sx={{ marginBottom: '10px' }}>OUTROS</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Typography sx={{ marginTop: '120px' }}>
                        ASSINATURA DO PACIENTE OU RESPONSÁVEL:__________________________________________________
                    </Typography>
                </Box>
            )}
        </>
    );
};

export default EntradaExterna;
