import { Box, Button, Grid } from '@mui/material';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useApi } from 'Service/axios';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

export function ConsultItems() {
    const { id, nome } = useParams();
    const navigate = useNavigate();
    const api = useApi();
    const [rows, setRows] = useState([]);
    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.2 },
        { field: 'item', headerName: 'ITEM', flex: 1 },
        { field: 'qtd', headerName: 'QUANTIDADE', flex: 1 },
        { field: 'unidade', headerName: 'UNIDADE', flex: 1 },
        { field: 'warehouse', headerName: 'ALMO', flex: 1 },
        { field: 'location', headerName: 'LOC', flex: 1 },
        { field: 'lote', headerName: 'LOTE', flex: 1, valueGetter: (params) => (params.row.lote === '0' ? 'N/A' : params.row.lote) },
        { field: 'observacao', headerName: 'OBSERVACAO', flex: 1 }
    ];

    function navigateToMainTable() {
        navigate('/main/estoque/farmacia/atendimentos/consulta');
    }

    async function getAllItens(id) {
        try {
            const { data } = await api.getAllPedidosMedicamentoFinalizadosByProntuario(id);
            const row = data.map((item) => ({
                id: item?.pedido_medicacao?.id,
                conduta_id: item.id,
                item_id: item?.pedido_medicacao?.item_pedido?.id,
                medico_id: item.responsavel_id,
                uuid: uuidv4(),
                item: item?.pedido_medicacao?.item_pedido?.descricao,
                warehouse: item?.movimento?.almoxarifado?.descricao,
                location: item?.movimento?.localizacao?.descricao,
                lote: item?.movimento?.lote,
                qtd: item?.pedido_medicacao?.quantidade_item,
                unidade: item?.pedido_medicacao?.item_pedido?.unidade?.descricao,
                unidade_id: item?.pedido_medicacao?.item_pedido?.unidade?.id,
                observacao: item?.observacao,
                psicotropico: item?.pedido_medicacao?.item_pedido?.psicotropico,
                almoxarifado_id: item?.pedido_medicacao?.item_pedido?.almoxarifado_id,
                localizacao_id: item?.pedido_medicacao?.item_pedido?.localizacao_id
            }));

            setRows(row);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    useEffect(() => {
        getAllItens(id);
    }, []);

    return (
        <MainCard title={`FA ${id} - ${nome}`}>
            <Grid spacing={gridSpacing} sx={{ position: 'relative' }}>
                <Box sx={{ width: '100%', marginBottom: '1rem' }}>
                    <Button onClick={navigateToMainTable} variant="contained">
                        Voltar para consultas
                    </Button>
                </Box>
                <Box sx={{ width: '100%' }}>
                    <DataGrid rows={rows} columns={columns} hideFooter={true} />
                </Box>
            </Grid>
        </MainCard>
    );
}
