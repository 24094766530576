import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { MenuItem, TextField } from '@mui/material';
import { useApi } from 'Service/axios';
import { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2
};

export function EcgModal({ open, setOpen, prontuarioId, get }) {
    const handleClose = () => setOpen(false);
    const [procedimentos, setProcedimentos] = useState([]);
    const [selectedProcedimento, setSelectedProcedimento] = useState(null);
    const [observation, setObservation] = useState('');
    const api = useApi();

    async function getEcg() {
        try {
            const { data } = await api.getAllProcedimentos();
            setProcedimentos(data);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    async function handleSubmit(event) {
        const userString = localStorage.getItem('userInfos');
        const userObject = JSON.parse(userString);
        event.preventDefault();
        const payload = {
            prontuarioId: prontuarioId,
            procedimentoId: selectedProcedimento,
            usuarioId: userObject.usuario.id,
            observacao: observation
        };

        try {
            await api.linkEcgConduct(payload);
            handleClose();
            get();
            setObservation('');
            setSelectedProcedimento(null);
            setProcedimentos([]);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    useEffect(() => {
        if (open) getEcg();
    }, [open]);

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
                <h3 style={{ textAlign: 'center' }}>Adicionar Procedimento</h3>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Procedimento"
                        select
                        value={selectedProcedimento}
                        fullWidth
                        onChange={(e) => setSelectedProcedimento(e.target.value)}
                        sx={{ marginBottom: '1rem' }}
                    >
                        {procedimentos?.map((procedimento) => (
                            <MenuItem key={procedimento.id} value={procedimento.id}>
                                {procedimento.descricao}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField label="Observação" fullWidth value={observation} onChange={(e) => setObservation(e.target.value)} />
                    <Button variant="contained" type="submit" fullWidth sx={{ marginTop: '20px' }}>
                        Inserir
                    </Button>
                </form>
            </Box>
        </Modal>
    );
}
