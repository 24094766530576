import { Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import TabelaPainelChamadas from './components/TabelaPainelChamadas';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useApi } from 'Service/axios';
const azureRegion = process.env.REACT_APP_azureRegion;
const azureKey = process.env.REACT_APP_azureKey;
const azureWEBSOCKET = process.env.REACT_APP_websocket_azure;

const ChamadasPainel = () => {
    const api = useApi();
    // socket
    const [isReady, setIsReady] = useState(false);
    const [val, setVal] = useState(null);
    const ws = useRef(null);

    const [data, setData] = useState([]);
    const [dataAtual, setDataAtual] = useState(new Date());
    const [pacienteAtual, setPacienteAtual] = useState(null);
    const [statusWindow, setStatusWindow] = useState(false);
    const [audioUrl, setAudioUrl] = useState('');
    const [callInitiated, setCallInitiated] = useState(false);

    const formatarNumero = (numero) => {
        return numero < 10 ? `0${numero}` : numero;
    };
    const dia = formatarNumero(dataAtual.getDate());
    const mes = formatarNumero(dataAtual.getMonth() + 1);
    const ano = dataAtual.getFullYear();

    const fetchAccessToken = async (azureRegion, azureKey) => {
        try {
            const response = await api.getAzureAccessKey(azureRegion, azureKey);
            return response.data;
        } catch (error) {
            toast.error('Erro ao obter token de acesso azure:', error);
        }
    };

    function sendPing(ws) {
        if (ws.current.readyState === WebSocket.OPEN) {
            ws.current.send('ping');
        }
    }

    // Chamada api Speech da azure
    const handleConvertTextToSpeech = async (azureRegion, azureKey) => {
        try {
            // oAuth do token AZURE
            const token = await fetchAccessToken(azureRegion, azureKey);
            // XML com o conteúdo da chamada
            const ssmlContent = `<speak version='1.0' xml:lang='pt-BR'><voice xml:lang='pt-BR' xml:gender='Female'
            name='pt-BR-FranciscaNeural'>
            ${pacienteAtual?.paciente}, ${pacienteAtual?.setor}
             </voice></speak>`;
            // Endpoint
            const ttsEndpoint = `https://${azureRegion}.tts.speech.microsoft.com/cognitiveservices/v1`;
            // Headers obrigatórios
            const response = await fetch(ttsEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/ssml+xml',
                    Authorization: `Bearer ${token}`,
                    'X-Microsoft-OutputFormat': 'audio-16khz-32kbitrate-mono-mp3',
                    'User-Agent': 'telaChamadas'
                },
                body: ssmlContent
            });
            const audioBlob = await response.blob();
            const audioUrl = URL.createObjectURL(audioBlob);
            setAudioUrl(audioUrl);
            setCallInitiated(true);
        } catch (error) {
            toast.error('Erro ao converter texto em fala:', error);
        }
    };
    // Controle de data e hora
    useEffect(() => {
        const intervalId = setInterval(() => {
            setDataAtual(new Date());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    // Conexão socket
    useEffect(() => {
        //const socket = new WebSocket(azureWEBSOCKET);
        const socket = new WebSocket('ws://localhost:8080');

        socket.onopen = () => setIsReady(true);
        socket.onclose = () => setIsReady(false);
        socket.onmessage = (event) => setVal(event.data);

        ws.current = socket;

        return () => {
            socket.close();
        };
    }, []);

    // Ping na conexão do socket a cada 30 segundos (em teoria para não suspender a conexão após minutos sem uso)
    useEffect(() => {
        const pingInterval = setInterval(() => sendPing(ws), 30000);

        return () => {
            clearInterval(pingInterval);
        };
    }, []);

    // Controle dos objetos que chegam do websocket
    useEffect(() => {
        if (val) {
            try {
                const novoPacienteChamada = JSON.parse(val);
                const { ficha, senha, setor, paciente, data, hora, hora_chamada_completa } = novoPacienteChamada;

                setData((prev) => {
                    const novosCampos = [...prev];

                    const pacienteRepetido = novosCampos.find((item) => item.ficha === ficha && item.setor === setor);

                    // Caso seja uma segunda chamada do paciente
                    // apenas realiza a chamada, mas não acrescenta na tabela
                    if (pacienteRepetido) {
                        setPacienteAtual({ ficha, senha, setor, paciente, data, hora, hora_chamada_completa });
                    } else {
                        // Adiciona o novo item
                        if (novosCampos.length >= 5) {
                            novosCampos.shift();
                        }
                        novosCampos.push({ ficha, senha, setor, paciente, data, hora, hora_chamada_completa });
                        setPacienteAtual({ ficha, senha, setor, paciente, data, hora, hora_chamada_completa });
                    }

                    return novosCampos;
                });
            } catch (error) {
                toast.error('Erro ao realizar chamada:', error);
            }
        }
    }, [val]);

    // Chamada do paciente usando azure
    useEffect(() => {
        if (pacienteAtual && pacienteAtual.hora) {
            handleConvertTextToSpeech(azureRegion, azureKey);
        }
    }, [pacienteAtual]);

    // Reproduzir o audio da chamada que chega pela api da azure
    useEffect(() => {
        if (audioUrl && callInitiated) {
            const audio = new Audio(audioUrl);
            audio.play().then(() => {
                setCallInitiated(false);
            });
        }
    }, [audioUrl, callInitiated]);

    // Tela cheia ao entrar no componente
    useEffect(() => {
        let elemento = document.documentElement;
        elemento.requestFullscreen();
    }, []);

    return (
        <Box sx={{ width: '100vw', padding: 0 }}>
            <Box
                sx={{
                    width: '100%',
                    height: '180px',
                    backgroundColor: '#0B3C5D',
                    padding: '20px 40px'
                }}
            >
                <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                    <Typography variant="h4" sx={{ color: '#fff', fontSize: '2rem' }}>
                        {dia}/{mes}/{ano} {statusWindow ? '-' : ''}
                    </Typography>

                    <Typography variant="h4" sx={{ color: '#fff', fontSize: '2rem' }}>
                        {formatarNumero(dataAtual.getHours())}:{formatarNumero(dataAtual.getMinutes())}:
                        {formatarNumero(dataAtual.getSeconds())}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', gap: '20px', width: '90%', margin: '0 auto', marginTop: '-110px' }}>
                <Box
                    sx={{
                        flex: 1,
                        minHeight: '200px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignContent: 'space-between',
                        backgroundColor: '#fafafa',
                        border: '1px solid #000',
                        borderRadius: '6px'
                    }}
                >
                    <Box sx={{ display: 'flex', padding: '1rem', flexDirection: 'column' }}>
                        <Typography variant="h2">PACIENTE:</Typography>
                        <Typography variant="h1" sx={{ fontSize: '3.4rem', color: '#000' }}>
                            {pacienteAtual?.paciente}
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: '550px',
                        minHeight: '200px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignContent: 'space-between',
                        backgroundColor: '#fafafa',
                        border: '1px solid #000',
                        borderRadius: '6px',
                        paddingLeft: '15px'
                    }}
                >
                    <Box sx={{ display: 'flex', padding: '0.5rem', flexDirection: 'column' }}>
                        <Typography variant="h2">LOCAL:</Typography>
                        <Typography variant="h1" sx={{ fontSize: '3.4rem', color: '#000' }}>
                            {pacienteAtual?.setor}
                        </Typography>
                        {callInitiated && (
                            <audio style={{ display: 'none' }} controls autoPlay>
                                <track kind="captions" src="#" srclang="en" label="Portuguese" />
                                <source src={audioUrl} type="audio/mpeg" />
                                Seu navegador não suporta o elemento de áudio.
                            </audio>
                        )}
                    </Box>
                </Box>
            </Box>

            <Divider sx={{ width: '100%', margin: '1rem auto' }} />
            <Box sx={{ width: '90%', margin: '2rem auto' }}>
                <Typography variant="h3">ÚLTIMAS CHAMADAS</Typography>
                <TabelaPainelChamadas data={data} />
            </Box>
        </Box>
    );
};

export default ChamadasPainel;
