// assets
import { IconUser, IconFileAnalytics, IconAdjustments } from '@tabler/icons';

const icons = {
    IconUser,
    IconFileAnalytics,
    IconAdjustments
};

const budget = {
    id: 'lobby',
    title: 'Recepção',
    type: 'group',
    children: [
        {
            id: 'lobby',
            title: 'Prontuários',
            type: 'item',
            url: '/main/lobby',
            icon: icons.IconUser,
            breadcrumbs: false
        },
        {
            id: 'medicos',
            title: 'Cadastro de Médicos',
            type: 'item',
            url: '/main/cadastro-medicos',
            icon: icons.IconAdjustments,
            breadcrumbs: false
        },
        {
            id: 'relatorios',
            title: 'Relatórios',
            type: 'item',
            url: '/main/relatorios-prontuarios',
            icon: icons.IconFileAnalytics,
            breadcrumbs: false
        }
    ]
};

export default budget;
