import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useLocation } from 'react-router-dom';
import { FooterBox, MainContainer, TextBox, Title } from './style';
import SantaCasaLogo from '../../../assets/images/Logos/logo2.png';
import { useState, useEffect } from 'react';
import { formatarData } from 'utils/formatarData';

const Comparecimento = () => {
    // ---- INSTÂNCIAS ----
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const nome = searchParams.get('nome');
    const entrada = searchParams.get('entrada');
    const horaEntrada = searchParams.get('horaEntrada');
    const saida = searchParams.get('saida');
    const horaSaida = searchParams.get('horaSaida');
    const atendimento = searchParams.get('atendimento');
    const observacao = searchParams.get('observacao');
    const [loading, setLoading] = useState(true);

    // ---- FUNÇÕES ----
    if (!loading) window.print();

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1500);
    }, []);
    return (
        <MainContainer>
            <Title variant="h3">DECLARAÇÃO DE COMPARECIMENTO</Title>
            <TextBox>
                <Typography>{`DECLARO PARA OS FINS QUE O(A) SR.(A) ${nome} COMPARECEU NESTE HOSPITAL CHEGANDO AS ${horaEntrada} DO DIA ${formatarData(
                    entrada
                )} PARA ${atendimento}.`}</Typography>
                <Typography>{`COM SAÍDA AS ${horaSaida} DO DIA ${formatarData(saida)}`}</Typography>
                <Typography>{`OBSERVAÇÔES: ${observacao}`}</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '7em' }}>
                    <Typography>_______________________________________________________________________</Typography>
                    <Typography>ASSINATURA E CARIMBO</Typography>
                </Box>
            </TextBox>
            <FooterBox>
                <img src={SantaCasaLogo} alt="Santa Casa Logotipo" style={{ width: '90px' }} />
                <Box>
                    <Typography>SANTA CASA DE MISERICÓRDIA DE JOANÓPOLIS</Typography>
                    <Typography>RUA FRANCISCO WOLHERS N°57 - CENTRO - JOANÓPOLIS - SP</Typography>
                    <Typography>CEP: 12980-000 - TELEFONE: (11) 4539-9333</Typography>
                </Box>
            </FooterBox>
        </MainContainer>
    );
};

export default Comparecimento;
