import { Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Aguardando from 'components/Triagem/Cards/Aguardando';
import TempoEspera from 'components/Triagem/Cards/TempoEspera';
import TabelaPainelTriagem from './components/Tabela';
import { useContext } from 'react';
import { ChamadaContext } from 'context/ChamadasContext';
import CardPrimary from './components/Cards/CardPrimary';
import PersonIcon from '@mui/icons-material/Person';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CardHora from './components/Cards/CardHora';

const PainelTriagem = () => {
    const { atendimentos, quantidadeAguardando } = useContext(ChamadaContext);
    // --- STATES --- \\
    const [dataAtual, setDataAtual] = useState(new Date());
    const [waitTime, setWaitTime] = useState('');
    const [quantidade, setQuantidade] = useState(0);
    const [limiteTempo, setLimiteTempo] = useState(false);
    // --- FUNCTIONS --- \\
    const formatarNumero = (numero) => {
        return numero < 10 ? `0${numero}` : numero;
    };

    const dia = formatarNumero(dataAtual.getDate());
    const mes = formatarNumero(dataAtual.getMonth() + 1);
    const ano = dataAtual.getFullYear();

    // ----EFFECTS --- //

    useEffect(() => {
        const intervalId = setInterval(() => {
            setDataAtual(new Date());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        if (atendimentos.length > 0) {
            const calculateWaitTime = () => {
                const recordDate = new Date(atendimentos[0]?.data_atendimento);
                const currentDate = new Date();
                const timeDifference = currentDate.getTime() - recordDate.getTime();
                const waitTimeInSeconds = Math.floor(timeDifference / 1000);

                const hours = Math.floor(waitTimeInSeconds / 3600);
                const minutes = Math.floor((waitTimeInSeconds % 3600) / 60);
                const seconds = waitTimeInSeconds % 60;

                const waitTimeMoreThan10Minutes = waitTimeInSeconds > 600; // 600 seconds = 10 minutes
                setLimiteTempo(waitTimeMoreThan10Minutes);

                setWaitTime(`${hours}h ${minutes}m ${seconds}s`);

                // Use a variável waitTimeMoreThan10Minutes conforme necessário
            };
            const intervalId = setInterval(calculateWaitTime, 1000);

            return () => clearInterval(intervalId);
        }

        setQuantidade(quantidadeAguardando);
    }, [atendimentos]);

    useEffect(() => {
        let elemento = document.documentElement;
        elemento.requestFullscreen();
    }, []);

    return (
        <Box sx={{ width: '100%', marginTop: '1rem' }}>
            <Box
                sx={{
                    width: '90%',
                    display: 'flex',
                    margin: '0 auto',
                    gap: '1rem',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <Typography variant="h1" sx={{ textAlign: 'center' }}>
                    ESCUTA INICIAL
                </Typography>
                <Box sx={{ display: 'flex', gap: '1rem' }}>
                    <Typography variant="h3">
                        DATA: {dia}/{mes}/{ano}
                    </Typography>
                    <Typography variant="h3">
                        HORA: {formatarNumero(dataAtual.getHours())}:{formatarNumero(dataAtual.getMinutes())}:
                        {formatarNumero(dataAtual.getSeconds())}
                    </Typography>
                </Box>
            </Box>

            <Box
                sx={{
                    width: '90%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '3rem',
                    margin: '2rem auto'
                }}
            >
                <CardPrimary
                    data={quantidadeAguardando}
                    legenda={'Aguardando'}
                    icon={<PersonIcon />}
                    size={'6rem'}
                    quantidade={quantidade}
                />
                <CardHora data={waitTime} legenda={'Tempo Espera'} icon={<AccessTimeIcon />} size={'3.8rem'} limiteTempo={limiteTempo} />
            </Box>

            <Divider sx={{ width: '90%', margin: '0 auto' }} />

            <Box sx={{ width: '90%', margin: '0 auto', height: '500px' }}>
                <TabelaPainelTriagem atendimentos={atendimentos} />
            </Box>
        </Box>
    );
};

export default PainelTriagem;
