import { createContext, useState } from 'react';

const ConjuntoLaudoContext = createContext();

function LaudoProvider({ children }) {
    const [laudoPayload, setLaudoPayload] = useState([]);
    const [fichaBancadaPayload, setFichaBancadaPayload] = useState([]);

    return (
        <ConjuntoLaudoContext.Provider value={{ laudoPayload, setLaudoPayload, fichaBancadaPayload, setFichaBancadaPayload }}>
            {children}
        </ConjuntoLaudoContext.Provider>
    );
}

export { ConjuntoLaudoContext, LaudoProvider };
