import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useApi } from 'Service/axios';
import { HeaderBox, Title, Container, Mid, Body, CampoContainer, Campo } from './style';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { toast } from 'react-toastify';

const FichaBancada = () => {
    const { id, numero, prontuarioId, idExameProntuario } = useParams();
    const api = useApi();
    const [paciente, setPaciente] = useState(null);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState('');
    const [idade, setIdade] = useState('');
    const [exame, setExame] = useState(null);
    const [camposPorCategoria, setCamposPorCategoria] = useState({});
    const [medico, setMedico] = useState([]);

    const getPaciente = async (id) => {
        const response = await api.getPacienteById(id);
        setPaciente(response.data);

        setTimeout(() => {
            setLoading(false);
        }, 1000);
        // ======================== DATA + IDADE ========================
        const formatDate = (inputDate) => {
            const dateParts = inputDate.split('-');
            const year = parseInt(dateParts[0]);
            const month = parseInt(dateParts[1]) - 1;
            const day = parseInt(dateParts[2]);
            return new Date(year, month, day);
        };

        const padWithZero = (number) => {
            return (number < 10 ? '0' : '') + number;
        };

        const formatDateNascimento = (inputDate) => {
            const dateParts = inputDate.split('-');
            const year = parseInt(dateParts[0]);
            const month = parseInt(dateParts[1]);
            const day = parseInt(dateParts[2]);
            return padWithZero(day) + '/' + padWithZero(month) + '/' + year;
        };

        const dataNascimento = formatDate(response.data.data_nascimento);
        const dataAtual = new Date();
        const diffMilliseconds = dataAtual - dataNascimento;
        const diffDate = new Date(diffMilliseconds);
        const idadeAnos = diffDate.getUTCFullYear() - 1970;
        const idadeMeses = diffDate.getUTCMonth();
        const idadeDias = diffDate.getUTCDate() - 1;
        setIdade(`${idadeAnos} anos, ${idadeMeses} meses e ${idadeDias} dias`);
        setData(formatDateNascimento(response.data.data_nascimento));
    };

    const groupByCategory = (campos) => {
        const camposPorCategoria = {};
        campos.forEach((campo) => {
            if (!camposPorCategoria[campo.categoria]) {
                camposPorCategoria[campo.categoria] = [];
            }
            camposPorCategoria[campo.categoria].push(campo);
        });
        return camposPorCategoria;
    };

    const getExame = async (id) => {
        try {
            const response = await api.getExameEspecialById(id);
            setExame(response.data);
            const camposPorCategoria = groupByCategory(response.data.campos_exames_especiais);
            setCamposPorCategoria(camposPorCategoria);
            return response.data;
        } catch (error) {
            if (error.response.status === 404) {
                const response = await api.getExameById(id);
                setExame(response.data);
                const camposPorCategoria = groupByCategory(response.data.campos_especificos);
                setCamposPorCategoria(camposPorCategoria);
                return response.data;
            }

            toast.error('Erro ao buscar dados do exame');
        }
    };

    const getMedico = async () => {
        try {
            const response = await api.getAllExameData(prontuarioId, id, idExameProntuario);
            setMedico(response?.data?.medico?.usuario?.info_medico?.nome_completo);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    };
    useEffect(() => {
        getPaciente(numero);
        getExame(id);
        getMedico(prontuarioId, id, idExameProntuario);
    }, [id]);

    if (loading === false && paciente.nome_completo) {
        window.print();
    }

    return (
        <>
            {paciente !== null && (
                <Container>
                    <Box>
                        <Title
                            variant="h1"
                            sx={{
                                fontSize: '1.5em',
                                marginTop: '1em',
                                marginBottom: '1em'
                            }}
                        >
                            <b>FICHA DE BANCADA</b>
                        </Title>
                    </Box>
                    <HeaderBox>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: '1em',
                                fontSize: '1em'
                            }}
                        >
                            <Title
                                sx={{
                                    marginLeft: '1em'
                                }}
                            >
                                NOME: <b>{paciente.nome_completo}</b>
                            </Title>
                            <Title>
                                CONVÊNIO: <b>{paciente.convenio.descricao}</b>
                            </Title>
                            <Title
                                sx={{
                                    marginRight: '1em'
                                }}
                            >
                                TELEFONE: <b>{paciente.telefone}</b>
                            </Title>
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '.4em' }}>
                            <Title
                                sx={{
                                    marginLeft: '1em'
                                }}
                            >
                                MÉDICO SOLICITANTE: <b>{medico}</b>
                            </Title>
                            <Title>
                                DATA DE NASCIMENTO: <b>{data}</b>
                            </Title>
                            <Title
                                sx={{
                                    marginRight: '1em'
                                }}
                            >
                                IDADE: <b>{idade}</b>
                            </Title>
                        </Box>
                    </HeaderBox>

                    <Mid>
                        <Box
                            sx={{
                                marginLeft: '.50em',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                width: '98%'
                            }}
                        >
                            <Title>
                                EXAME:
                                <b>
                                    {exame?.id} - {exame?.nome}
                                </b>
                            </Title>
                            <Title>
                                CÓDIGO DE AMOSTRA: <b>{prontuarioId}</b>
                            </Title>
                        </Box>
                    </Mid>

                    <Body>
                        <Box
                            sx={{
                                width: '100%',
                                paddingTop: '1em'
                            }}
                        >
                            {Object.keys(camposPorCategoria).map((categoria, index) => (
                                <Box key={index}>
                                    <Typography
                                        sx={{
                                            display: 'flex',
                                            marginLeft: '1em',
                                            fontWeight: 'bold',
                                            height: '25px',
                                            alignItems: 'center'
                                        }}
                                    >
                                        {categoria}:
                                    </Typography>

                                    <CampoContainer>
                                        {camposPorCategoria[categoria].map((campo, campoIndex) => (
                                            <Campo key={campoIndex}>
                                                <Typography
                                                    sx={{
                                                        marginLeft: '1em',
                                                        fontWeight: 'bold'
                                                    }}
                                                >
                                                    {campo.campo_nome}
                                                </Typography>
                                                :
                                            </Campo>
                                        ))}
                                    </CampoContainer>
                                </Box>
                            ))}
                        </Box>
                    </Body>
                </Container>
            )}
        </>
    );
};
export default FichaBancada;
