import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, Box, Tooltip, TextField, FormControl, MenuItem, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApi } from 'Service/axios';
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import ActionButtons from 'components/Kits/ActionButtonsKits';
import ActionButtonsCreate from 'components/Kits/ActionButtonsCreateKits';
import SaveIcon from '@mui/icons-material/Save';
import { toast } from 'react-toastify';
import ActionButtonsCreateKits from 'components/Kits/ActionButtonsCreateKits';
import ActionButtonEdit from 'components/Kits/EditKits/ActionButtonEdit';
// ============================= INITIALSTATE =============================
const initialState = {
    descricao: '',
    apelido: '',
    itens: []
};

const NovaEntradaKits = () => {
    // ============================= INSTÂNCIAS =============================
    const api = useApi();
    const navigate = useNavigate();
    // ============================= STATES =============================
    const [campos, setCampos] = useState([initialState]);
    const [openCreate, setOpenCreate] = useState(false);
    const [itens, setItens] = useState([]);
    const [listAllItens, setListAllItens] = useState([]);
    const [listAllAlmoxarifado, setListAllAlmoxarifado] = useState([]);
    const [listAllLocalizacao, setListAllLocalizacao] = useState([]);
    const [listAllFornecedor, setListAllFornecedor] = useState([]);
    const [loading, setLoading] = useState(false);
    const [kit, setKit] = useState();
    const columns = [
        {
            field: 'CÓDIGO',
            headerName: 'CÓDIGO',
            flex: 1,
            valueGetter: (params) => params.row.item_id
        },
        { field: 'descricao', headerName: 'DESCRIÇÃO', flex: 2 },
        { field: 'unidade', headerName: 'UN', flex: 2 },
        { field: 'quantidade', headerName: 'QNTD', flex: 0.5 },

        {
            field: 'acao',
            headerName: 'AÇÕES',
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <ActionButtonEdit
                    itens={itens}
                    setCampos={setCampos}
                    setItens={setItens}
                    row={params.row}
                    atualizarCampoItem={atualizarCampoItem}
                    removerCampoItem={removerCampoItem}
                    listAllItens={listAllItens}
                    listAllAlmoxarifado={listAllAlmoxarifado}
                    listAllLocalizacao={listAllLocalizacao}
                />
            )
        }
    ];

    const somaValorTotal = itens.reduce((acumulador, objeto) => {
        return acumulador + Number(objeto.valor_total);
    }, 0);

    // ============================= FUNÇÕES =============================

    const atualizarCampo = (indiceCampo, campo, valor) => {
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            novosCampos[indiceCampo][campo] = valor;
            return novosCampos;
        });
    };

    const adicionarCampoItem = (indiceCampo) => {
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            novosCampos[indiceCampo].itens.push({
                item_id: '',
                descricao: '',
                quantidade: 1,
                unidade: '',
                unidade_id: '',
                indiceCampoItem: '',
                valor_unitario: 0,
                valor_total: 0,
                almoxarifado_id: '',
                localizacao_id: '',
                lote: '',
                validade: '',
                observacao: ''
            });
            return novosCampos;
        });
    };

    const removerCampoItem = (indiceCampo, indiceCampoItem) => {
        setItens((prevItens) => {
            const novosItens = [...prevItens];

            const teste = novosItens.find((item) => item.item_id === indiceCampoItem || item.id === indiceCampoItem + 1);
            const filtredItens = novosItens.filter((item) => item !== teste);
            return filtredItens;
        });
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            novosCampos[0].itens.splice(indiceCampoItem, 1);
            return novosCampos;
        });
        if (openCreate) {
            setOpenCreate(false);
        }
    };

    const atualizarCampoItem = (indiceCampo, indiceCampoItem, campo, valor) => {
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            novosCampos[indiceCampo].itens[indiceCampoItem][campo] = valor;
            return novosCampos;
        });
    };

    const handleSelectionModelChange = (newSelection) => {
        setSelectedItems(newSelection);
    };

    const handleClose = () => {
        setOpenCreate(false);
    };

    const handleOpenAndCreateCampoItem = (indiceCampo) => {
        setOpenCreate(true);
        adicionarCampoItem(indiceCampo);
    };

    const getListAllItens = async () => {
        const response = await api.getAllItems();
        setListAllItens(response.data);
    };

    const getListAllAlmoxarifado = async () => {
        const response = await api.getAlmoxarifados();
        setListAllAlmoxarifado(response.data);
    };

    const getListAllLocalizacao = async () => {
        const response = await api.getAllLocalizacoes();
        setListAllLocalizacao(response.data);
    };

    const getAllFonecedor = async () => {
        const response = await api.getAllFornecedor();
        setListAllFornecedor(response.data);
    };

    const getDate = () => {
        const date = new Date();
        let currentDay = String(date.getDate()).padStart(2, '0');
        let currentMonth = String(date.getMonth() + 1).padStart(2, '0');
        let currentYear = date.getFullYear();
        let currentDate = `${currentYear}-${currentMonth}-${currentDay}`;
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            /* novosCampos[0].movimento = currentDate; */
            return novosCampos;
        });
    };

    //====== SUBMIT ======
    const handleSubmit = async (e, campo) => {
        e.preventDefault();

        const data = { entrada: campo, itens: itens };

        try {
            setLoading(true);
            const response = await api.createKits({ descricao: data.entrada.descricao, apelido: data.entrada.apelido, itens: data.itens });

            /*  atualizarCampo(indiceCampo, 'descricao', ''), atualizarCampo(indiceCampo, 'apelido', ''); */
            setCampos([{ descricao: '', apelido: '', itens: [] }]);

            setItens([]);
            setOpenCreate(false);
            setTimeout(() => {
                setLoading(false);
            }, 1000);

            toast.success('Entrada cadastrada');
        } catch (error) {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
            toast.error(`Erro ao criar entrada: ${error.message}`);
        }
    };

    useEffect(() => {
        getListAllItens();
        getListAllAlmoxarifado();
        getListAllLocalizacao();
        getAllFonecedor();
        getDate();
    }, []);

    return (
        <MainCard title="Cadastro Kit">
            <Grid container spacing={gridSpacing}>
                <Box sx={{ width: '80%', margin: '0 auto' }}>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', marginLeft: '1em', marginTop: '1em' }}>
                        {campos.map((campo, indiceCampo) => (
                            <>
                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: '1em',
                                            width: '100%',
                                            marginBottom: '1em'
                                        }}
                                    >
                                        <FormControl fullWidth>
                                            <TextField
                                                type="text"
                                                label="Descrição"
                                                value={campo.descricao}
                                                onChange={(e) => atualizarCampo(indiceCampo, 'descricao', e.target.value)}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <TextField
                                                type="text"
                                                label="Apelido"
                                                value={campo.apelido}
                                                onChange={(e) => atualizarCampo(indiceCampo, 'apelido', e.target.value)}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </FormControl>
                                    </Box>
                                </Box>
                                <Box sx={{ width: '100%', marginTop: '2em', borderTop: '2px dashed #eee' }}>
                                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '1em' }}>
                                        <Box>
                                            <Typography variant="h4">Relação de itens</Typography>
                                        </Box>
                                        <Box>
                                            <Tooltip title="Adicionar item">
                                                <Button
                                                    variant="contained"
                                                    color="success"
                                                    onClick={(e) => handleOpenAndCreateCampoItem(indiceCampo)}
                                                >
                                                    <AddIcon />
                                                </Button>
                                            </Tooltip>
                                        </Box>
                                    </Box>

                                    <Box sx={{ marginTop: '2em', display: 'flex', flexDirection: 'column', height: '300px' }}>
                                        <DataGrid
                                            rows={itens}
                                            columns={columns}
                                            hideFooter={true}
                                            onSelectionModelChange={() => handleSelectionModelChange(params.id)}
                                        />
                                        <Box
                                            sx={{
                                                width: '40%',
                                                marginLeft: 'auto',
                                                marginTop: '1em',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                {/* <h4>Valor Total:</h4>
                                                <Box
                                                    sx={{
                                                        border: '1px solid #ccc',
                                                        padding: '8px 16px',
                                                        borderRadius: 2
                                                    }}
                                                >
                                                    {somaValorTotal.toFixed(2)}
                                                </Box> */}
                                            </Box>

                                            <Tooltip title="Salvar entrada">
                                                <Button
                                                    variant="contained"
                                                    color="success"
                                                    disabled={loading}
                                                    onClick={(e) => handleSubmit(e, campo)}
                                                >
                                                    <SaveIcon />
                                                </Button>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                </Box>
                                {campos[0]?.itens.map((item, indiceCampoItem) => (
                                    <ActionButtonsCreateKits
                                        key={indiceCampoItem}
                                        open={openCreate}
                                        handleClose={handleClose}
                                        itens={itens}
                                        setItens={setItens}
                                        campos={campos}
                                        setCampos={setCampos}
                                        adicionarCampoItem={adicionarCampoItem}
                                        atualizarCampoItem={atualizarCampoItem}
                                        removerCampoItem={removerCampoItem}
                                        indiceCampoItem={indiceCampoItem}
                                        item={item}
                                        listAllItens={listAllItens}
                                        listAllAlmoxarifado={listAllAlmoxarifado}
                                        listAllLocalizacao={listAllLocalizacao}
                                    />
                                ))}
                            </>
                        ))}
                    </Box>
                </Box>
            </Grid>
        </MainCard>
    );
};

export default NovaEntradaKits;
