import { Box, Button, Grid, IconButton, Tooltip } from '@mui/material';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { AddExamModal } from './components/AddExamModal';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import { AddGrupo } from './components/AddGrupo';

export function RegisterXRay() {
    const api = useApi();
    const [rows, setRows] = useState([]);
    const [currentRow, setCurrentRow] = useState(null);
    const [open, setOpen] = useState(false);
    const [openGrupo, setOpenGrupo] = useState(false);
    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.5 },
        { field: 'descricao', headerName: 'Descrição', flex: 1 },
        { field: 'codigo', headerName: 'Código', flex: 1 },
        { field: 'grupo', headerName: 'Grupo', flex: 1, valueGetter: (params) => params?.row?.grupo_raio_x?.descricao },
        {
            field: 'actions',
            headerName: 'Ações',
            flex: 1,
            renderCell: (params) => (
                <Box>
                    <Tooltip title="Editar">
                        <IconButton
                            onClick={() => {
                                setOpen(true);
                                setCurrentRow(params.row);
                            }}
                        >
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Deletar">
                        <IconButton onClick={() => handleDelete(params.row.id)}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            )
        }
    ];

    async function allXray() {
        try {
            const { data } = await api.getAllXRay();

            setRows(data);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    async function handleDelete(id) {
        try {
            await api.deleteXray(id);
            allXray();
            toast.success('Raio-x deletado com sucesso!');
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    useEffect(() => {
        allXray();
    }, []);

    return (
        <MainCard title="CADASTRO RAIO-X">
            <Grid spacing={gridSpacing}>
                <Box sx={{ width: '100%', display: 'flex', gap: '1rem' }}>
                    <Button variant="contained" onClick={() => setOpen(true)} sx={{ marginBottom: '16px' }}>
                        Adicionar Raio-X
                    </Button>
                    <Button variant="contained" onClick={() => setOpenGrupo(true)} sx={{ marginBottom: '16px' }}>
                        Grupo Raio-X
                    </Button>
                </Box>
                <Box>
                    <AddExamModal get={allXray} currentRow={currentRow} open={open} setOpen={setOpen} setCurrentRow={setCurrentRow} />
                    <AddGrupo get={allXray} open={openGrupo} setOpen={setOpenGrupo} />
                    {rows.length > 0 && <DataGrid rows={rows} columns={columns} hideFooter />}
                </Box>
            </Grid>
        </MainCard>
    );
}
