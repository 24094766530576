import { useParams, Link } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, Box, TablePagination, Autocomplete, Tooltip, TextField } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useState } from 'react';
import { useEffect } from 'react';
import { useApi } from 'Service/axios';
import { HeaderBox, TableBox } from './style';
import LocalizacaoTabela from 'components/Estoque/Localizacao/Tabela';
import DrawerCreateLocalizacao from 'components/Estoque/Localizacao/DrawerCreate';
import { toast } from 'react-toastify';
import DrawerEditLocalizacao from 'components/Estoque/Localizacao/DrawerEdit';
import CategoriaTabela from 'components/Estoque/Categoria/Tabela';
import DrawerCreateCategoria from 'components/Estoque/Categoria/DrawerCreate';
import DrawerEditCategoria from 'components/Estoque/Categoria/DrawerEdit';

const Categoria = () => {
    // ============================= INSTÂNCIAS =============================
    const api = useApi();
    // ============================= STATES =============================
    const [allCategorias, setAllCategorias] = useState([]);
    const [selectedCategoria, setSelectedCategoria] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [categoria, setCategoria] = useState(null);
    // ============================= FUNÇÕES =============================

    const handleClickOpenEdit = (e, item) => {
        e.preventDefault();
        setCategoria(item);
        setOpenEdit(true);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getCategorias = async () => {
        try {
            const response = await api.getAllCategorias();
            setAllCategorias(response.data);
        } catch (error) {
            toast.error(error.response.data.mensagem);
        }
    };

    useEffect(() => {
        getCategorias();
    }, []);

    return (
        <MainCard title="CATEGORIA">
            <Grid container spacing={gridSpacing}>
                {/* DRAWERS */}
                <DrawerCreateCategoria open={open} handleClose={handleClose} getCategorias={getCategorias} />
                <DrawerEditCategoria open={openEdit} handleClose={handleCloseEdit} getCategorias={getCategorias} item={categoria} />
                <HeaderBox>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={allCategorias}
                        getOptionLabel={(categoria) => categoria.descricao}
                        value={selectedCategoria}
                        onChange={(event, newValue) => {
                            setSelectedCategoria(newValue);
                        }}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Pesquisar Categoria" />}
                    />
                    <Button variant="contained" size="medium" onClick={() => setOpen(true)} sx={{ height: '40px' }}>
                        Adicionar
                    </Button>
                </HeaderBox>

                <TableBox>
                    <CategoriaTabela
                        allCategorias={allCategorias}
                        setAllCategorias={setAllCategorias}
                        selectedCategoria={selectedCategoria}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        getCategorias={getCategorias}
                        handleClickOpenEdit={handleClickOpenEdit}
                    />
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={allCategorias.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableBox>
            </Grid>
        </MainCard>
    );
};

export default Categoria;
