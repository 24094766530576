import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip
} from '@mui/material';
import React from 'react';
import { OrderButton } from './style';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import Row from './Row';
import { maxHeight } from '@mui/system';
import { useState } from 'react';

const ExamesTabela = ({ allExames, setAllExames, selectedExame, page, rowsPerPage, getExames }) => {
    const [orderById, setOrderById] = useState('desc');
    const [orderByNome, setOrderByNome] = useState('desc');
    const [orderByMaterial, setOrderByMaterial] = useState('desc');
    const [orderBySigla, setOrderBySigla] = useState('desc');
    const [orderByMetodo, setOrderByMetodo] = useState('desc');

    const handleSortById = () => {
        const sortedExames = [...allExames].sort((a, b) => {
            if (orderById === 'desc') {
                return b.id - a.id;
            } else {
                return a.id - b.id;
            }
        });

        setAllExames(sortedExames);
        setOrderById(orderById === 'asc' ? 'desc' : 'asc');
    };

    const handleSortByNome = () => {
        const sortedExames = [...allExames].sort((a, b) => {
            if (orderByNome === 'asc') {
                return a.nome.localeCompare(b.nome);
            } else {
                return b.nome.localeCompare(a.nome);
            }
        });

        setAllExames(sortedExames);
        setOrderByNome(orderByNome === 'asc' ? 'desc' : 'asc');
    };

    const handleSortByMaterial = () => {
        const sortedExames = [...allExames].sort((a, b) => {
            if (orderByMaterial === 'asc') {
                return a.material.localeCompare(b.material);
            } else {
                return b.material.localeCompare(a.material);
            }
        });

        setAllExames(sortedExames);
        setOrderByMaterial(orderByMaterial === 'asc' ? 'desc' : 'asc');
    };

    const handleSortBySigla = () => {
        const sortedExames = [...allExames].sort((a, b) => {
            if (orderBySigla === 'asc') {
                return a.sigla.localeCompare(b.sigla);
            } else {
                return b.sigla.localeCompare(a.sigla);
            }
        });

        setAllExames(sortedExames);
        setOrderBySigla(orderBySigla === 'asc' ? 'desc' : 'asc');
    };

    const handleSortByMetodo = () => {
        const sortedExames = [...allExames].sort((a, b) => {
            if (orderByMetodo === 'asc') {
                return a.metodo.localeCompare(b.metodo);
            } else {
                return b.metodo.localeCompare(a.metodo);
            }
        });

        setAllExames(sortedExames);
        setOrderByMetodo(orderByMetodo === 'asc' ? 'desc' : 'asc');
    };

    return (
        <TableContainer
            component={Paper}
            sx={{
                maxHeight: '300px'
            }}
        >
            <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
                <TableHead>
                    <TableRow>
                        {/* <TableCell sx={{ pl: 3 }} /> */}
                        <OrderButton>
                            <TableSortLabel onClick={handleSortById} direction={orderById === 'asc' ? 'desc' : 'asc'}>
                                <b>ID</b>
                            </TableSortLabel>
                        </OrderButton>
                        <OrderButton align="center">
                            <TableSortLabel onClick={handleSortByNome} direction={orderByNome === 'asc' ? 'desc' : 'asc'}>
                                <b>Nome</b>
                            </TableSortLabel>
                        </OrderButton>
                        <OrderButton align="center">
                            <TableSortLabel onClick={handleSortByMaterial} direction={orderByMaterial === 'asc' ? 'desc' : 'asc'}>
                                <b>Material</b>
                            </TableSortLabel>
                        </OrderButton>
                        <OrderButton align="center">
                            <TableSortLabel onClick={handleSortBySigla} direction={orderBySigla === 'asc' ? 'desc' : 'asc'}>
                                <b>Sigla</b>
                            </TableSortLabel>
                        </OrderButton>
                        <OrderButton align="center">
                            <TableSortLabel onClick={handleSortByMetodo} direction={orderByMetodo === 'asc' ? 'desc' : 'asc'}>
                                <b>Método</b>
                            </TableSortLabel>
                        </OrderButton>
                        <TableCell align="center">
                            <b>Ações</b>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {allExames.length > 0 &&
                        allExames
                            .filter((exame) => (selectedExame ? exame.nome.includes(selectedExame.nome) : true))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((item) => <Row key={item.id} item={item} getExames={getExames} />)}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ExamesTabela;
