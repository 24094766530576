import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip
} from '@mui/material';
import React from 'react';
import { OrderButton } from './style';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import formateDate from 'utils/formatDate-1';
import { IconFileInvoice } from '@tabler/icons';
import { useState } from 'react';

const ExamesAtendimentoTabela = ({ allFichas, setAllFichas, selectedFicha, page, rowsPerPage }) => {
    const [orderById, setOrderById] = useState('asc');
    const [orderByPaciente, setOrderByPaciente] = useState('asc');
    const [orderByConsulta, setOrderByConsulta] = useState('asc');
    const [orderByDate, setOrderByDate] = useState('asc');
    const [orderByHora, setOrderByHora] = useState('asc');

    const handleSortById = () => {
        const sortedFichas = [...allFichas].sort((a, b) => {
            if (orderById === 'desc') {
                return b.id - a.id;
            } else {
                return a.id - b.id;
            }
        });

        setAllFichas(sortedFichas);
        setOrderById(orderById === 'asc' ? 'desc' : 'asc');
    };

    const handleSortByPaciente = () => {
        const sortedFichas = [...allFichas].sort((a, b) => {
            if (orderByPaciente === 'asc') {
                return a.paciente.nome_completo.localeCompare(b.paciente.nome_completo);
            } else {
                return b.paciente.nome_completo.localeCompare(a.paciente.nome_completo);
            }
        });

        setAllFichas(sortedFichas);
        setOrderByPaciente(orderByPaciente === 'asc' ? 'desc' : 'asc');
    };

    const handleSortByTipoConsulta = () => {
        const sortedFichas = [...allFichas].sort((a, b) => {
            if (orderByConsulta === 'asc') {
                return a.tipo_consultum.descricao.localeCompare(b.tipo_consultum.descricao);
            } else {
                return b.tipo_consultum.descricao.localeCompare(a.tipo_consultum.descricao);
            }
        });

        setAllFichas(sortedFichas);
        setOrderByConsulta(orderByConsulta === 'asc' ? 'desc' : 'asc');
    };

    const handleSortByData = () => {
        const sortedFichas = [...allFichas].sort((a, b) => {
            if (orderByDate === 'asc') {
                return a.data_atendimento.localeCompare(b.data);
            } else {
                return b.data_atendimento.localeCompare(a.data);
            }
        });

        setAllFichas(sortedFichas);
        setOrderByDate(orderByDate === 'asc' ? 'desc' : 'asc');
    };

    const handleSortByHora = () => {
        const sortedFichas = [...allFichas].sort((a, b) => {
            if (orderByHora === 'asc') {
                return a.hora_atendimento.localeCompare(b.hora);
            } else {
                return b.hora_atendimento.localeCompare(a.hora);
            }
        });

        setAllFichas(sortedFichas);
        setOrderByHora(orderByHora === 'asc' ? 'desc' : 'asc');
    };

    return (
        <TableContainer component={Paper} sx={{ maxHeight: '320px' }}>
            <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <OrderButton>
                            <TableSortLabel onClick={handleSortById} direction={orderById === 'asc' ? 'desc' : 'asc'}>
                                <b>ID</b>
                            </TableSortLabel>
                        </OrderButton>
                        <OrderButton>
                            <TableSortLabel onClick={handleSortById} direction={orderById === 'asc' ? 'desc' : 'asc'}>
                                <b>TIPO</b>
                            </TableSortLabel>
                        </OrderButton>
                        <OrderButton align="center">
                            <TableSortLabel onClick={handleSortByPaciente} direction={orderByPaciente === 'asc' ? 'desc' : 'asc'}>
                                <b>Paciente</b>
                            </TableSortLabel>
                        </OrderButton>
                        <OrderButton align="center">
                            <TableSortLabel onClick={handleSortByTipoConsulta} direction={orderByConsulta === 'asc' ? 'desc' : 'asc'}>
                                <b>Tipo Consulta</b>
                            </TableSortLabel>
                        </OrderButton>
                        <OrderButton align="center">
                            <TableSortLabel onClick={handleSortByData} direction={orderByDate === 'asc' ? 'desc' : 'asc'}>
                                <b>Data</b>
                            </TableSortLabel>
                        </OrderButton>
                        <OrderButton align="center">
                            <TableSortLabel onClick={handleSortByHora} direction={orderByHora === 'asc' ? 'desc' : 'asc'}>
                                <b>Hora</b>
                            </TableSortLabel>
                        </OrderButton>
                        <TableCell align="center">
                            <b>Ações</b>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {allFichas
                        .filter((ficha) =>
                            selectedFicha
                                ? String(ficha.id).includes(selectedFicha.id) ||
                                  ficha?.paciente?.nome_completo.includes(selectedFicha?.paciente?.nome_completo)
                                : true
                        )
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((item) => (
                            <TableRow key={item.id}>
                                <TableCell>{item.id}</TableCell>
                                <TableCell>{item?.procedimento_externo ? 'P.E' : 'F.A'}</TableCell>
                                <TableCell align="center">{item?.paciente.nome_completo}</TableCell>
                                <TableCell align="center">{item?.tipo_consultum?.descricao}</TableCell>
                                <TableCell align="center">{formateDate(item.data_atendimento)}</TableCell>
                                <TableCell align="center">{item.hora_atendimento}</TableCell>
                                <TableCell align="center">
                                    <Link to={`/main/laboratorio/ficha/${item?.paciente?.id}/prontuario/${item.id}`}>
                                        <Tooltip title="Incluir Exames">
                                            <IconButton>
                                                <IconFileInvoice />
                                            </IconButton>
                                        </Tooltip>
                                    </Link>
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ExamesAtendimentoTabela;
