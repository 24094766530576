import { Autocomplete, FormControl, MenuItem, TextField, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

const NewItemForm = ({
    atualizarCampoItem,
    listAllItens,
    listAllAlmoxarifado,
    listAllLocalizacao,
    item,
    filtredLocations,
    setFiltredLocations,
    lotesItem,
    handleGetLotes,
    isEdit
}) => {
    const [selectedItem, setSelectedItem] = useState(null);

    const handleChangeItem = async (newValue) => {
        // Mostrar apenas as localizações disponíveis pro item
        setFiltredLocations(
            listAllLocalizacao.filter(
                (localizacao) =>
                    localizacao?.almoxarifado_id === newValue?.almoxarifado?.id ||
                    (newValue?.kit_itens && newValue.kit_itens.length > 0 && newValue.kit_itens[0]?.almoxarifado_id)
            )
        );

        atualizarCampoItem('item', newValue?.descricao);
        atualizarCampoItem('item_id', newValue?.id);
        atualizarCampoItem('unidade', newValue?.unidade?.descricao);
        atualizarCampoItem('unidade_id', newValue?.unidade?.id);
        atualizarCampoItem('almoxarifado_id', newValue?.almoxarifado?.id);
        atualizarCampoItem('localizacao_id', newValue?.localizacao?.id);
        atualizarCampoItem('psicotropico', newValue?.psicotropico);

        // Controle de Kits e lotes

        if (newValue?.kit_itens) {
            atualizarCampoItem('kit_itens', [...newValue?.kit_itens]);
        } else {
            const payload = { id: newValue.id, almoxarifado_id: newValue.almoxarifado_id, localizacao_id: newValue.localizacao_id };
            const lotes = await handleGetLotes(payload);
            atualizarCampoItem('disponivel', lotes?.data[0]?.quantidade_disponivel || 0);
        }
    };

    function displayItemName(item) {
        if (isEdit) {
            return `${item.item_id} - ${item.item}`;
        } else {
            return `${item.id} - ${item.descricao}`;
        }
    }

    useEffect(() => {
        if (isEdit && item?.item_id) {
            setSelectedItem(item);
        }
    }, [item]);

    return (
        <Box sx={{ marginTop: '1rem' }}>
            <Box sx={{ width: '100%', display: 'flex', gap: '1em' }}>
                <FormControl fullWidth>
                    <Autocomplete
                        options={listAllItens}
                        getOptionLabel={(item) => displayItemName(item)}
                        value={selectedItem}
                        disabled={isEdit}
                        onChange={(e, newValue) => {
                            handleChangeItem(newValue), setSelectedItem(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} label="Item" />}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <TextField label="UN" value={item?.unidade} disabled InputLabelProps={{ shrink: true }} />
                </FormControl>
            </Box>
            <Box sx={{ display: 'flex', gap: '1em', marginTop: '1em', marginBottom: '1em' }}>
                <FormControl fullWidth>
                    <TextField
                        label="Quantidade"
                        type="number"
                        disabled={isEdit}
                        defaultValue={1}
                        value={item?.qtd}
                        onChange={(e) => atualizarCampoItem('qtd', parseFloat(e.target.value))}
                    />
                </FormControl>
                {item?.disponivel > 0 && !item?.lote && (
                    <FormControl fullWidth>
                        <TextField label="Disponível" type="number" value={item?.disponivel} disabled InputLabelProps={{ shrink: true }} />
                    </FormControl>
                )}
            </Box>
            <Box sx={{ display: 'flex', gap: '1em', marginTop: '1em', marginBottom: '1em' }}>
                <FormControl fullWidth>
                    <TextField
                        label="Almoxarifado"
                        select
                        value={item?.almoxarifado_id}
                        disabled={selectedItem?.kit_itens}
                        onChange={(e) => atualizarCampoItem('almoxarifado_id', e.target.value)}
                        InputLabelProps={{ shrink: true }}
                    >
                        {listAllAlmoxarifado.map((almoxarifado) => (
                            <MenuItem key={almoxarifado.id} value={almoxarifado.id}>
                                {almoxarifado.descricao}
                            </MenuItem>
                        ))}
                    </TextField>
                </FormControl>
                <FormControl fullWidth>
                    <TextField
                        label="Localização"
                        disabled={selectedItem?.kit_itens}
                        select
                        value={item.localizacao_id}
                        onChange={(e) => atualizarCampoItem('localizacao_id', e.target.value)}
                        InputLabelProps={{ shrink: true }}
                    >
                        {filtredLocations.map((localizacao) => (
                            <MenuItem key={localizacao.id} value={localizacao.id}>
                                {localizacao.descricao}
                            </MenuItem>
                        ))}
                    </TextField>
                </FormControl>
            </Box>
            <Box sx={{ display: 'flex', gap: '1em', marginTop: '1em', marginBottom: '1em' }}>
                <FormControl fullWidth>
                    <TextField
                        label="Observação"
                        disabled={selectedItem?.kit_itens}
                        value={item.observacao}
                        onChange={(e) => atualizarCampoItem('observacao', e.target.value)}
                    />
                </FormControl>
            </Box>
            {item?.psicotropico && (
                <Box sx={{ display: 'flex', gap: '1em' }}>
                    <FormControl fullWidth>
                        <TextField label="Lote" select value={item.lote} onChange={(e) => atualizarCampoItem('lote', e.target.value)}>
                            {lotesItem.length > 0 ? (
                                lotesItem
                                    .filter((lote) => lote.quantidade_disponivel > 0)
                                    .map((lote) => (
                                        <MenuItem key={lote.lote} value={lote.lote}>
                                            {`${lote.lote} = ${lote.quantidade_disponivel}`}
                                        </MenuItem>
                                    ))
                            ) : (
                                <MenuItem>Sem lotes disponíveis para esta localização e almoxarifado</MenuItem>
                            )}
                        </TextField>
                    </FormControl>
                </Box>
            )}
        </Box>
    );
};

export default NewItemForm;
