import React from 'react';
import { CustomModal } from './style';
import { ReactComponent as CloseModal } from '../../assets/closeModalDelete.svg';
import { ReactComponent as ChargeIcon } from '../../assets/icon-modal.svg';
import { Stepper, Step, StepLabel, Box, Button, TableContainer, Table, TableBody, TableRow, TableCell } from '@mui/material';
import { useState } from 'react';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';

const ModalPacientDetails = ({ open, onClose, paciente, convenio }) => {
    const api = useApi();

    const [etapa, setEtapa] = useState(0);
    const maxEtapa = 3;

    const handleProximaEtapa = () => {
        setEtapa((prevEtapa) => prevEtapa + 1);
    };

    const handleEtapaAnterior = () => {
        setEtapa((prevEtapa) => prevEtapa - 1);
    };
    const handleClose = () => onClose(false);

    return (
        <CustomModal open={open} onClose={onClose}>
            <div className="modal">
                {etapa === 0 && (
                    <>
                        <div className="header">
                            <div className="header">
                                <ChargeIcon />
                                <p>Dados do Paciente</p>
                            </div>
                            <div className="close-button">
                                <CloseModal onClick={handleClose} />
                            </div>
                        </div>
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <h3>Nome</h3>
                                        </TableCell>
                                        <TableCell>
                                            <span>{paciente?.nome_completo}</span>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <h3>Nome Social</h3>
                                        </TableCell>
                                        <TableCell>
                                            <span>{paciente?.nome_social}</span>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <h3>Sexo</h3>
                                        </TableCell>
                                        <TableCell>
                                            <span>{paciente?.sexo === 3 ? 'outros' : paciente?.sexo === 1 ? 'masculino' : 'feminino'}</span>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <h3>Cor</h3>
                                        </TableCell>
                                        <TableCell>
                                            <span>{paciente?.cor}</span>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <h3>Estado Civil</h3>
                                        </TableCell>
                                        <TableCell>
                                            <span>{paciente?.estado_civil}</span>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <h3>Data de Nascimento</h3>
                                        </TableCell>
                                        <TableCell>
                                            <span>{paciente?.data_nascimento}</span>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <h3>Telefone</h3>
                                        </TableCell>
                                        <TableCell>
                                            <span>{paciente?.telefone}</span>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <h3>Nome da Mãe</h3>
                                        </TableCell>
                                        <TableCell>
                                            <span>{paciente?.nome_mae}</span>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <h3>Nome do Pai</h3>
                                        </TableCell>
                                        <TableCell>
                                            <span>{paciente?.nome_pai}</span>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                )}
                {etapa === 1 && (
                    <>
                        <div className="header">
                            <div className="header">
                                <ChargeIcon />
                                <p>Documentos</p>
                            </div>
                            <div className="close-button">
                                <CloseModal onClick={handleClose} />
                            </div>
                        </div>
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <h3>CPF</h3>
                                        </TableCell>
                                        <TableCell>
                                            <span>{paciente?.cpf}</span>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <h3>RG</h3>
                                        </TableCell>
                                        <TableCell>
                                            <span>{paciente?.rg}</span>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <h3>Cartão do Sus</h3>
                                        </TableCell>
                                        <TableCell>
                                            <span>{paciente?.cartao_sus}</span>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                )}
                {etapa === 2 && (
                    <>
                        <div className="header">
                            <div className="header">
                                <ChargeIcon />
                                <p>Endereço</p>
                            </div>
                            <div className="close-button">
                                <CloseModal onClick={handleClose} />
                            </div>
                        </div>
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <h3>Naturalidade</h3>
                                        </TableCell>
                                        <TableCell>
                                            <span>{paciente?.naturalidade}</span>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <h3>CEP</h3>
                                        </TableCell>
                                        <TableCell>
                                            <span>{paciente?.cep}</span>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <h3>Tipo de Logradouro</h3>
                                        </TableCell>
                                        <TableCell>
                                            <span>{paciente?.tipo_logradouro}</span>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <h3>Endereço</h3>
                                        </TableCell>
                                        <TableCell>
                                            <span>{paciente?.endereco}</span>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <h3>Número</h3>
                                        </TableCell>
                                        <TableCell>
                                            <span>{paciente?.numero}</span>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <h3>Bairro</h3>
                                        </TableCell>
                                        <TableCell>
                                            <span>{paciente?.bairro}</span>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <h3>Cidade</h3>
                                        </TableCell>
                                        <TableCell>
                                            <span>{paciente?.cidade}</span>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <h3>Estado</h3>
                                        </TableCell>
                                        <TableCell>
                                            <span>{paciente?.estado}</span>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <h3>Código IBGE</h3>
                                        </TableCell>
                                        <TableCell>
                                            <span>{paciente?.ibge}</span>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                )}
                {etapa === 3 && (
                    <>
                        <div className="header">
                            <div className="header">
                                <ChargeIcon />
                                <p>Outros</p>
                            </div>
                            <div className="close-button">
                                <CloseModal onClick={handleClose} />
                            </div>
                        </div>
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <h3>Convênio</h3>
                                        </TableCell>
                                        <TableCell>
                                            <span>{convenio?.descricao}</span>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <h3>Óbito</h3>
                                        </TableCell>
                                        <TableCell>
                                            <span>{paciente?.obito === false ? 'Não' : 'Sim'}</span>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                )}
                <Box sx={{ mt: 2, width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    {etapa > 0 && (
                        <Button type="button" onClick={handleEtapaAnterior}>
                            Voltar
                        </Button>
                    )}

                    {etapa <= 2 && (
                        <Button type="button" onClick={handleProximaEtapa} sx={{ marginLeft: '85%' }}>
                            Próximo
                        </Button>
                    )}
                    {/* {etapa === 3 && <Button type="submit">Enviar</Button>} */}
                </Box>
                <Stepper activeStep={etapa} alternativeLabel sx={{ marginBottom: '3em' }}>
                    <Step>
                        <StepLabel>Informações Pessoais</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Documentos</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Endereço</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Outros</StepLabel>
                    </Step>
                </Stepper>
            </div>
        </CustomModal>
    );
};

export default ModalPacientDetails;
