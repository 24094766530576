import { useState } from 'react';

const useChamadas = () => {
    const [chamadas, setChamadas] = useState([]);

    // FAZER CHAMADA A API PARA PEGAR AS CHAMADAS
    const getChamadas = async () => {
        //const response = await api.getAllChamadas();
        //setChamadas(response.data);
    };

    return { chamadas, setChamadas, getChamadas };
};

export default useChamadas;
