import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    MenuItem,
    Select,
    TextField,
    InputLabel
} from '@mui/material';
import { Box, flexbox } from '@mui/system';
import React from 'react';
import { useState } from 'react';

const ModalAdicionarExame = ({
    openModal,
    setOpenModal,
    allExames,
    allMedicos,
    handleSelectExame,
    handleSelectMedico,
    selectedExame,
    selectedMedico,
    handleAddExameToTable
}) => {
    return (
        <Dialog open={openModal}>
            <DialogContent>
                <DialogContentText>Adicionar Exame</DialogContentText>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '300px', marginTop: '1rem', gap: '20px' }}>
                    <FormControl fullWidth>
                        <InputLabel>Qual o Exame?</InputLabel>
                        <Select label="Qual o Exame?" multiple value={selectedExame || ''} onChange={(e) => handleSelectExame(e)}>
                            {allExames.length > 0 &&
                                allExames.map((item) => (
                                    <MenuItem key={item.id} value={item}>
                                        {item.nome}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel>Médico Solicitante</InputLabel>
                        <Select label="Médico Solicitante" value={selectedMedico || ''} onChange={(e) => handleSelectMedico(e)}>
                            {allMedicos.length > 0 &&
                                allMedicos.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item?.info_medico?.nome_completo}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button color="error" onClick={() => setOpenModal(false)}>
                    Cancelar
                </Button>
                <Button variant="contained" onClick={() => handleAddExameToTable(selectedExame)}>
                    Concluir
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ModalAdicionarExame;
