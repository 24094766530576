import { useNavigate } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, Box, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useState } from 'react';
import { useEffect } from 'react';
import { useApi } from 'Service/axios';
import { TableBox } from './style';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import ActionButtonsTabelaKits from 'components/Kits/ActionButtonTabelaKits';

const EntradaEstoqueKits = () => {
    // ============================= INSTÂNCIAS =============================
    const api = useApi();
    const navigate = useNavigate();
    // ============================= STATES =============================
    const [allEntradaKits, setAllEntradaKits] = useState([]);
    const columns = [
        /* { field: 'id', headerName: 'ID', flex: 0.5 }, */
        { field: 'descricao', headerName: 'Descrição', flex: 2 },
        { field: 'apelido', headerName: 'Apelido', flex: 2 },
        {
            field: 'acao',
            headerName: 'AÇÕES',
            flex: 1,
            sortable: false,
            renderCell: (params) => <ActionButtonsTabelaKits row={params.row} getEntradas={getKits} />
        }
    ];

    // ============================= FUNÇÕES =============================

    const handleSelectionModelChange = (newSelection) => {
        setSelectedItems(newSelection);
    };

    const handleNavigate = () => {
        navigate('/main/estoque/nova-entrada/cadastro/kits');
    };

    const getKits = async () => {
        try {
            const response = await api.getKits();

            setAllEntradaKits(response.data);
        } catch (error) {
            toast.error(error.response.data.mensagem);
        }
    };

    useEffect(() => {
        getKits();
    }, []);

    return (
        <MainCard>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '2em'
                }}
            >
                <Typography sx={{ fontSize: '1.125rem', fontWeight: '500' }}>Entrada</Typography>
                <Button variant="contained" size="medium" onClick={handleNavigate} sx={{ height: '40px' }}>
                    Adicionar
                </Button>
            </Box>
            <Grid container spacing={gridSpacing}>
                <TableBox>
                    <DataGrid
                        rows={allEntradaKits}
                        columns={columns}
                        hideFooter={true}
                        onSelectionModelChange={() => handleSelectionModelChange(params.id)}
                    />
                </TableBox>
            </Grid>
        </MainCard>
    );
};

export default EntradaEstoqueKits;
