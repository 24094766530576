import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Autocomplete, Divider, TextField } from '@mui/material';
import { useState, useEffect } from 'react';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px'
};

export function ModalRoom({ getProntuario }) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [row, setRow] = useState([]);
    const [selectedClinic, setSelectedClinic] = useState(null);
    const api = useApi();
    const { prontuarioId } = useParams();

    async function get() {
        try {
            const { data } = await api.getRooms();
            console.log('🚀 ~ get ~ data:', data);
            setRow(data);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    useEffect(() => {
        if (open) {
            get();
        }
    }, [open]);

    async function LinkRoom() {
        if (!selectedClinic) return toast.error('Selecione uma sala');
        try {
            await api.linkRoomToProntuario(prontuarioId, selectedClinic?.id);
            toast.success('Linkado com sucesso');
            handleClose();
            getProntuario();
        } catch (error) {
            console.log('🚀 ~ LinkRoom ~ error:', error);
        }
    }

    return (
        <div>
            <Button onClick={handleOpen} variant="contained">
                Local
            </Button>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <h3 style={{ textAlign: 'center' }}>Local de espera do paciente</h3>
                    <Divider />

                    <Autocomplete
                        options={row}
                        sx={{ margin: '20px' }}
                        getOptionLabel={(option) => option.descricao}
                        onChange={(event, value) => setSelectedClinic(value)}
                        renderInput={(params) => <TextField {...params} label="Local de espera" />}
                    />

                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <Button variant="contained" sx={{ width: '250px' }} onClick={LinkRoom}>
                            Salvar
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
