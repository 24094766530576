import { Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import TabelaPainelChamadas from './components/TabelaPainelChamadas';
import { useEffect } from 'react';
import { useContext } from 'react';
import { WebsocketContext } from 'context/WebsocketContext';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { ChamadaContext } from 'context/ChamadasContext';
import { useApi } from 'Service/axios';
import Sino from '../../../assets/sound.mp3';

const ChamadasPainel2 = () => {
    const sino = new Audio(Sino);
    const api = useApi();
    const [isReady, val, send] = useContext(WebsocketContext);

    const [data, setData] = useState([]);
    const [dataAtual, setDataAtual] = useState(new Date());
    const [pacienteAtual, setPacienteAtual] = useState(null);

    const formatarNumero = (numero) => {
        return numero < 10 ? `0${numero}` : numero;
    };

    const dia = formatarNumero(dataAtual.getDate());
    const mes = formatarNumero(dataAtual.getMonth() + 1);
    const ano = dataAtual.getFullYear();

    // Controle de data e hora
    useEffect(() => {
        const intervalId = setInterval(() => {
            setDataAtual(new Date());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    // Controle dos objetos que chegam do websocket
    // useEffect(() => {
    //     if (val) {
    //         try {
    //             const novoPacienteChamada = JSON.parse(val);
    //             const { ficha, senha, setor, paciente, data, hora, hora_chamada_completa } = novoPacienteChamada;

    //             setData((prev) => {
    //                 const novosCampos = [...prev];

    //                 const pacienteRepetido = novosCampos.find((item) => item.ficha === ficha && item.setor === setor);

    //                 // Caso seja uma segunda chamada do paciente
    //                 // apenas realiza a chamada, mas não acrescenta na tabela
    //                 if (pacienteRepetido) {
    //                     setPacienteAtual(pacienteRepetido);
    //                     sino.play();
    //                 } else {
    //                     // Adiciona o novo item
    //                     if (novosCampos.length >= 5) {
    //                         novosCampos.shift();
    //                     }
    //                     novosCampos.push({ ficha, senha, setor, paciente, data, hora, hora_chamada_completa });
    //                     setPacienteAtual({ ficha, senha, setor, paciente, data, hora, hora_chamada_completa });
    //                     sino.play();
    //                 }

    //                 return novosCampos;
    //             });
    //         } catch (error) {
    //             toast.error('Erro ao realizar chamada:', error);
    //         }
    //     }
    // }, [val]);

    // CONTROLE DA VOZ DO NAVEGADOR
    // useEffect(() => {
    //     if ('speechSynthesis' in window && pacienteAtual?.paciente) {
    //         // Criando um novo objeto SpeechSynthesisUtterance
    //         let msg = new SpeechSynthesisUtterance();

    //         // Configurando o texto que será falado
    //         msg.text = `${pacienteAtual?.paciente}, ${pacienteAtual?.setor}`;

    //         // Falando o texto
    //         window.speechSynthesis.speak(msg);
    //     }
    // }, [pacienteAtual]);

    useEffect(() => {
        if (val) {
            try {
                const novoPacienteChamada = JSON.parse(val);
                const { ficha, senha, setor, paciente, data, hora, hora_chamada_completa } = novoPacienteChamada;

                setData((prev) => {
                    const novosCampos = [...prev];

                    const pacienteRepetido = novosCampos.find((item) => item.ficha === ficha && item.setor === setor);

                    // Caso seja uma segunda chamada do paciente
                    // apenas realiza a chamada, mas não acrescenta na tabela
                    if (pacienteRepetido) {
                        setPacienteAtual(pacienteRepetido);
                        sino.play();
                    } else {
                        // Adiciona o novo item
                        if (novosCampos.length >= 5) {
                            novosCampos.shift();
                        }
                        novosCampos.push({ ficha, senha, setor, paciente, data, hora, hora_chamada_completa });
                        setPacienteAtual({ ficha, senha, setor, paciente, data, hora, hora_chamada_completa });

                        // Toca o som quando uma nova chamada é recebida
                        sino.play();
                    }

                    return novosCampos;
                });
            } catch (error) {
                toast.error('Erro ao realizar chamada:', error);
            }
        }
    }, [val]);

    // // CONTROLE DA VOZ DO NAVEGADOR
    // useEffect(() => {
    //     if ('speechSynthesis' in window && pacienteAtual?.paciente) {
    //         // Criando um novo objeto SpeechSynthesisUtterance
    //         let msg = new SpeechSynthesisUtterance();

    //         // Configurando o texto que será falado
    //         msg.text = `${pacienteAtual?.paciente}, ${pacienteAtual?.setor}`;

    //         // Falando o texto
    //         window.speechSynthesis.speak(msg);
    //     }
    // }, [pacienteAtual]);

    return (
        <Box sx={{ width: '100vw', padding: 0 }}>
            <Box
                sx={{
                    width: '100%',
                    height: '180px',
                    backgroundColor: '#0B3C5D',
                    padding: '20px 40px'
                }}
            >
                <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                    <Typography variant="h4" sx={{ color: '#fff', fontSize: '2rem' }}>
                        {dia}/{mes}/{ano}
                    </Typography>

                    <Typography variant="h4" sx={{ color: '#fff', fontSize: '2rem' }}>
                        {formatarNumero(dataAtual.getHours())}:{formatarNumero(dataAtual.getMinutes())}:
                        {formatarNumero(dataAtual.getSeconds())}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', gap: '20px', width: '90%', margin: '0 auto', marginTop: '-110px' }}>
                <Box
                    sx={{
                        flex: 1,
                        minHeight: '200px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignContent: 'space-between',
                        backgroundColor: '#fafafa',
                        border: '1px solid #000',
                        borderRadius: '6px'
                    }}
                >
                    <Box sx={{ display: 'flex', padding: '1rem', flexDirection: 'column' }}>
                        <Typography variant="h2">PACIENTE:</Typography>
                        <Typography variant="h1" sx={{ fontSize: '3.4rem', color: '#000' }}>
                            {pacienteAtual?.paciente}
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: '550px',
                        minHeight: '200px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignContent: 'space-between',
                        backgroundColor: '#fafafa',
                        border: '1px solid #000',
                        borderRadius: '6px',
                        paddingLeft: '15px'
                    }}
                >
                    <Box sx={{ display: 'flex', padding: '0.5rem', flexDirection: 'column' }}>
                        <Typography variant="h2">LOCAL:</Typography>
                        <Typography variant="h1" sx={{ fontSize: '3.4rem', color: '#000' }}>
                            {pacienteAtual?.setor}
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <Divider sx={{ width: '100%', margin: '1rem auto' }} />
            <Box sx={{ width: '90%', margin: '2rem auto' }}>
                <Typography variant="h3">ÚLTIMAS CHAMADAS</Typography>
                <TabelaPainelChamadas data={data} />
            </Box>
        </Box>
    );
};

export default ChamadasPainel2;
