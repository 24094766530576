// assets
import { IconBellRinging } from '@tabler/icons';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';
const icons = {
    IconBellRinging,
    PersonalVideoIcon
};

const chamadas = {
    id: 'chamadas',
    title: 'Paineis',
    type: 'group',
    children: [
        {
            id: 'espera',
            title: 'Painel Chamadas',
            type: 'item',
            url: '/chamadas/painel',
            icon: icons.IconBellRinging,
            breadcrumbs: false
        },
        {
            id: 'painelEscuta',
            title: 'Painel Escuta',
            type: 'item',
            url: '/escuta/painel',
            icon: icons.PersonalVideoIcon,
            breadcrumbs: false
        },
        {
            id: 'conjunto-paineis',
            title: 'Lista',
            type: 'collapse',
            icon: icons.IconBellRinging,
            children: [
                {
                    id: 'consultaProcedimentosPainel',
                    title: 'Painel Procedimenetos',
                    type: 'item',
                    url: '/chamadas/procedimentos/painel',
                    icon: icons.PersonalVideoIcon,
                    breadcrumbs: false
                },

                {
                    id: 'painelrayx',
                    title: 'Painel Raio-x',
                    type: 'item',
                    url: '/chamadas/atendimento/raio-x/painel',
                    icon: icons.PersonalVideoIcon,
                    breadcrumbs: false
                },
                {
                    id: 'painel-atendimento',
                    title: 'Painel Consultório médico',
                    type: 'item',
                    url: '/chamadas/consultorio/painel',
                    icon: icons.PersonalVideoIcon,
                    breadcrumbs: false
                },
                {
                    id: 'painel-farmacia',
                    title: 'Painel Farmácia',
                    type: 'item',
                    url: '/chamadas/farmacia/painel',
                    icon: icons.PersonalVideoIcon,
                    breadcrumbs: false
                },
                {
                    id: 'painel-laboratorio',
                    title: 'Painel Labortório',
                    type: 'item',
                    url: '/chamadas/laboratorio/painel',
                    icon: icons.PersonalVideoIcon,
                    breadcrumbs: false
                },
                {
                    id: 'painel-enfermagem',
                    title: 'Painel Enfermagem',
                    type: 'item',
                    url: '/chamadas/enfermagem/painel',
                    icon: icons.PersonalVideoIcon,
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default chamadas;
